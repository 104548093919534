import { useEffect, useState } from "react";

import Form from "components/Form";
import { Section, Title, Wrapper } from "components/JoinComponents";
import styled from "styled-components";
// import useCounsels from "hooks/useCounsels";

const DoctorProfileStep1 = ({ register, watch, formState: { errors } }) => {
  const { attachment } = watch();
  const [attachmentName, setAttachmentName] = useState("");

  useEffect(() => {
    if (attachment && attachment.length > 0) {
      if (typeof attachment === "string") {
        setAttachmentName(attachment);
      } else {
        setAttachmentName(attachment[0].name);
      }
    }
  }, [attachment]);

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <Wrapper>
      {/* <Section>
        <Form.Row>
          <Form.Label>
            상담 특징 <span>(300자 이내)</span>
          </Form.Label>
          <Form.Textarea
            maxLength={300}
            placeholder="상담의 특징에 대해 작성해 주세요."
            {...register("counsel_features")}
          />
          {errors?.counsel_features && (
            <Form.Error>{errors?.counsel_features?.message}</Form.Error>
          )}
        </Form.Row>
      </Section> */}

      <Section>
        <Title>상담사 이야기</Title>
        <Form.Row>
          <Form.Label>
            Q. 주요 상담 기법 {/* <span>(200자 이내)</span> */}
          </Form.Label>
          <Form.BorderInput
            // maxLength={200}
            placeholder="A. 상담사의 입력 내용"
            {...register("doctor_stories.0")}
          />
        </Form.Row>

        <Form.Row>
          <Form.Label>
            Q. 이 상담을 추천하는 사람은? {/* <span>(200자 이내)</span> */}
          </Form.Label>
          <Form.BorderInput
            // maxLength={200}
            placeholder="A. 상담사의 입력 내용"
            {...register("doctor_stories.1")}
          />
        </Form.Row>

        <Form.Row>
          <Form.Label>
            Q. 상담을 망설이시는 분들께 한 마디{" "}
            {/* <span>(200자 이내)</span> */}
          </Form.Label>
          <Form.BorderInput
            // maxLength={200}
            placeholder="A. 상담사의 입력 내용"
            {...register("doctor_stories.2")}
          />
        </Form.Row>
      </Section>

      <Section>
        <Title>
          수납 정보 입력<span className="required">*</span>
        </Title>
        <Form.Row>
          <Form.InputField
            label="예금주"
            placeholder="홍길동"
            maxLength={10}
            {...register("account_info.holder", {
              required: { value: true, message: "예금주를 입력하세요." },
            })}
            error={errors?.account_info?.holder?.message}
          />
        </Form.Row>

        <Form.Row>
          <Form.InputField
            label="은행명"
            placeholder="우리은행"
            {...register("account_info.bank", {
              required: { value: true, message: "은행명을 입력하세요." },
            })}
            error={errors?.account_info?.bank?.message}
          />
        </Form.Row>

        <Form.Row>
          <Form.InputField
            label="계좌번호"
            placeholder="000-000-000000"
            {...register("account_info.account_number", {
              required: { value: true, message: "계좌번호를 입력하세요." },
            })}
            error={errors?.account_info?.account_number?.message}
          />
        </Form.Row>
      </Section>

      <Section>
        <Title>기관 정보 입력</Title>
        <Form.Row>
          <Form.InputField
            label="소속기관"
            placeholder="기관명"
            {...register("organization_info.name")}
          />
        </Form.Row>
        <Form.Row>
          <Form.InputField
            label="연락처"
            placeholder="-없이 번호 입력"
            {...register("organization_info.phone_number")}
          />
        </Form.Row>
        <Form.Row>
          <Form.InputField
            label="주소"
            placeholder="지역명"
            {...register("organization_info.address")}
          />
        </Form.Row>

        <Title>
          자격사항 증빙자료 등록<span className="required">*</span>
        </Title>
        <AttachmentInfo>
          <div className="info">
            <p>
              • 미술치료, 상담 관련 자격증, 경력 또는 임상 경력 증명서를 1개의
              파일로 제출해 주세요.
            </p>
            <p>• 형식: pdf, zip</p>
          </div>
          <FileSection>
            <div className="file-selector">
              <input
                type="file"
                id="attachment"
                {...register("attachment", {
                  // validate: {
                  //   isJpgOrPng: (v) => {
                  //     const isValid = /jpeg|png|jpg/.test(v[0].type);
                  //     if (!isValid) {
                  //       alert("대표썸네일은 JPG / PNG 형식만 가능합니다.");
                  //     }
                  //     return isValid;
                  //   },
                  // },
                })}
                style={{ display: "none" }}
              />
              <label htmlFor="attachment">첨부파일 업로드</label>
            </div>
            {attachmentName && (
              <div className="preview">
                <label>첨부파일 :</label>
                {attachmentName.startsWith("http") ? (
                  <a href={attachmentName} target="_blank">
                    다운로드
                  </a>
                ) : (
                  <>{attachmentName}</>
                )}
              </div>
            )}
          </FileSection>
          {/* <button>첨부파일 업로드</button> */}
        </AttachmentInfo>
      </Section>
    </Wrapper>
  );
};
export default DoctorProfileStep1;

const AttachmentInfo = styled.div`
  & > .info {
    width: 100%;
    background: #f8f8f8;
    border-radius: 10px;
    padding: 24px 20px;
    margin-bottom: 22px;

    & > p {
      font: normal normal 400 15px/25px Pretendard;
      letter-spacing: -0.15px;
      color: #6e6e6e;
    }
    & > p + p {
      margin-top: 15px;
    }
  }

  & > button {
    font: normal normal 500 14px/17px Pretendard;
    letter-spacing: -0.21px;
    color: #333333;
    width: 140px;
    height: 31px;
    background: #ffffff;
    border: 1px solid #efefef;
    border-radius: 5px;
  }
`;
const FileSection = styled.div`
  width: 100%;
  & > .preview {
    font: normal normal 400 15px/25px Pretendard;
    letter-spacing: -0.15px;
    color: #6e6e6e;
    margin-top: 20px;

    label {
      margin-right: 16px;
    }
    a {
      text-decoration: underline;
    }
  }
  & > .file-selector {
    & > label {
      padding: 7px 27px;
      background: #ffffff;
      border: 1px solid #efefef;
      border-radius: 5px;
      font: normal normal 500 14px/17px Pretendard;
      letter-spacing: -0.21px;
      color: #333333;
    }
  }
`;
