import styled from "styled-components";

import CheckSVG from "assets/svg/check-blue.svg";

const RefundInfo = () => (
  <Wrapper>
    <Line />
    <Section>
      <label>구매 전 확인!</label>
      <p>
        • 상담권 이용 기간은 결제 후 5일 이내입니다.
        <br />• 기간 내에 이용하지 않은 상담권은 자동 소멸되며 환불이
        불가합니다.
      </p>

      <label>환불 규정</label>
      <p>
        • 상담 일자 확정 전 혹은 예약된 상담 시간 3시간 이전: <b>100% 환불</b>
        <br />• 예약된 상담 시간 3시간 이내: <b>50% 환불</b>
        <br />• 예약된 상담 시간에 접속하지 않은 경우: <b>환불 불가</b>
        <br />• 상담권 이용기간 만료: <b>환불 불가</b>
        <br />• 환불 신청은 내 정보{">"}내 상담 내역{">"}결제 정보에서
        가능합니다.
      </p>
    </Section>
  </Wrapper>
);
export default RefundInfo;

const Wrapper = styled.div``;
const Section = styled.div`
  padding: 20px 15px;
  width: 100%;
  margin-bottom: 60px;
  & > label {
    display: inline-block;
    font: normal normal 600 16px/19px Pretendard;
    letter-spacing: -0.24px;
    color: #101010;
    margin-bottom: 12px;

    &::before {
      content: url(${CheckSVG});
      width: 10px;
      height: 7px;
      padding-right: 8px;
    }
  }
  & > p {
    font: normal normal 400 14px/24px Pretendard;
    letter-spacing: -0.14px;
    color: #6e6e6e;
    & > b {
      font: normal normal 400 14px/17px Pretendard;
      letter-spacing: -0.14px;
      color: #101010;
    }
  }

  & > p + label {
    margin-top: 30px;
  }
`;
const Line = styled.div`
  width: 100%;
  height: 16px;
  background: #f8f8f8 0% 0% no-repeat padding-box;
`;
