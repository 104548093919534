import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import ImageModal from "containers/ImageModal";
import { CallMessage } from "utils/constant";

const MessageBubble = ({
  identity,
  author,
  body,
  attachedMedia,
  dateCreated,
  connectVideoCall,
}) => {
  const [mediaURL, setMediaURL] = useState("");
  const [modalStatus, setModalStatus] = useState(false);
  const isImage = attachedMedia.length > 0;

  const openImageModal = () => {
    setModalStatus(true);
  };

  const changeState = (x) => {
    setModalStatus(x);
  };

  useEffect(() => {
    if (attachedMedia.length > 0) {
      (async () => {
        const url = await attachedMedia[0].getCachedTemporaryUrl();
        setMediaURL(url);
      })();
    }
  }, [attachedMedia]);

  const CallMessageArr = Object.values(CallMessage);

  const MessageBody = ({ message, isMy }) => {
    const filteredMessage = CallMessageArr.filter(
      (content) => message === content.message
    );

    if (filteredMessage.length > 0) {
      const CallIcon = filteredMessage[0].icon;
      return (
        <CallMessageWrapper onClick={connectVideoCall}>
          <CallIcon style={{ marginRight: "8px" }} />
          {message}
        </CallMessageWrapper>
      );
    }

    return (
      <MessageWrapper
        className={
          message.includes("신청하신 상담권은") ||
          message.includes("내담자님이 상담을 원하는 요일과 시간입니다.")
            ? "WelcomeMessage"
            : ""
        }
        isMy={isMy}
      >
        {message}
      </MessageWrapper>
    );
  };

  const isMy = author === identity;

  return (
    <>
      {modalStatus && (
        <ImageModal changeState={changeState} mediaURL={mediaURL} />
      )}
      <Message isMy={isMy}>
        {isMy ? (
          <>
            <DateWrapper>{moment(dateCreated).format("A h:mm")}</DateWrapper>
            {isImage ? (
              <>
                {/* <ImagePreview onClick={openImageModal} mediaURL={mediaURL} /> */}
                <img
                  src={mediaURL}
                  onClick={openImageModal}
                  width={"250px"}
                  style={{
                    backgroundSize: "contain",
                    boxShadow: "0px 0px 10px #00000019",
                    borderRadius: "15px",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "100% 100%",
                    maxHeight: "400px",
                  }}
                />
              </>
            ) : (
              <MessageBody message={body} isMy={isMy} />
            )}
          </>
        ) : (
          <>
            {isImage ? (
              // <ImagePreview onClick={openImageModal} mediaURL={mediaURL} />
              <img
                src={mediaURL}
                onClick={openImageModal}
                width={"250px"}
                style={{
                  backgroundSize: "contain",
                  boxShadow: "0px 0px 10px #00000019",
                  borderRadius: "15px",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100% 100%",
                  maxHeight: "400px",
                }}
              />
            ) : (
              <MessageBody message={body} isMy={isMy} />
            )}
            <DateWrapper>
              {moment(dateCreated).format("M/D A h:mm")}
            </DateWrapper>
          </>
        )}
      </Message>
    </>
  );
};

const Message = styled.div`
  white-space: pre-line;
  display: flex;
  justify-content: ${(p) => (p.isMy ? "flex-end" : "flex-start")};
  align-items: flex-end;
  max-width: calc(100vw - 30px);
`;

const ImagePreview = styled.div`
  width: 175px;
  height: 175px;
  background: ${(p) =>
    `transparent url(${p.mediaURL}) 50% 50% no-repeat padding-box`};
  background-size: contain;
  box-shadow: 0px 0px 10px #00000019;
  border-radius: 15px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
`;

const DateWrapper = styled.span`
  display: inline-block;
  font: normal normal 400 10px/16px Pretendard;
  letter-spacing: -0.13px;
  color: #999999;
  padding-right: 8px;
  padding-left: 8px;
`;

const CallMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  border: 1px solid #e6e6e6;
  padding: 9px 16px;
  border-radius: 15px;
  font: normal normal 400 14px/18px Pretendard;
`;

const MessageWrapper = styled.div`
  width: fit-content;
  background: ${(p) => (p.isMy ? "#91A7FF" : "#F8F8F8")};
  border-radius: 15px;
  font: normal normal 400 14px/18px Pretendard;
  letter-spacing: -0.23px;
  color: ${(p) => (p.isMy ? "#ffffff" : "#333333")};
  padding: 12px;
`;

export default MessageBubble;
