import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as HomeSVG } from "assets/svg/footer-home.svg";
import { ReactComponent as ActiveHomeSVG } from "assets/svg/footer-home-active.svg";
import { ReactComponent as MySVG } from "assets/svg/footer-my.svg";
import { ReactComponent as ActiveMySVG } from "assets/svg/footer-my-active.svg";
import { ReactComponent as MyinfoSVG } from "assets/svg/footer-myinfo.svg";
import { ReactComponent as ActiveMyinfoSVG } from "assets/svg/footer-myinfo-active.svg";
import { ReactComponent as ActiveDoorSVG } from "assets/svg/door-active.svg";
import { ReactComponent as DoorSVG } from "assets/svg/door.svg";

import useNavigate from "hooks/useNavigate";

const Footer = () => {
  const { pathname } = useLocation();
  const { goConsultingRoom, goHome, goMyinfo, goContents } = useNavigate();

  const isRoom = /room/.test(pathname);
  const isMyinfo = /myinfo/.test(pathname);
  const isContents = /contents/.test(pathname);
  const isInfo = /info/.test(pathname);

  const activeMenu =
    isMyinfo || isInfo
      ? "myinfo"
      : isRoom
      ? "room"
      : isContents
      ? "isContents"
      : "home";

  return (
    <Wrapper className="footerWrapper">
      <Menu $active={activeMenu === "home"} onClick={goHome}>
        <div style={{ marginTop: "15px" }}>
          {activeMenu === "home" ? <ActiveHomeSVG /> : <HomeSVG />}
        </div>
        <div>홈</div>
      </Menu>
      <Menu
        $active={activeMenu === "isContents"}
        onClick={goContents}
        id="mindCare"
      >
        <div style={{ marginTop: "15px" }}>
          {activeMenu === "isContents" ? <ActiveMySVG /> : <MySVG />}
        </div>
        <div>마음케어</div>
      </Menu>
      <Menu $active={activeMenu === "room"} onClick={goConsultingRoom}>
        <div style={{ marginTop: "15px" }}>
          {activeMenu === "room" ? <ActiveDoorSVG /> : <DoorSVG />}
        </div>
        <div>내 상담실</div>
      </Menu>
      <Menu
        $active={activeMenu === "myinfo"}
        onClick={pathname === "/myinfo/login" ? undefined : goMyinfo}
      >
        <div style={{ marginTop: "15px" }}>
          {activeMenu === "myinfo" ? <ActiveMyinfoSVG /> : <MyinfoSVG />}
        </div>
        <div>내 정보</div>
      </Menu>
    </Wrapper>
  );
};

export default Footer;

const Wrapper = styled.div`
  position: sticky;
  width: 100%;
  height: 61px;
  background: white 0% 0% no-repeat padding-box;
  box-shadow: 0px -0.5px 0px #0000004d;
  bottom: 0;
  z-index: 999;
  display: flex;
  // gap: 25pt;
  justify-content: center;
  flex-shrink: 0;
  padding-bottom: 61px;
`;

const Menu = styled.div`
  width: 25%;
  height: 61pt;
  text-align: center;
  font: normal normal 500 10px/12px Pretendard;
  letter-spacing: 0px;
  color: ${(p) => (p.$active ? "#485AF3" : "#999999")};
  flex-direction: column;
  justify-content: center;
  gap: 4px;

  svg {
    width: 17px;
    height: 17px;
  }
`;
