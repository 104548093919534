import { useEffect, useState } from "react";
import styled from "styled-components";

import client from "apis/client";
import { fetchMe as fetchClient } from "apis/user";
import { fetchMe as fetchDoctor } from "apis/doctor";
import useAuth from "hooks/useAuth";

const NotificationSettings = () => {
  const [check, setCheck] = useState();
  const { userType } = useAuth();
  const switchBtn = () => {
    setCheck(!check);
  };

  useEffect(() => {
    if (userType === "client") {
      try {
        fetchClient().then((res) => {
          setCheck(res.data.push);
        });
      } catch (e) {
        alert("에러가 발생했습니다.");
        console.log(e);
        return;
      }
    } else if (userType === "doctor") {
      try {
        fetchDoctor().then((res) => {
          setCheck(res.data.push);
        });
      } catch (e) {
        alert("에러가 발생했습니다.");
        console.log(e);
        return;
      }
    }
  }, []);

  useEffect(() => {
    if (check === undefined) return;
    if (userType === "client") {
      client.post("/user/push/enable", { enable: check }).then((res) => {});
    } else if (userType === "doctor") {
      client.post("/doctor/push/enable", { enable: check }).then((res) => {});
    }
  }, [check]);

  return (
    <Wrapper className="mainWrapper">
      <Body className="body">
        <HeadText>Push 알림 허용</HeadText>
        <BodyText>
          알림을 해제하시면 새로운 메시지나 상담 예약에 <br />
          대한 알림을 받으실 수 없습니다.
        </BodyText>
        {check === undefined ? (
          <></>
        ) : (
          <>
            {" "}
            <input
              checked={check}
              onChange={switchBtn}
              className="switch-checkbox"
              id={`switch-new`}
              type="checkbox"
            />
            <label
              className="switch-label"
              htmlFor={`switch-new`}
              style={{ background: check && "#30D259" }}
            >
              <span className="switch-button" />
            </label>
          </>
        )}
      </Body>
    </Wrapper>
  );
};

export default NotificationSettings;

const Wrapper = styled.div`
  padding-top: 36px;
  padding-left: 15px;
  padding-right: 15px;
  height: 100%;
  overflow-y: auto;
`;

const Body = styled.div`
  height: 100%;
  overflow-y: auto;
  flex-grow: 1;
`;

const HeadText = styled.div`
  font: normal normal 700 11pt Pretendard;
`;

const BodyText = styled.div`
  margin-top: 5.7pt;
  font: normal normal 400 9pt Pretendard;
  line-height: 14pt;
  color: #6e6e6e;
  display: inline-block;
`;
