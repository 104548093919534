import { useEffect, useState } from "react";

import client from "apis/client";

const useCreditCards = () => {
  const [creditCards, setCreditCards] = useState([]);

  const fetch = async () => {
    const {
      data: { credit_cards },
    } = await client.get("/user/credit_cards");
    setCreditCards(credit_cards);
  };

  useEffect(() => {
    fetch();
  }, []);

  return { creditCards, availblePayment: creditCards.length > 0, fetch };
};
export default useCreditCards;
