import { useEffect, useState } from "react";
import {
  Outlet,
  useNavigate as useRouterNavigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import styled from "styled-components";
import Header from "components/Header";
import Footer from "../Footer";
import useAuth from "hooks/useAuth";
import { useRecoilState } from "recoil";

import { beforeSteps, examineStep, friendlyName } from "atomStates";
import { ReactComponent as X } from "assets/svg/x.svg";

const getInfoPageTitle = (infoType) => {
  switch (infoType) {
    case "usepolicy":
      return "서비스 이용약관";
    case "privacy":
      return "개인정보 처리방침";
    case "health_privacy":
      return "건강정보 처리방침";
    case "refund":
      return "환불 규정";
    default:
      return "사업자 정보";
  }
};
const SubPageLayout = (conversation) => {
  const [friendly, setFriendly] = useRecoilState(friendlyName);
  const [user, setUser] = useState();
  const [doctors, setDoctors] = useState();
  const [step, setStep] = useRecoilState(examineStep);
  const [beforeStep, setBeforeStep] = useRecoilState(beforeSteps);

  const { userType } = useAuth();
  const { pathname } = useLocation();
  const navigate = useRouterNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const isDoctorDetail = /doctor/.test(pathname) && params.hasOwnProperty("id");
  const isInfo = /info/.test(pathname);
  const isJoin = /join/.test(pathname);
  const isContents = /contents/.test(pathname);
  const isExamine = /contents\/examine/.test(pathname);
  const isStep = /contents\/examine\/step/.test(pathname);
  const isExamineResults = /contents\/examine\/results/.test(pathname);
  const isNotification = /notification/.test(pathname);
  const isDoctor = /doctor/.test(pathname);
  // const isDoctorJoin =
  //   /join/.test(pathname) && params.hasOwnProperty("userType");
  const isDoctorJoinRequest = /join\/doctor-request/.test(pathname);
  const isReservation = /reservation/.test(pathname);
  const isReviewForm = /review/.test(pathname);
  const isConsultingRoom = /room/.test(pathname);
  const isRoom = isConsultingRoom && params.hasOwnProperty("roomId");
  const isPalette = /palette/.test(pathname);
  const isPasswordReset = /reset/.test(pathname);
  const showHeader = !isPalette;
  const showFooter =
    !isDoctorDetail &&
    !isRoom &&
    !isDoctorJoinRequest &&
    !isJoin &&
    !isPasswordReset;
  const termType = searchParams.get("termType");

  useEffect(() => {
    window.scrollTo(0, 0);

    var isMobile =
      /iPad|iPhone|iPod|Android/.test(window.navigator.userAgent) &&
      !window.MSStream;

    if (isMobile) {
      var originalHeight;
      var isKeyboardOpen = false;

      var handleInput = function () {
        var currentHeight = window.innerHeight;

        if (currentHeight < originalHeight && !isKeyboardOpen) {
          document.getElementById("header").style.position = "absolute";
          document.getElementById("header").style.top = "0";
          isKeyboardOpen = true;
        } else if (currentHeight >= originalHeight && isKeyboardOpen) {
          document.getElementById("header").style.position = "fixed";
          document.getElementById("header").style.top = "";
          isKeyboardOpen = false;
        }
      };

      window.addEventListener("input", handleInput);

      return () => {
        window.removeEventListener("input", handleInput);
      };
    }
  }, []);

  const referrer = document.referrer;
  useEffect(() => {
    setDoctors("");
    setUser("");
    if (conversation.conversation === null) {
      setUser(friendly.split("-")[1]);
      setDoctors(friendly.split("-")[0]);
    } else {
      if (conversation.conversation !== undefined) {
        setUser(conversation.conversation.friendlyName.split("-")[1]);
        setDoctors(conversation.conversation.friendlyName.split("-")[0]);
      }
    }
  }, [conversation, friendly]);

  return (
    <Wrapper className="mainWrapper">
      {showHeader && (
        <Header className="header headerWrapper">
          {/* <Header.GoBackButton onClick={() => navigate(-1)} /> */}
          {(isConsultingRoom && !isRoom) || (isContents && !isExamine) ? (
            <></>
          ) : isConsultingRoom && isRoom ? (
            <Header.GoBackButton
              onClick={() => navigate("/room")}
              onTouchStart={() => navigate("/room")}
            />
          ) : (
            <Header.GoBackButton
              onClick={() =>
                referrer.indexOf("/myinfo/login") != -1
                  ? navigate("/")
                  : navigate(-1)
              }
            />
          )}

          {/* <Header.GoBackButton
            onClick={() =>
              isConsultingRoom
                ? isRoom
                  ? window.location.replace(`/room`)
                  : navigate(-2)
                : navigate(-1)
            }
          /> */}
          <div style={{ paddingTop: "26px" }}>
            {isReservation
              ? /reservation\/$d./.test(pathname)
                ? ""
                : "상담 신청하기"
              : isJoin
              ? isDoctorJoinRequest
                ? "가입 요청"
                : `${userType === "doctor" ? "상담사 " : ""}회원가입`
              : isDoctorDetail
              ? isReviewForm
                ? "후기 작성"
                : "상담사 프로필"
              : isConsultingRoom
              ? isRoom
                ? `${
                    userType === "doctor"
                      ? `${user !== undefined ? user : ""}`
                      : `${doctors !== undefined ? doctors : ""}`
                  }`
                : "내 상담실"
              : isInfo
              ? getInfoPageTitle(termType)
              : isPasswordReset
              ? "비밀번호 찾기"
              : isDoctor
              ? "상담사 목록"
              : isContents && !isExamine
              ? "마음케어"
              : isNotification
              ? "알림"
              : isExamineResults
              ? "테스트 결과"
              : isExamine
              ? "미술 심리 테스트"
              : ""}
          </div>
          {isStep ? (
            <ExamineStopButton
              onClick={() => {
                setBeforeStep(step);
                setStep(0);
              }}
            >
              <X width={12} height={12} />
            </ExamineStopButton>
          ) : (
            ""
          )}
        </Header>
      )}
      <Body>
        <Outlet />
      </Body>
      {showFooter && <Footer />}
    </Wrapper>
  );
};

export default SubPageLayout;

const Wrapper = styled.div`
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Body = styled.div`
  flex-grow: 1;
  // padding-top: 50px;
  height: 100%;
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
`;

const ExamineStopButton = styled.button`
  position: absolute;
  right: 0;
  padding: 20px 15px;
  top: 9px;
  
  &:hover {
    background: none;
  }
}
`;
