import axios from "axios";

import { ERROR_MESSAGE_KEY, isError } from "apis/errorCode";

const handleFailedData = (resp) => {
  const { data } = resp;

  if (isError(data)) {
    return Promise.reject(new Error(data[ERROR_MESSAGE_KEY]));
  }

  return resp;
};

const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 180000,
  withCredentials: true,
});

client.interceptors.response.use(handleFailedData);

export default client;
