import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { ReactComponent as ArrowSVG } from "assets/svg/chevron-left.svg";
import { useRecoilState } from "recoil";
import { examineStep, paymentState } from "atomStates";
import useNavigate from "hooks/useNavigate";

const GoBackButton = ({ onClick, onTouchStart }) => {
  const { goExamine } = useNavigate();
  const [step, setStep] = useRecoilState(examineStep);
  const [paymentStates, setPaymentStates] = useRecoilState(paymentState);
  const { pathname } = useLocation();

  return (
    <>
      {paymentStates === "success" ? (
        <></>
      ) : (
        <>
          <Wrapper
            onClick={() => {
              if (
                /contents\/examine\/step/.test(pathname) === true &&
                step !== 1
              ) {
                setStep(step - 1);
              } else {
                if (/contents\/examine\/results/.test(pathname) === true) {
                  goExamine();
                } else {
                  onClick();
                }
              }
            }}
            onTouchStart={onTouchStart}
          >
            <ArrowSVG />
          </Wrapper>
        </>
      )}
    </>
  );
};
export default GoBackButton;

const Wrapper = styled.div`
  position: fixed;
  padding: 23px 15px;
  \ & > svg {
    & > path {
      stroke: #404040;
    }
  }
`;
