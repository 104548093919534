import styled from "styled-components";

import Drawer from "./";
import { Button, DrawerBody, DrawerFooter } from "./Comps";

import { ReactComponent as FailSVG } from "assets/svg/x.svg";

const FailRegisterPayment = ({ isShow, onClose, text }) => (
  <Drawer isShow={isShow}>
    <Wrapper>
      <Icon>
        <FailSVG />
      </Icon>

      <h1>카드 등록 실패</h1>
      <p>{text}</p>
    </Wrapper>
    <Footer>
      <Button onClick={onClose}>확인</Button>
    </Footer>
  </Drawer>
);
export default FailRegisterPayment;

const Wrapper = styled(DrawerBody)`
  h1 {
    font: normal normal bold 25px/30px Pretendard;
    letter-spacing: -0.25px;
    color: #101010;
    margin-bottom: 40px;
  }

  p {
    font: normal normal 400 15px/18px Pretendard;
    letter-spacing: -0.23px;
    color: #404040;
  }
`;
const Footer = styled(DrawerFooter)`
  padding-bottom: 50px;
`;

const Icon = styled.div`
  width: 34px;
  height: 34px;
  background: #e6e6e6;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
`;
