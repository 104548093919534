import styled from "styled-components";

const Drawer = ({ isShow, children }) => (
  <Wrapper className="consultation_date" $show={isShow}>
    <div className="drawer-mask" />
    <div className="drawer-content-wrapper">
      <div className="drawer-content">{children}</div>
    </div>
  </Wrapper>
);
export default Drawer;

const Wrapper = styled.div`
  display: ${(p) => (p.$show ? "block" : "none")};
  position: fixed;
  inset: 0;
  z-index: 1000;
  pointer-events: none;

  & > .drawer-mask {
    position: absolute;
    inset: 0;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.45);
    pointer-events: auto;
  }

  & > .drawer-content-wrapper {
    width: 100%;
    left: 0;
    bottom: 0;
    box-shadow: -6px 0 16px -8px #00000014, -9px 0 28px #0000000d,
      -12px 0 48px 16px #00000008;
    position: absolute;
    z-index: 1000;
    transition: all 0.3s;

    & > .drawer-content {
      width: 100%;
      display: relative;
      /* height: 100%; */
      min-height: 419px;
      overflow: auto;
      background: #fff;
      pointer-events: auto;
      border-radius: 15px 15px 0px 0px;
    }
  }
`;
