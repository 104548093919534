const ERROR_CODE = [
  4000, 4001, 4010, 4020, 4040, 11001, 12001, 12003, 12004, 100000, 12002,
];

export const ERROR_CODE_KEY = 'code';
export const ERROR_MESSAGE_KEY = 'message';

export const isError = (resp) =>
  resp.hasOwnProperty(ERROR_CODE_KEY) &&
  resp.hasOwnProperty(ERROR_MESSAGE_KEY) &&
  ERROR_CODE.includes(resp[ERROR_CODE_KEY]);
