import styled from "styled-components";
import { fetchNotify } from "apis/notification";
import { useEffect, useState } from "react";
import moment from "moment";
import useAuth from "hooks/useAuth";

const Notification = () => {
  const [page, setPage] = useState(1);
  const [notify, setNotify] = useState([]);
  const { userType } = useAuth();
  useEffect(() => {
    fetchNotify(page, userType).then((res) => {
      console.log(res.data);
      setNotify(res.data);
    });
  }, [fetchNotify]);

  return (
    <Wrapper className="mainWrapper">
      <Body className="body">
        {Object.keys(notify).length > 0 ? (
          notify.map((notify) => (
            <Card>
              <Title>{notify.title}</Title>
              <Contents>{notify.body}</Contents>
              <Date>{moment(notify.created_at).format("MMMM Do, h:mm a")}</Date>
            </Card>
          ))
        ) : (
          <div style={{ textAlign: "center", height: "100%", display: "flex" }}>
            <div
              style={{
                color: "#999999",
                fontSize: "15px",
                width: "100%",
                margin: "auto 0",
              }}
            >
              예약 현황을 확인할 수 있어요.
            </div>
          </div>
        )}
      </Body>
    </Wrapper>
  );
};

export default Notification;

const Wrapper = styled.div`
  padding-top: 68px;
  padding-bottom: 61px;
  height: 100%;
  overflow-y: auto;
`;

const Body = styled.div`
  height: 100%;
  overflow-y: auto;
  flex-grow: 1;
`;

const Card = styled.div`
  border-top: solid 1px #e6e6e6;
  padding: 18px 15px;
`;
const Title = styled.div`
  font: normal normal 700 17px/24px Pretendard;
  margin-bottom: 3px;
`;
const Contents = styled.div`
  font: normal normal 500 14px/24px Pretendard;
  color: #6e6e6e;
  letter-spacing: -0.2px;
  margin-bottom: 1.5px;
`;
const Date = styled.div`
  font: normal normal 500 14px/24px Pretendard;
  color: #9f9f9f;
  letter-spacing: -0.3px;
`;
