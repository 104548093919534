import "react-calendar/dist/Calendar.css"; // css import

import { useEffect, useState } from "react";
import styled from "styled-components";

import Drawer from "./";
import { Button, DrawerBody, DrawerFooter } from "./Comps";

import moment from "moment";
import Calendar from "react-calendar";

const DateTimeConfirm = ({
  isShow,
  baseDate,
  baseTime,
  onConfirm,
  onClose,
}) => {
  const times = baseTime.length > 0 ? baseTime[0].split("-") : ["09"];

  const [confirmDate, setConfirmDate] = useState(baseDate);
  const [confirmHour, setConfirmHour] = useState(times[0]);
  const [confirmMinutes, setConfirmMinutes] = useState("00");
  const [calState, setCalState] = useState(false);

  const [value, setValue] = useState(new Date());

  const handleConfirm = async () => {
    await onConfirm(`${confirmDate} ${confirmHour}:${confirmMinutes}`);
    window.location.reload();
  };

  useEffect(() => {
    setConfirmDate(baseDate);
  }, [baseDate]);

  return (
    <DrawerWrapper>
      <Drawer isShow={isShow}>
        <Wrapper>
          <Title>상담 시간 선택</Title>
          {calState ? (
            <div>
              <Calendar
                formatDay={(locale, date) => moment(date).format("DD")}
                minDetail="month"
                maxDetail="month"
                navigationLabel={null}
                showNeighboringMonth={false}
                onChange={(e) => {
                  setConfirmDate(moment(e).format("YYYY-MM-DD"));
                  setValue(e);
                  setCalState(false);
                }}
                value={value}
              />
            </div>
          ) : (
            <></>
          )}

          <FieldRow>
            <div
              style={{
                width: "100px",
                height: "48px",
                background: "#f8f8f8",
                borderRadius: "5px",
                font: "normal normal 500 15px/18px Pretendard",
                letterSpacing: "-0.23px",
                color: "#101010",
                paddingTop: "16px",
                textAlign: "center",
                cursor: "pointer",
              }}
            >
              <div onClick={() => setCalState(true)}>
                {moment(value).format("YYYY-MM-DD")}
              </div>
            </div>
            <Select
              value={confirmHour}
              onChange={(e) => setConfirmHour(e.target.value)}
            >
              {[...Array(24).keys()].map((i) => (
                <option key={i} value={String(i).padStart(2, "0")}>
                  {String(i).padStart(2, "0")}
                </option>
              ))}
            </Select>
            <Select onChange={(e) => setConfirmMinutes(e.target.value)}>
              {[...Array(6).keys()].map((i) => (
                <option key={i} value={String(i).padEnd(2, "0")}>
                  {String(i).padEnd(2, "0")}
                </option>
              ))}
            </Select>
          </FieldRow>
        </Wrapper>
        <Footer>
          <Button onClick={handleConfirm}>확인</Button>
          <CancelButton
            onClick={onClose}
            style={{
              width: "271px",
              height: "48px",
              background: "#E6E6E6",
              borderRadius: "10px",
              color: "#6E6E6E",
              font: "normal normal 400 16px/19px Pretendard",
            }}
          >
            취소
          </CancelButton>
        </Footer>
      </Drawer>
    </DrawerWrapper>
  );
};
export default DateTimeConfirm;

const DrawerWrapper = styled.div`
  .drawer-content {
    min-height: 319px !important;
  }
`;

const Wrapper = styled(DrawerBody)`
  align-items: flex-start;
  padding: 34px;
`;
const Footer = styled(DrawerFooter)`
  padding-bottom: 50px;
`;
const Title = styled.div`
  width: 100%;
  padding-bottom: 16px;
  font: normal normal bold 17px/20px Pretendard;
  letter-spacing: -0.17px;
  color: #101010;
  border-bottom: 1px solid #e6e6e6;
`;
const CancelButton = styled(Button)`
  background: #ffffff;
  color: #333333;
  margin-top: 6px;
`;
const FieldRow = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  margin-top: 16px;
  margin-left: 28px;
  display: relative;
`;
const Select = styled.select`
  width: 84px;
  height: 48px;
  background: #f8f8f8;
  border-radius: 5px;
  font: normal normal 500 15px/18px Pretendard;
  letter-spacing: -0.23px;
  color: #101010;
`;
