import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import CheckSVG from "assets/svg/check.svg";

import useNavigate from "hooks/useNavigate";

const PartnerSuccess = () => {
  const { goHome } = useNavigate();
  const [searchParams] = useSearchParams();
  const partner = searchParams.get("partner");
  const name = searchParams.get("name");
  const email = searchParams.get("email");

  return (
    <Wrapper>
      <Icon />
      <Title>제휴 기관 인증 완료</Title>
      <Message>제휴 기관의 인증이 모두 완료되었습니다.</Message>

      <PartnerInfo>
        <div>
          <label>제휴기관</label>
          <span>{partner}</span>
        </div>
        <div>
          <label>닉네임</label>
          <span>{name}</span>
        </div>
        {/* <div>
          <label>이메일</label>
          <span>{email}</span>
        </div> */}
      </PartnerInfo>

      <Button onClick={goHome}>홈으로</Button>
    </Wrapper>
  );
};
export default PartnerSuccess;

const Wrapper = styled.section`
  height: 100%;
  padding: 0 15px;
  padding-top: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Icon = styled.div`
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background: #485af3;
  background-image: url(${CheckSVG});
  background-repeat: no-repeat;
  background-position: center;
`;
const Title = styled.div`
  font: normal normal bold 24px/29px Pretendard;
  letter-spacing: -0.24px;
  color: #101010;
  margin-bottom: 12px;
`;
const Message = styled.div`
  font: normal normal 500 15px/18px Pretendard;
  letter-spacing: -0.23px;
  color: #404040;
  margin-bottom: 48px;
`;
const PartnerInfo = styled.div`
  width: 100%;
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 15px;
  padding: 29px 44px;
  margin-bottom: 248px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  label {
    display: inline-block;
    width: 51px;
    font: normal normal 400 15px/18px Pretendard;
    letter-spacing: -0.3px;
    color: #999999;
    margin-right: 16px;
  }
  span {
    font: normal normal 500 15px/18px Pretendard;
    letter-spacing: -0.15px;
    color: #101010;
  }
`;
const Button = styled.button`
  width: 271px;
  height: 48px;
  background: #333333;
  border-radius: 10px;
  font: normal normal 500 16px/19px Pretendard;
  letter-spacing: -0.16px;
  color: #ffffff;
`;
