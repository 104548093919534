import client from "./client";

const DOCTOR_PAGE_LENGTH = 10;

/**
 * 상담과목 조회
 */
export const fetchCounsels = () => client.get("/counsels");

/**
 * 회원 가입(치료상담사)
 */
export const signup = (params) => client.post("/doctor", params);

/**
 * 회원 가입(치료상담사) - step2
 */
export const signEdit = (params) =>
  client.put("/doctor", params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

/**
 * 상담사 목록 조회
 */
export const fetchDoctors = (page, counselId, counselKind, order, query) =>
  client.get(
    `/doctor?page=${page}&per=${DOCTOR_PAGE_LENGTH}${
      counselId ? `&counsel_ids=${[counselId]}` : ""
    }${
      counselKind ? `&search_counsel_kind=${counselKind}` : ""
    }${`&order=${order}`}${query ? `&search_text=${query}` : ""}`
  );
/**
 * 내 정보(상담상담사)
 */
export const fetchMe = () => client.get("/doctor/me");
