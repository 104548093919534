import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { DOCTOR_PATH } from "App";
import HomeSubSection from "components/HomeSubSection";
import CounselorOverview from "components/CounselorOverview";
import useDoctors from "hooks/useDoctors";
import useIntersect from "hooks/useIntersect";
import { useEffect, useState } from "react";

const CounselorList = ({ counselKind }) => {
  const navigate = useNavigate();
  const { doctors, isFetching, hasNextPage, setNextPage } = useDoctors(
    "",
    counselKind,
    ""
  );

  const moveDoctorList = () => navigate(`${DOCTOR_PATH}`);
  const moveDoctorDetail = (id) => navigate(`${DOCTOR_PATH}/${id}`);

  const scrollRef = useIntersect(async (entry, observer) => {
    observer.unobserve(entry.target);
    if (hasNextPage && !isFetching) {
      setNextPage();
    }
  });

  const [checked, setChecked] = useState("전체");
  const [filterDoctors, setFilterDoctors] = useState([]);

  useEffect(() => {
    if (doctors.length <= 0) return;
    setFilterDoctors([]);
    doctors.map((doctor) => {
      if (checked === "전체") {
        setFilterDoctors((prev) => [...prev, doctor]);
      } else {
        // 백앤드 도움 없이 하드코딩 요청 건
        if (
          doctor.doctor_type === checked ||
          doctor.name === "박은미" ||
          doctor.name === "이민경" ||
          doctor.name === "김가현"
        ) {
          setFilterDoctors((prev) => [...prev, doctor]);
        }
      }
    });
  }, [doctors, checked]);

  return (
    <HomeDoctorList>
      <HomeSubSection
        title={
          <>
            <Title>상담사 목록 👩‍⚕️</Title>
            <More onClick={moveDoctorList}>전체보기 {">"}</More>
          </>
        }
      >
        <div>
          <Button
            className={checked === "전체" ? "checked" : ""}
            onClick={() => {
              setChecked("전체");
            }}
          >
            전체
          </Button>
          <Button
            className={checked === "일반상담" ? "checked" : ""}
            onClick={() => {
              setChecked("일반상담");
            }}
          >
            심리상담
          </Button>
          <Button
            className={checked === "미술상담" ? "checked" : ""}
            onClick={() => {
              setChecked("미술상담");
            }}
          >
            미술심리상담
          </Button>
        </div>
        <Counselors>
          {filterDoctors.map((doctor) => (
            <CounselorOverview
              key={doctor.id}
              {...doctor}
              onClick={() => moveDoctorDetail(doctor.id)}
            />
          ))}
          <ScrollTarget ref={scrollRef} />
        </Counselors>
      </HomeSubSection>
    </HomeDoctorList>
  );
};
export default CounselorList;

const Title = styled.div`
  display: flex;
  align-items: center;

  & > img {
    margin-left: 4px;
  }
`;
const More = styled.div`
  font: normal normal 500 13px/16px Pretendard;
  letter-spacing: -0.13px;
  color: #6e6e6e;
`;
const Counselors = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12pt;
`;
const ScrollTarget = styled.div`
  height: 1px;
`;

const HomeDoctorList = styled.div`
  padding: 0 12pt 8pt 12pt;
`;

const Button = styled.button`
  padding: 10px;
  background-color: #f8f8f8;
  border-radius: 5px;
  margin-bottom: 12px;
  margin-right: 8px;
`;
