import { useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { Client } from "@twilio/conversations";

import {
  twilioConversationState,
  twilioTokenState,
  twilioClientState,
  messagesState,
  friendlyName,
} from "atomStates";
import useNavigate from "hooks/useNavigate";

const useTwilioConversation = () => {
  const { goRoom } = useNavigate();
  const token = useRecoilValue(twilioTokenState);
  const [twilioClient, setTwilioClient] = useRecoilState(twilioClientState);
  const setConversationState = useSetRecoilState(twilioConversationState);
  const setMessagesState = useSetRecoilState(messagesState);
  // const [client, setClient] = useState(null);
  const [status, setStatus] = useState("");
  const [conversations, setConversations] = useState([]);

  const [friendly, setFriendly] = useRecoilState(friendlyName);

  const init = useCallback(async () => {
    // if (!!client) return;
    // if (!token) {
    //   setStatus("noToken");
    //   return;
    // }
    const newClient = new Client(token);
    newClient.on("stateChanged", (state) => {
      // if (state === "failed") {
      //   return;
      // }
      if (state === "initialized") {
        // 대화 목록 가져오기
        newClient
          .getSubscribedConversations()
          .then((conversations) => {
            if (conversations.items.length > 0) {
              const validConversations = conversations.items.filter(
                (conversation) =>
                  !!conversation.uniqueName &&
                  conversation.uniqueName.includes("doctor")
              );
              setConversations(validConversations);
            }
          })
          .catch((e) => console.log(e));

        // newClient.getSubscribedConversations().then((c) => {
        //   console.log(c);
        //   conversations.items.map((cc) => {
        //     try {
        //       cc.delete();
        //     } catch (e) {
        //       console.log(e);
        //     }
        //   });
        // });
        try {
          // setClient(newClient);
          setStatus(state);
          setTwilioClient(newClient);
        } catch (e) {
          console.log(e);
        }
      }
    });
    // newClient.on("messageAdded", (message) => {
    //   setMessagesState((state) =>
    //     Object.assign({
    //       identity: newClient.user.identity,
    //       messages: [...state.messages, message],
    //     })
    //   );
    // });
  }, [token, setTwilioClient]);

  const joinConversation = async (friendlyName, uniqueName) => {
    /**
     * 채팅방 명명규칙
     * friendlyName: {상담사이름} 상담사
     * uniqueName: doctor{상담사ID}-user${내담자ID}
     *
     * 채팅방이 없으면 생성, 있으면 찾아서 active (conversation 변수에 할당)
     */

    // if (!twilioClient) return;
    const activeConversation = conversations.find(
      (c) => c.uniqueName === uniqueName
    );

    if (activeConversation) {
      try {
        console.info("채팅멤버 추가");
        const identities = uniqueName.split("-");
        Promise.all(
          identities.map((member) => {
            activeConversation.add(member);
          })
        );
        setFriendly(friendlyName);
        goRoom(uniqueName);
      } catch (e) {
        console.log(e);
      }

      activeConversation
        .getMessages()
        .then((messages) => {
          setMessagesState({
            identity: twilioClient.user.identity,
            messages: messages.items,
          });
        })
        .catch((e) => console.log(e));

      activeConversation.on("messageAdded", (message) => {
        setMessagesState((prev) => {
          const ids = prev.messages.map((message) => message.sid);
          return {
            identity: prev.identity,
            messages:
              ids.find((id) => id === message.sid) === undefined
                ? [...prev.messages, message]
                : prev.messages,
          };
        });
      });

      try {
        setConversationState({
          client: twilioClient,
          conversation: activeConversation,
        });
      } catch (e) {
        console.log(e);
      }
    } else {
      // 채팅방 생성
      try {
        const newConversation = await twilioClient.createConversation({
          friendlyName,
          uniqueName,
        });

        if (newConversation) {
          try {
            const identities = uniqueName.split("-");
            Promise.all(
              identities.map((member) => newConversation.add(member))
            );
          } catch (e) {
            console.log(e);
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    if (!!token && !twilioClient) {
      init();
    }
  }, [token, twilioClient, init]);

  return {
    status,
    conversations,
    joinConversation,
  };
};
export default useTwilioConversation;
