// import { useRecoilValue } from 'recoil';
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import styled from "styled-components";

import { update as updateProfile } from "apis/user";
import Form from "components/Form";

import { PASSWORD_DISMATCH_ERROR, PASSWORD_ERROR } from "utils/message";
import { PASSWORD_PATTERN } from "utils/regex";

const Password = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setError,
    watch,
    formState: { errors },
  } = useForm();
  const password = useRef("");
  const current_password = useRef("");
  const confirm_password = useRef("");
  password.current = watch("password", "");
  current_password.current = watch("current_password", "");
  confirm_password.current = watch("confirm_password", "");

  const [btnState, setBtnState] = useState(true);

  const onSubmit = async (params) => {
    try {
      await updateProfile(params).then((res) => {
        alert("비밀번호가 변경되었습니다.");
        navigate(-1);
      });
    } catch (e) {
      setError("current_password", {
        message: e.response.data.message,
      });
    }
  };

  return (
    <Wrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.InputField
          type="password"
          label="현재 비밀번호"
          placeholder="영문, 숫자, 특수기호 조합 8자리 이상"
          error={errors?.current_password?.message}
          {...register("current_password", {
            minLength: { value: 7, message: PASSWORD_ERROR },
            required: { value: true, message: PASSWORD_ERROR },
            pattern: {
              value: PASSWORD_PATTERN,
              message: PASSWORD_ERROR,
            },
            onChange: (e) => {
              if (
                password.current !== "" &&
                confirm_password.current !== "" &&
                e.target.value !== ""
              ) {
                setBtnState(false);
              } else {
                setBtnState(true);
              }
            },
          })}
        />
        <Form.InputField
          type="password"
          label="새로운 비밀번호"
          placeholder="영문, 숫자, 특수기호 조합 8자리 이상"
          {...register("password", {
            minLength: { value: 7, message: PASSWORD_ERROR },
            required: { value: true, message: PASSWORD_ERROR },
            pattern: {
              value: PASSWORD_PATTERN,
              message: PASSWORD_ERROR,
            },
            onChange: (e) => {
              if (
                current_password.current !== "" &&
                confirm_password.current !== "" &&
                e.target.value !== ""
              ) {
                setBtnState(false);
              } else {
                setBtnState(true);
              }
            },
          })}
          error={errors?.password?.message}
        />

        <Form.InputField
          type="password"
          label="비밀번호 재확인"
          placeholder="비밀번호를 다시 입력해 주세요."
          error={errors?.confirm_password?.message}
          {...register("confirm_password", {
            // minLength: { value: 7, message: PASSWORD_ERROR },
            // required: { value: true, message: PASSWORD_ERROR },
            // pattern: {
            //   value: PASSWORD_PATTERN,
            //   message: PASSWORD_ERROR,
            // },
            validate: (value) =>
              value === password.current || PASSWORD_DISMATCH_ERROR,
            onChange: (e) => {
              if (
                current_password.current !== "" &&
                password.current !== "" &&
                e.target.value !== ""
              ) {
                setBtnState(false);
              } else {
                setBtnState(true);
              }
            },
          })}
        />

        <Form.SubmitRow>
          <BtnSection>
            <Form.SubmitButton disabled={btnState} type="submit">
              변경 완료
            </Form.SubmitButton>
          </BtnSection>
        </Form.SubmitRow>
      </Form>
    </Wrapper>
  );
};
export default Password;

const Wrapper = styled.div`
  background: #ffffff;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 36px;
`;

const BtnSection = styled.div`
  width: 100%;
  background-color: #fff;
  padding-top: 24px;
  padding-bottom: 24px;
  border-top: 1px solid #efefef;
  position: absolute;
  bottom: 0px;
  text-align: center;
  z-index: 999;
`;
