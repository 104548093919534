import styled from "styled-components";

import GoBackButton from "./GoBackButton";

const Wrapper = styled.div`
  width: 414px;
  height: 52pt;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-bottom: 1px solid #efefef;
  text-align: center;
  font: normal normal bold 17px/20px Pretendard;
  letter-spacing: -0.26px;
  color: #101010;
  opacity: 1;
  position: fixed;
  top: 0;
  z-index: 999;
`;

const Header = Object.assign(Wrapper, {
  GoBackButton,
});
export default Header;
