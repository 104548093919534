import { useRecoilState } from "recoil";

import client from "apis/client";
import { refundState } from "atomStates";

const useRefund = () => {
  const [refund, setRefund] = useRecoilState(refundState);

  const setReservation = (reservation) => setRefund({ reservation });

  return {
    refund,
    setReservation,
  };
};
export default useRefund;
