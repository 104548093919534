import { useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";

import client from "apis/client";
import FailRegisterPayment from "components/Drawer/FailRegisterPayment";
import ProgressRegisterPayment from "components/Drawer/ProgressRegisterPayment";
import Form from "components/Form";

const RegistrationPayment = () => {
  const [showFail, setShowFail] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [text, setText] = useState("");
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const registerPayment = async () => {
    const { card_number, expiry, birth, pwd_2digit } = watch();
    const expirySplit = expiry.split("/");
    await client.post("/user/credit_cards", {
      card_number: card_number.join("-"),
      expiry: "20" + expirySplit[1] + "-" + expirySplit[0],
      birth: birth.replaceAll("/", ""),
      pwd_2digit,
    });

    // goMyPayment();
    // navigate(-2);
  };

  const setStateText = (data) => {
    if (data === "Card number은(는) 이미 존재합니다") {
      setText("이미 등록된 카드입니다.");
    } else {
      setText("카드 정보를 다시 확인해 주세요");
    }
  };

  const onSubmit = (data) => {
    setShowProgress(true);
  };

  return (
    <Wrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label>카드 번호</label>
          <CardNumber>
            <input
              type="tel"
              {...register("card_number.0", {
                required: { value: true, message: "" },
                minLength: 4,
                onChange: (e) => {
                  const { value } = e.target;

                  const numberValue = value.replaceAll("/", "").match(/\d+/g);
                  if (numberValue) {
                    setValue("card_number.0", numberValue[0].slice(0, 4));
                  } else {
                    setValue("card_number.0", "");
                  }
                },
              })}
            />
            <div style={{ display: "inline-block" }}>-</div>
            <input
              type="tel"
              {...register("card_number.1", {
                required: { value: true, message: "" },
                minLength: 4,
                onChange: (e) => {
                  const { value } = e.target;

                  const numberValue = value.replaceAll("/", "").match(/\d+/g);
                  if (numberValue) {
                    setValue("card_number.1", numberValue[0].slice(0, 4));
                  } else {
                    setValue("card_number.1", "");
                  }
                },
              })}
            />
            <div style={{ display: "inline-block" }}>-</div>
            <input
              type="tel"
              {...register("card_number.2", {
                required: { value: true, message: "" },
                minLength: 4,
                onChange: (e) => {
                  const { value } = e.target;

                  const numberValue = value.replaceAll("/", "").match(/\d+/g);
                  if (numberValue) {
                    setValue("card_number.2", numberValue[0].slice(0, 4));
                  } else {
                    setValue("card_number.2", "");
                  }
                },
              })}
            />
            <div style={{ display: "inline-block" }}>-</div>
            <input
              type="tel"
              {...register("card_number.3", {
                required: { value: true, message: "" },
                minLength: 4,
                onChange: (e) => {
                  const { value } = e.target;

                  const numberValue = value.replaceAll("/", "").match(/\d+/g);
                  if (numberValue) {
                    setValue("card_number.3", numberValue[0].slice(0, 4));
                  } else {
                    setValue("card_number.3", "");
                  }
                },
              })}
            />
          </CardNumber>
        </div>

        <InputField
          label="유효 기간"
          type="tel"
          placeholder="MM / YY"
          {...register("expiry", {
            required: { value: true, message: "" },
            minLength: 5,
            onChange: (e) => {
              const { value } = e.target;

              const numberValue = value.replaceAll("/", "").match(/\d+/g);
              if (numberValue) {
                setValue(
                  "expiry",
                  numberValue[0]
                    .slice(0, 4)
                    .match(/.{1,2}/g)
                    .join("/")
                );
              } else {
                setValue("expiry", "");
              }
            },
          })}
          error={errors?.expiry?.message}
        />

        <InputField
          label="생년월일"
          type="tel"
          placeholder="YY / MM / DD"
          {...register("birth", {
            required: { value: true, message: "" },
            onChange: (e) => {
              const { value } = e.target;

              const numberValue = value.replaceAll("/", "").match(/\d+/g);
              if (numberValue) {
                setValue(
                  "birth",
                  numberValue[0]
                    .slice(0, 6)
                    .match(/.{1,2}/g)
                    .join("/")
                );
              } else {
                setValue("birth", "");
              }
            },
          })}
          error={errors?.expiry?.message}
        />

        <InputField
          label="카드 비밀번호"
          type="tel"
          placeholder="앞 두 자리"
          {...register("pwd_2digit", {
            required: { value: true, message: "" },
            minLength: 2,
            onChange: (e) => {
              const { value } = e.target;

              const numberValue = value.replaceAll("/", "").match(/\d+/g);
              if (numberValue) {
                setValue("pwd_2digit", numberValue[0].slice(0, 2));
              } else {
                setValue("pwd_2digit", "");
              }
            },
          })}
          error={errors?.pwd_2digit?.message}
        />

        <Footer>
          <Form.SubmitButton type="submit">등록하기</Form.SubmitButton>
          {Object.keys(errors).length > 0 ? (
            <Form.Error>일부 항목 입력이 유효하지 않습니다.</Form.Error>
          ) : (
            <div style={{ marginTop: "31px" }}></div>
          )}
        </Footer>
      </Form>

      <FailRegisterPayment
        isShow={showFail}
        onClose={() => setShowFail(false)}
        text={text}
      />

      <ProgressRegisterPayment
        isShow={showProgress}
        setStateText={setStateText}
        onFail={() => setShowFail(true)}
        onRegister={registerPayment}
        onClose={() => setShowProgress(false)}
      />
    </Wrapper>
  );
};
export default RegistrationPayment;

const Wrapper = styled.section`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 34px 15px 160px 15px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  label {
    font: normal normal 600 14px/17px Pretendard;
    letter-spacing: -0.14px;
    color: #333333;
    margin-bottom: 21px;
    display: block;
  }

  input {
    padding-bottom: 10px;
    font: normal normal 300 18px/21px Pretendard;
    // letter-spacing: -0.27px;
    border-bottom: 1px solid #e6e6e6;
  }
`;
const CardNumber = styled.div`
  // display: flex;
  & > input {
    width: 60px;
    text-align: center;
  }
`;
const Footer = styled(Form.SubmitRow)`
  box-sizing: border-box;
  width: calc(100% - 30px);
  display: flex;
  justify-content: center;
  background: #fff;
  position: absolute;
  bottom: 0;
  padding-top: 10px;
`;
const InputField = styled(Form.InputField)`
  width: 178px;
`;
