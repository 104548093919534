import { useState } from "react";
import styled from "styled-components";

import client from "apis/client";
import DeletePaymentMethod from "components/Popups/DeletePaymentMethod";
import CreditCard from "components/Profile/CreditCard";
import useCreditCards from "hooks/useCreditCards";
import useNavigate from "hooks/useNavigate";

const Payment = () => {
  const { creditCards, fetch } = useCreditCards();
  const { goRegistPayment } = useNavigate();

  const [selected, setSelected] = useState("");
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const deletePayment = async () => {
    await client.delete(`/user/credit_cards/${selected}`);
    await fetch();

    setShowDeletePopup(false);
  };

  return (
    <Wrapper>
      {creditCards.length === 0 ? (
        <NoPayment>
          등록된 카드가 없습니다.
          <br /> 카드 등록 후 결제할 수 있습니다.
          <br />
          *하나카드 결제 진행은 어려우니 양해 부탁드립니다.
          <button onClick={goRegistPayment}>결제수단 추가</button>
        </NoPayment>
      ) : (
        <>
          {creditCards.map((card) => (
            <CreditCard
              key={card.id}
              {...card}
              isActive={selected === card.id}
              onClick={() => setSelected(card.id)}
              onClickDelete={() => {
                setSelected(card.id);
                setShowDeletePopup(true);

                // deletePayment(card.id)
              }}
            />
          ))}
          <button onClick={goRegistPayment}>결제 수단 추가</button>
        </>
      )}

      {showDeletePopup && (
        <DeletePaymentMethod
          onConfirm={deletePayment}
          onClose={() => setShowDeletePopup(false)}
        />
      )}
    </Wrapper>
  );
};
export default Payment;

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background: #ffffff;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 36px;
  padding-bottom: 24px;

  & > h2 {
    font: normal normal 600 18px/21px Pretendard;
    letter-spacing: -0.27px;
    color: #101010;
  }

  & > button {
    width: 271px;
    height: 48px;
    background: #333333 0% 0% no-repeat padding-box;
    border-radius: 10px;
    font: normal normal 500 16px/19px Pretendard;
    letter-spacing: -0.16px;
    color: #ffffff;
    position: absolute;
    bottom: 24px;
  }
`;
const NoPayment = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font: normal normal normal 15px/24px Pretendard;
  letter-spacing: -0.15px;
  color: #999999;
  text-align: center;

  & > button {
    margin-top: 28px;
    width: 166px;
    height: 48px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    font: normal normal 500 16px/19px Pretendard;
    letter-spacing: -0.16px;
    color: #000000;
  }
`;
