import styled from "styled-components";

import Reservation from "components/Reservation/MyReservation";
import NoReservation from "components/Reservation/NoReservation";
import useMyReservation from "hooks/useMyReservation";
import useNavigate from "hooks/useNavigate";
import useIntersect from "hooks/useIntersect";

const MyReservation = ({
  conversations,
  onCreateConversation,
  onSelectConversation,
}) => {
  const { goRoom } = useNavigate();
  const { reservations, isFetching, hasNextPage, setNextPage } =
    useMyReservation();
  const scrollRef = useIntersect(async (entry, observer) => {
    observer.unobserve(entry.target);
    if (hasNextPage && !isFetching) {
      setNextPage();
    }
  });
  return (
    <Wrapper>
      {reservations.length > 0 ? (
        <div>
          {reservations.map((reservation) => (
            <Reservation
              key={reservation.id}
              {...reservation}
              onClickEnterRoom={(uniqueName) => {
                const conversation = conversations.find(
                  (c) => c.uniqueName === uniqueName
                );
                if (conversation) {
                  onSelectConversation(conversation);
                  goRoom(conversation.uniqueName);
                }
              }}
              onClickSetDateTime={onCreateConversation}
            />
          ))}
          <ScrollTarget ref={scrollRef} />
        </div>
      ) : (
        <NoReservation />
      )}
    </Wrapper>
  );
};
export default MyReservation;

const Wrapper = styled.section`
  width: 100%;
  height: 100%;
  padding: 40px 15px;
  padding-bottom: 120px;
  overflow: hidden;
  overflow-y: auto;
`;
const ScrollTarget = styled.div`
  height: 1px;
`;
