import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";

import ConsultingRoomOverview from "components/ConsultingRoom/ConsultingRoomOverview";
import useNavigate from "hooks/useNavigate";
import { getIdsFromUniqueName } from "utils/twilio";
import useAuth from "hooks/useAuth";
import client from "apis/client";

const ConsultingRooms = ({
  conversations,
  onSelectConversation,
  fetchConversations,
}) => {
  const { user } = useAuth();
  const { userType } = useAuth();
  const { goHome } = useNavigate();

  useEffect(() => {
    const refreshChatList = setInterval(() => {
      (async () => await fetchConversations())();
    }, 10000);

    return () => {
      clearInterval(refreshChatList);
    };
  }, [fetchConversations]);

  return (
    <Wrapper>
      {conversations.length > 0 ? (
        conversations.map((conversation) => {
          return (
            <ConsultingRoomItem
              key={conversation.uniqueName}
              conversation={conversation}
              user={user}
              onSelectConversation={onSelectConversation}
              userType={userType}
            />
          );
        })
      ) : (
        <NoRooms>
          <p>상담 내역이 없습니다.</p>
          <button onClick={goHome}>상담 받기</button>
        </NoRooms>
      )}
    </Wrapper>
  );
};

const ConsultingRoomItem = ({
  conversation,
  user,
  onSelectConversation,
  userType,
}) => {
  const [lastMessageInfo, setLastMessageInfo] = useState(null);
  const [counselRoom, setCounselRoom] = useState([]);

  const [doctorId] = getIdsFromUniqueName(conversation.uniqueName);
  const splitFriendlyName = conversation.friendlyName?.split("-");
  const roomName = user.isDoctor ? splitFriendlyName[1] : splitFriendlyName[0];
  const { goRoom } = useNavigate();

  const fetchChatMessage = useCallback(async () => {
    try {
      await conversation.getMessages(1).then((messages) => {
        setLastMessageInfo(messages.items[0]);
      });

      if (userType === "client") {
        client.get("/user/counsel_rooms").then((res) => {
          res.data.counsel_rooms.forEach(function (v) {
            if (user && v.name.includes(`user${user.id}`)) {
              setCounselRoom((prev) => [...prev, v]);
            }
          });
        });
      } else {
        client.get("/doctor/counsel_rooms").then((res) => {
          res.data.counsel_rooms.forEach(function (v) {
            if (user && v.name.includes(`doctor${user.id}`)) {
              setCounselRoom((prev) => [...prev, v]);
            }
          });
        });
      }
    } catch (e) {
      console.error("fetchChatMessage", e);
      window.location.reload();
    }
  }, [conversation, user, userType]);

  useEffect(() => {
    fetchChatMessage();
    const refreshChatMessage = setInterval(() => {
      fetchChatMessage();
    }, 10000);

    return () => {
      clearInterval(refreshChatMessage);
    };
  }, [conversation, fetchChatMessage]);

  return (
    <ConsultingRoomOverview
      key={conversation.sid}
      doctorId={doctorId}
      userId={conversation.uniqueName.split("-user")[[1]]}
      roomName={roomName}
      lastMessage={conversation.lastMessage}
      lastMessageDateCreated={lastMessageInfo?.dateCreated}
      lastMessageStr={lastMessageInfo?.body}
      count={0}
      onClick={async () => {
        onSelectConversation(conversation);
        goRoom(conversation.uniqueName);
      }}
      counselRoom={counselRoom}
      userType={userType}
    />
  );
};

export default ConsultingRooms;

const Wrapper = styled.section`
  padding: 0 15px;
  padding-top: 65px;
  padding-bottom: 120px;
  height: 100%;
  overflow-y: auto;
`;
const NoRooms = styled.section`
  width: 100%;
  text-align: center;
  margin-top: 200px;
  p {
    font: normal normal 400 15px/18px Pretendard;
    letter-spacing: -0.15px;
    color: #999999;
    margin-bottom: 28px;
  }
  button {
    width: 166px;
    height: 48px;
    background: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    font: normal normal 500 16px/19px Pretendard;
    letter-spacing: -0.16px;
    color: #000000;
  }
`;
