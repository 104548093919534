import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 36px 15px 0 15px;
`;
export const Title = styled.div`
  display: flex;
  font: normal normal 600 28px/44px Pretendard;
  letter-spacing: -0.42px;
  color: #101010;
`;

/**
 * 이메일, 비밀번호 필드
 */
const FormWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const Field = styled.div`
  color: #333333;
`;
const Label = styled.div`
  font: normal normal 600 14px/17px Pretendard;
  letter-spacing: -0.14px;
`;
const Input = styled.input`
  width: 100%;
  border-bottom: 1px solid #e6e6e6;
  font: normal normal 300 18px/21px Pretendard;
  letter-spacing: -0.27px;
  padding: 15px 0%;

  &:focus {
    border: none;
    box-shadow: none;
    border-bottom: 1px solid #e6e6e6;
  }
`;
export const LoginForm = Object.assign(FormWrapper, { Field, Label, Input });

export const CenterSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    width: 270px;
  }
`;

export const LoginButton = styled.button`
  margin-top: 40px;
  width: 100%;
  height: 55px;
  background: #333333 0% 0% no-repeat padding-box;
  border-radius: 10px;
  font: normal normal 500 16px/19px Pretendard;
  letter-spacing: -0.16px;
  color: #ffffff;
`;
