import styled from "styled-components";

import useNavigate from "hooks/useNavigate";

const FindAndJoin = () => {
  const { goJoin, goPasswordReset } = useNavigate();

  return (
    <>
      <Wrapper>
        <FindPassword onClick={goPasswordReset}>비밀번호 찾기</FindPassword>
        <Join onClick={goJoin}>회원가입</Join>
      </Wrapper>
      <Wrapper style={{ marginTop: "20px" }}>
        <Inquiry
          onClick={() => {
            window.open("https://forms.gle/6ZmE8qjUSenwu7od8");
          }}
        >
          문의하기
        </Inquiry>
      </Wrapper>
    </>
  );
};

export default FindAndJoin;

const Wrapper = styled.section`
  margin-top: 12px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font: normal normal normal 14px/17px Pretendard;
  letter-spacing: -0.28px;
`;
const FindPassword = styled.div`
  color: #6e6e6e;
  cursor: pointer;
`;
const Join = styled.div`
  color: #101010;
  cursor: pointer;
`;

const Inquiry = styled.div`
  margin-left: auto;
  text-decoration: underline;
  color: #6e6e6e;
  cursor: pointer;
`;
