import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { DOCTOR_PATH } from "App";
import { ReactComponent as SearchSVG } from "assets/svg/search-bar.svg";
import { ReactComponent as ArrowSVG } from "assets/svg/chevron-left.svg";
import Header from "components/Header";
import useCounsels from "hooks/useCounsels";

const Search = () => {
  const navigate = useNavigate();
  const counsels = useCounsels();
  const [query, setQuery] = useState("");

  return (
    <Wrapper className="mainWrapper">
      <HeaderSection className="headerWrapper">
        <GoBackButton onClick={() => navigate(-1)}>
          <ArrowSVG />
        </GoBackButton>
        <Input>
          <SearchSVG
            onClick={() => {
              if (!query) {
                alert("검색어를 입력해주세요.");
              } else {
                navigate(`${DOCTOR_PATH}?search_text=${query}`);
              }
            }}
          />
          <input
            placeholder="증상, 상담사를 검색해 보세요."
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (query === "" || query === " ") {
                  alert("검색어를 입력해주세요.");
                  return;
                }
                navigate(`${DOCTOR_PATH}?query=${query}`);
              }
            }}
          />
        </Input>
      </HeaderSection>

      <BodySection>
        <h4>상담 과목 검색</h4>
        <CounselSection className="btnSection">
          {counsels.map((counsel, i) => {
            return (
              <Counsel
                className="summaryBtn"
                key={counsel.id}
                onClick={() =>
                  navigate(
                    `${DOCTOR_PATH}?counsel_id=${counsel.id}&counsel=${counsel.subject}&query=${query}`
                  )
                }
              >
                {counsel.subject}
              </Counsel>
            );
          })}
        </CounselSection>
      </BodySection>
    </Wrapper>
  );
};
export default Search;

const Wrapper = styled.section`
  padding-top: 18pt;
`;
const BodySection = styled.section`
  padding: 0 15px;
  height: 100vh;
  margin-top: 60pt;

  & > h2 {
    font: normal normal bold 17px/20px Pretendard;
    letter-spacing: -0.26px;
    color: #101010;
  }
`;
const HeaderSection = styled(Header)`
  border-bottom: none;
  padding: 0 15px 0 0;
`;
const CounselSection = styled.section`
  margin-top: 20px;
  flex-wrap: wrap;
  gap: 8px;
`;
const Counsel = styled.button`
  width: 50pt;
  height: 26pt;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 20px;
  margin: 5px 4px;
  font: normal normal 500 15px/18px Pretendard;
  letter-spacing: -0.23px;
  color: #101010;
`;
const Input = styled.div`
  width: 89%;
  height: 36px;
  background: #f8f8f8;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  float: right;
  margin-top: 26px;
  & > input {
    width: 90%;
    background-color: unset;
    margin-left: 8px;
    font: normal normal 300 15px/18px Pretendard;
    letter-spacing: -0.23px;
  }
`;

const GoBackButton = styled.div`
  position: fixed;
  padding-top: 7pt;
  padding-left: 15pt;
  margin-top: 26px;
  \ & > svg {
    & > path {
      stroke: #404040;
    }
  }
`;
