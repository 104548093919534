import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import HomeSubSection from "components/HomeSubSection";
import useCounsels from "hooks/useCounsels";
import { DOCTOR_PATH } from "App";
import { Situation } from "components/Situation";
import { Swiper, SwiperSlide } from "swiper/react";
import { useEffect, useState } from "react";

const MySituation = () => {
  const navigate = useNavigate();
  const counsels = useCounsels();
  const [counselList, setCounselList] = useState([]);
  useEffect(() => {
    if (counsels.length === 0) {
      return;
    }
    setCounselList(counsels);
  }, [counsels]);

  return (
    <MySituationSection className="MySituationSection">
      <HomeSubSection title={<Title>지금 내 상황은? &#128173;</Title>}>
        <Counsels>
          {counsels.length > 0 ? (
            <Swiper slidesPerView={5} spaceBetween={16}>
              {Object.values(counselList).length > 0 ? (
                counselList.map((counsel, i) => (
                  <SwiperSlide
                    style={{ width: "60px !important", cursor: "pointer" }}
                    key={i}
                    onClick={() =>
                      navigate(
                        `${DOCTOR_PATH}?counsel_id=${counsel.id}&counsel=${counsel.subject}`
                      )
                    }
                  >
                    <Counsel
                      key={counsel.id}
                      style={{
                        backgroundImage: `url(${Situation(counsel.subject)})`,
                      }}
                    ></Counsel>
                    <Text>{counsel.subject}</Text>
                  </SwiperSlide>
                ))
              ) : (
                <></>
              )}
            </Swiper>
          ) : (
            <Skull>
              <div style={{ height: "79px" }}>
                <Counsel
                  style={{ background: "#EBEDEF", height: "60px" }}
                ></Counsel>
                <Text style={{ background: "#EBEDEF", height: "14px" }}></Text>
              </div>
              <div style={{ height: "79px" }}>
                <Counsel
                  style={{ background: "#EBEDEF", height: "60px" }}
                ></Counsel>
                <Text style={{ background: "#EBEDEF", height: "14px" }}></Text>
              </div>
              <div style={{ height: "79px" }}>
                <Counsel
                  style={{ background: "#EBEDEF", height: "60px" }}
                ></Counsel>
                <Text style={{ background: "#EBEDEF", height: "14px" }}></Text>
              </div>
              <div style={{ height: "79px" }}>
                <Counsel
                  style={{ background: "#EBEDEF", height: "60px" }}
                ></Counsel>
                <Text style={{ background: "#EBEDEF", height: "14px" }}></Text>
              </div>
              <div style={{ height: "79px" }}>
                <Counsel
                  style={{ background: "#EBEDEF", height: "60px" }}
                ></Counsel>
                <Text style={{ background: "#EBEDEF", height: "14px" }}></Text>
              </div>
            </Skull>
          )}
        </Counsels>
      </HomeSubSection>
    </MySituationSection>
  );
};
export default MySituation;

const Title = styled.div`
  display: flex;
  align-items: center;

  & > img {
    margin-left: 4px;
  }
`;
const Counsels = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;
const Counsel = styled.span`
  height: 60px;
  width: 60px;
  background-size: 100% 100%;
  display: flex;
`;

const MySituationSection = styled.div`
  padding-right: 12pt;
  padding-left: 12pt;
`;

const Text = styled.div`
  text-align: center;
  margin-top: 5px;
  font-weight: 600;
  font-size: 14px;
`;

const Skull = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  gap: 16px;
`;
