import { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import DoctorItem from "components/Doctor/Doctor";
import NoAuthReservation from "components/Popups/NoAuthReservation";
import useAuth from "hooks/useAuth";
import useDoctor from "hooks/useDoctor";
import useMyReservation from "hooks/useMyReservation";
import useNavigate from "hooks/useNavigate";
import useReviews from "hooks/useReviews";

const Doctor = () => {
  const { goReservation, goReviewForm } = useNavigate();
  const { user, userType } = useAuth();
  const { id } = useParams();

  const [showNoAuth, setShowNoAuth] = useState(false);
  const [noAuthMessage, setNoAuthMessage] = useState("");

  const doctor = useDoctor(id);
  const { activeReservation } = useMyReservation(id);
  const reviews = useReviews(id);

  const handleReservation = () => {
    if (!user) {
      // setShowNoAuth(true);
      setNoAuthMessage("로그인 후 상담권을 선택하세요.");
    } else {
      goReservation(id);
    }
  };

  const handleCreateReview = () => {
    if (!user) {
      // setShowNoAuth(true);
      setNoAuthMessage("로그인 후 작성하세요");
    } else {
      if (!activeReservation) {
        // b. 해당 상담사에게 상담 받지 않은 경우 -> "작성 권한인 없습니다" alert
        alert("작성 권한이 없습니다");
      } else {
        // a. 해당 상담사에게 상담 받은 경우 -> 후기 작성 페이지로 이동
        goReviewForm(id, doctor.name, activeReservation.id);
      }
    }
  };

  return (
    <Wrapper className="doctorsWrapper">
      <DoctorItem
        {...doctor}
        activeReservationId={activeReservation?.id}
        reviews={reviews}
        onCreateReview={handleCreateReview}
      />

      {noAuthMessage && (
        <NoAuthReservation
          alertMessage={noAuthMessage}
          onClose={() => setNoAuthMessage("")}
        />
      )}

      <ActionSection className="doctorsActionSection">
        <ReservationButton
          onClick={handleReservation}
          disabled={userType === "doctor" ? true : false}
          id="consultationButton"
        >
          {userType === "doctor"
            ? "상담은 내담자만 신청할 수 있습니다"
            : "상담 신청하기"}
        </ReservationButton>
      </ActionSection>
    </Wrapper>
  );
};
export default Doctor;
const Wrapper = styled.section`
  // padding: 36px;
  // padding-bottom: 30pt;
  height: 100%;
  overflow-y: auto;
`;
const ActionSection = styled.div`
  width: 100%;
  background-color: #fff;
  padding-top: 24px;
  padding-bottom: 24px;
  border-top: 1px solid #efefef;
  position: absolute;
  bottom: 0px;
  text-align: center;
  z-index: 999;
`;
const ReservationButton = styled.button`
  width: 271px;
  height: 48px;
  border: none;
  background: #333333;
  border-radius: 10px;
  font: normal normal 500 16px/19px Pretendard;
  -webkit-letter-spacing: -0.16px;
  -moz-letter-spacing: -0.16px;
  -ms-letter-spacing: -0.16px;
  letter-spacing: -0.16px;
  color: #ffffff;

  &:disabled {
    min-width: 30px;
    color: #f9f9f9;
    cursor: default;
    background-color: #999999;
    background-image: none;
    border: none;
    border-radius: 10px;
    padding: 15px 31px;
    font-size: 15px;
  }
`;
