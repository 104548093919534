import { useEffect, useState } from "react";

import client from "apis/client";

const useDoctor = (doctorId) => {
  const [doctor, setDoctor] = useState(null);
  useEffect(() => {
    if (!doctorId) return;

    (async () => {
      const { data } = await client.get(`/doctor/${doctorId}`);
      setDoctor(data);
    })();
  }, [doctorId]);

  return doctor;
};
export default useDoctor;
