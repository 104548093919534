import { setUser } from "utils/storage";

import client from "./client";

/**
 * 유저 로그인
 */
export const userLogin = async (email, password) => {
  const resp = await client.post("/user/login", { email, password });
  setUser({
    ...resp.data,
    userType: "client",
  });
  return resp;
};

export const userLogout = async () => {
  const resp = await client.delete("/user/logout");
  setUser("");

  return resp;
};

/**
 * 상담상담사 로그인
 */
export const doctorLogin = async (params) => {
  const resp = await client.post("/doctor/login", params);
  setUser({
    ...resp.data,
    userType: "doctor",
  });

  return resp;
};
export const doctorLogout = async () => {
  const resp = client.delete("/doctor/logout");
  setUser("");

  return resp;
};

/**
 * 상담상담사 회원 휴대폰 인증코드 보내기
 */
export const requestPhoneCode = (phone) =>
  client.post("/doctor/phone", { phone });
