import styled from "styled-components";

import ArrowSVG from "assets/svg/chevron-left.svg";

export const Username = styled.div`
  display: flex;
  align-items: center;
  font: normal normal bold 28px/34px Pretendard;
`;
export const Greeting = styled.div`
  height: 78px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font: normal normal 600 28px/34px Pretendard;
  letter-spacing: -0.42px;
  color: #101010;
`;
export const AccountDelete = styled.div`
  font: normal normal 500 15px/18px Pretendard;
  letter-spacing: 0px;
  color: #999999;
  display: inline-block;
  cursor: pointer;
`;

/**
 * 메뉴 아이템
 */
const MenuWrapper = styled.div`
  margin-top: 42px;
  margin-bottom: 40px;
`;
const MenuItem = styled.div`
  position: relative;
  height: 55px;
  display: flex;
  align-items: center;
  padding-left: 21px;
  font: normal normal 600 16px/19px Pretendard;
  letter-spacing: -0.16px;
  color: #333333;
  margin: 10px 0;
  cursor: pointer;

  &::before {
    position: absolute;
    left: 0px;
    content: "";
    width: 16px;
    height: 18px;
    background: transparent url(${(p) => p.$icon}) 100% 100% no-repeat
      padding-box;
  }

  &::after {
    position: absolute;
    right: 0px;
    content: "";
    width: 6px;
    height: 15px;
    background: transparent url(${ArrowSVG}) 0% 0% no-repeat padding-box;
    transform: rotate(180deg);
  }

  & + & {
    border-top: 1px solid #e6e6e6;
  }
`;
export const Menu = Object.assign(MenuWrapper, { Item: MenuItem });

/**
 *  법정 정보 링크
 */
const CompanyInfoWrapper = styled.div`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
`;
const CompanyInfoButton = styled.div`
  margin-top: 33px;
  width: fit-content;
  border: 1px solid #efefef;
  border-radius: 5px;
  font: normal normal 500 14px/17px Pretendard;
  letter-spacing: -0.21px;
  color: #9d9d9d;
  padding: 7px 26px;
  cursor: pointer;
`;
export const CompanyInfo = Object.assign(CompanyInfoWrapper, {
  Button: CompanyInfoButton,
});
