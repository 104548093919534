import { forwardRef } from "react";
import styled from "styled-components";

import ErrorSVG from "assets/svg/field-error.svg";

const InputField = forwardRef(({ label, error, required, ...rest }, ref) => (
  <Wrapper>
    <Label $required={required}>{label}</Label>
    <Input ref={ref} {...rest} />
    {error && <Error>{error}</Error>}
  </Wrapper>
));

export default InputField;

const Wrapper = styled.section``;
const Label = styled.div`
  font: normal normal 600 14px/17px Pretendard;
  letter-spacing: -0.14px;
  color: #333333;

  &::after {
    content: ${(p) => (p.$required ? "*" : "")};
    color: #485af3;
  }
`;
const Input = styled.input`
  width: 100%;
  font: normal normal 300 18px/21px Pretendard;
  letter-spacing: -0.27px;
  color: #0e0e11;
  padding: 15px 0;
  margin-top: 6px;
  border-bottom: 1px solid #e6e6e6;

  &:focus {
    border: none;
    box-shadow: none;
    border-bottom: 1px solid #e6e6e6;
  }
`;
const Error = styled.div`
  position: relative;
  margin-top: 15px;
  font: normal normal 500 13px/16px Pretendard;
  letter-spacing: -0.13px;
  color: #ed5d4e;
  padding-left: 22px;

  &::before {
    position: absolute;
    left: 0;
    content: "";
    width: 16px;
    height: 16px;
    background: url(${ErrorSVG});
  }
`;
