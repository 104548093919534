import styled from "styled-components";

import useNavigate from "hooks/useNavigate";

import examineStartImage from "assets/images/examineStartImage.png";

import Share from "./Share";
import { useRecoilState } from "recoil";
import { examineStep } from "atomStates";

const Contents = () => {
  const { goExamineStart } = useNavigate();
  const [step, setStep] = useRecoilState(examineStep);

  return (
    <Wrapper>
      <img
        src={examineStartImage}
        width={"100%"}
        style={{ margin: "0 auto" }}
        onClick={() => {
          goExamineStart();
          setStep(1);
        }}
      />
      <div style={{ height: "150px" }}>
        <Share location={"examine"} />
      </div>
    </Wrapper>
  );
};

export default Contents;

const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #ffffff;
  margin-top: 65px;
  overflow-y: auto;
  text-align: center;
  margin-bottom: 40px;
`;
