import styled from "styled-components";

import { useEffect, useState } from "react";
import { fetchGalleries } from "apis/content";
import Share from "./Share";

const Gallery = () => {
  const [emCode, setEmCode] = useState("");

  useEffect(() => {
    fetchGalleries().then((v) => {
      setEmCode(v.data.embed_code);
    });
  }, [fetchGalleries]);

  return (
    <Wrapper className="mainWrapper">
      <Body className="body">
        <ContentsMenu>힐링 갤러리</ContentsMenu>
        <div style={{ margin: "0 15px" }}>
          <Contents dangerouslySetInnerHTML={{ __html: emCode }}></Contents>
        </div>
        {/* <Tag className="tag">by. Piecial</Tag> */}
        <Share location={"galleries"} />
      </Body>
    </Wrapper>
  );
};

export default Gallery;

const Wrapper = styled.div`
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Body = styled.div`
  height: 100%;
  overflow-y: auto;
  flex-grow: 1;
  padding-top: 69px;
`;

const ContentsMenu = styled.div`
  margin-top: 20pt;
  margin-left: 10pt;
  font: normal normal 600 17.5pt/21pt Pretendard;
  display: inline-block;
`;

const Contents = styled.div`
  width: 100%;
  height: 330px;
  background-color: white;
  margin: 14pt auto;
  border-radius: 15pt;
  text-align: center;
  box-shadow: rgb(100 100 111 / 20%) 0 0 2pt 2pt;
`;

const Tag = styled.div`
  display: inline-block;
  position: relative;
  bottom: 1pt;
  left: 76%;
  background-color: #efefef;
  padding: 3pt 10pt;
  border-radius: 5pt;
  font: normal normal 550 10pt Pretendard;
  color: #6e6e6e;
`;
