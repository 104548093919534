import { useCallback } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { twilioTokenState, userState } from "atomStates";
import client from "apis/client";

const useTwilioToken = () => {
  const user = useRecoilValue(userState);
  const [token, setToken] = useRecoilState(twilioTokenState);

  const generateToken = useCallback(async () => {
    if (!!token) return;
    if (!user) return;
    const {
      data: { token: newToken },
    } = await client.post("/twilio", {
      identity: `${user.hasOwnProperty("counsels") ? "doctor" : "user"}${
        user.id
      }`,
      room_name: user.name || user.nickname,
    });
    setToken(newToken);
  }, [user, token, setToken]);

  return { generateToken };
};
export default useTwilioToken;
