import { useRecoilState, useRecoilValue } from "recoil";
import moment from "moment";
import "moment/locale/ko";
import styled from "styled-components";

import Drawer from "./";
import { Button, DrawerBody, DrawerFooter } from "./Comps";
import { friendlyName, paymentState, userState } from "atomStates";
import useReservation from "hooks/useReservation";
import useNavigate from "hooks/useNavigate";
import { generateFriendlyName, generateUniqueName } from "utils/twilio";

import { ReactComponent as MorningSVG } from "assets/svg/morning.svg";
import { ReactComponent as NoonSVG } from "assets/svg/noon.svg";
import { ReactComponent as NightSVG } from "assets/svg/night.svg";
import { useEffect, useState } from "react";

const DATE_RANGE_LENGTH = 5;
const DATE_FORMAT = "YYYY-MM-DD";
const MORNING = "09-13";
const NOON = "13-17";
const NIGHT = "17-21";

let dates = [...Array(DATE_RANGE_LENGTH).keys()].map((i) => {
  return moment().add(i, "days");
});
dates = dates.filter((data) => data !== undefined);
const ReservationDateTime = ({
  isShow,
  onReservation,
  prepareRoom,
  onClose,
}) => {
  const { id: userId, nickname } = useRecoilValue(userState);
  const { goRoom } = useNavigate();
  const {
    reservation: { doctor_name, doctor_id, date, times, counsel_kind },
    setDate,
    setTimes,
    reservationDateTime,
  } = useReservation();
  const [validation, setValidation] = useState(false);
  const [friendly, setFriendly] = useRecoilState(friendlyName);
  const [paymentStates, setPaymentStates] = useRecoilState(paymentState);
  const [btnClick, setBtnClick] = useState(true);

  useEffect(() => {
    setValidation(false);
  }, [date, times]);

  return (
    <Drawer isShow={isShow}>
      <Wrapper>
        <section>
          <label>상담 희망 요일</label>
          <DATES>
            {dates.map((d, i) => (
              <span
                key={i}
                className={d.format(DATE_FORMAT) === date ? "active" : ""}
                onClick={() => {
                  if (date === d.format(DATE_FORMAT)) {
                    setDate("");
                  } else {
                    setDate(d.format(DATE_FORMAT));
                  }
                }}
              >
                {d.format("ddd")}
              </span>
            ))}
          </DATES>
        </section>
        <section>
          <label>
            상담 희망 시간
            <span>*중복 선택이 가능합니다</span>
          </label>
          <Times>
            <div
              className={times.includes(MORNING) ? "active" : ""}
              onClick={() => setTimes(MORNING)}
            >
              <MorningSVG />
              9시 - 13시
            </div>
            <div
              className={times.includes(NOON) ? "active" : ""}
              onClick={() => setTimes(NOON)}
            >
              <NoonSVG />
              13시 - 17시
            </div>
            <div
              className={times.includes(NIGHT) ? "active" : ""}
              onClick={() => setTimes(NIGHT)}
            >
              <NightSVG />
              17시 - 21시
            </div>
          </Times>
        </section>
      </Wrapper>
      <Footer>
        {!validation ? (
          <></>
        ) : (
          <ErrorMessage>
            상담을 희망하시는 요알과 시간을 선택해주세요.
          </ErrorMessage>
        )}

        <Button
          onClick={async () => {
            if (!btnClick) return;
            if (!userId) return;
            if (!date || !times) {
              setValidation(true);
              return;
            }
            setBtnClick(false);
            if (onReservation) {
              // 마이페이지 > 내 상담 내역에서 상단 일자 접수 시,
              await onReservation(date, times);
              window.location.reload();
            } else {
              // 1. 채팅방 생성
              const friendlyName = generateFriendlyName(doctor_name, nickname);
              const uniqueName = generateUniqueName(doctor_id, userId);
              setFriendly(friendlyName);
              try {
                await prepareRoom(
                  friendlyName,
                  uniqueName,
                  `${counsel_kind}`,
                  moment(moment()).add(4, "days").format("YYYY.MM.DD"),
                  moment(date).format("ddd"),
                  times
                );
              } catch (e) {
                alert("에러가 발생했습니다.");
                console.log(e);
                return;
              }

              if (times.length < 1 || date.length < 1) {
                setValidation(true);
                return;
              } else {
                setValidation(false);
                onClose();
                goRoom(uniqueName);
              }
              // 희망 일시, 시간
              try {
                await reservationDateTime();
              } catch (e) {
                console.log(e);
              }

              // goMyReservation();
            }
            setPaymentStates("");
          }}
        >
          예약 신청
        </Button>
      </Footer>
    </Drawer>
  );
};
export default ReservationDateTime;

const Wrapper = styled(DrawerBody)`
  height: 611px;
  padding: 0 34px;
  padding-top: 34px;

  section {
    & > label {
      display: block;
      width: 100%;
      font: normal normal bold 17px/20px Pretendard;
      letter-spacing: -0.17px;
      color: #101010;
      border-bottom: 1px solid #e6e6e6;
      padding-bottom: 16px;
      margin-bottom: 16px;

      & > span {
        font: normal normal 500 12px/15px Pretendard;
        letter-spacing: -0.12px;
        color: #999999;
        margin-left: 8px;
      }
    }
  }
  section + section {
    margin-top: 44px;
  }
`;
const DATES = styled.div`
  width: 100%;
  height: 52px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  display: flex;

  & > span {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f8f8f8;
    font: normal normal 500 15px/18px Pretendard;
    letter-spacing: -0.15px;
    color: #999999;
  }
  & > span.active {
    background: #485af3;
    color: #fff;
  }
  & > span + span {
    border-left: 1px solid #e6e6e6;
  }
  & > span:first-child {
    border-radius: 5px 0 0 5px;
  }
  & > span:last-child {
    border-radius: 0 5px 5px 0;
  }
`;
const Times = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  & > div {
    width: 100%;
    height: 48px;
    background: #f8f8f8;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font: normal normal 500 15px/18px Pretendard;
    letter-spacing: -0.23px;
    color: #101010;

    & > svg {
      margin-right: 12px;
    }

    &.active {
      background: #485af3;
      color: #fff;
    }
  }
`;
const Footer = styled(DrawerFooter)`
  padding-bottom: 24px;
`;

const ErrorMessage = styled.div`
  margin: 10pt auto 10pt auto;
  font-size: 13pt;
  -webkit-letter-spacing: -0.13px;
  -moz-letter-spacing: -0.13px;
  -ms-letter-spacing: -0.13px;
  letter-spacing: -0.13px;
  color: #ed5d4e;
`;
