import CheckboxField from "components/CheckboxField";
import Form from "components/Form";
import { CounselKind, CounselKinds } from "components/JoinComponents";

import { Section } from ".";

const DoctorInfoStep2 = ({ register, formState: { errors } }) => {
  return (
    <>
      <Section>
        <Form.Row>
          <Form.Label>
            상담 방식* <span>(중복 선택 가능)</span>
          </Form.Label>

          <CounselKinds>
            <CounselKind>
              <CheckboxField label="화상" />
              <Form.BorderInput {...register("counsel_kind.video")} />원
            </CounselKind>
            <CounselKind>
              <CheckboxField label="음성" />
              <Form.BorderInput {...register("counsel_kind.voice")} />원
            </CounselKind>
            <CounselKind>
              <CheckboxField label="채팅" />
              <Form.BorderInput {...register("counsel_kind.chat")} />원
            </CounselKind>
            <CounselKind>
              <CheckboxField label="방문" />
              <Form.BorderInput {...register("counsel_kind.visit")} />원
            </CounselKind>
          </CounselKinds>
        </Form.Row>
      </Section>

      <Section>
        <div className="title">수납 정보 입력*</div>
        <Form.Row>
          <Form.InputField
            label="예금주"
            placeholder="홍길동"
            maxLength={10}
            {...register("account_info.holder", {
              required: { value: true, message: "예금주를 입력하세요." },
            })}
            error={errors?.account_info?.holder?.message}
          />
        </Form.Row>

        <Form.Row>
          <Form.InputField
            label="은행명"
            placeholder="우리은행"
            {...register("account_info.bank", {
              required: { value: true, message: "은행명을 입력하세요." },
            })}
            error={errors?.account_info?.bank?.message}
          />
        </Form.Row>

        <Form.Row>
          <Form.InputField
            label="계좌번호"
            placeholder="000-000-000000"
            {...register("account_info.account_number", {
              required: { value: true, message: "계좌번호를 입력하세요." },
            })}
            error={errors?.account_info?.account_number?.message}
          />
        </Form.Row>
      </Section>

      <Section>
        <div className="title">기관 정보 입력</div>
        <Form.Row>
          <Form.InputField
            label="소속기관"
            placeholder="기관명"
            {...register("organization_info.name")}
          />
        </Form.Row>
        <Form.Row>
          <Form.InputField
            label="연락처"
            placeholder="-없이 번호 입력"
            {...register("organization_info.phone_number")}
          />
        </Form.Row>
        <Form.Row>
          <Form.InputField
            label="주소"
            placeholder="지역명"
            {...register("organization_info.address")}
          />
        </Form.Row>

        <div className="title">자격사항 증빙자료 등록*</div>
      </Section>
    </>
  );
};
export default DoctorInfoStep2;
