import styled from "styled-components";

import { ReactComponent as StarSVG } from "assets/svg/star-outline.svg";
import { ReactComponent as CatSVG } from "assets/svg/cat-svgrepo-com.svg";
import { ReactComponent as ParachteSVG } from "assets/svg/parachute-box-solid-svgrepo-com.svg";
import { ReactComponent as TreeSVG } from "assets/svg/tree-branch-4-svgrepo-com.svg";
import { ReactComponent as RatSVG } from "assets/svg/noun-rat-626050.svg";
import { ReactComponent as ChickSVG } from "assets/svg/noun-chick-1097160.svg";

import { ReactComponent as Layers1SVG } from "assets/svg/layers_1.svg";
import { ReactComponent as Layers2SVG } from "assets/svg/layers_2.svg";
import { ReactComponent as Layers3SVG } from "assets/svg/layers_3.svg";
import { ReactComponent as TrashSVG } from "assets/svg/trash-outline.svg";

import theSea from "assets/images/theSea.png";
import wave from "assets/images/wave.png";
import volcano from "assets/images/volcano.png";

import { Rnd } from "react-rnd";
import React, { useEffect, useState } from "react";

export const Enable = {
  bottom: false,
  bottomLeft: false,
  bottomRight: false,
  left: false,
  right: false,
  top: false,
  topLeft: false,
  topRight: false,
};

const ExamineStep9 = ({ setResults, results, nextStep }) => {
  const [opacity, setOpacity] = useState(false);
  const [catList, setCatList] = useState([]);
  const [starList, setStarList] = useState([]);
  const [parachteList, setParachteList] = useState([]);
  const [treeList, setTreeList] = useState([]);
  const [ratList, setRatList] = useState([]);
  const [chickList, setChickList] = useState([]);

  const [starId, setStarId] = useState(1);
  const [catId, setCatId] = useState(1);
  const [parachteId, setParachteId] = useState(1);
  const [treeId, setTreeId] = useState(1);
  const [ratId, setRatId] = useState(1);
  const [chickId, setChickId] = useState(1);

  const [applyState, setApplyState] = useState(false);

  const apply = () => {
    setApplyState(true);
    if (applyState) nextStep();
  };

  const addComponent = (img) => {
    if (img === "cat") {
      if (catList.length === 3) return;
      setCatList([...catList, catId]);
      setResults({
        ...results,
        cats: [
          ...results.cats,
          {
            id: catId,
            size: "2",
            bold: "1",
          },
        ],
      });
      setCatId(catId + 1);
    } else if (img === "star") {
      if (starList.length === 3) return;
      setStarList([...starList, starId]);
      setResults({
        ...results,
        stars: [
          ...results.stars,
          {
            id: starId,
            size: "2",
            bold: "1",
          },
        ],
      });
      setStarId(starId + 1);
    } else if (img === "parachte") {
      if (parachteList.length === 3) return;
      setParachteList([...parachteList, parachteId]);
      setResults({
        ...results,
        parachutes: [
          ...results.parachutes,
          {
            id: parachteId,
            size: "2",
            bold: "1",
          },
        ],
      });
      setParachteId(parachteId + 1);
    } else if (img === "tree") {
      if (treeList.length === 3) return;
      setTreeList([...treeList, treeId]);
      setResults({
        ...results,
        trees: [
          ...results.trees,
          {
            id: treeId,
            size: "2",
            bold: "1",
          },
        ],
      });
      setTreeId(treeId + 1);
    } else if (img === "rat") {
      if (ratList.length === 3) return;
      setRatList([...ratList, ratId]);
      setResults({
        ...results,
        rats: [
          ...results.rats,
          {
            id: ratId,
            size: "2",
            bold: "1",
          },
        ],
      });
      setRatId(ratId + 1);
    } else if (img === "chick") {
      if (chickList.length === 3) return;
      setChickList([...chickList, chickId]);
      setResults({
        ...results,
        chicks: [
          ...results.chicks,
          {
            id: chickId,
            size: "2",
            bold: "1",
          },
        ],
      });
      setChickId(chickId + 1);
    }
  };
  return (
    <>
      <div style={{ margin: "0 auto", position: "relative" }}>
        <div style={{ textAlign: "center", margin: "0 auto" }}>
          {results.background === "화산" ? (
            <div
              className="examineStep9_Image"
              style={{
                width: "345px",
                height: "345px",
                border: "1px solid #E6E6E6",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 15px 0px",
                borderRadius: "5pt",
                backgroundImage: `url(${volcano})`,
                backgroundSize: "100% 100%",
              }}
            />
          ) : results.background === "해안가의 잔잔한 파도" ? (
            <div
              className="examineStep9_Image"
              style={{
                width: "345px",
                height: "345px",
                border: "1px solid #E6E6E6",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 15px 0px",
                borderRadius: "5pt",
                backgroundImage: `url(${theSea})`,
                backgroundSize: "100% 100%",
              }}
            />
          ) : results.background === "거친 파도" ? (
            <div
              className="examineStep9_Image"
              style={{
                width: "345px",
                height: "345px",
                margin: "0px 15px",
                border: "1px solid #E6E6E6",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 15px 0px",
                borderRadius: "5pt",
                backgroundImage: `url(${wave})`,
                backgroundSize: "100% 100%",
              }}
            />
          ) : (
            <></>
          )}
          {starList.map((id) => {
            return (
              <Rnd
                default={{
                  x: 0,
                  y: 0,
                  width: 140,
                }}
                enableResizing={Enable}
              >
                <div
                  id={`star_${id}`}
                  className="box"
                  style={{
                    opacity: opacity ? "0.6" : "1",
                    position: "absolute",
                    textAlign: "center",
                    resize: "both",
                  }}
                >
                  {!applyState ? (
                    <LayersButton>
                      <div
                        style={{
                          display: "inline-block",
                          borderRight: "solid 1px #e6e6e6",
                          paddingRight: "6px",
                        }}
                      >
                        <Layers1SVG
                          style={{ margin: "0 6px" }}
                          onTouchStart={() => {
                            results.stars.map((star) => {
                              if (star.id === id) {
                                star.bold = "1";
                              }
                              document.getElementById(
                                `starSVG_${id}`
                              ).style.strokeWidth = "10";
                            });
                          }}
                          onClick={() => {
                            results.stars.map((star) => {
                              if (star.id === id) {
                                star.bold = "1";
                              }
                            });
                            document.getElementById(
                              `starSVG_${id}`
                            ).style.strokeWidth = "10";
                          }}
                        />
                        <Layers2SVG
                          style={{ margin: "0 6px" }}
                          onTouchStart={() => {
                            results.stars.map((star) => {
                              if (star.id === id) {
                                star.bold = "2";
                              }
                            });
                            document.getElementById(
                              `starSVG_${id}`
                            ).style.strokeWidth = "30";
                          }}
                          onClick={() => {
                            results.stars.map((star) => {
                              if (star.id === id) {
                                star.bold = "2";
                              }
                            });
                            document.getElementById(
                              `starSVG_${id}`
                            ).style.strokeWidth = "30";
                          }}
                        />
                        <Layers3SVG
                          style={{ margin: "0 6px" }}
                          onTouchStart={() => {
                            results.stars.map((star) => {
                              if (star.id === id) {
                                star.bold = "3";
                              }
                            });
                            document.getElementById(
                              `starSVG_${id}`
                            ).style.strokeWidth = "50";
                          }}
                          onClick={() => {
                            results.stars.map((star) => {
                              if (star.id === id) {
                                star.bold = "3";
                              }
                            });
                            document.getElementById(
                              `starSVG_${id}`
                            ).style.strokeWidth = "50";
                          }}
                        />
                      </div>
                      <TrashSVG
                        style={{ marginLeft: "6px" }}
                        onTouchStart={() => {
                          setStarList(starList.filter((star) => star !== id));
                          results.stars = results.stars.filter(
                            (star) => star.id !== id
                          );
                        }}
                        onClick={() => {
                          setStarList(starList.filter((star) => star !== id));
                          results.stars = results.stars.filter(
                            (star) => star.id !== id
                          );
                        }}
                      />
                    </LayersButton>
                  ) : (
                    <div
                      style={{
                        width: "137.06px",
                        height: "39.47px",
                        marginBottom: "10px",
                      }}
                    ></div>
                  )}
                  <Rnd
                    default={{
                      x: 45,
                      y: 45,
                      width: 60,
                      height: 60,
                    }}
                    minHeight={30}
                    minWidth={30}
                    maxHeight={90}
                    maxWidth={90}
                    style={{
                      border: !applyState ? "solid 1px red" : "",
                      position: "initial",
                    }}
                    onResizeStop={(e, dir, refToElement, delta, position) => {
                      results.stars.map((star) => {
                        if (star.id === id) {
                          if (delta.width > 0 || delta.height > 0) {
                            star.size = "3";
                          } else if (delta.width < 0 || delta.height < 0) {
                            star.size = "1";
                          } else {
                            star.size = "2";
                          }
                        }
                      });
                    }}
                  >
                    <StarSVG
                      id={`starSVG_${id}`}
                      width={"100%"}
                      height={"100%"}
                    />
                  </Rnd>
                </div>
              </Rnd>
            );
          })}
          {catList.map((id) => (
            <Rnd
              default={{
                x: 0,
                y: 0,
                width: 140,
              }}
              enableResizing={Enable}
            >
              <div
                id={`cat_${id}`}
                className="box"
                style={{
                  opacity: opacity ? "0.6" : "1",
                  position: "absolute",
                  textAlign: "center",
                }}
              >
                {!applyState ? (
                  <LayersButton>
                    <div
                      style={{
                        display: "inline-block",
                        borderRight: "solid 1px #e6e6e6",
                        paddingRight: "6px",
                      }}
                    >
                      <Layers1SVG
                        style={{ margin: "0 6px" }}
                        onTouchStart={() => {
                          results.cats.map((cat) => {
                            if (cat.id === id) {
                              cat.bold = "1";
                            }
                          });
                          document.getElementById(
                            `catSVG_${id}`
                          ).style.strokeWidth = "10";
                        }}
                        onClick={() => {
                          results.cats.map((cat) => {
                            if (cat.id === id) {
                              cat.bold = "1";
                            }
                          });
                          document.getElementById(
                            `catSVG_${id}`
                          ).style.strokeWidth = "10";
                        }}
                      />
                      <Layers2SVG
                        style={{ margin: "0 6px" }}
                        onTouchStart={() => {
                          results.cats.map((cat) => {
                            if (cat.id === id) {
                              cat.bold = "2";
                            }
                          });
                          document.getElementById(
                            `catSVG_${id}`
                          ).style.strokeWidth = "30";
                        }}
                        onClick={() => {
                          results.cats.map((cat) => {
                            if (cat.id === id) {
                              cat.bold = "2";
                            }
                          });
                          document.getElementById(
                            `catSVG_${id}`
                          ).style.strokeWidth = "30";
                        }}
                      />
                      <Layers3SVG
                        style={{ margin: "0 6px" }}
                        onTouchStart={() => {
                          results.cats.map((cat) => {
                            if (cat.id === id) {
                              cat.bold = "3";
                            }
                          });
                          document.getElementById(
                            `catSVG_${id}`
                          ).style.strokeWidth = "50";
                        }}
                        onClick={() => {
                          results.cats.map((cat) => {
                            if (cat.id === id) {
                              cat.bold = "3";
                            }
                          });
                          document.getElementById(
                            `catSVG_${id}`
                          ).style.strokeWidth = "50";
                        }}
                      />
                    </div>
                    <TrashSVG
                      style={{ marginLeft: "6px" }}
                      onTouchStart={() => {
                        results.cats = results.cats.filter(
                          (cat) => cat.id !== id
                        );
                        setCatList(catList.filter((cat) => cat !== id));
                      }}
                      onClick={() => {
                        results.cats = results.cats.filter(
                          (cat) => cat.id !== id
                        );
                        setCatList(catList.filter((cat) => cat !== id));
                      }}
                    />
                  </LayersButton>
                ) : (
                  <div
                    style={{
                      width: "137.06px",
                      height: "39.47px",
                      marginBottom: "10px",
                    }}
                  ></div>
                )}
                <Rnd
                  default={{
                    x: 45,
                    y: 45,
                    width: 60,
                    height: 60,
                  }}
                  minHeight={30}
                  minWidth={30}
                  maxHeight={90}
                  maxWidth={90}
                  style={{
                    border: !applyState ? "solid 1px red" : "",
                    position: "initial",
                  }}
                  onResizeStop={(e, dir, refToElement, delta, position) => {
                    results.cats.map((cat) => {
                      if (cat.id === id) {
                        if (delta.width > 0 || delta.height > 0) {
                          cat.size = "3";
                        } else if (delta.width < 0 || delta.height < 0) {
                          cat.size = "1";
                        } else {
                          cat.size = "2";
                        }
                      }
                    });
                  }}
                >
                  <CatSVG id={`catSVG_${id}`} width={"100%"} height={"100%"} />
                </Rnd>
              </div>
            </Rnd>
          ))}

          {parachteList.map((id) => (
            <Rnd
              default={{
                x: 0,
                y: 0,
                width: 140,
              }}
              enableResizing={Enable}
            >
              <div
                id={`parachte_${id}`}
                className="box"
                style={{
                  opacity: opacity ? "0.6" : "1",
                  position: "absolute",
                  textAlign: "center",
                }}
              >
                {!applyState ? (
                  <LayersButton>
                    <div
                      style={{
                        display: "inline-block",
                        borderRight: "solid 1px #e6e6e6",
                        paddingRight: "6px",
                      }}
                    >
                      <Layers1SVG
                        style={{ margin: "0 6px" }}
                        onTouchStart={() => {
                          results.parachutes.map((parachute) => {
                            if (parachute.id === id) {
                              parachute.bold = "1";
                            }
                          });
                          document.getElementById(
                            `parachteSVG_${id}`
                          ).style.strokeWidth = "10";
                        }}
                        onClick={() => {
                          results.parachutes.map((parachute) => {
                            if (parachute.id === id) {
                              parachute.bold = "1";
                            }
                          });
                          document.getElementById(
                            `parachteSVG_${id}`
                          ).style.strokeWidth = "10";
                        }}
                      />
                      <Layers2SVG
                        style={{ margin: "0 6px" }}
                        onTouchStart={() => {
                          results.parachutes.map((parachute) => {
                            if (parachute.id === id) {
                              parachute.bold = "2";
                            }
                          });
                          document.getElementById(
                            `parachteSVG_${id}`
                          ).style.strokeWidth = "30";
                        }}
                        onClick={() => {
                          results.parachutes.map((parachute) => {
                            if (parachute.id === id) {
                              parachute.bold = "2";
                            }
                          });
                          document.getElementById(
                            `parachteSVG_${id}`
                          ).style.strokeWidth = "30";
                        }}
                      />
                      <Layers3SVG
                        style={{ margin: "0 6px" }}
                        onTouchStart={() => {
                          results.parachutes.map((parachute) => {
                            if (parachute.id === id) {
                              parachute.bold = "3";
                            }
                          });
                          document.getElementById(
                            `parachteSVG_${id}`
                          ).style.strokeWidth = "50";
                        }}
                        onClick={() => {
                          results.parachutes.map((parachute) => {
                            if (parachute.id === id) {
                              parachute.bold = "3";
                            }
                          });
                          document.getElementById(
                            `parachteSVG_${id}`
                          ).style.strokeWidth = "50";
                        }}
                      />
                    </div>
                    <TrashSVG
                      style={{ marginLeft: "6px" }}
                      onTouchStart={() => {
                        results.parachutes = results.parachutes.filter(
                          (parachute) => parachute.id !== id
                        );
                        setParachteList(
                          parachteList.filter((parachte) => parachte !== id)
                        );
                      }}
                      onClick={() => {
                        results.parachutes = results.parachutes.filter(
                          (parachute) => parachute.id !== id
                        );
                        setParachteList(
                          parachteList.filter((parachte) => parachte !== id)
                        );
                      }}
                    />
                  </LayersButton>
                ) : (
                  <div
                    style={{
                      width: "137.06px",
                      height: "39.47px",
                      marginBottom: "10px",
                    }}
                  ></div>
                )}
                <Rnd
                  default={{
                    x: 45,
                    y: 45,
                    width: 60,
                    height: 60,
                  }}
                  minHeight={30}
                  minWidth={30}
                  maxHeight={90}
                  maxWidth={90}
                  style={{
                    border: !applyState ? "solid 1px red" : "",
                    position: "initial",
                  }}
                  onResizeStop={(e, dir, refToElement, delta, position) => {
                    results.parachutes.map((parachute) => {
                      if (parachute.id === id) {
                        if (delta.width > 0 || delta.height > 0) {
                          parachute.size = "3";
                        } else if (delta.width < 0 || delta.height < 0) {
                          parachute.size = "1";
                        } else {
                          parachute.size = "2";
                        }
                      }
                    });
                  }}
                >
                  <ParachteSVG
                    id={`parachteSVG_${id}`}
                    width={"100%"}
                    height={"100%"}
                  />
                </Rnd>
              </div>
            </Rnd>
          ))}
          {treeList.map((id) => (
            <Rnd
              default={{
                x: 0,
                y: 0,
                width: 140,
              }}
              enableResizing={Enable}
            >
              <div
                className="box"
                style={{
                  opacity: opacity ? "0.6" : "1",
                  position: "absolute",
                  textAlign: "center",
                }}
              >
                {!applyState ? (
                  <LayersButton>
                    <div
                      style={{
                        display: "inline-block",
                        borderRight: "solid 1px #e6e6e6",
                        paddingRight: "6px",
                      }}
                    >
                      <Layers1SVG
                        style={{ margin: "0 6px" }}
                        onTouchStart={() => {
                          results.trees.map((tree) => {
                            if (tree.id === id) {
                              tree.bold = "1";
                            }
                          });
                          document.getElementById(
                            `treeSVG_${id}`
                          ).style.strokeWidth = "10";
                        }}
                        onClick={() => {
                          results.trees.map((tree) => {
                            if (tree.id === id) {
                              tree.bold = "1";
                            }
                          });
                          document.getElementById(
                            `treeSVG_${id}`
                          ).style.strokeWidth = "10";
                        }}
                      />
                      <Layers2SVG
                        style={{ margin: "0 6px" }}
                        onTouchStart={() => {
                          results.trees.map((tree) => {
                            if (tree.id === id) {
                              tree.bold = "2";
                            }
                          });
                          document.getElementById(
                            `treeSVG_${id}`
                          ).style.strokeWidth = "30";
                        }}
                        onClick={() => {
                          results.trees.map((tree) => {
                            if (tree.id === id) {
                              tree.bold = "2";
                            }
                          });
                          document.getElementById(
                            `treeSVG_${id}`
                          ).style.strokeWidth = "30";
                        }}
                      />
                      <Layers3SVG
                        style={{ margin: "0 6px" }}
                        onTouchStart={() => {
                          results.trees.map((tree) => {
                            if (tree.id === id) {
                              tree.bold = "3";
                            }
                          });
                          document.getElementById(
                            `treeSVG_${id}`
                          ).style.strokeWidth = "50";
                        }}
                        onClick={() => {
                          results.trees.map((tree) => {
                            if (tree.id === id) {
                              tree.bold = "3";
                            }
                          });
                          document.getElementById(
                            `treeSVG_${id}`
                          ).style.strokeWidth = "50";
                        }}
                      />
                    </div>
                    <TrashSVG
                      style={{ marginLeft: "6px" }}
                      onTouchStart={() => {
                        results.trees = results.trees.filter(
                          (tree) => tree.id !== id
                        );
                        setTreeList(treeList.filter((tree) => tree !== id));
                      }}
                      onClick={() => {
                        results.trees = results.trees.filter(
                          (tree) => tree.id !== id
                        );
                        setTreeList(treeList.filter((tree) => tree !== id));
                      }}
                    />
                  </LayersButton>
                ) : (
                  <div
                    style={{
                      width: "137.06px",
                      height: "39.47px",
                      marginBottom: "10px",
                    }}
                  ></div>
                )}
                <Rnd
                  default={{
                    x: 45,
                    y: 45,
                    width: 60,
                    height: 60,
                  }}
                  minHeight={30}
                  minWidth={30}
                  maxHeight={90}
                  maxWidth={90}
                  style={{
                    border: !applyState ? "solid 1px red" : "",
                    position: "initial",
                  }}
                  onResizeStop={(e, dir, refToElement, delta, position) => {
                    results.trees.map((tree) => {
                      if (tree.id === id) {
                        if (delta.width > 0 || delta.height > 0) {
                          tree.size = "3";
                        } else if (delta.width < 0 || delta.height < 0) {
                          tree.size = "1";
                        } else {
                          tree.size = "2";
                        }
                      }
                    });
                  }}
                >
                  <TreeSVG
                    id={`treeSVG_${id}`}
                    width={"100%"}
                    height={"100%"}
                  />
                </Rnd>
              </div>
            </Rnd>
          ))}
          {ratList.map((id) => (
            <Rnd
              default={{
                x: 0,
                y: 0,
                width: 140,
              }}
              enableResizing={Enable}
            >
              <div
                className="box"
                style={{
                  opacity: opacity ? "0.6" : "1",
                  position: "absolute",
                  textAlign: "center",
                }}
              >
                {!applyState ? (
                  <LayersButton>
                    <div
                      style={{
                        display: "inline-block",
                        borderRight: "solid 1px #e6e6e6",
                        paddingRight: "6px",
                      }}
                    >
                      <Layers1SVG
                        style={{ margin: "0 6px" }}
                        onTouchStart={() => {
                          results.rats.map((rat) => {
                            if (rat.id === id) {
                              rat.bold = "1";
                            }
                          });
                          document.getElementById(
                            `ratSVG_${id}`
                          ).style.strokeWidth = "10";
                        }}
                        onClick={() => {
                          results.rats.map((rat) => {
                            if (rat.id === id) {
                              rat.bold = "1";
                            }
                          });
                          document.getElementById(
                            `ratSVG_${id}`
                          ).style.strokeWidth = "10";
                        }}
                      />
                      <Layers2SVG
                        style={{ margin: "0 6px" }}
                        onTouchStart={() => {
                          results.rats.map((rat) => {
                            if (rat.id === id) {
                              rat.bold = "2";
                            }
                          });
                          document.getElementById(
                            `ratSVG_${id}`
                          ).style.strokeWidth = "30";
                        }}
                        onClick={() => {
                          results.rats.map((rat) => {
                            if (rat.id === id) {
                              rat.bold = "2";
                            }
                          });
                          document.getElementById(
                            `ratSVG_${id}`
                          ).style.strokeWidth = "30";
                        }}
                      />
                      <Layers3SVG
                        style={{ margin: "0 6px" }}
                        onTouchStart={() => {
                          results.rats.map((rat) => {
                            if (rat.id === id) {
                              rat.bold = "3";
                            }
                          });
                          document.getElementById(
                            `ratSVG_${id}`
                          ).style.strokeWidth = "50";
                        }}
                        onClick={() => {
                          results.rats.map((rat) => {
                            if (rat.id === id) {
                              rat.bold = "3";
                            }
                          });
                          document.getElementById(
                            `ratSVG_${id}`
                          ).style.strokeWidth = "50";
                        }}
                      />
                    </div>
                    <TrashSVG
                      style={{ marginLeft: "6px" }}
                      onTouchStart={() => {
                        results.rats = results.rats.filter(
                          (rat) => rat.id !== id
                        );
                        setRatList(ratList.filter((rat) => rat !== id));
                      }}
                      onClick={() => {
                        results.rats = results.rats.filter(
                          (rat) => rat.id !== id
                        );
                        setRatList(ratList.filter((rat) => rat !== id));
                      }}
                    />
                  </LayersButton>
                ) : (
                  <div
                    style={{
                      width: "137.06px",
                      height: "39.47px",
                      marginBottom: "10px",
                    }}
                  ></div>
                )}
                <Rnd
                  default={{
                    x: 45,
                    y: 45,
                    width: 60,
                    height: 60,
                  }}
                  minHeight={30}
                  minWidth={30}
                  maxHeight={90}
                  maxWidth={90}
                  style={{
                    border: !applyState ? "solid 1px red" : "",
                    position: "initial",
                  }}
                  onResizeStop={(e, dir, refToElement, delta, position) => {
                    results.rats.map((rat) => {
                      if (rat.id === id) {
                        if (delta.width > 0 || delta.height > 0) {
                          rat.size = "3";
                        } else if (delta.width < 0 || delta.height < 0) {
                          rat.size = "1";
                        } else {
                          rat.size = "2";
                        }
                      }
                    });
                  }}
                >
                  <RatSVG id={`ratSVG_${id}`} width={"100%"} height={"100%"} />
                </Rnd>
              </div>
            </Rnd>
          ))}
          {chickList.map((id) => (
            <Rnd
              default={{
                x: 0,
                y: 0,
                width: 140,
              }}
              enableResizing={Enable}
            >
              <div
                className="box"
                style={{
                  opacity: opacity ? "0.6" : "1",
                  position: "absolute",
                  textAlign: "center",
                }}
              >
                {!applyState ? (
                  <LayersButton>
                    <div
                      style={{
                        display: "inline-block",
                        borderRight: "solid 1px #e6e6e6",
                        paddingRight: "6px",
                      }}
                    >
                      <Layers1SVG
                        style={{ margin: "0 6px" }}
                        onTouchStart={() => {
                          results.chicks.map((chick) => {
                            if (chick.id === id) {
                              chick.bold = "1";
                            }
                          });
                          document.getElementById(
                            `chickSVG_${id}`
                          ).style.strokeWidth = "10";
                        }}
                        onClick={() => {
                          results.chicks.map((chick) => {
                            if (chick.id === id) {
                              chick.bold = "1";
                            }
                          });
                          document.getElementById(
                            `chickSVG_${id}`
                          ).style.strokeWidth = "10";
                        }}
                      />
                      <Layers2SVG
                        style={{ margin: "0 6px" }}
                        onTouchStart={() => {
                          results.chicks.map((chick) => {
                            if (chick.id === id) {
                              chick.bold = "2";
                            }
                          });
                          document.getElementById(
                            `chickSVG_${id}`
                          ).style.strokeWidth = "30";
                        }}
                        onClick={() => {
                          results.chicks.map((chick) => {
                            if (chick.id === id) {
                              chick.bold = "2";
                            }
                          });
                          document.getElementById(
                            `chickSVG_${id}`
                          ).style.strokeWidth = "30";
                        }}
                      />
                      <Layers3SVG
                        style={{ margin: "0 6px" }}
                        onTouchStart={() => {
                          results.chicks.map((chick) => {
                            if (chick.id === id) {
                              chick.bold = "3";
                            }
                          });
                          document.getElementById(
                            `chickSVG_${id}`
                          ).style.strokeWidth = "50";
                        }}
                        onClick={() => {
                          results.chicks.map((chick) => {
                            if (chick.id === id) {
                              chick.bold = "3";
                            }
                          });
                          document.getElementById(
                            `chickSVG_${id}`
                          ).style.strokeWidth = "50";
                        }}
                      />
                    </div>
                    <TrashSVG
                      style={{ marginLeft: "6px" }}
                      onTouchStart={() => {
                        results.chicks = results.chicks.filter(
                          (chick) => chick.id !== id
                        );
                        setChickList(chickList.filter((chick) => chick !== id));
                      }}
                      onClick={() => {
                        results.chicks = results.chicks.filter(
                          (chick) => chick.id !== id
                        );
                        setChickList(chickList.filter((chick) => chick !== id));
                      }}
                    />
                  </LayersButton>
                ) : (
                  <div
                    style={{
                      width: "137.06px",
                      height: "39.47px",
                      marginBottom: "10px",
                    }}
                  ></div>
                )}
                <Rnd
                  default={{
                    x: 45,
                    y: 45,
                    width: 60,
                    height: 60,
                  }}
                  minHeight={30}
                  minWidth={30}
                  maxHeight={90}
                  maxWidth={90}
                  style={{
                    border: !applyState ? "solid 1px red" : "",
                    position: "initial",
                  }}
                  onResizeStop={(e, dir, refToElement, delta, position) => {
                    results.chicks.map((chick) => {
                      if (chick.id === id) {
                        if (delta.width > 0 || delta.height > 0) {
                          chick.size = "3";
                        } else if (delta.width < 0 || delta.height < 0) {
                          chick.size = "1";
                        } else {
                          chick.size = "2";
                        }
                      }
                    });
                  }}
                >
                  <ChickSVG
                    id={`chickSVG_${id}`}
                    width={"100%"}
                    height={"100%"}
                  />
                </Rnd>
              </div>
            </Rnd>
          ))}
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
        <Icon
          className="examineStep9_Icon"
          onClick={() => {
            addComponent("star");
          }}
        >
          <StarSVG
            width={30}
            height={30}
            strokeWidth={35}
            style={{ marginTop: "13px" }}
          />
        </Icon>
        <Icon
          className="examineStep9_Icon"
          onClick={() => {
            addComponent("cat");
          }}
        >
          <CatSVG
            width={30}
            height={30}
            strokeWidth={35}
            style={{ marginTop: "13px" }}
          />
        </Icon>

        <Icon
          className="examineStep9_Icon"
          onClick={() => {
            addComponent("parachte");
          }}
        >
          <ParachteSVG
            width={30}
            height={30}
            strokeWidth={35}
            style={{ marginTop: "13px" }}
          />
        </Icon>
        <Icon
          className="examineStep9_Icon"
          onClick={() => {
            addComponent("tree");
          }}
        >
          <TreeSVG
            width={30}
            height={30}
            strokeWidth={35}
            style={{ marginTop: "13px" }}
          />
        </Icon>
        <Icon
          className="examineStep9_Icon"
          onClick={() => {
            addComponent("rat");
          }}
        >
          <RatSVG
            width={30}
            height={30}
            strokeWidth={35}
            style={{ marginTop: "13px" }}
          />
        </Icon>
        <Icon
          className="examineStep9_Icon"
          onClick={() => {
            addComponent("chick");
          }}
        >
          <ChickSVG
            width={30}
            height={30}
            strokeWidth={35}
            style={{ marginTop: "13px" }}
          />
        </Icon>
      </div>
      <div style={{ margin: "21px 0 0 10px" }}>
        * 한 종류의 스티커는 최대 3개까지 배치할 수 있어요.
      </div>
      <NextButton
        style={{
          background: `${
            !applyState
              ? "#333333 0% 0% no-repeat padding-box"
              : "#485AF3 0% 0% no-repeat padding-box"
          }`,
        }}
        onClick={() => {
          apply();
        }}
      >
        {!applyState ? "적용하기" : "완성했어요"}
      </NextButton>
    </>
  );
};

export default ExamineStep9;

const NextButton = styled.button`
  margin: 28px 0 0 0;
  width: 271px;
  height: 48px;
  border-radius: 10px;
  font: normal normal 500 16px/19px Pretendard;
  letter-spacing: -0.16px;
  color: #ffffff;
  margin-left: auto;
  margin-right: auto;
`;

const Icon = styled.div`
  display: inline-block;
  width: 60px;
  height: 60px;
  border: 1px solid #e6e6e6;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 15px 0px;
  border-radius: 5pt;
  margin: 0 4px;
`;

const LayersButton = styled.div`
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 15px 0px;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 9px 10px;
  margin-bottom: 10px;
`;
