import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";

import client from "apis/client";
import useRefund from "hooks/useRefund";
import useNavigate from "hooks/useNavigate";

const statusToLabel = (status) => {
  switch (status) {
    case "ready":
      return "미결제";
    case "paid":
      return "결제완료";
    case "cancelled":
      return "결제취소";
    case "failed":
      return "결제실패";
    default:
      return "";
  }
};

const MyReservationPaymentInfo = () => {
  const { goMyReservationPaymentRefund } = useNavigate();
  const { reservationId } = useParams();
  const [counselKind, setCounselKind] = useState("");
  const [doctorName, setDoctorName] = useState("");
  const [confirmDate, setConfirmDate] = useState(null);
  const [payment, setPayment] = useState(null);

  const { setReservation } = useRefund();

  const fetch = useCallback(async () => {
    if (!reservationId) return;

    const { data } = await client.get(`/user/reservations/${reservationId}`);
    const {
      counsel_kind,
      confirm_date,
      doctor: { name },
      payments,
    } = data;
    setReservation(data);

    setCounselKind(counsel_kind);
    setDoctorName(name);
    setConfirmDate(confirm_date);
    setPayment(payments);
  }, [reservationId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <Wrapper>
      <Counsel>
        <div className="title">{counselKind} 상담</div>
        <div>{doctorName} 상담사 (50분 x 1회)</div>
      </Counsel>

      <PaymentStatus>
        <div className="title">
          <span>결제 정보</span>
        </div>
        <div className="status">{statusToLabel(payment?.status)}</div>
      </PaymentStatus>

      <InfoRow>
        <label>결제 일시</label>
        <div>
          {payment ? moment(payment.created_at).format("YY.MM.DD hh:mm") : ""}
        </div>
      </InfoRow>
      <InfoRow>
        <label>결제 수단</label>
        <div>{payment?.pay_method}</div>
      </InfoRow>
      <InfoRow>
        <label>상품 금액</label>
        <div>{payment?.amount.toLocaleString()}원</div>
      </InfoRow>

      <Divider />

      <PaymentTotal>
        <label>최종 결제 금액</label>
        <div>{payment?.amount.toLocaleString()}원</div>
      </PaymentTotal>

      <ActionRow>
        {(!confirmDate ||
          moment().diff(moment(confirmDate), "minutes") < 0) && (
          <RefundButton
            onClick={() => goMyReservationPaymentRefund(reservationId)}
          >
            환불 신청
          </RefundButton>
        )}
      </ActionRow>
    </Wrapper>
  );
};
export default MyReservationPaymentInfo;

const Wrapper = styled.section`
  width: 100%;
  height: 100%;
  padding: 20px 15px 0 15px;
  /* overflow: hidden; */
  overflow-y: auto;
  padding-bottom: 100px;
`;
const Counsel = styled.div`
  padding: 24px;
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 15px;
  font: normal normal 400 14px/17px Pretendard;
  letter-spacing: -0.14px;
  color: #6e6e6e;
  margin-bottom: 36px;

  .title {
    font: normal normal 600 20px/24px Pretendard;
    letter-spacing: -0.2px;
    color: #101010;
    margin-bottom: 11px;
  }
`;
const PaymentStatus = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 44px;

  .title {
    font: normal normal 600 18px/21px Pretendard;
    letter-spacing: -0.36px;
    color: #101010;
  }

  .status {
    width: 76px;
    background: #f0f2ff 0% 0% no-repeat padding-box;
    border-radius: 5px;
    font: normal normal 500 14px/17px Pretendard;
    letter-spacing: 0px;
    color: #485af3;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 0;
  }
`;
const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  font: normal normal 500 15px/18px Pretendard;
  letter-spacing: -0.15px;
  color: #101010;

  label {
    font: normal normal 400 15px/18px Pretendard;
    letter-spacing: -0.3px;
    color: #999999;
  }

  & + & {
    margin-top: 16px;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #e6e6e6;
  margin: 28px 0;
`;

const PaymentTotal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background: #f8f8f8;
  border-radius: 10px;
  width: 100%;
  font: normal normal bold 20px/24px Pretendard;
  letter-spacing: -0.2px;
  color: #1a1a1a;
  padding: 28px 16px;

  label {
    font: normal normal 500 16px/19px Pretendard;
    letter-spacing: -0.16px;
    color: #101010;
    margin-bottom: 10px;
  }
`;

const ActionRow = styled.div`
  width: 100%;
  margin-top: 19px;
  display: flex;
  justify-content: flex-end;
`;

const RefundButton = styled.button`
  width: 88px;
  height: 31px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #efefef;
  border-radius: 5px;
  font: normal normal 500 14px/17px Pretendard;
  letter-spacing: -0.21px;
  color: #6e6e6e;
`;
