import { useEffect, useRef, useState, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import qs from "qs";

import { ReactComponent as ActionSVG } from "assets/svg/actions.svg";
import { ReactComponent as ImageSVG } from "assets/svg/image.svg";
import { ReactComponent as BrushSVG } from "assets/svg/brush.svg";
import { ReactComponent as ReceiverSVG } from "assets/svg/receiver.svg";
import { ReactComponent as ChatBubbleSVG } from "assets/svg/chatbubble.svg";
import MessageSection from "components/ConsultingRoom/MessageSection";
import VideoSection from "components/ConsultingRoom/VideoSection";
import Palette from "containers/Palette";
import { getIdsFromUniqueName } from "utils/twilio";
import { PaletteMessage, CallMessage } from "utils/constant";
import client from "apis/client";
import moment from "moment";
import useAuth from "hooks/useAuth";
import {
  checkRNVideoScreenSupport,
  MobileOS,
  getAppInfo,
} from "utils/checkMobile";
import DefaultImage from "assets/images/default.png";
import CallWaitingSection from "components/ConsultingRoom/CallWaitingSection";
import { friendlyName } from "atomStates";
import { useRecoilState } from "recoil";

const initLastMessage = {
  state: { body: "" },
  attachedMedia: [],
};

const initRequestAuthor = {
  image: DefaultImage,
  name: "내담자",
};

const ConsultingRoom = ({
  conversations,
  twilioIdentity,
  onLeaveRoom,
  isChatScroll,
  rollbackChatScrollState,
}) => {
  const imageRef = useRef(null);
  const messageRef = useRef(null);
  const { userType } = useAuth();
  const messageInputRef = useRef(null);
  const { roomId } = useParams();
  const [isPaletteOn, setPaletteOn] = useState(false);
  const [conversation, setConversation] = useState(null);
  const [sharedImage, setSharedImage] = useState(null); // 화상채팅 중 공유된 이미지.
  const [isOnPalette, setOnPalette] = useState(false);
  const [isDrawing, setIsDrawing] = useState(false);
  const [lastMessage, setLastMessage] = useState(initLastMessage);
  const [isCallRequest, setCallRequest] = useState(false);
  const [isOpenCallModal, setOpenCallModal] = useState(false);
  const [showBubbleMessage, setShowBubbleMessage] = useState(false);
  const [isMessageAdded, setMessageAdded] = useState(false);
  const [requestAuthor, setRequestAuthor] = useState(initRequestAuthor);
  const appVideoScreenSupport = useMemo(() => checkRNVideoScreenSupport(), []);
  const [friendly, setFriendly] = useRecoilState(friendlyName);

  const [onVideoCall, setOnVideoCall] = useState(false);

  const [showActions, setShowActions] = useState(false);
  const [messages, setMessages] = useState([]);

  const { isMinddrawingApp, platform, appVersion } = getAppInfo();
  const doctorId = useMemo(
    () => (conversation?.uniqueName ?? "").split("-user")[0].split("doctor")[1],
    [conversation?.uniqueName]
  );

  useEffect(() => {
    if (
      conversation?.uniqueName &&
      twilioIdentity &&
      qs.parse(window.location.search, { ignoreQueryPrefix: true })
        ?.video_on === "1"
    ) {
      setOnVideoCall(true);
    }
  }, [conversation?.uniqueName, twilioIdentity]);

  useEffect(() => {
    if (!conversation) return;

    if (userType === "client") {
      client
        .put("/user/counsel_rooms", {
          user_last_visited_at: moment().format("YYYY-MM-DD HH:mm:ss"),
          name: conversation.channelState.uniqueName,
        })
        .then((res) => {});
    } else {
      client
        .put("/doctor/counsel_rooms", {
          doctor_last_visited_at: moment().format("YYYY-MM-DD HH:mm:ss"),
          name: conversation.channelState.uniqueName,
        })
        .then((res) => {});
    }
  }, [conversation, userType]);

  const sendMessage = useCallback(async () => {
    let inputMessage = messageInputRef.current.value;
    if (!conversation || !inputMessage) return;

    if (userType === "client") {
      client
        .put("/user/counsel_rooms", {
          user_last_sent_at: moment().format("YYYY-MM-DD HH:mm:ss"),
          name: conversation.channelState.uniqueName,
        })
        .then((res) => {});
    } else {
      client
        .put("/doctor/counsel_rooms", {
          doctor_last_sent_at: moment().format("YYYY-MM-DD HH:mm:ss"),
          name: conversation.channelState.uniqueName,
        })
        .then((res) => {});
    }

    conversation.sendMessage(inputMessage).catch((e) => {
      console.log(e);
      window.location.reload();
    });
    messageInputRef.current.value = "";
    messageInputRef.current.focus();
  }, [conversation, userType]);

  const checkInLastView = useCallback(() => {
    if (!messageRef.current) return false;
    return (
      messageRef.current.scrollHeight - messageRef.current.scrollTop <
      window.outerHeight * 1.5
    );
  }, []);

  const scrollToLastMessage = useCallback(
    (isForced = false) => {
      if (!messageRef.current) {
        return;
      }

      const isLastView = checkInLastView();

      if (isLastView || isForced) {
        messageRef.current.scrollTop = messageRef.current.scrollHeight;
      }
    },
    [checkInLastView]
  );

  const showLastMessage = () => {
    setShowBubbleMessage(true);
    setTimeout(() => {
      setShowBubbleMessage(false);
    }, 3000);
  };

  // 화상상담에서 채팅상담실로 돌아왔을 때 최신메세지로 스크롤을 이동
  useEffect(() => {
    if (isChatScroll) {
      scrollToLastMessage(true);
      setCallRequest(false);
      rollbackChatScrollState();
    }
  }, [isChatScroll, rollbackChatScrollState, scrollToLastMessage]);

  const triggerPalette = useCallback(() => {
    setPaletteOn(!isPaletteOn);
  }, [isPaletteOn]);

  const sendImage = useCallback(
    async (file) => {
      conversation.sendMessage({ contentType: file.type, media: file });
    },
    [conversation]
  );

  const enterMessage = useCallback(
    (e) => {
      if (e.keyCode === 13) {
        if (messageInputRef.current.value) {
          sendMessage();
        }
      }
    },
    [sendMessage]
  );

  const checkIsMyMessage = useCallback(
    (author) => {
      if (author.includes("user") && userType === "client") {
        return true;
      }

      if (author.includes("doctor") && userType === "doctor") {
        return true;
      }

      return false;
    },
    [userType]
  );

  useEffect(() => {
    if (conversation) {
      try {
        conversation
          .getMessages()
          .then((msgs) => {
            setMessages(msgs.items);
            setLastMessage(msgs.items[msgs.items.length - 1]);
            setTimeout(() => {
              scrollToLastMessage(true);
            }, 100);
          })
          .catch((e) => {
            console.log(e);
            window.location.reload();
          });

        conversation.on("messageAdded", (message) => {
          setMessageAdded(true);
          setLastMessage(message);

          const isLastView = checkInLastView();
          const isMyMessage = checkIsMyMessage(message.state.author);
          if (!isLastView && !isMyMessage) {
            showLastMessage(message.state.body);
          }

          const ids = messages.find((message) => message.sid);

          if (ids === undefined) {
            setMessages((prev) => [...prev, message]);
          }
        });
      } catch (e) {
        console.error("COnsultion Room Conversation Get Message Error", e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation]);

  useEffect(() => {
    scrollToLastMessage();
  }, [lastMessage, scrollToLastMessage]);

  useEffect(() => {
    if (lastMessage?.attachedMedia.length) {
      // 받은 메세지가 미디어(이미지) 포함 메세지면,
      (async () => {
        const url = await lastMessage.attachedMedia[0].getCachedTemporaryUrl();
        setSharedImage(url);
      })();
    } else {
      setSharedImage(null);
    }

    if (isMessageAdded) {
      if (lastMessage.state.body === PaletteMessage.StartPalette) {
        setIsDrawing(checkIsMyMessage(lastMessage.state.author));
        setOnPalette(true);
      } else if (lastMessage.state.body === PaletteMessage.EndPalette) {
        if (!(isMinddrawingApp && appVideoScreenSupport)) {
          alert(PaletteMessage.EndPalette);
        }
        setOnPalette(false);
        setIsDrawing(false);
        setLastMessage(initLastMessage);
      }
    }
  }, [
    appVideoScreenSupport,
    checkIsMyMessage,
    isMessageAdded,
    isMinddrawingApp,
    lastMessage,
  ]);

  useEffect(() => {
    return () => {
      onLeaveRoom();
    };
  }, [onLeaveRoom]);

  const callRNVideoView = useCallback(() => {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: "NAVIGATION_VIDEO",
        params: {
          userType,
          isCallRequest,
          roomName: conversation?.uniqueName,
          identity: twilioIdentity,
        },
      })
    );
  }, [conversation?.uniqueName, isCallRequest, twilioIdentity, userType]);

  const fetchVideoSection = useCallback(() => {
    if (isMinddrawingApp && appVideoScreenSupport) {
      // 앱이라면 딥링크로 앱 화면을 연다
      setOnVideoCall(false);
      callRNVideoView();
      return null;
    }

    const sendTwilioMessage = (message) => {
      conversation.sendMessage(message);
    };

    return (
      <VideoSection
        identity={twilioIdentity}
        triggerPalette={triggerPalette}
        roomName={conversation?.uniqueName}
        sharedImage={sharedImage}
        removeSharedImage={() => setSharedImage(null)}
        isOnPalette={isOnPalette}
        onVideoCall={onVideoCall}
        isDrawing={isDrawing}
        isCallRequest={isCallRequest}
        sendTwilioMessage={sendTwilioMessage}
        lastMessage={lastMessage.state.body}
        handleClose={() => {
          setSharedImage(null);
          setOnVideoCall(false);
          setCallRequest(false);
          setTimeout(() => scrollToLastMessage(true), 10);
        }}
        handleUpload={sendImage}
      />
    );
  }, [
    appVideoScreenSupport,
    callRNVideoView,
    conversation,
    isCallRequest,
    isDrawing,
    isMinddrawingApp,
    isOnPalette,
    lastMessage?.state?.body,
    onVideoCall,
    scrollToLastMessage,
    sendImage,
    sharedImage,
    triggerPalette,
    twilioIdentity,
  ]);

  useEffect(() => {
    const conversation = conversations.find((c) => c.uniqueName === roomId);
    if (conversation) {
      setFriendly(conversation.friendlyName);
    }
    setConversation(conversation);
  }, [conversations, roomId]);

  useEffect(() => {
    if (showActions) {
      scrollToLastMessage();
    }
  }, [scrollToLastMessage, showActions]);

  const triggerVideoCall = useCallback(() => {
    if (
      isMinddrawingApp &&
      platform === MobileOS.android &&
      appVersion === "1.0.0"
    ) {
      alert("화상상담을 위해서는 앱 업데이트가 필요합니다.");
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "OPEN_BROWSER",
          params: {
            url: "https://play.google.com/store/apps/details?id=com.arttherapy", // required
          },
        })
      );
      return;
    }

    if (
      !isCallRequest &&
      (lastMessage.state.body !== CallMessage.Start.message ||
        lastMessage.state.body !== CallMessage.Success.message ||
        lastMessage.state.body !== CallMessage.Failure.message)
    ) {
      setCallRequest(true);
      conversation.sendMessage(CallMessage.Start.message);

      const roomName = conversation?.uniqueName ?? "";
      const other = roomName.replace(twilioIdentity, "").replace("-", "");
      const [otherId] = getIdsFromUniqueName(other);

      // Push 요청 api 호출
      (async () => {
        const [doctorName, userNickname] =
          conversation?.friendlyName?.split("-") || [];
        if (userType === "client") {
          await client
            .post("/user/push/publish", {
              doctor_id: otherId,
              title: userNickname ?? "마음드로잉",
              body: "내담자가 통화 연결을 요청합니다.",
              url: window.location.pathname,
            })
            .then((res) => {});
        } else {
          await client
            .post("/doctor/push/publish", {
              user_id: otherId,
              title: doctorName ?? "상담사",
              body: "통화 연결을 요청합니다.",
              url: window.location.pathname,
            })
            .then((res) => {});
        }
      })();
    }

    setOnVideoCall(true);
  }, [
    appVersion,
    conversation,
    isCallRequest,
    isMinddrawingApp,
    lastMessage?.state?.body,
    platform,
    twilioIdentity,
    userType,
  ]);

  const fetchDoctorInfo = useCallback(async (doctorId) => {
    return await client.get(`/doctor/${doctorId}`);
  }, []);

  useEffect(() => {
    if (
      isMessageAdded &&
      lastMessage.author !== twilioIdentity &&
      !isCallRequest &&
      lastMessage.state.body === CallMessage.Start.message
    ) {
      const requestAuthor = lastMessage.author;
      const userNickname = conversation?.friendlyName?.split("-")[1] || "";
      if (requestAuthor.includes("doctor")) {
        fetchDoctorInfo(requestAuthor.replace("doctor", "")).then(
          (response) => {
            setRequestAuthor({
              image: response?.data?.img || DefaultImage,
              name: response?.data?.name
                ? `${response?.data?.name} 상담사`
                : "상담사",
            });
          }
        );
      } else {
        setRequestAuthor({
          image: DefaultImage,
          name: userNickname ?? "내담자",
        });
      }

      setOpenCallModal(true);
    } else {
      setOpenCallModal(false);
    }
  }, [
    conversation?.friendlyName,
    fetchDoctorInfo,
    isCallRequest,
    isMessageAdded,
    lastMessage,
    twilioIdentity,
  ]);

  const declineVideoCall = useCallback(() => {
    setOpenCallModal(false);
  }, []);

  const apporveVideoCall = useCallback(() => {
    setOpenCallModal(false);
    setOnVideoCall(true);
  }, []);

  return (
    <Wrapper>
      {isOpenCallModal && (
        <CallWaitingSection
          requestAuthor={requestAuthor}
          declineVideoCall={declineVideoCall}
          apporveVideoCall={apporveVideoCall}
        />
      )}
      <MessageSection
        messages={messages}
        identity={twilioIdentity}
        ref={messageRef}
        userType={userType}
        doctorId={doctorId}
        connectVideoCall={triggerVideoCall}
      />
      <div id="local-media"></div>
      {isPaletteOn && (
        <Palette
          triggerPalette={triggerPalette}
          conversations={conversations}
        />
      )}
      {onVideoCall ? (
        fetchVideoSection()
      ) : (
        <ActionSection className="actionBar">
          {showBubbleMessage && (
            <BubbleMessageWrapper>
              <ButtonWrapper onClick={() => scrollToLastMessage(true)}>
                <ChatBubbleSVG />
              </ButtonWrapper>
              <BubbleMessage>{lastMessage.state.body}</BubbleMessage>
            </BubbleMessageWrapper>
          )}

          <ActionBar>
            <ToggleButton
              className={showActions ? "on" : ""}
              onClick={() => setShowActions(!showActions)}
            >
              <ActionSVG />
            </ToggleButton>
            <MessageInput>
              <input
                ref={messageInputRef}
                placeholder="메시지를 입력하세요."
                onKeyUp={enterMessage}
                onClick={() => {
                  setShowActions(false);
                  setTimeout(() => {
                    messageRef.current?.scrollTo(
                      0,
                      messageRef.current.scrollHeight
                    );
                  }, 1);
                }}
              />
              {conversation && (
                <button
                  onClick={() => {
                    sendMessage();
                    setTimeout(() => {
                      messageRef.current?.scrollTo(
                        0,
                        messageRef.current.scrollHeight
                      );
                    }, 1);
                  }}
                >
                  보내기
                </button>
              )}
            </MessageInput>
          </ActionBar>
          {showActions && (
            <Actions>
              <Action
                onClick={() => imageRef && imageRef.current.click()}
                style={{ backgroundColor: "#92BCB9" }}
              >
                <ImageSVG />
                <input
                  ref={imageRef}
                  type="file"
                  id="image"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    const { files } = e.target;
                    if (files.length > 0) {
                      sendImage(files[0]);
                    }
                  }}
                />
                <label htmlFor="image">사진</label>
              </Action>
              <Action
                onClick={triggerPalette}
                style={{ backgroundColor: "#A692F9" }}
              >
                <BrushSVG />
                <label>팔레트</label>
              </Action>
              <Action
                onClick={triggerVideoCall}
                style={{ backgroundColor: "#F9A692" }}
              >
                <ReceiverSVG />
                <label>통화하기</label>
              </Action>
            </Actions>
          )}
        </ActionSection>
      )}
    </Wrapper>
  );
};
export default ConsultingRoom;

const Wrapper = styled.section`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const ActionSection = styled.div`
  width: 100%;
  position: sticky;
  bottom: 0;
  border-top: 1px solid #efefef;
  border-radius: 10px 10px 0 0;
  background: #ffffff;
  flex-shrink: 0;
`;

const ActionBar = styled.div`
  padding: 8pt 10px;
  background: #ffffff;
  display: flex;
  align-items: center;
  bottom: 0px;
  z-index: 999;
  position: sticky;
  width: 100%;
`;

const BubbleMessageWrapper = styled.div`
  position: absolute;
  top: -50px;
  width: 80%;
  height: auto;
  border-radius: 15px;
  margin: 0 10%;
  border: 1px solid #efefef;
  background-color: #fff;
  display: flex;
  align-items: center;
  transition-property: width;
  transition-duration: 2s;
`;

const BubbleMessage = styled.div`
  width: calc(100% - 40px);
  font: normal normal 400 15px/18px Pretendard;
  padding: 5px 10px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ButtonWrapper = styled.div`
  padding: 5px 10px;
`;

const ToggleButton = styled.div`
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.on {
    & > svg {
      transform: rotate(45deg);
    }
  }
`;
const MessageInput = styled.div`
  flex-grow: 1;
  display: flex;

  background: #f8f8f8;
  border-radius: 10px;

  & > input {
    width: 100%;
    background-color: transparent;
    font: normal normal 300 15px/18px Pretendard;
    letter-spacing: -0.23px;
    padding: 13px 20px;
    border: unset;

    &:focus {
      box-shadow: none;
    }
  }
  & > button {
    border-radius: 10px;
    flex-shrink: 0;
    background-color: #f8f8f8;
    font: normal normal 500 14px/17px Pretendard;
    letter-spacing: -0.28px;
    color: #101010;
    padding: 16px;
    background-image: unset;
    box-shadow: none;
  }
`;
const Actions = styled.section`
  padding-top: 9px;
  padding-bottom: 77px;
  display: flex;
  justify-content: center;
  position: sticky;
  z-index: 999;
`;
const Action = styled.div`
  width: 56px;
  height: 56px;
  background: #999999 0% 0% no-repeat padding-box;
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  & > label {
    font: normal normal 500 13px/16px Pretendard;
    letter-spacing: -0.26px;
    color: #404040;
    position: absolute;
    top: 64px;
  }

  & + & {
    margin-left: 36px;
  }
`;
