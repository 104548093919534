import styled from "styled-components";

import ErrorSVG from "assets/svg/field-error.svg";

import ButtonCheckboxField from "./ButtonCheckboxField";
import InputField from "./InputField";

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 17px;
  height: 100%;
  overflow-y: auto;
`;
const Row = styled.div`
  position: relative;
`;
const Label = styled.div`
  font: normal normal 600 14px/17px Pretendard;
  letter-spacing: -0.14px;
  color: #333333;
  margin-bottom: 21px;

  & > span {
    font: normal normal 500 14px/17px Pretendard;
    letter-spacing: -0.14px;
    color: #999999;
  }
  & > span.required {
    color: #485af3;
  }
`;
const Button = styled.button`
  position: absolute;
  right: 0;
  top: 33px;
  width: 63px;
  height: 31px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #efefef;
  border-radius: 5px;
  font: normal normal 500 14px/17px Pretendard;
  letter-spacing: -0.21px;
  color: #6e6e6e;
`;
const Error = styled.div`
  display: inline-block;
  position: relative;
  margin-bottom: 12px;
  font: normal normal 500 13px/16px Pretendard;
  letter-spacing: -0.13px;
  color: #ed5d4e;
  padding-left: 22px;

  &::before {
    position: absolute;
    left: 0;
    content: "";
    width: 16px;
    height: 16px;
    background: url(${ErrorSVG});
  }
`;
const SubmitRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const SubmitButton = styled.button`
  width: 271px;
  height: 48px;
  border: ${(p) => (p.$light ? "1px solid #E6E6E6" : "none")};
  background: ${(p) => (p.$light ? "#ffffff" : "#333333")};
  border-radius: 10px;
  font: normal normal 500 16px/19px Pretendard;
  letter-spacing: -0.16px;
  color: ${(p) => (p.$light ? "#333333" : "#ffffff")};

  &:disabled {
    font: normal normal 500 16px/19px Pretendard !important;
    border-radius: 10px !important;

    background: #cbcbcb;
    color: #ffffff;
  }
`;
const Textarea = styled.textarea`
  width: 100%;
  height: 110px;
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 10px;
  font: normal normal 400 14px/17px Pretendard;
  letter-spacing: -0.14px;
  padding: 16px;
`;
const BorderInput = styled.input`
  width: 100%;
  height: 47px;
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 10px;
  font: normal normal 400 14px/17px Pretendard;
  letter-spacing: -0.14px;
  padding: 16px;

  &:focus {
    border: 1px solid #efefef !important;
  }
`;

const Form = Object.assign(Wrapper, {
  Button,
  ButtonCheckboxField,
  BorderInput,
  InputField,
  Error,
  Label,
  Textarea,
  Row,
  SubmitRow,
  SubmitButton,
});
export default Form;
