import { useState } from "react";
import styled from "styled-components";
import client from "apis/client";
import useNavigate from "hooks/useNavigate";

import InputField from "components/Form/InputField";

const PasswordResetRequest = () => {
  const { goLogin } = useNavigate();
  const [email, setEmail] = useState("");

  const handleRequest = async () => {
    try {
      await client.post("/user/password/search", {
        email,
      });
      alert("이메일이 발송되었습니다.");
      goLogin();
    } catch (e) {
      alert(e.response.data.message);
    }
  };

  return (
    <Wrapper>
      <Title>
        새로운 비밀번호를
        <br /> 설정합니다.
      </Title>

      <Info>
        계정의 이메일 주소를 입력하시면
        <br /> 비밀번호를 재설정할 수 있는 링크를 이메일로 보내드립니다.
      </Info>

      <InputField
        label="이메일"
        type="email"
        placeholder="example@domain.com"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />

      <Footer>
        <Button disabled={!email} onClick={handleRequest}>
          메일 보내기
        </Button>
      </Footer>
    </Wrapper>
  );
};
export default PasswordResetRequest;

const Wrapper = styled.section`
  padding: 0 15px;
  padding-top: 75pt;

  font: normal normal 400 15px/25px Pretendard;
  letter-spacing: -0.23px;
  color: #404040;
`;
const Title = styled.div`
  font: normal normal 600 28px/44px Pretendard;
  letter-spacing: -0.2pt;
  color: #101010;
  margin-bottom: 15px;
  line-height: 44px;
`;
const Info = styled.div`
  font: normal normal 400 15px/25pt Pretendard;
  letter-spacing: -0.23pt;
  line-height: 24px;
  color: #404040;
  margin-bottom: 32pt;
`;
const Footer = styled.div`
  width: 100%;
  padding-bottom: 24px;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  // margin-bottom: 29px;

  & > button {
    width: 271px;
    height: 48px;
    background: #333333 0% 0% no-repeat padding-box;
    border-radius: 10px;
    font: normal normal 500 16px/19px Pretendard;
    letter-spacing: -0.16px;
    color: #ffffff;
  }
`;

const Button = styled.button`
  width: 271px;
  height: 48px;
  background: #333333 0% 0% no-repeat padding-box;
  border-radius: 10px;
  font: normal normal 500 16px/19px Pretendard;
  letter-spacing: -0.16px;
  color: #ffffff;

  &:disabled {
    background: #f0f0f0;
  }
`;
