import styled from "styled-components";

const TimeTag = () => <Wrapper>50분 x 1회</Wrapper>;
export default TimeTag;

const Wrapper = styled.div`
  background: #efefef 0% 0% no-repeat padding-box;
  border-radius: 5px;

  font: normal normal 500 13px/16px Pretendard;
  letter-spacing: -0.13px;
  color: #101010;
  padding: 4px 12px;
  width: fit-content;
`;
