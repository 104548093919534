import { useEffect, useState } from "react";

import client from "apis/client";

const useReview = (id) => {
  const [review, setReview] = useState(null);

  useEffect(() => {
    if (!id) return;

    (async () => {
      const { data } = await client.get(`/user/reviews/${id}`);
      setReview(data);
    })();
  }, [id]);

  return review;
};
export default useReview;
