import styled from "styled-components";

import useAuth from "hooks/useAuth";
import useNavigate from "hooks/useNavigate";

import HistorySVG from "assets/svg/myinfo-history.svg";
import EditSVG from "assets/svg/myinfo-edit.svg";
import LogoutSVG from "assets/svg/logout.svg";
import SettingsSVG from "assets/svg/settings.svg";

import {
  Username,
  Greeting,
  Menu,
  CompanyInfo,
  AccountDelete,
} from "./StyledComps";

const MyinfoPage = () => {
  const {
    goCompanyInfo,
    goProfile,
    goMyReservation,
    goDoctorReservation,
    goDoctorProfile,
    goWithdraw,
    goNotificationSettings,
  } = useNavigate();
  const { user, logout } = useAuth();
  return (
    <Wrapper>
      {user && (
        <>
          <Greeting>
            <div>안녕하세요,</div>
            <Username>{user.name}님 👋</Username>
          </Greeting>

          <Menu>
            <Menu.Item
              $icon={HistorySVG}
              onClick={user.isDoctor ? goDoctorReservation : goMyReservation}
            >
              내 상담 내역
            </Menu.Item>

            <Menu.Item
              $icon={EditSVG}
              onClick={user.isDoctor ? goDoctorProfile : goProfile}
            >
              내 정보 수정
            </Menu.Item>
            <Menu.Item $icon={LogoutSVG} onClick={logout}>
              로그아웃
            </Menu.Item>
            <Menu.Item $icon={SettingsSVG} onClick={goNotificationSettings}>
              알림설정
            </Menu.Item>
          </Menu>
          <div>
            <div style={{ textAlign: "right" }}>
              {user?.userType !== "doctor" && (
                <AccountDelete onClick={goWithdraw}>탈퇴하기</AccountDelete>
              )}
            </div>
            <div style={{ textAlign: "right" }}>
              {user?.userType !== "doctor" && (
                <AccountDelete
                  style={{
                    marginTop: "20px",
                    textDecoration: "underline",
                    color: "#6E6E6E",
                  }}
                  onClick={() => {
                    window.open(
                      "https://docs.google.com/forms/d/e/1FAIpQLSfcXZF26CTw4tRObHexHvx4CAu0TYQJhxZmZ18PiwcloNV1RA/viewform"
                    );
                  }}
                >
                  문의하기
                </AccountDelete>
              )}
            </div>
          </div>
          <CompanyInfo>
            <CompanyInfo.Button onClick={goCompanyInfo}>
              임팩트스테이션(주) 사업자 정보
            </CompanyInfo.Button>
          </CompanyInfo>
        </>
      )}
    </Wrapper>
  );
};
export default MyinfoPage;

const Wrapper = styled.div`
  padding-top: 36px;
  padding-left: 15px;
  padding-right: 15px;
  height: 100%;
  overflow-y: auto;
`;
