import styled from "styled-components";
import useNavigate from "hooks/useNavigate";
import { beforeSteps } from "atomStates";
import { useRecoilState } from "recoil";

const WarningPage = ({ setStep }) => {
  const { goExamine } = useNavigate();
  const [beforeStep, setBeforeStep] = useRecoilState(beforeSteps);
  return (
    <Background className="background">
      <Modal className="warningModal">
        <Title>돌아가시기 전에</Title>
        <Contents>
          처음으로 이동하실 경우 지금까지의 내용은 저장되지 않습니다. <br />
          처음으로 이동하시겠습니까?
        </Contents>
        <BtnSection>
          <Button onClick={goExamine}>처음으로 이동하기</Button>
          <ContinueButton onClick={() => setStep(beforeStep)}>
            테스트 계속하기
          </ContinueButton>
        </BtnSection>
      </Modal>
    </Background>
  );
};

export default WarningPage;

const Background = styled.div`
  z-index: 9999;
  height: 100%;
  position: fixed;
  background-color: #707070 !important;
`;

const Modal = styled.div`
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
`;

const Title = styled.div`
  margin: 21pt 0 21pt 11pt;
  font: normal normal 700 17.5pt/11px Pretendard;
`;

const Contents = styled.div`
  margin: 0 0 0 11pt;
  font: normal normal 500 11pt/11px Pretendard;
  line-height: 15pt;
`;

const Button = styled.button`
  border: solid 1px #485af3;
  padding: 12px 26pt 12px 26pt;
  border-radius: 5pt;
  margin: 20pt 5pt;
  color: #485af3;
  font: normal normal 500 10pt/11px Pretendard;
  background-color: #ffffff;
`;

const ContinueButton = styled.button`
  border: solid 1px #485af3;
  padding: 12px 26pt 12px 26pt;
  border-radius: 5pt;
  margin: 20pt 5pt;
  color: #ffffff;
  font: normal normal 500 10pt/11px Pretendard;
  background-color: #485af3;
`;

const BtnSection = styled.div`
  text-align: center;
`;
