import { useCallback, useEffect, useState } from "react";

import client from "apis/client";

const PAGE_LENGTH = "30";
const useDoctorReservation = (status) => {
  const [reservations, setReservations] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [total, setTotal] = useState(0);

  const fetch = useCallback(async () => {
    const {
      data: {
        reservations,
        page: { pages, total },
      },
    } = await client.get(
      `/doctor/reservations?page=${page}&per=${PAGE_LENGTH}&status=${status}`
    );
    setReservations([]);
    setReservations((prev) => [...prev, ...reservations]);
    setPageCount(pages);
    setTotal(total);
  }, [page, status]);
  const fetchMore = useCallback(
    () => pageCount > page && setPage(page + 1),
    [page, pageCount]
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    reservations,
    pageCount,
    total,
    fetchMore,
    setPage,
  };
};
export default useDoctorReservation;
