import useNavigate from "hooks/useNavigate";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import Popup, { ActionButton } from ".";

const NoAuthReservation = ({ alertMessage, onClose }) => {
  const location = useLocation();
  const { goLogin } = useNavigate();

  return (
    <Popup>
      <Wrapper>
        <label>{alertMessage || "로그인 후 상담권을 선택하세요."}</label>
        <ActionButton onClick={() => goLogin(location.pathname)}>
          로그인하기
        </ActionButton>
        <button
          className="cancel"
          onClick={onClose}
          style={{
            width: "271px",
            height: "48px",
            background: "#E6E6E6",
            borderRadius: "10px",
            color: "#6E6E6E",
            font: "normal normal 400 16px/19px Pretendard",
          }}
        >
          취소
        </button>
      </Wrapper>
    </Popup>
  );
};
export default NoAuthReservation;

const Wrapper = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 80px;

  & > label {
    font: normal normal 500 18px/21px Pretendard;
    letter-spacing: -0.27px;
    color: #101010;
    margin-bottom: 60px;
  }

  & > button.cancel {
    background-color: unset;
    font: normal normal 500 16px/19px Pretendard;
    letter-spacing: -0.16px;
    color: #333333;
    margin-top: 10px;
    background-image: none;
    box-shadow: none;
    width: 271px;
  }
`;
