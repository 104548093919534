import React from "react";
import styled from "styled-components";

import { ReactComponent as ApproveCallSVG } from "assets/svg/call-approve.svg";
import { ReactComponent as DeclineCallSVG } from "assets/svg/call-decline.svg";


const CallWaitingSection = ({
  requestAuthor,
  declineVideoCall,
  apporveVideoCall,
}) => {
  return (
      <Wrapper>
        <ProfileWrapper>
          <ProfileImage src={requestAuthor.image} />
          <CallTitle>{requestAuthor.name}</CallTitle>
          <CallDesc>통화 연결을 요청합니다</CallDesc>
        </ProfileWrapper>
        <ButtonWrapper>
          <CallApproveButton onClick={apporveVideoCall}>
            <ApproveCallSVG/>
          </CallApproveButton>
          <CallDeclineBUtton onClick={declineVideoCall}>
            <DeclineCallSVG/>
          </CallDeclineBUtton>
        </ButtonWrapper>
      </Wrapper>

  );
};

export default CallWaitingSection;

const Wrapper = styled.section`
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background:rgba(0,0,0,0.7);
  backdrop-filter: blur(10px);
`;

const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20vh;
`;

const ProfileImage = styled.img`
  display: flex;
  width: 80px;
  height: 80px;
  border-radius: 15px;
`;

const CallTitle = styled.div`
  font: normal normal 600 24px/20px Pretendard;
  color: #fff;
  margin-top: 20px;
`;

const CallDesc = styled.div`
  font: normal normal 400 16px/18px Pretendard;
  color: #BDBDBD;
  margin-top: 12px;
`;

const ButtonWrapper = styled.div``;

const CommonButton = styled.button`
  width: 68px;
  height: 68px;
  border-radius: 34px;
`;

const CallApproveButton = styled(CommonButton)`
  position: absolute;
  left: 60px;
  bottom: 90px;
  background-color: #62C53B;
`;

const CallDeclineBUtton = styled(CommonButton)`
  position: absolute;
  right: 60px;
  bottom: 90px;
  background-color: #ED5D4E;
`;
