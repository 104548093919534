import { useRecoilState } from "recoil";

import client from "apis/client";
import { reservationState, ReservationInitial } from "atomStates";

const useReservation = () => {
  const [reservation, setReservation] = useRecoilState(reservationState);
  const {
    purchase_id,
    doctor_id,
    counsel_kind,
    customer_uid,
    price,
    date,
    times,
  } = reservation;

  // 구매원 선택
  const prepareReservation = (
    doctor_id,
    counsel_kind,
    credit,
    partner_name,
    user_nickname
  ) => {
    setReservation({
      ...reservation,
      doctor_id,
      counsel_kind: counsel_kind,
      credit,
      partner_name,
      user_nickname,
    });
  };

  // 예약 생성 (백앤드 reservation step1)
  const createPurchase = async () => {
    if (!doctor_id && !counsel_kind) {
      alert("잘못된 접근입니다.");
      return;
    }

    const {
      data: {
        doctor: { id, name, counsel_kind: counsel_kind_price_info },
        id: purchase_id,
      },
    } = await client.post(`/user/reservations`, {
      doctor_id,
      counsel_kind,
    });
    setReservation({
      ...reservation,
      doctor_id: id,
      doctor_name: name,
      counsel_kind: counsel_kind,
      purchase_id,
      price: counsel_kind_price_info[counsel_kind],
    });
  };
  // 예약 결재 (백앤드 reservation step2)
  const payPurchase = async () => {
    const {
      data: { merchant_uid },
    } = await client.post(`/user/reservations/${purchase_id}/payment`, {
      customer_uid,
      amount: price,
    });
    setReservation({ ...reservation, merchant_uid });
  };

  // 크레딧 예약 결재
  const payCreditPurchase = async () => {
    const {
      data: { status },
    } = await client.post(`/user/reservations/${purchase_id}/member_payment`, {
      amount: price,
    });
    setReservation({ ...reservation, status });
  };

  // 상담일자/시간 설정 (백앤드 reservation step3)
  const reservationDateTime = async () => {
    try {
      await client.patch(`/user/reservations/${purchase_id}`, {
        date,
        times,
        doctor_id,
      });
      setReservation(ReservationInitial);
    } catch (e) {
      throw e;
    }
  };
  const setCustomerUid = (customer_uid) =>
    setReservation({ ...reservation, customer_uid });
  const setDate = (date) => setReservation({ ...reservation, date });
  const setTimes = (time) => {
    if (reservation.times.includes(time)) {
      setReservation({
        ...reservation,
        times: times.filter((t) => t !== time),
      });
    } else {
      setReservation({ ...reservation, times: [...times, time] });
    }
  };
  return {
    reservation,
    isProcessing: !!purchase_id, // 필요없을 듯?
    prepareReservation,
    createPurchase,
    setCustomerUid,
    setDate,
    setTimes,
    payCreditPurchase,
    payPurchase,
    reservationDateTime,
  };
};
export default useReservation;
