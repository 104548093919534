import { ReactComponent as FaceBookSVG } from "assets/svg/facebook.svg";
import { ReactComponent as KakaoSVG } from "assets/svg/kakao.svg";
import { ReactComponent as LinkSVG } from "assets/svg/link.svg";
import { ReactComponent as OutLineSVG } from "assets/svg/share.svg";
import { ReactComponent as TwitterSVG } from "assets/svg/twitter.svg";

import massgeBoxPNG from "assets/images/messageboxImg.png";

import { shareImage } from "atomStates";

import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";

const Share = (data) => {
  const [copyState, setCopyState] = useState(false);
  const [shareImages, setShareImages] = useRecoilState(shareImage);

  useEffect(() => {
    const script = document.createElement("script");
    script.scr = "https://developers.kakao.com/sdk/js/kakao.js";
    script.async = true;
    document.body.appendChild(script);
    return () => document.body.removeChild(script);
  }, []);

  const sharingBtn = (sns) => {
    let devices = window.navigator.userAgent;
    const url = window.location.href;
    // 카카오 일 경우
    if (sns === "kakao") {
      const kakao = window.Kakao;

      // 카카오 Set
      if (!kakao.isInitialized()) {
        // kakao.init("fd9e8a9197fc216fe370e8ba28c0904c");
        kakao.init("7bc97b4da48d4ee75ac57e8732d82555");
      }
      if (data.location === "galleries") {
        kakao.Link.sendDefault({
          objectType: "feed",
          content: {
            title: "마음드로잉",
            description: "힐링 갤러리에서 잠깐 쉬어가세요 :)",
            imageUrl: shareImages,
            link: {
              webUrl: url,
            },
          },
          buttons: [
            {
              title: "갤러리 바로가기",
              link: {
                mobileWebUrl: url,
                webUrl: url,
              },
            },
          ],
        });
      } else if (url.includes("step")) {
        kakao.Link.sendDefault({
          objectType: "feed",
          content: {
            title: "마음드로잉 미술 심리테스트",
            description: JSON.stringify(data.data)
              .replace("[", "")
              .replace(`"`, " ")
              .replace("]", ""),
            imageUrl: `${process.env.PUBLIC_URL}assets/images/result.png`,
            link: {
              webUrl: url,
            },
          },
          buttons: [
            {
              title: "마술심리테스트 결과보기",
              link: {
                mobileWebUrl: url,
                webUrl: url,
              },
            },
          ],
        });
      } else if (data.location === "examine") {
        kakao.Link.sendDefault({
          objectType: "feed",
          content: {
            title: "마음드로잉",
            description: "그림으로 알아보는 내 마음 상태",
            imageUrl: process.env.REACT_APP_BASE_URL + massgeBoxPNG.substr(1),
            link: {
              webUrl: url,
            },
          },
          buttons: [
            {
              title: "미술심리테스트 해보기",
              link: {
                mobileWebUrl: url,
                webUrl: url,
              },
            },
          ],
        });
      }
      setCopyState(false);
    } else if (sns === "facebook") {
      window.open("https://www.facebook.com/sharer/sharer.php?u=" + url);
      setCopyState(false);
    } else if (sns === "twitter") {
      window.open("https://www.twitter.com/intent/tweet?&url=" + url);
      setCopyState(false);
    } else if (sns === "outline") {
      if (data.location === "examine") {
        if (navigator.share) {
          navigator
            .share({
              title: "마음드로잉",
              url: url,
            })
            .then(() => {})
            .catch((e) => {
              console.log(e);
            });
        }
      } else if (data.location === "galleries") {
        if (navigator.share) {
          navigator.share({
            title: "마음드로잉",
            url: url,
          });
        }
      }
    } else if (sns === "link") {
      const textarea = document.createElement("textarea");
      document.body.appendChild(textarea);

      if (data.location === "examine") {
        textarea.value = url;
      } else if (data.location === "galleries") {
        textarea.value = url;
      }

      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      setCopyState(true);

      setTimeout(() => {
        setCopyState(false);
      }, 1500);
    }
  };
  return (
    <>
      <Logo>
        <KakaoSVG
          className="shareIcon"
          id={
            data.location === "galleries"
              ? "gallerieskakao"
              : data.location === "examine"
              ? "examinekakao"
              : ""
          }
          onClick={() => sharingBtn("kakao")}
          style={{ cursor: "pointer" }}
        />
        <FaceBookSVG
          className="shareIcon"
          id={
            data.location === "galleries"
              ? "galleriesfaceBook"
              : data.location === "examine"
              ? "examinefaceBook"
              : ""
          }
          onClick={() => sharingBtn("facebook")}
          style={{ cursor: "pointer" }}
        />
        <TwitterSVG
          className="shareIcon"
          id={
            data.location === "galleries"
              ? "galleriestwitter"
              : data.location === "examine"
              ? "examinetwitter"
              : ""
          }
          onClick={() => sharingBtn("twitter")}
          style={{ cursor: "pointer" }}
        />
        <LinkSVG
          className="shareIcon"
          onClick={() => sharingBtn("link")}
          style={{ cursor: "pointer" }}
        />
        {/* <OutLineSVG
          className="shareIcon"
          onClick={() => sharingBtn("outline")}
          style={{ cursor: "pointer" }}
        /> */}
      </Logo>
      <div style={{ height: "15px", marginBottom: "20px" }}>
        {copyState ? (
          <CopyText>웹 링크 주소가 복사되었습니다.</CopyText>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Share;

const Logo = styled.div`
  text-align: center;
  margin-top: 15pt;
  cursur: pointer;
`;

const CopyText = styled.div`
  text-align: center;
  margin: 10px auto 0 auto;
  color: #ffffff;
  font-weight: 500;
  padding: 12px 18px;
  background-color: #333333;
  width: 272px;
  font-size: 14px;

  animation: fadeout 3s;
  -moz-animation: fadeout 3s; /* Firefox */
  -webkit-animation: fadeout 3s; /* Safari and Chrome */
  -o-animation: fadeout 3s; /* Opera */
  animation-fill-mode: forwards;
`;
