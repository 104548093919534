import { useState } from "react";
import styled from "styled-components";

const ExamineStep11 = ({ setResults, results, nextStep }) => {
  const [buttonStates, setButtonStates] = useState({
    button1: false,
    button2: false,
    button3: false,
    button4: false,
  });

  const handleMouseEnter = (buttonId) => {
    setButtonStates((prevState) => ({
      ...prevState,
      [buttonId]: true,
    }));
  };

  const handleMouseLeave = (buttonId) => {
    setButtonStates((prevState) => ({
      ...prevState,
      [buttonId]: false,
    }));
  };
  return (
    <>
      <Button
        value="1"
        hovered={buttonStates.button1}
        onMouseEnter={() => handleMouseEnter("button1")}
        onMouseLeave={() => handleMouseLeave("button1")}
        onTouchStart={() => handleMouseEnter("button1")}
        onTouchEnd={() => handleMouseLeave("button1")}
        onClick={(e) => {
          setResults({
            ...results,
            additional_questions: {
              ...results.additional_questions,
              q4: e.target.value,
            },
          });
          nextStep();
        }}
      >
        매우 그렇다
      </Button>
      <Button
        value="2"
        hovered={buttonStates.button2}
        onMouseEnter={() => handleMouseEnter("button2")}
        onMouseLeave={() => handleMouseLeave("button2")}
        onTouchStart={() => handleMouseEnter("button2")}
        onTouchEnd={() => handleMouseLeave("button2")}
        onClick={(e) => {
          setResults({
            ...results,
            additional_questions: {
              ...results.additional_questions,
              q4: e.target.value,
            },
          });
          nextStep();
        }}
      >
        그렇다
      </Button>
      <Button
        value="3"
        hovered={buttonStates.button3}
        onMouseEnter={() => handleMouseEnter("button3")}
        onMouseLeave={() => handleMouseLeave("button3")}
        onTouchStart={() => handleMouseEnter("button3")}
        onTouchEnd={() => handleMouseLeave("button3")}
        onClick={(e) => {
          setResults({
            ...results,
            additional_questions: {
              ...results.additional_questions,
              q4: e.target.value,
            },
          });
          nextStep();
        }}
      >
        아니다
      </Button>
      <Button
        value="4"
        hovered={buttonStates.button4}
        onMouseEnter={() => handleMouseEnter("button4")}
        onMouseLeave={() => handleMouseLeave("button4")}
        onTouchStart={() => handleMouseEnter("button4")}
        onTouchEnd={() => handleMouseLeave("button4")}
        onClick={(e) => {
          setResults({
            ...results,
            additional_questions: {
              ...results.additional_questions,
              q4: e.target.value,
            },
          });
          nextStep();
        }}
      >
        매우 아니다
      </Button>
    </>
  );
};

export default ExamineStep11;

const Button = styled.button`
  font: normal normal 500 11pt/11px Pretendard;
  border: solid 1px #e6e6e6;
  background-color: #ffffff;
  color: #101010;
  margin: 0 10pt -4pt 10pt;
  padding: 15pt 10pt;
  text-align: left;
  border-radius: 5pt;

  &:hover {
    background-color: ${(props) => (props.hovered ? "#8ba8ff" : "#ffffff")};
    color: ${(props) => (props.hovered ? "#ffffff" : "#101010")};
  }

  &:checked {
    background-color: #8ba8ff;
    color: #ffffff;
  }
`;
