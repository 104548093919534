import { useEffect, useState, useMemo } from "react";

import { fetchMe as fetchClient } from "apis/user";
import { fetchMe as fetchDoctor } from "apis/doctor";
import { getUser } from "utils/storage";

const useUser = () => {
  const { userType } = getUser() || { userType: "" };
  const [user, setUser] = useState(null);

  /**
   * 내담자 프로필 수정 시 Form 필드 initial값으로 사용.
   */
  const userParamForUpdate = useMemo(() => {
    if (!user) return {};

    const {
      active,
      deleted,
      id,
      kakao_url,
      phone_confirm,
      token,
      visit,
      ...defaultParams
    } = user;
    return defaultParams;
  }, [user]);
  useEffect(() => {
    (async () => {
      const { data } =
        userType === "doctor" ? await fetchDoctor() : await fetchClient();
      setUser(data);
    })();
  }, [userType]);

  return { user, userParamForUpdate, userType };
};
export default useUser;
