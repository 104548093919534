import client from "./client";

/**
 * 내 정보(내담자)
 */
export const fetchMe = () => client.get("/user/me");

/**
 * 회원 가입(내담자)
 */
export const signup = (params) => client.post("/user", params);

/**
 * 회원 정보 수정(내담자)
 */
export const update = (params) => client.put("/user", params);
