import { useEffect, useState } from "react";
import styled from "styled-components";

const ExamineModal = ({
  step,
  openModal,
  setOpenModal,
  results,
  setResults,
  nextStep,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [state, setState] = useState(true);

  useEffect(() => {
    if (inputValue.length > 0) {
      setState(false);
    } else {
      setState(true);
    }
  }, [inputValue]);

  return (
    <Modal
      id="modal"
      onClick={(e) => {
        if (e.target.id === "modal") setOpenModal(false);
      }}
    >
      <Wapper>
        <Title>근무 형태를 직접 입력해 주세요.</Title>
        <Input
          value={inputValue}
          placeholder="근무 형태 입력"
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setResults({ ...results, work_type: inputValue });
              setOpenModal(false);
              nextStep();
              return;
            }
          }}
        />
        <Error>
          {state ? "텍스트를 입력한 뒤 확인 버튼을 눌러주세요. " : ""}
        </Error>
        <Button
          onClick={() => {
            setOpenModal(false);
          }}
        >
          다시 선택
        </Button>
        <Button
          onClick={() => {
            setResults({ ...results, work_type: inputValue });
            setOpenModal(false);
            nextStep();
          }}
        >
          확인
        </Button>
      </Wapper>
    </Modal>
  );
};

export default ExamineModal;

const Modal = styled.div`
  position: fixed;
  top: 0;
  inset-inline-end: 0;
  bottom: 0;
  inset-inline-start: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
`;
const Wapper = styled.div`
  width: 334px;
  height: 213px;
  background-color: #ffffff;
  border-radius: 10px;
  top: 100px;
  position: relative;
  margin: 0 auto;
  padding: 30px 16px;
`;
const Title = styled.div`
  font-size: 17px;
  font-weight: 700;
`;
const Input = styled.input`
  width: 294px;
  height: 44px;
  background-color: #f8f8f8;
  border: solid 1px #e6e6e6;
  border-radius: 5px;
  margin-top: 16px;
  padding: 0 6px;

  &::placeholder {
    color: #bebebe;
    padding: 0 6px;
    font-size: 17px;
    font-weight: 500;
  }
`;
const Error = styled.div`
  color: #ed5d4e;
  font-size: 13px;
  margin-top: 8px;
  height: 13px;
`;
const Button = styled.button`
  width: 50%;
  height: 70px;
  margin-top: 10px;
`;
