import { useState, useRef, useCallback, useEffect } from "react";
import styled from "styled-components";

import {
  connect,
  createLocalTracks,
  createLocalAudioTrack,
  createLocalVideoTrack,
} from "twilio-video";

import { ReactComponent as BrushSVG } from "assets/svg/brush-white.svg";
import { ReactComponent as ImageSVG } from "assets/svg/image-file.svg";
import { ReactComponent as MicSVG } from "assets/svg/mic.svg";
import { ReactComponent as MicOffSVG } from "assets/svg/mic-off.svg";
import { ReactComponent as PhoneSVG } from "assets/svg/phone-white.svg";
import { ReactComponent as VideoSVG } from "assets/svg/video-white.svg";
import { ReactComponent as VideoOffSVG } from "assets/svg/video-off.svg";
import { ReactComponent as ArrowDownSVG } from "assets/svg/chevron-down.svg";
// import { ReactComponent as GearSVG } from "assets/svg/gear.svg";
import { ReactComponent as SettingMicSVG } from "assets/svg/setting-mic.svg";
import { ReactComponent as SettingVideoSVG } from "assets/svg/setting-video.svg";
import { ReactComponent as SettingVolumeSVG } from "assets/svg/setting-volume.svg";
import OnPalettePNG from "assets/svg/on-palette.png";

import useLoading from "hooks/useLoading";
import LoadingBar from "components/Loading";
import client from "apis/client";
import { trackpubsToTracks } from "utils/twilio";
import isMobile from "utils/checkMobile";
import MyTracks from "./MyTracks";
import useVideo from "hooks/useRemoteVideo";
import useLocalVideo from "hooks/useLocalVideo";
import { PaletteMessage, CallMessage } from "utils/constant";

const mediaErrors = [
  "NotAllowedError",
  "NotFoundError",
  "NotReadableError",
  "OverconstrainedError",
  "TypeError",
];

function handleMediaError(error) {
  console.error("Failed to acquire media:", error.name, error.message);
}

const VideoSection = ({
  identity,
  triggerPalette,
  roomName,
  sharedImage,
  isOnPalette,
  isCallRequest,
  lastMessage,
  handleUpload,
  handleClose,
  isDrawing,
  removeSharedImage,
  sendTwilioMessage,
}) => {
  const [audioDeviceId, setAudioDeviceId] = useState("");
  const [videoDeviceId, setVideoDeviceId] = useState("");
  const [volumeDeviceId, setVolumeDeviceId] = useState("");

  const { isLoading, turnOffLoading } = useLoading(true);

  const imageRef = useRef(null);
  const remoteVideoRef = useRef();
  const remoteAudioRef = useRef();
  const videoSelectRef = useRef();
  const audioSelectRef = useRef();
  const volumeSelectRef = useRef();
  const [room, setRoom] = useState(null);
  const [participant, setParticipant] = useState(null);

  const [availableAudios, setAvailableAudios] = useState([]);
  const [availableSpeakers, setAvailableSpeakers] = useState([]);
  const [availableVideos, setAvailableVideos] = useState([]);

  const [previewOn, setPreviewOn] = useState(false);
  const [settingsOn, setSettingsOn] = useState(false);
  const [token, setToken] = useState("");

  const {
    remoteCameraOn,
    remoteAudioTrack,
    remoteVideoTrack,
    trackSubscribed,
    trackUnsubscribed,
    trackEnabled,
    trackDisabled,
  } = useVideo();

  const {
    micOn,
    cameraOn,
    toggleMic,
    toggleCamera,
    audioTrack,
    videoTrack,
    setAudioTrack,
    setVideoTrack,
  } = useLocalVideo();

  const togglePalette = useCallback(async () => {
    if (!isDrawing) {
      await sendTwilioMessage(PaletteMessage.StartPalette);
    }
    triggerPalette();
    toggleCamera();
  }, [isDrawing, sendTwilioMessage, toggleCamera, triggerPalette]);

  const togglePreview = useCallback(() => {
    if (isDrawing) {
      togglePalette();
    }
    setPreviewOn(false);
    removeSharedImage();
  }, [isDrawing, removeSharedImage, togglePalette]);

  const toggleSettings = () => {
    fetchDevices();
    setSettingsOn(!settingsOn);
  };

  const togglePhotos = () => {
    imageRef && imageRef.current.click();
  };

  const fetchDevices = useCallback(() => {
    // device 검색.
    navigator.mediaDevices?.enumerateDevices().then((devices) => {
      const audios = devices.filter(
        (device) => device.kind === "audioinput" // && device.deviceId !== "default"
      );
      const speakers = devices.filter(
        (device) => device.kind === "audiooutput" // && device.deviceId !== "default"
      );
      const videos = devices.filter(
        (device) => device.kind === "videoinput" // && device.deviceId !== "default"
      );

      setAvailableAudios(audios);
      setAvailableSpeakers(speakers);
      setAvailableVideos(videos);
    });
  }, []);

  const handleChangeDevice = async () => {
    const audioTrack = await createLocalAudioTrack({
      deviceId: { exact: audioSelectRef.current.value },
    });

    const videoTrack = await createLocalVideoTrack({
      deviceId: { exact: videoSelectRef.current.value },
    });

    room.localParticipant.publishTracks([audioTrack, videoTrack]);

    setAudioDeviceId(audioSelectRef.current.value);
    setVideoDeviceId(videoSelectRef.current.value);
    setVolumeDeviceId(volumeSelectRef.current.value);
    setAudioTrack(audioTrack);
    setVideoTrack(videoTrack);
  };

  const hangUp = async () => {
    console.log("통화 끊기");
    if (!room) return;

    if (isCallRequest) {
      await sendTwilioMessage(CallMessage.End.message);
    }

    await room.disconnect();
    handleClose();
  };

  const disconnected = (room, error) => {
    if (error) {
      console.log(
        "You were disconnected from the Room:",
        error.code,
        error.message
      );
    }

    console.log("Twilio Video 연결 끊기");

    room.localParticipant.tracks.forEach((publication) => {
      publication.track.stop();
      const attachedElements = publication.track.detach();
      attachedElements.forEach((element) => element.remove());
    });
  };

  const setupRemoteNotifications = useCallback(
    (publication) => {
      if (publication.isSubscribed) {
        trackEnabled(publication);
      }

      publication.on("subscribed", (track) => {
        // Indicate to the user that the mobile user has added video.
        trackSubscribed(track);
      });

      publication.on("unsubscribed", (track) => {
        // Indicate to the user that the mobile user has removed video.
        trackUnsubscribed(track);
      });
    },
    [trackEnabled, trackSubscribed, trackUnsubscribed]
  );

  const participantConnected = useCallback(
    async (participant) => {
      console.log(`A remote Participant 들어옴`);

      if (isCallRequest && lastMessage === CallMessage.Start.message) {
        await sendTwilioMessage(CallMessage.Success.message);
      }

      // Set up remote video notifications for the VideoTracks that are
      // already published.
      participant.tracks.forEach(setupRemoteNotifications);

      // Set up remote video notifications for the VideoTracks that will be
      // published later.
      participant.on("trackPublished", setupRemoteNotifications);

      setParticipant(participant);
    },
    [isCallRequest, lastMessage, sendTwilioMessage, setupRemoteNotifications]
  );

  const participantDisconnected = (participant) => {
    console.log(`A remote Participant 나감:`, participant);
    setParticipant(null);
  };

  const fetchRoom = useCallback(
    (room) => {
      if (!room) return null;

      room.participants.forEach(participantConnected);
      room.on("trackPublished", (publication) => console.log(publication));
      room.on("participantConnected", participantConnected);
      room.on("participantDisconnected", participantDisconnected);
      room.once("disconnected", disconnected);

      const localAudio = trackpubsToTracks(room.localParticipant.audioTracks);
      const localVideo = trackpubsToTracks(room.localParticipant.videoTracks);

      setAudioTrack(localAudio);
      setVideoTrack(localVideo);

      localAudio.enable();
      localVideo.enable();

      turnOffLoading();
      setRoom(room);
    },
    [participantConnected, setAudioTrack, setVideoTrack, turnOffLoading]
  );

  // 이미지 공유하면, 표시.
  useEffect(() => {
    if (sharedImage) {
      if (!cameraOn) toggleCamera();
      setPreviewOn(true);
    } else {
      setPreviewOn(false);
    }
  }, [cameraOn, sharedImage, toggleCamera]);

  // 1. video용 토큰 가져오기
  useEffect(() => {
    // 1.1 토큰 가져오고,
    (async () => {
      const {
        data: { token },
      } = await client.post("/twilio", {
        identity: identity,
        room_name: roomName,
      });
      setToken(token);
    })();

    // device 검색.
    fetchDevices();
  }, [fetchDevices, identity, roomName]);

  // 에러 알림 추가
  useEffect(() => {
    // Handle media error raised by createLocalAudioTrack.
    createLocalAudioTrack().catch(handleMediaError);

    // Handle media error raised by createLocalVideoTrack.
    createLocalVideoTrack().catch(handleMediaError);

    // Handle media error raised by createLocalTracks.
    createLocalTracks().catch(handleMediaError);
  }, []);

  // 2. token 받아오면, twilio-video 실행.
  useEffect(() => {
    if (!token) return;

    createLocalTracks({
      audio: true,
      video: true,
    })
      .then(async (localTracks) => {
        return connect(token, { name: roomName, tracks: localTracks }).catch(
          (error) => {
            if (mediaErrors.includes(error.name)) {
              handleMediaError(error);
            }
          }
        );
      })
      .then(fetchRoom);
  }, [roomName, token]);

  // 상대방이 들어오면, 이벤트 bind
  useEffect(() => {
    if (!participant) return;

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);
    participant.on("trackDisabled", trackDisabled);
    participant.on("trackEnabled", trackEnabled);
  }, [
    participant,
    trackDisabled,
    trackEnabled,
    trackSubscribed,
    trackUnsubscribed,
  ]);

  // application-backgrounding-in-mobile-browsers
  useEffect(() => {
    const visibilityChangeHandler = async () => {
      if (document.visibilityState === "hidden") {
        // The app has been backgrounded. So, stop and unpublish your LocalVideoTrack.
        videoTrack.stop();
        room.localParticipant.unpublishTrack(videoTrack);
      } else {
        // The app has been foregrounded, So, create and publish a new LocalVideoTrack.
        const localVideoTrack = await createLocalVideoTrack();
        await room.localParticipant.publishTrack(localVideoTrack);
        setVideoTrack(localVideoTrack);
      }
    };

    if (isMobile && room) {
      document.addEventListener("visibilitychange", visibilityChangeHandler);
    }

    return () =>
      window.removeEventListener("visibilitychange", visibilityChangeHandler);
  }, [room, setVideoTrack, videoTrack]);

  // 음성 연결
  useEffect(() => {
    if (remoteAudioTrack) {
      remoteAudioTrack.attach(remoteAudioRef.current);
    }
  }, [remoteAudioTrack]);

  // 비디오 연결
  useEffect(() => {
    if (remoteVideoTrack) {
      remoteVideoTrack.attach(remoteVideoRef.current);
    }
  }, [remoteVideoTrack]);

  useEffect(() => {
    if (!audioTrack) return;

    if (micOn) {
      audioTrack.enable();
    } else {
      audioTrack.disable();
    }
  }, [audioTrack, micOn]);

  useEffect(() => {
    if (!videoTrack) return;

    if (cameraOn) {
      videoTrack.enable();
    } else {
      videoTrack.disable();
    }
  }, [cameraOn, videoTrack]);

  const FooterComponent = () => {
    if (previewOn) {
      return;
    }

    if (settingsOn) {
      return (
        <Settings>
          <div className="title">설정</div>
          <div className="setting-section">
            {availableAudios.length > 0 && (
              <div className="row">
                <SettingMicSVG />
                <select defaultValue={audioDeviceId} ref={audioSelectRef}>
                  {availableAudios
                    .filter((device) => device.deviceId !== "default")
                    .map((device) => (
                      <option key={device.deviceId} value={device.deviceId}>
                        {device.label}
                      </option>
                    ))}
                </select>
              </div>
            )}
            {availableVideos.length > 0 && (
              <div className="row">
                <SettingVideoSVG />
                <select defaultValue={videoDeviceId} ref={videoSelectRef}>
                  {availableVideos
                    .filter((device) => device.deviceId !== "default")
                    .map((device) => (
                      <option key={device.deviceId} value={device.deviceId}>
                        {device.label}
                      </option>
                    ))}
                </select>
              </div>
            )}
            {availableSpeakers.length > 0 && (
              <div className="row">
                <SettingVolumeSVG />
                <select defaultValue={volumeDeviceId} ref={volumeSelectRef}>
                  {availableSpeakers
                    .filter((device) => device.deviceId !== "default")
                    .map((device) => (
                      <option key={device.groupId} value={device.groupId}>
                        {device.label}
                      </option>
                    ))}
                </select>
              </div>
            )}
          </div>
          <div className="actions">
            <button
              onClick={async () => {
                await handleChangeDevice();
                toggleSettings();
              }}
            >
              적용
            </button>
            <button className="cancel" onClick={toggleSettings}>
              취소
            </button>
          </div>
        </Settings>
      );
    }

    return (
      <Footer>
        <Action $active={micOn} onClick={toggleMic}>
          {micOn ? <MicSVG /> : <MicOffSVG />}
        </Action>
        <Action $active={cameraOn} onClick={toggleCamera}>
          {cameraOn ? <VideoSVG /> : <VideoOffSVG />}
        </Action>
        <Action $active={true} onClick={togglePhotos}>
          <ImageSVG />
          <input
            ref={imageRef}
            type="file"
            id="image"
            style={{ display: "none" }}
            onChange={(e) => {
              const { files } = e.target;
              if (files.length > 0) {
                handleUpload(files[0]);
              }
            }}
          />
          <label style={{ display: "none" }} htmlFor="image">
            사진
          </label>
        </Action>
        <Action $active={true} onClick={togglePalette}>
          <BrushSVG />
        </Action>
        <Action
          $active={true}
          onClick={hangUp}
          style={{ background: "#ED5D4E" }}
        >
          <PhoneSVG />
        </Action>
      </Footer>
    );
  };

  return (
    <Wrapper>
      {!isDrawing && isOnPalette && !sharedImage && (
        <PaletteOnWrapper>
          <PaletteOnImage src={OnPalettePNG} />
        </PaletteOnWrapper>
      )}

      {previewOn && (
        <ImagePreview $image={sharedImage}>
          <ArrowDownSVG onClick={togglePreview} />
        </ImagePreview>
      )}

      {!previewOn && !participant && (
        <RemoteWaitingWrapper>
          <RemoteWaitingTitle>잠시만 기다려 주세요 :)</RemoteWaitingTitle>
          <RemoteWaitingDesc>상대방이 접속 중입니다..</RemoteWaitingDesc>
        </RemoteWaitingWrapper>
      )}

      <video
        ref={remoteVideoRef}
        autoPlay={true}
        style={
          previewOn
            ? {
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "50%",
                height: "40%",
                objectFit: "cover",
                display: remoteCameraOn ? "block" : "none",
                objectPosition: "center",
              }
            : {
                width: "100%",
                height: "100%",
                objectFit: "cover",
                display: remoteCameraOn ? "block" : "none",
                objectPosition: "center",
              }
        }
      />
      <audio ref={remoteAudioRef} autoPlay={true} muted={false} />
      {/* <SettingButton onClick={toggleSettings} /> */}

      {videoTrack && (
        <MyTracks
          previewOn={previewOn}
          audioTrack={audioTrack}
          videoTrack={videoTrack}
        />
      )}
      <FooterComponent />
      <LoadingBar isLoading={isLoading} />
    </Wrapper>
  );
};
export default VideoSection;

const Wrapper = styled.section`
  width: 100%;
  height: 100%;
  background-color: #efeeec;
  position: absolute;
  bottom: 0;
  z-index: 9000;
`;

const RemoteWaitingWrapper = styled.div`
  width: 100%;
  height: calc(100% - 175px);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const RemoteWaitingTitle = styled.div`
  text-align: center;
  font-size: 30px;
  margin-bottom: 20px;
  font-weight: bold;
`;

const RemoteWaitingDesc = styled.div`
  text-align: center;
  font-size: 18px;
`;

const Footer = styled.section`
  width: 100%;
  height: 185px;
  background: #333333 0% 0% no-repeat padding-box;
  border-radius: 15px 15px 0px 0px;
  position: absolute;
  bottom: 0;
  z-index: 9100;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
`;
const Action = styled.div`
  cursor: pointer;
  width: 56px;
  height: 56px;
  background: ${(p) => (p.$active ? "#404040" : "#F8F8F8")};
  border-radius: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
`;

const PaletteOnWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 9101;
  background-color: #fff;
`;

const PaletteOnImage = styled.img`
  position: absolute;
  height: 100%;
`;

const ImagePreview = styled.div`
  width: 100%;
  height: 60%;
  background-color: #efeeec;
  top: 0;
  position: absolute;
  z-index: 9102;
  background: ${(p) => `#fff url(${p.$image}) 50% 50% no-repeat padding-box`};
  background-size: contain;

  & > svg {
    position: absolute;
    top: 30px;
    right: 15px;
    z-index: 9099;
  }
`;

// const SettingButton = styled(GearSVG)`
//   z-index: 9010;
//   position: absolute;
//   top: 20px;
//   left: 15px;
// `;

const Settings = styled.section`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 10px #9999991a;
  border: 1px solid #efefef;
  border-radius: 15px 15px 0px 0px;
  padding: 34px 0;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 9020;

  & > .title {
    font: normal normal 600 17px/20px Pretendard;
    letter-spacing: -0.26px;
  }

  & > .setting-section {
    margin-top: 13px;
    margin-bottom: 53px;

    & > .row {
      display: flex;
      align-items: center;

      & + & {
        margin-top: 20px;
      }

      & > svg {
        width: 45px;
      }
      & > select {
        width: 282px;
        height: 48px;
        background: #f8f8f8;
        border-radius: 5px;
        font: normal normal 500 15px/18px Pretendard;
        letter-spacing: -0.23px;
        color: #101010;
        margin: 10px 0;
      }
    }
  }

  & > .actions {
    display: flex;
    flex-direction: column;

    & > button {
      width: 271px;
      height: 48px;
      background: #333333;
      border-radius: 10px;
      font: normal normal 500 16px/19px Pretendard;
      letter-spacing: -0.16px;
      color: #ffffff;
    }

    & > button.cancel {
      background: #fff;
      color: #333333;
    }
  }
`;
