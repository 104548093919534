import StudySVG from "assets/svg/study.svg";
import DepressionSVG from "assets/svg/depression.svg";
import IdentitySVG from "assets/svg/identity.svg";
import FutureSVG from "assets/svg/future.svg";
import RelationshipSVG from "assets/svg/relationship.svg";
import FamilySVG from "assets/svg/family.svg";
import StressSVG from "assets/svg/stress.svg";
import BurnoutSVG from "assets/svg/burnout.svg";
import CareerSVG from "assets/svg/career.svg";
import MarriageSVG from "assets/svg/marriage.svg";
import MotivationPNG from "assets/images/motivation.png";
import SelfEsteemPNG from "assets/images/selfEsteem.png";

export const Situation = (type) => {
  if (type === "우울,불안" || type === "우울·불안") {
    return DepressionSVG;
  } else if (type === "연애,결혼" || type === "연애·결혼") {
    return MarriageSVG;
  } else if (type === "정체성") {
    return IdentitySVG;
  } else if (type === "미래") {
    return FutureSVG;
  } else if (type === "학업,진로" || type === "학업·진로") {
    return StudySVG;
  } else if (type === "대인관계") {
    return RelationshipSVG;
  } else if (type === "가족,육아" || type === "가족·육아") {
    return FamilySVG;
  } else if (type === "스트레스") {
    return StressSVG;
  } else if (type === "번아웃") {
    return BurnoutSVG;
  } else if (type === "커리어") {
    return CareerSVG;
  } else if (type === "자존감") {
    return SelfEsteemPNG;
  } else if (type === "동기부여") {
    return MotivationPNG;
  }
};
