import { atom } from "recoil";

export const ReservationInitial = {
  user_nickname: "", // 구매자(내담자)) 닉네임
  doctor_id: 0, // 상담사 id
  doctor_name: "", // 상담사 이름
  price: "", // 금액
  counsel_kind: "", // 상담권 종류
  credit: 0, // 제휴 회원일 경우, 사용가능한 크레딧
  partner_name: "", // 제휴기관명
  purchase_id: 0, // 구매 id
  customer_uid: "", // 결재 수단 id
  merchant_uid: "", // 결재 완료 id
  status: "",
  // 상담일자/시간 (step3)
  date: "",
  times: [],
};

export const userState = atom({
  key: "userState",
  default: null,
});

export const userTokenState = atom({
  key: "userTokenState",
  default: "",
});

export const reservationState = atom({
  key: "reservationState",
  default: ReservationInitial,
});

/**
 * twilio 토큰 : 채팅 / video 공용 사용
 * 토큰 발급 시, identity는 user.id 이용
 */
export const twilioTokenState = atom({
  key: "twilioTokenState",
  default: "",
});

/**
 * twilio Conversation 클라이언트
 */
export const twilioClientState = atom({
  key: "twilioClientState",
  default: null,
});

// twilio 메세지
export const messagesState = atom({
  key: "messagesState",
  default: {
    identity: "",
    messages: [],
  },
  dangerouslyAllowMutability: true,
});
// twilio 대화목록
// export const conversationState = atom({
//   key: "conversationState",
//   default: [],
// });
export const twilioConversationState = atom({
  key: "twilioConversationState",
  default: {
    client: null,
    conversation: null,
  },
  dangerouslyAllowMutability: true,
});

export const twilioState = atom({
  key: "twilioState",
  default: {
    type: "chat", //채팅 타입 : chat / voice / video
    token: "",
    room: null,
    chatRoom: null, // 기본 채팅방.
    audioTrack: null, // localParticipant's audioTrack
    videoTrack: null, // localParticipant's videoTrack
    participant: null, // 상대방 (1:1 대화)
    participantAudioTrack: null, // 상대방 audioTrack
    participantVideoTrack: null, // 상대방 videoTrack
  },
});

/**
 * 환불 신청용 state
 */
export const refundState = atom({
  key: "refundState",
  default: {
    reservation: null,
  },
});

// room 상단 채팅방 이름
export const friendlyName = atom({
  key: "friendlyName",
  default: "",
});

// 미술심리테스트 / 갤러리 이미지
export const shareImage = atom({
  key: "shareImage",
  default: "",
});

// 미술심리테스트 결과
export const examineResult = atom({
  key: "examineResult",
  default: "",
});

export const examineStep = atom({
  key: "examineStep",
  default: 1,
});

export const beforeSteps = atom({
  key: "beforeSteps",
  default: 0,
});

export const paymentState = atom({
  key: "paymentState",
  default: "",
});
