import client from "./client";

export const fetchNotify = async (page, userType) => {
  const notify = await client.get(
    `/${userType === "client" ? "user" : "doctor"}/notifications`,
    {
      page: page,
      per: 10,
    }
  );
  return notify;
};
