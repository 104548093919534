import PropTypes from "prop-types";

import styled from "styled-components";

import Form from "components/Form";
import useNavigate from "hooks/useNavigate";
import { PHONE_ERROR } from "utils/message";
import { PHONE_PATTERN } from "utils/regex";

const FormSection = ({ email, nickname, register, formState: { errors } }) => {
  const { goChangePassword } = useNavigate();

  return (
    <Wrapper>
      <Form.InputField
        label="이메일"
        placeholder="email"
        disabled={true}
        defaultValue={email}
        style={{ color: "#999999" }}
      />
      <Form.InputField
        label="닉네임"
        placeholder="홍길동"
        disabled={true}
        defaultValue={nickname}
        style={{ color: "#999999" }}
      />
      <Form.Row>
        <Form.InputField
          label="비밀번호"
          placeholder="홍길동"
          disabled={true}
          defaultValue={"********"}
          style={{ color: "#999999" }}
        />
        <Form.Button onClick={goChangePassword}>변경</Form.Button>
      </Form.Row>
      <Form.InputField
        label="휴대폰 번호"
        type="tel"
        placeholder="-없이 번호 입력"
        {...register("phone", {
          required: true,
          pattern: {
            value: PHONE_PATTERN,
            message: PHONE_ERROR,
          },
          minLength: {
            value: 11,
            message: PHONE_ERROR,
          },
          maxLength: {
            value: 11,
            message: PHONE_ERROR,
          },
        })}
        error={errors?.phone?.message}
      />
    </Wrapper>
  );
};

export default FormSection;

FormSection.protoTypes = {
  email: PropTypes.string.isRequired,
  nickname: PropTypes.string.isRequired,
  register: PropTypes.object,
  formState: PropTypes.object,
};

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 17px;
  padding: 36px 0 40px 0;
`;
