import useNavigate from "hooks/useNavigate";
import styled from "styled-components";

import Popup, { ActionButton } from ".";

const InfoPartnerCheck = ({ onClose }) => {
  const { goMyPartner } = useNavigate();

  return (
    <Popup>
      <Wrapper>
        <label>제휴기관 멤버십을 인증하시겠습니까?</label>
        <p>
          제휴 기관 직원이시라면 인증 화면으로 연결됩니다.
          <br /> (인증은 추후 ‘내 정보 수정’에서도 가능합니다)
        </p>
        <ActionButton onClick={goMyPartner}>확인</ActionButton>
        <CloseButton
          onClick={onClose}
          style={{
            width: "271px",
            height: "48px",
            background: "#E6E6E6",
            borderRadius: "10px",
            color: "#6E6E6E",
            font: "normal normal 400 16px/19px Pretendard",
          }}
        >
          취소
        </CloseButton>
      </Wrapper>
    </Popup>
  );
};
export default InfoPartnerCheck;

const Wrapper = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 63px;
  padding-bottom: 20px;

  & > label {
    font: normal normal bold 22px/27px Pretendard;
    letter-spacing: -0.25px;
    color: #101010;
    margin-bottom: 24px;
  }

  & > p {
    font: normal normal normal 12pt/22pt Pretendard;
    letter-spacing: -0.23px;
    color: #404040;
    margin-bottom: 48px;
    text-align: center;
  }

  & > button.cancel {
    background-color: unset;
    font: normal normal 500 16px/19px Pretendard;
    letter-spacing: -0.16px;
    color: #333333;
    margin-top: 10px;
  }
`;
const CloseButton = styled(ActionButton)`
  background: #fff;
  color: #333;
  margin-top: 10px;
`;
