import styled from "styled-components";

import Banner from "containers/Banner";
import CounselorList from "containers/CounselorList";
import MySituation from "containers/MySituation";
import MatchingConsultation from "containers/MatchingConsultation";
import { checkDeviceOS, MobileOS, getAppInfo } from "utils/checkMobile";
const HomePage = () => {
  return (
    <Wrapper className="homeWrapper" id="homePage">
      <Banner />

      <Sections>
        <MySituation />
        <MatchingConsultation />
        <CounselorList />
      </Sections>
    </Wrapper>
  );
};
export default HomePage;

const Wrapper = styled.div`
  // padding: 12px 2px 0px 2px;
`;
const Sections = styled.div`
  display: flex;
  flex-direction: column;
  gap: 45px;
  margin-top: 36px;
`;

const DividingLine = styled.div`
  height: 8px;
  background-color: #f8f8f8;
  border: #f8f8f8;
`;
