import { useEffect, useState } from "react";
import { useLocation, useNavigate as _useNavigate } from "react-router-dom";

import {
  Wrapper,
  Title,
  LoginForm,
  LoginButton,
  CenterSection,
} from "./StyledComps";
import FindAndJoin from "./FindAndJoin";
// import SNSLogin from "./SNSLogin";
import LoginFooter from "components/LoginFooter";
import useAuth from "hooks/useAuth";
import useNavigate from "hooks/useNavigate";
import { MYINFO_PATH } from "App";

const ClientLogin = () => {
  const { loginClient } = useAuth();
  const location = useLocation();
  const { goDoctorLogin } = useNavigate();
  const navigate = _useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const changeEmail = (e) => setEmail(e.target.value);
  const changePassword = (e) => setPassword(e.target.value);
  const login = async () => {
    try {
      if (!email || !password) {
        throw new Error();
      }
      await loginClient(email, password, () => {
        const { state } = location;
        if (
          state !== undefined &&
          state.hasOwnProperty("prevPath") &&
          state.prevPath !== undefined
        ) {
          navigate(state.prevPath);
        } else {
          navigate(MYINFO_PATH);
        }
      });
    } catch (e) {
      alert("정확한 정보를 입력하세요");
    }
  };

  return (
    <Wrapper className="LoginSection">
      <Title>
        로그인 후 <br />
        이용 가능합니다.
      </Title>

      <LoginForm>
        <LoginForm.Field>
          <LoginForm.Input
            value={email}
            type="email"
            onChange={changeEmail}
            placeholder="아이디(이메일)"
          />
        </LoginForm.Field>
        <LoginForm.Field>
          <LoginForm.Input
            value={password}
            type="password"
            onChange={changePassword}
            placeholder="비밀번호"
          />
        </LoginForm.Field>
      </LoginForm>

      <CenterSection>
        <LoginButton onClick={login}>로그인</LoginButton>
        <FindAndJoin />
        {/* <SNSLogin /> */}
        <LoginFooter
          linkLabel={"상담사 로그인"}
          message={"상담사이신가요?"}
          onClick={goDoctorLogin}
        />
      </CenterSection>
    </Wrapper>
  );
};

export default ClientLogin;
