import { useCallback, useEffect, useState, useRef } from "react";
import styled from "styled-components";

import { ReactComponent as StarSVG } from "assets/svg/star.svg";
import { ReactComponent as ChatSVG } from "assets/svg/chat.svg";
import { ReactComponent as PhoneSVG } from "assets/svg/phone.svg";
import { ReactComponent as UserSVG } from "assets/svg/user.svg";
import { ReactComponent as VideoSVG } from "assets/svg/video.svg";

import { ReactComponent as ClockSVG } from "assets/svg/clock.svg";

import location from "assets/svg/location.svg";

import useNavigate from "hooks/useNavigate";
import Review from "./Review";

const Doctor = ({
  id,
  counsel_kind,
  counsels,
  experience,
  introduction,
  doctor_stories,
  name,
  img,
  reviews,
  star,
  reviews_count,
  organization_info,
  activeReservationId,
  onCreateReview,
  preferred_days_of_week,
  preferred_times,
}) => {
  const { goReviewForm } = useNavigate();
  const mapContainer = useRef(null);

  const [coordinate, setCoordinate] = useState({ x: 0, y: 0 });
  const [preferredDaysOfWeek, setPreferredDaysOfWeek] = useState([]);
  const [preferredTimes, setPreferredTimes] = useState([]);

  const getCoordinate = async () => {
    const { Geocoder, Status } = window.kakao.maps.services;

    const geocoder = new Geocoder();
    await geocoder.addressSearch(
      organization_info?.address,
      (result, status) => {
        if (status === Status.OK) {
          const { x, y } = result[0];
          setCoordinate({ x: Number(x), y: Number(y) });
        }
      }
    );
  };

  const initMap = useCallback(() => {
    if (mapContainer.current) {
      const container = mapContainer.current;
      const coords = new window.kakao.maps.LatLng(coordinate.y, coordinate.x);

      const imageSrc = location,
        imageSize = new window.kakao.maps.Size(64, 69),
        imageOption = { offset: new window.kakao.maps.Point(35, 57) };

      const markerImage = new window.kakao.maps.MarkerImage(
        imageSrc,
        imageSize,
        imageOption
      );

      const marker = new window.kakao.maps.Marker({
        position: coords,
        clickable: true,
        image: markerImage,
      });
      const options = {
        center: coords,
        level: 4,
        draggable: true,
      };
      const map = new window.kakao.maps.Map(container, options);
      marker.setMap(map);

      const iwContent = `
      <div class ="label">
        <span class="left"></span>
        <span class="center">${organization_info.address}</span>
        <span class="right"></span>
      </div>
      `;

      const customOverlay = new window.kakao.maps.CustomOverlay({
        position: new window.kakao.maps.LatLng(
          coordinate.y + 0.0012,
          coordinate.x
        ),
        content: iwContent,
      });

      customOverlay.setMap(map, marker);
    }
  }, [coordinate]);

  useEffect(() => {
    initMap();
  }, [initMap]);

  useEffect(() => {
    (async () => getCoordinate())();
  }, [organization_info?.address]);

  useEffect(() => {
    if (preferred_days_of_week === undefined) return;
    if (preferred_times === undefined) return;

    setPreferredDaysOfWeek(preferred_days_of_week);
    setPreferredTimes(preferred_times);
  }, [preferred_days_of_week, preferred_times]);

  return (
    <Wrapper>
      <Row>
        <PhotoSection $image={img} />
        <ProfileSection>
          <Name>{name}</Name>
          <StarSection>
            <StarSVG />
            <span className="star">{Number(star).toFixed(1)}</span>
            <span className="reviews">({reviews_count})</span>
          </StarSection>
          {counsel_kind && (
            <KindSection>
              {counsel_kind.chat > 0 && (
                <div>
                  <ChatSVG />
                </div>
              )}
              {counsel_kind.video > 0 && (
                <div>
                  <VideoSVG />
                </div>
              )}
              {counsel_kind.visit > 0 && (
                <div>
                  <UserSVG />
                </div>
              )}
              {counsel_kind.voice > 0 && (
                <div>
                  <PhoneSVG />
                </div>
              )}
            </KindSection>
          )}
        </ProfileSection>
      </Row>
      <Section>
        <>
          <h2>상담 선호 주제</h2>
          {counsels !== undefined &&
            counsels.map((value, i) => {
              return <Subject>#{value.subject}</Subject>;
            })}
        </>
      </Section>
      <Section>
        <>
          <h2>
            상담 선호 시간
            <ClockSVG
              style={{
                marginLeft: "5px",
                width: "14px",
                height: "14px",
                display: "inline-block",
              }}
            />
          </h2>
          <Date>요일</Date>
          {preferredDaysOfWeek.map((value, i) => (
            <div style={{ display: "inline-block", margin: "0 0 14px 10px" }}>
              {value}
            </div>
          ))}
          <br />
          <Date>시간</Date>{" "}
          <div style={{ display: "inline-block", margin: "0 0 14px 10px" }}>
            {preferredTimes.map((value, i) => {
              return (
                <>
                  {value === "오전(9~13시)" ? (
                    <>
                      <Morning>{value}</Morning>
                    </>
                  ) : value === "오후(13~17시)" ? (
                    <Afternoon>{value}</Afternoon>
                  ) : value === "저녁(17~21시)" ? (
                    <Evening>{value}</Evening>
                  ) : (
                    <></>
                  )}
                </>
              );
            })}
          </div>
        </>
      </Section>
      <Section>
        <h2>소개</h2>
        {introduction}
      </Section>
      <Section>
        <h2>주요 자격 및 경력</h2>
        <div className="experience">
          {experience
            ?.split("\n" || "\r\n")
            .filter(Boolean)
            .map((row, i) => {
              return (
                <span key={i}>
                  {row}
                  <br />
                </span>
              );
            })}
        </div>
      </Section>
      {doctor_stories?.some((story) => !!story) && (
        <Section>
          <h2>상담사 이야기</h2>
          {doctor_stories[0] &&
            !!doctor_stories[0].replace(/\r\n|\r|\n/, "") && (
              <div>
                <h3>Q. 선생님의 주요 상담 기법에 대해 말씀해 주세요.</h3>
                <div className="story">A. {doctor_stories[0]}</div>
              </div>
            )}
          {doctor_stories[1] &&
            !!doctor_stories[1].replace(/\r\n|\r|\n/, "") && (
              <div style={{ marginTop: "32px" }}>
                <h3>Q. 어떤 분에게 상담을 추천하시나요?</h3>
                <div className="story">A. {doctor_stories[1]}</div>
              </div>
            )}
          {doctor_stories[2] &&
            !!doctor_stories[2].replace(/\r\n|\r|\n/, "") && (
              <div style={{ marginTop: "32px" }}>
                <h3>Q. 상담 치료를 망설이는 분들께 한 마디를 하신다면?</h3>
                <div className="story">A. {doctor_stories[2]}</div>
              </div>
            )}
        </Section>
      )}

      {coordinate.x > 0 && (
        <Section>
          <h2>지도</h2>
          {mapContainer ? <Map id="map" ref={mapContainer} /> : ""}
        </Section>
      )}

      <Section>
        <ReviewButton onClick={onCreateReview}>후기 작성</ReviewButton>
        <h2>상담 후기</h2>

        {reviews?.length > 0 ? (
          reviews.map((review) => (
            <Review
              key={review.id}
              {...review}
              onClickEdit={() =>
                goReviewForm(id, name, activeReservationId, review.id)
              }
            />
          ))
        ) : (
          <NoReview>아직 작성된 후기가 없습니다.</NoReview>
        )}
      </Section>
    </Wrapper>
  );
};
export default Doctor;

const Wrapper = styled.section`
  width: 100%;
  padding: 20px 15px 97px 15px;
  margin-top: 20px;
`;
const Row = styled.div`
  display: flex;
  margin-top: 36pt;
`;
const Section = styled.div`
  position: relative;
  font: normal normal 500 15px/18px Pretendard;
  letter-spacing: -0.15px;
  color: #101010;
  padding: 28px 15px 0 15px;
  margin-bottom: 24px;
  line-height: 24px;

  & > h2 {
    font: normal normal 600 17px/20px Pretendard;
    letter-spacing: -0.26px;
    color: #999999;
    margin-bottom: 12px;
  }

  & > h3 {
    font: normal normal 600 15px/18px Pretendard;
    letter-spacing: -0.15px;
    color: #101010;
    margin-bottom: 16px;
  }

  & .experience {
    margin-left: 16px;
    padding-left: 16px;
    border-left: 1px dashed #999999;
    line-height: 24px;

    & > span + span {
      width: 100%;
      padding-top: 14px;
      display: inline-block;
    }
  }

  & .story {
    font: normal normal 400 15px/27px Pretendard;
    letter-spacing: -0.15px;
    color: #6e6e6e;
    margin-top: 24px;
  }

  & + & {
    border-top: 1px solid #efefef;
  }
`;
const PhotoSection = styled.div`
  width: 92px;
  height: 92px;
  border-radius: 15pt;
  margin-top: auto;
  background-image: url(${(p) => p.$image});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  flex-shrink: 0;
  border: solid 1px #e6e6e6;
`;
const ProfileSection = styled.div`
  margin-left: 16pt;
`;
const Name = styled.div`
  margin-top: 4pt;
  font: normal normal bold 24px/29px Pretendard;
  letter-spacing: -0.36px;
  color: #101010;

  &::after {
    content: "상담사";
    font: normal normal 400 14px/29px Pretendard;
    letter-spacing: -0.21px;
    color: #6e6e6e;
    margin-left: 7px;
  }
`;
const Counsel = styled.label`
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border-radius: 5px;
  font: normal normal 500 14px/17px Pretendard;
  letter-spacing: -0.21px;
  color: #101010;
`;
const StarSection = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;

  & > span.star {
    font: normal normal 600 15px/18px Pretendard;
    letter-spacing: -0.15px;
    color: #101010;
    margin-left: 4px;
  }
  & > span.reviews {
    font: normal normal 400 15px/18px Pretendard;
    letter-spacing: -0.15px;
    color: #999999;
    margin-left: 4px;
  }
`;
const KindSection = styled.div`
  width: 100%;
  padding-top: 21px;
  display: flex;

  & > div {
    padding-right: 12px;
    height: 12px;
  }
  & > div + div {
    border-left: 1px solid #e6e6e6;
    padding-right: 12px;
    padding-left: 12px;
  }
`;
const Map = styled.div`
  width: 100%;
  height: 185px;
`;
const ReviewButton = styled.button`
  width: 75px;
  height: 28px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  background: #ffffff;
  font: normal normal 500 13px/16px Pretendard;
  letter-spacing: -0.13px;
  color: #101010;
  position: absolute;
  top: 23px;
  right: 0;

  &:hover {
    color: #333333;
    background-color: #ffffff;
  }
`;
const NoReview = styled.div`
  font: normal normal normal 15px/27px Pretendard;
  letter-spacing: -0.15px;
  color: #cbcbcb;
  padding: 32px 0 0 0;
  text-align: center;
`;

const Date = styled.div`
  display: inline-block;
  padding-right: 10px;
  border-right: solid 1pt #e6e6e6;
  font-weight: 600;
`;

const Morning = styled.div`
  display: inline-block;
  background-color: #faf3dd;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  font-size: 12px;
  padding: 1px 8px;
  margin-right: 4px;
`;

const Afternoon = styled.div`
  display: inline-block;
  background-color: #eef3ed;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  font-size: 12px;
  padding: 1px 8px;
  margin-right: 4px;
`;

const Evening = styled.div`
  display: inline-block;
  background-color: #f6f3f8;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  font-size: 12px;
  padding: 1px 8px;
`;

const Subject = styled.div`
  display: inline-block;
  border: solid 1px #ebebeb;
  padding: 3px 12px;
  border-radius: 5pt;
  font-size: 13px;
  margin-right: 6px;
  margin-bottom: 6px;
`;
