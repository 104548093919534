import useNavigate from "hooks/useNavigate";
import styled from "styled-components";

import Popup, { ActionButton } from ".";

const FailPartnerCheck = ({ onClose }) => {
  const { goLogin } = useNavigate();

  return (
    <Popup>
      <Wrapper>
        <label>제휴 기관 인증 실패</label>
        <p>제휴 기관 정보와 일치하지 않습니다.</p>
        <ActionButton onClick={onClose}>확인</ActionButton>
      </Wrapper>
    </Popup>
  );
};
export default FailPartnerCheck;

const Wrapper = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 80px;

  & > label {
    font: normal normal 500 18px/21px Pretendard;
    letter-spacing: -0.27px;
    color: #101010;
    margin-bottom: 24px;
  }

  & > p {
    font: normal normal 400 15px/18px Pretendard;
    letter-spacing: -0.23px;
    color: #404040;
    margin-bottom: 54px;
  }

  & > button.cancel {
    background-color: unset;
    font: normal normal 500 16px/19px Pretendard;
    letter-spacing: -0.16px;
    color: #333333;
    margin-top: 20px;
  }
`;
