import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

import client from "apis/client";
// import serviceTXT from "assets/txt/service_220902.txt";
// import privateTXT from "assets/txt/private_220818.txt";
// import healthTXT from "assets/txt/health_220818.txt";
// import refundTXT from "assets/txt/refund_220818.txt";
import moment from "moment";

const Terms = () => {
  const [searchParams] = useSearchParams();
  const [content, setContent] = useState("");
  const termType = searchParams.get("termType");
  const [dataList, setDataList] = useState([]);
  // const targetFile =
  //   termType === "service"
  //     ? serviceTXT
  //     : termType === "private"
  //     ? privateTXT
  //     : termType === "health"
  //     ? healthTXT
  //     : refundTXT;

  const fetchPolicy = useCallback(async () => {
    await client.get(`/policies/${termType}`).then((data) => {
      setDataList(data.data.policies, dataList);
      const contents = data.data.policies[0].body;
      setContent(contents.replaceAll("\n", "<br/>"));
    });
  }, [termType]);

  const onChange = (e) => {
    for (var i = 0; i < dataList.length; i++) {
      if (dataList[i].last_updated_at === e.target.value) {
        setContent(dataList[i].body.replaceAll("\n", "<br/>"));
        break;
      } else {
        continue;
      }
    }
    // setContent((e.target.value).replaceAll("\n", "<br/>"));
  };

  // useEffect(() => {
  //   fetch(targetFile)
  //     .then((r) => r.text())
  //     .then((text) => {
  //       setContent(text.replaceAll("\n", "<br/>"));
  //     });
  // }, [targetFile]);

  useEffect(() => {
    fetchPolicy();
  }, [fetchPolicy]);
  return (
    <Wrapper>
      <DateSelector>
        현행 시행날짜:
        <select onChange={onChange}>
          {dataList.map((dataList) => (
            <option value={dataList.last_updated_at}>
              {dataList.last_updated_at}
            </option>
          ))}
        </select>
      </DateSelector>
      <Content dangerouslySetInnerHTML={{ __html: content }}></Content>
    </Wrapper>
  );
};
export default Terms;

const Wrapper = styled.section`
  padding: 100px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const DateSelector = styled.div`
  width: fit-content;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e6e6e6;
  border-radius: 5px;

  font: normal normal 500 14px/17px Pretendard;
  letter-spacing: -0.14px;
  color: #333333;
  padding: 10px 28px;

  // &::after {
  //   content: "▼";
  //   margin-left: 18px;
  // }
`;
const Content = styled.div`
  margin-top: 40px;
  font: normal normal 400 13px/16px Pretendard;
  letter-spacing: -0.13px;
  color: #101010;
  height: calc(100vh - 330px);
  overflow-y: auto;
`;
