import styled from "styled-components";

import { getLabelDescription } from "utils/message";
import RefundInfo from "./RefundInfo";

const ReservationConfirm = ({ counselKind, price }) => {
  const { label, description } = getLabelDescription(counselKind);

  return (
    <Wrapper>
      <CounselInfo>
        <label>{label}</label>
        <div>• 50분 x 1회</div>
        <div>{description}</div>
      </CounselInfo>

      <PriceInfo>
        <div className="row">
          <label>{label}</label>
          <span>{price.toLocaleString()}원</span>
        </div>
        <hr />

        <div className="confirm-box">
          <label>결제 금액</label>
          <div>{price.toLocaleString()} 원</div>
        </div>
      </PriceInfo>

      <RefundInfo />
    </Wrapper>
  );
};
export default ReservationConfirm;

const Wrapper = styled.div`
  width: 100%;
  height: 92%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const CounselInfo = styled.div`
  border: 1px solid #efefef;
  border-radius: 15px;
  padding: 24px 24px 16px 24px;
  margin: 0 15px;
  font: normal normal 400 13px/16px Pretendard;
  letter-spacing: -0.28px;
  color: #6e6e6e;

  & > label {
    display: block;
    font: normal normal 600 20px/24px Pretendard;
    letter-spacing: -0.2px;
    color: #101010;
    margin-bottom: 11px;
  }

  & > div + div {
    margin-top: 12px;
  }
`;
const PriceInfo = styled.div`
  & > hr {
    height: 1px;
    background-color: #e6e6e6;
    margin: 20px 0;
    border: none;
  }

  & > .row {
    display: flex;
    justify-content: space-between;
    margin: 29px 15px 0 15px;
    font: normal normal 500 17px/20px Pretendard;
    letter-spacing: -0.17px;
    color: #101010;

    & > label {
      font: normal normal 500 15px/18px Pretendard;
      letter-spacing: -0.3px;
      color: #999999;
    }
  }

  & > .row + .row {
    margin-top: 16px;
  }

  & > .confirm-box {
    padding: 28px 16px;
    background: #f8f8f8 0% 0% no-repeat padding-box;
    border-radius: 10px;
    text-align: right;
    margin: 0 15px 20px 15px;
    font: normal normal bold 20px/24px Pretendard;
    letter-spacing: -0.2px;
    color: #1a1a1a;
    & > label {
      font: normal normal 500 16px/19px Pretendard;
      letter-spacing: -0.16px;
      color: #101010;
    }

    & > label + div {
      margin-top: 10px;
    }
  }
`;
