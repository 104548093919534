import styled from "styled-components";

import TimeTag from "./TimeTag";

import CheckSVG from "assets/svg/check.svg";
import { getLabelDescription } from "utils/message";

const CounselKind = ({ selected, kind, price, onClick }) => {
  const { label, description } = getLabelDescription(kind);
  return (
    <Wrapper $active={selected} onClick={onClick}>
      <Title>{label}</Title>
      <TimeTag />
      <Description>{description}</Description>
      <Price>{price.toLocaleString()}</Price>
      {selected && <Selected />}
    </Wrapper>
  );
};
export default CounselKind;

const Wrapper = styled.section`
  position: relative;
  width: 100%;
  height: 190px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: ${(p) => (p.$active ? "1px solid #485AF3" : "1px solid #efefef")};
  border-radius: 15px;
  padding: 21px 24px;
`;
const Title = styled.div`
  font: normal normal 600 20px/24px Pretendard;
  letter-spacing: -0.2px;
  color: #101010;
  margin-bottom: 16px;
`;
const Description = styled.div`
  font: normal normal 400 13px/16px Pretendard;
  letter-spacing: -0.3px;
  color: #6e6e6e;
  margin-top: 12px;
  height: 32px;
`;
const Price = styled.div`
  font: normal normal 600 17px/20px Pretendard;
  letter-spacing: -0.26px;
  color: #101010;
  text-align: right;
  margin-top: 18px;

  &::after {
    content: "원";
  }
`;
const Selected = styled.div`
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background-color: #485af3;
  background-image: url(${CheckSVG});
  background-repeat: no-repeat;
  background-size: 10px 7px;
  background-position: center;
  position: absolute;
  right: 0;
  top: -10px;
`;
