import { useState, useCallback } from "react";

const useLocalVideo = () => {
  const [micOn, setMicOn] = useState(true);
  const [cameraOn, setCameraOn] = useState(true);
  const [audioTrack, setAudioTrack] = useState(null);
  const [videoTrack, setVideoTrack] = useState(null);

  const toggleMic = useCallback(() => {
    if (!audioTrack) return;

    if (micOn) {
      setMicOn(false);
    } else {
      setMicOn(true);
    }
  }, [audioTrack, micOn]);

  const toggleCamera = useCallback(() => {
    if (!videoTrack) return;

    if (cameraOn) {
      setCameraOn(false);
    } else {
      setCameraOn(true);
    }
  }, [cameraOn, videoTrack]);

  return {
    micOn,
    cameraOn,
    toggleMic,
    toggleCamera,
    audioTrack,
    videoTrack,
    setAudioTrack,
    setVideoTrack,
  };
};

export default useLocalVideo;
