import styled from "styled-components";

import theSea from "assets/images/theSea.png";
import wave from "assets/images/wave.png";
import volcano from "assets/images/volcano.png";

const ExamineStep8 = ({ setResults, results, nextStep }) => {
  return (
    <div
      style={{ marginLeft: "10px", marginRight: "10px" }}
      className="examineStep8_ImageDiv"
    >
      <div style={{ display: "inline-block" }}>
        <Button
          className="examineStep8_Image"
          value="화산"
          onClick={() => {
            setResults({ ...results, background: "화산" });
            nextStep();
          }}
          style={{
            backgroundImage: `url(${volcano})`,
          }}
        ></Button>
        <Text className="examineStep8_Text">
          <BlueCircle />
          화산 배경
        </Text>
      </div>
      <div style={{ display: "inline-block" }}>
        <Button
          className="examineStep8_Image"
          value="해안가의 잔잔한 파도"
          onClick={() => {
            setResults({ ...results, background: "해안가의 잔잔한 파도" });
            nextStep();
          }}
          style={{
            backgroundImage: `url(${theSea})`,
          }}
        ></Button>
        <Text className="examineStep8_Text">
          <BlueCircle />
          해안가의 잔잔한 파도 배경
        </Text>
      </div>
      <div style={{ display: "inline-block" }}>
        <Button
          className="examineStep8_Image"
          value="거친 파도"
          onClick={() => {
            setResults({ ...results, background: "거친 파도" });
            nextStep();
          }}
          style={{
            backgroundImage: `url(${wave})`,
            backgroundSize: "100% 100%",
          }}
        ></Button>
        <Text className="examineStep8_Text">
          <BlueCircle />
          거친 파도 배경
        </Text>
      </div>
    </div>
  );
};

export default ExamineStep8;

const Button = styled.button`
  width: 185px;
  height: 210px;
  margin: 0px 4px;
  border: 1px solid #e6e6e6;
  border-radius: 5pt;
  background-size: 100% 100%;
  &:hover {
    background-color: #8ba8ff;
    color: #ffffff;
  }

  &:checked {
    background-color: #8ba8ff;
    color: #ffffff;
  }
`;

const Text = styled.div`
  margin: 5px;
  text-align: left;
  font-size: 15px;
`;

const BlueCircle = styled.div`
  width: 8px;
  height: 8px;
  display: inline-block;
  background-color: #91a7ff;
  border-radius: 50%;
  margin-bottom: 2px;
  margin-right: 8px;
`;
