import styled from "styled-components";
import useNavigate from "hooks/useNavigate";
import { useEffect, useState } from "react";
import CheckSVG from "assets/svg/check.svg";

const Examine = () => {
  const { goExamineStep } = useNavigate();
  const [btnState, setBtnState] = useState({
    btn_1: false,
    btn_2: false,
  });
  const [btnDisabled, setBtnDisabled] = useState(true);
  const nextButton = () => {
    if (btnState.btn_1 && btnState.btn_2) {
      goExamineStep();
    }
  };

  useEffect(() => {
    if (btnState.btn_1 && btnState.btn_2) setBtnDisabled(false);
  }, [btnState]);

  return (
    <Wrapper>
      <Title>개인정보 수집 및 이용 동의</Title>
      <Content>
        • 수집 항목 : 성별, 연령대, 학력, 근무 형태, 근무 경력
        <br />
        • 이용 목적 : 미술 심리 테스트 결과 분석
        <br />• 개인 정보 보유 및 이용 기간 : 결과 도출 이후 즉시 폐기
      </Content>
      <div style={{ margin: "0 15px" }}>
        <Input type="checkbox" id="check1" />
        <Label
          for="check1"
          onClick={() => {
            setBtnState({ ...btnState, btn_1: !btnState.btn_1 });
          }}
        >
          <Text>개인 정보 수집 및 이용에 동의합니다.</Text>
        </Label>{" "}
      </div>
      <Title>개인정보 목적 외 이용 및 제3자 제공 동의</Title>
      <Content>
        제공받는 자 : 글로벌사이버대학교 상담심리학과 김재훈 교수 연구팀
        <br />
        제공하는 항목 : 정보 주체의 성별, 연령대, 학력, 근무 형태, 근무 경력,
        미술 심리 테스트 결과
        <br />
        제공 받는 자의 이용 목적 : 미술 심리 테스트 결과를 기반으로 한 통계 분석
        및 미술 심리 검사 고도화를 위한 활용
        <br />
        개인정보 보유 및 이용 기간 : 수집일로부터 2년
      </Content>
      <div style={{ margin: "0 15px" }}>
        <Input type="checkbox" id="check2" />
        <Label
          for="check2"
          onClick={() => {
            setBtnState({ ...btnState, btn_2: !btnState.btn_2 });
          }}
        >
          <Text>개인 정보 수집 및 이용에 동의합니다.</Text>
        </Label>
      </div>
      <hr
        style={{
          border: "0",
          width: "100%",
          height: "1px",
          background: "#e6e6e6",
        }}
      />
      <Warning>
        * 이용자는 개인정보 수집 및 제3자 제공을 거부할 권리가 있으며,{" "}
        <Span>동의 거부 시 미술 심리 테스트를 진행할 수 없습니다.</Span>
      </Warning>
      <NextButton onClick={nextButton} disabled={btnDisabled}>
        다음으로
      </NextButton>
    </Wrapper>
  );
};

export default Examine;

const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #ffffff;
  margin-top: 69px;
`;

const Title = styled.div`
  font: normal normal 700 15pt/17px Pretendard;
  margin: 28px 14px 0px 14px;
`;

const Content = styled.div`
  font: normal normal 450 9.6pt/9px Pretendard;
  margin: 0 10pt;
  color: #8e8e8e;
  line-height: 22px;
  padding: 16px;
  background-color: #f8f8f8;
  border: solid 1px #e6e6e6;
  border-radius: 5px;
`;

const NextButton = styled.button`
  margin-bottom: 85px;
  margin-top: 44px;
  width: 271px;
  height: 48px;
  background: #333333 0% 0% no-repeat padding-box;
  border-radius: 10px;
  font: normal normal 500 16px/19px Pretendard;
  letter-spacing: -0.16px;
  color: #ffffff;
  margin-left: auto;
  margin-right: auto;

  &:hover {
    pointer-events: none !importtant;
  }
`;

const Input = styled.input`
  display: none;

  &:checked + label::after {
    content: "";
    width: 20px;
    height: 20px;
    text-align: center;
    position: absolute;
    left: -1px;
    top: -2px;
    background-color: #333333;
    background-image: url(${CheckSVG});
    background-size: 100% 100%;
  }
`;

const Label = styled.label`
  display: inline-block;
  position: relative;
  border: solid 2px #bebebe;
  background: #f8f8f8;
  width: 20px;
  height: 20px;
  vertical-align: middle;
`;

const Warning = styled.div`
  margin: 0 15px;
  font-size: 13px;
  color: #8e8e8e;
  line-height: 20px;
`;

const Span = styled.span`
  color: #101010;
  font-weight: 500;
`;

const Text = styled.div`
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  width: 400px;
  padding-left: 25px;
`;
