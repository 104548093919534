import { useEffect, useState } from "react";

import { fetchCounsels } from "apis/doctor";

const useCounsels = () => {
  const [counsels, setCounsels] = useState([]);

  useEffect(() => {
    (async () => {
      const {
        data: { counsels },
      } = await fetchCounsels();
      setCounsels(counsels);
    })();
  }, []);

  return counsels;
};
export default useCounsels;
