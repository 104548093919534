import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  height: 100%;
  overflow-y: auto;
`;
export const Section = styled.section`
  background: #ffffff;
  padding: 40px 15px 24px 15px;
  display: flex;
  flex-direction: column;
  gap: 23px;
`;
export const Title = styled.div`
  font: normal normal 600 18px/21px Pretendard;
  letter-spacing: -0.27px;
  color: #101010;

  & > span.required {
    color: #485af3;
  }
`;
export const Counsels = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;
export const CounselKinds = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const CounselKind = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  & > *:first-child {
    width: fit-content;
    flex-shrink: 0;
    margin-right: 56px;
  }
`;
