import { ReactComponent as CallStartSVG } from "assets/svg/call-start.svg";
import { ReactComponent as CallSuccessSVG } from "assets/svg/call-success.svg";
import { ReactComponent as CallEndSVG } from "assets/svg/call-end.svg";
import { ReactComponent as CallFailSVG } from "assets/svg/call-fail.svg";

export const PaletteMessage = {
  StartPalette: "팔레트 그리기를 시작하였습니다",
  EndPalette: "팔레트 그리기를 종료하였습니다",
};

export const CallMessage = {
  Start: {message:'연결 중입니다.', icon: CallStartSVG},
  Success: {message:'연결 되었습니다.', icon: CallSuccessSVG},
  End: {message:'연결이 종료되었습니다.', icon: CallEndSVG},
  Failure: {message:'연결에 실패했습니다.', icon: CallFailSVG},
}

export const CHAT_RESERVED_WORD = "MINDDRAWING_RESERVED_WORD_";

export const CHAT_RESERVED_WORD_OVERVIEW = {
  '001': '🔊 원활한 상담을 위해 카메라, 마이크 권한을 꼭 허용해주세요.',
  '002': '✨ 상담 시간이 종료되었습니다. 이번 상담은 어떠셨나요?',
}