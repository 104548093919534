export const EMAIL_ERROR = "이메일 형식을 확인해 주세요";
export const PASSWORD_ERROR =
  "영문, 숫자, 특수기호 조합 8자리 이상으로 설정하세요.";
export const PASSWORD_DISMATCH_ERROR = "비밀번호가 일치하지 않습니다.";
export const PHONE_ERROR = "휴대전화 번호 형식을 확인해 주세요.";
export const NICKNAME_REQUIRED_ERROR = "닉네임을 입력하세요.";
export const NICKNAME_ERROR = "닉네임 형식을 확인하세요.";
export const POLICY_ERROR = "모든 약관에 동의해 주세요.";

export const getLabelDescription = (kind) => {
  switch (kind) {
    case "chat":
      return {
        label: "텍스트 상담",
        description: "채팅을 통한 미술 심리 상담을 진행합니다.",
      };
    case "video":
      return {
        label: "화상 상담",
        description:
          "화상으로 얼굴을 마주보며 그림을 통한 미술 심리 상담이 진행됩니다.",
      };
    case "visit":
      return {
        label: "대면 상담",
        description: "별도의 공간에 직접 방문하여 미술 심리 상담을 진행합니다.",
      };
    case "voice":
      return {
        label: "전화 상담",
        description:
          "전화로 상호간 목소리를 들으며 그림을 통한 미술 심리 상담이 진행됩니다.",
      };
    default:
      return { label: "", description: "" };
  }
};
