import { useCallback, useEffect, useMemo, useState } from "react";

import client from "apis/client";

const PAGE_LENGTH = "10";
const useMyReservation = (doctorId) => {
  const [isFetching, setIsFetching] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [reservations, setReservations] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [total, setTotal] = useState(0);

  const setNextPage = () => setPage(page + 1);

  const activeReservation = useMemo(
    () => (reservations.length > 0 ? reservations[0] : null),
    [reservations]
  );
  const fetch = useCallback(async () => {
    setIsFetching(true);
    try {
      const {
        data: { reservations, page: pageInfo },
      } = await client.get(
        `/user/reservations?page=${page}&per=${PAGE_LENGTH}${
          doctorId !== undefined ? `&doctor_id=${doctorId}` : ""
        }`
      );

      const { pages = 1, total = 0 } = pageInfo || {};
      setReservations((prev) => [...prev, ...reservations]);
      setPageCount(pages);
      setTotal(total);
    } finally {
      setIsFetching(false);
    }
  }, [page, doctorId]);

  useEffect(() => {
    setHasNextPage(pageCount > page);
  }, [pageCount, page]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    isFetching,
    hasNextPage,
    reservations,
    activeReservation,
    pageCount,
    total,
    setNextPage,
  };
};
export default useMyReservation;
