import { useEffect, useState } from "react";
import styled from "styled-components";

import { requestPhoneCode } from "apis/auth";
import CheckboxField from "components/CheckboxField";
import Form from "components/Form";
import {
  Counsels,
  CounselKind,
  CounselKinds,
  Section,
  Title,
  Wrapper,
} from "components/JoinComponents";
import useCounsels from "hooks/useCounsels";
import { EMAIL_ERROR, PHONE_ERROR } from "utils/message";
import { EMAIL_PATTERN, PHONE_PATTERN } from "utils/regex";

const DoctorProfileStep1 = ({
  defaultParams,
  getItems,
  register,
  watch,
  formState: { errors },
}) => {
  const { img } = watch();
  const counsels = useCounsels();
  const [imagePreview, setImagePreview] = useState("");
  const [checkedItems, setCheckedItems] = useState([]);
  const [counselValue, setcounselValue] = useState({});
  const [earlyChecked, setEarlyChecked] = useState([]);
  const [day, setDay] = useState(["월", "화", "수", "목", "금", "토", "일"]);
  const [times, setTimes] = useState([
    "오전(9~13시)",
    "오후(13~17시)",
    "저녁(17~21시)",
  ]);
  const changeChecked = (checked, id) => {
    if (checked) {
      setCheckedItems(checkedItems.filter((el) => el !== id));
      setCheckedItems([...checkedItems, id]);
    } else {
      setCheckedItems(checkedItems.filter((el) => el !== id));
    }
  };
  useEffect(() => {
    getItems(checkedItems);
  }, [checkedItems]);

  useEffect(() => {
    setcounselValue((counselValue) => ({
      ...counselValue,
      ...defaultParams.counsel_kind,
    }));
  }, [defaultParams]);

  const requestCode = async () => {
    const phone = watch("phone");

    if (!phone) return;
    await requestPhoneCode(phone);
  };

  useEffect(() => {
    if (img && img.length > 0) {
      if (typeof img === "string") {
        setImagePreview(img);
      } else {
        setImagePreview(URL.createObjectURL(img[0]));
      }
    }
  }, [img]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (watch("counsel_kind") === undefined) return;
    Object.entries(watch("counsel_kind")).map((value, i) => {
      if (value[1] !== 0) {
        setEarlyChecked([...earlyChecked, value[0]]);
      }
    });
  }, [watch("counsel_kind")]);

  return (
    <Wrapper>
      <Section>
        <Title>기본 정보 입력</Title>

        <Form.InputField
          label="이메일*"
          type="email"
          placeholder="example@domain.com"
          {...register("email", {
            required: { value: true, message: EMAIL_ERROR },
            pattern: {
              value: EMAIL_PATTERN,
              message: EMAIL_ERROR,
            },
          })}
          error={errors?.email?.message}
        />
        <Form.InputField
          label="이름*"
          placeholder="홍길동"
          maxLength={10}
          disabled={true}
          {...register("name", {
            required: { value: true, message: "이름을 입력하세요." },
          })}
          error={errors?.name?.message}
        />
        <Form.Row>
          <Form.InputField
            label="휴대폰 번호"
            type="tel"
            placeholder="-없이 번호 입력"
            {...register("phone", {
              required: { value: true, message: PHONE_ERROR },
              pattern: {
                value: PHONE_PATTERN,
                message: PHONE_ERROR,
              },
              minLength: {
                value: 11,
                message: PHONE_ERROR,
              },
              maxLength: {
                value: 11,
                message: PHONE_ERROR,
              },
            })}
            error={errors?.phone?.message}
          />
          <Form.Button>인증</Form.Button>
        </Form.Row>

        <Form.Row>
          <Form.InputField
            label="인증번호 입력"
            placeholder="인증번호 입력"
            {...register("code", {
              // required: true,
            })}
            error={errors?.code?.message}
          />
          <Form.Button onClick={requestCode}>재전송</Form.Button>
        </Form.Row>
      </Section>

      <Section>
        <Form.Row>
          <Form.Label>
            프로필 사진<span className="required">*</span>
          </Form.Label>
          <Profile>
            <div className="preview">
              {imagePreview && <img src={imagePreview} alt="" />}
            </div>
            <div className="file-selector">
              <input
                type="file"
                id="img"
                {...register("img", {
                  // validate: {
                  //   isJpgOrPng: (v) => {
                  //     const isValid = /jpeg|png|jpg/.test(v[0].type);
                  //     if (!isValid) {
                  //       alert("대표썸네일은 JPG / PNG 형식만 가능합니다.");
                  //     }
                  //     return isValid;
                  //   },
                  // },
                })}
                style={{ display: "none" }}
              />
              <label htmlFor="img">이미지 선택</label>
            </div>
          </Profile>
          {errors?.img && <Form.Error>{errors?.img?.message}</Form.Error>}
        </Form.Row>
        <Form.Row>
          <Form.Label>
            상담 선호 요일
            <span className="required">* 중복 선택이 가능합니다.</span>
          </Form.Label>
          {day.map((value, i) => {
            return (
              <Form.ButtonCheckboxField
                key={i}
                label={value}
                value={value}
                preferredType={"day"}
                {...register("preferred_days_of_week", {
                  required: {
                    value: true,
                    message: "상담 선호 요일을 하나 이상 선택해 주세요.",
                  },
                })}
              />
            );
          })}
          {errors?.preferred_days_of_week && (
            <Form.Error>{errors?.preferred_days_of_week?.message}</Form.Error>
          )}
        </Form.Row>
        <Form.Row style={{ height: "80px" }}>
          <Form.Label>
            상담 선호 시간
            <span className="required">* 중복 선택이 가능합니다.</span>
          </Form.Label>
          {times.map((value, i) => {
            return (
              <Form.ButtonCheckboxField
                key={i}
                label={value}
                value={value}
                preferredType={"times"}
                {...register("preferred_times", {
                  required: {
                    value: true,
                    message: "상담 선호 시간을 하나 이상 선택해 주세요.",
                  },
                })}
              />
            );
          })}
          {errors?.preferred_time && (
            <Form.Error>{errors?.preferred_time?.message}</Form.Error>
          )}
        </Form.Row>
        <Form.Row>
          <Form.Label style={{ marginTop: "50px" }}>
            대표 상담 과목<span className="required">*</span>{" "}
            <span>(최대 3개)</span>
          </Form.Label>
          <Counsels>
            {counsels.map((counsel) => (
              <Form.ButtonCheckboxField
                key={counsel.id}
                label={counsel.subject}
                value={counsel.id}
                {...register("counsel_ids", {
                  required: {
                    value: true,
                    message: "대표 상담 과목을 하나 이상 선택하세요.",
                  },
                  maxLength: {
                    value: 3,
                    message: "대표 상담 과목은 최대 3개 선택가능합니다.",
                  },
                })}
              />
            ))}
          </Counsels>
          {errors?.counsel_ids && (
            <Form.Error>{errors?.counsel_ids?.message}</Form.Error>
          )}
        </Form.Row>
        <Form.Row>
          <Form.Label>
            한줄소개
            {/* <span className="required">*</span> <span>(50자 이내)</span> */}
          </Form.Label>
          <Form.Textarea
            placeholder="당신의 오늘은 어떠셨나요? 온기가 필요할 때 찾아오세요."
            {...register("introduction", {
              required: { value: true, message: "한줄소개를 입력하세요." },
              // maxLength: {
              //   value: 50,
              //   message: "50자 이내로 작성해주세요.",
              // },
            })}
          />
          {errors?.introduction && (
            <Form.Error>{errors?.introduction?.message}</Form.Error>
          )}
        </Form.Row>
        <Form.Row>
          <Form.Label>
            주요 자격 및 경력{/* * <span>(200자 이내)</span> */}
          </Form.Label>
          <Form.Textarea
            placeholder="- 본인의 경력을 작성해 주세요.&#13;&#10;- 경력 및 학력을 작성해 주세요&#13;&#10;- 미술치료 자격증 명칭 및 발급 기관을 반드시 써주세요."
            {...register("experience", {
              required: {
                value: true,
                message: "주요 자격 및 경력을 입력해주세요.",
              },
              // maxLength: {
              //   value: 200,
              //   message: "200자 이내로 작성해주세요.",
              // },
            })}
          />
          {errors?.experience && (
            <Form.Error>{errors?.experience?.message}</Form.Error>
          )}
        </Form.Row>
        <Form.Row>
          <Form.Label>
            상담 방식<span className="required">*</span>{" "}
            <span>(중복 선택 가능)</span>
          </Form.Label>
          <CounselKinds>
            <CounselKind>
              <CheckboxField
                label="화상"
                checked={checkedItems.includes("video")}
                onChange={(e) => {
                  changeChecked(e.currentTarget.checked, "video");
                }}
              />
              <Form.BorderInput
                type="number"
                {...register("counsel_kind.video")}
              />
              원
            </CounselKind>
            <CounselKind>
              <CheckboxField
                label="음성"
                checked={checkedItems.includes("voice")}
                onChange={(e) => {
                  changeChecked(e.currentTarget.checked, "voice");
                }}
              />
              <Form.BorderInput
                type="number"
                {...register("counsel_kind.voice")}
              />
              원
            </CounselKind>
            <CounselKind>
              <CheckboxField
                label="채팅"
                checked={checkedItems.includes("chat")}
                onChange={(e) => {
                  changeChecked(e.currentTarget.checked, "chat");
                }}
              />
              <Form.BorderInput
                type="number"
                {...register("counsel_kind.chat")}
              />
              원
            </CounselKind>
            <CounselKind>
              <CheckboxField
                label="방문"
                checked={checkedItems.includes("visit")}
                onChange={(e) => {
                  changeChecked(e.currentTarget.checked, "visit");
                }}
              />
              <Form.BorderInput
                type="number"
                {...register("counsel_kind.visit")}
              />
              원
            </CounselKind>
          </CounselKinds>
          {checkedItems.length === 0 && (
            <Form.Error>상담 방식을 선택하세요.</Form.Error>
          )}
        </Form.Row>
      </Section>
    </Wrapper>
  );
};
export default DoctorProfileStep1;

const Profile = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  position: relative;

  & > .preview {
    width: 140px;
    height: 140px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #efefef;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    & > img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }

  & > .file-selector {
    & > label {
      cursor: pointer;
      /* display: flex;
      align-items: center;
      justify-content: center; */
      background: #333333 0% 0% no-repeat padding-box;
      border-radius: 4px;
      font: normal normal 500 13px/16px Pretendard;
      letter-spacing: -0.26px;
      color: #ffffff;
      padding: 5px 18px;
      position: absolute;
      top: 58px;
      left: 24px;
    }
  }
`;

const Button = styled.span`
  width: ${(props) => `${props.type === "day" ? "45px" : "116px"}`};
  height: ${(props) => `${props.type === "day" ? "50px" : "48px"}`};
  border: solid 1px #e6e6e6;
  margin-right: 4px;
  border-radius: 5pt;
  font-weight: 450;
  color: #101010;
  padding: 15px;
  font-size: 14px;
`;
