import { useEffect, useState } from "react";
import styled from "styled-components";

import Drawer from "./";
import { Button, CancelButton, DrawerBody, DrawerFooter } from "./Comps";

import { ReactComponent as LoaderSVG } from "assets/svg/loader.svg";

import useNavigate from "hooks/useNavigate";

const PREPARE = "prepare";
const REGISTERING = "registering";
const SUCCESS = "success";

const ProgressRegisterPayment = ({
  isShow,
  onClose,
  onFail,
  onRegister,
  setStateText,
}) => {
  const [status, setStatus] = useState(PREPARE);
  const { goMyPayment } = useNavigate();

  const startRegister = async () => {
    setStatus(REGISTERING);

    try {
      await onRegister();
      setStatus(SUCCESS);
    } catch (e) {
      setStatus(PREPARE);
      onFail();
      onClose();
      setStateText(e.response.data.message);
    }
  };

  useEffect(() => {
    if (isShow) {
      startRegister();
    }
  }, [isShow]);

  return (
    <Drawer isShow={isShow}>
      <Wrapper>
        <Indicator>
          <span className="active" />
          <span
            className={[REGISTERING, SUCCESS].includes(status) ? "active" : ""}
          />
          <span className={status === SUCCESS ? "active" : ""} />
        </Indicator>
        {
          /* {status === PREPARE ? (
          <p>
            실제 결제되는 카드인지 확인하기 위해
            <br /> 10원이 결제되고, 확인 후 즉시 취소됩니다.
          </p>
        ) :  }*/
          status === REGISTERING ? (
            <>
              <p>
                카드 확인 중입니다.
                <br /> 잠시만 기다려 주세요..
              </p>
              <Loader>
                <LoaderSVG />
              </Loader>
            </>
          ) : (
            <p>카드가 확인되었습니다.</p>
          )
        }
      </Wrapper>
      {/* {status === PREPARE && (
        <Footer>
          <Button onClick={startRegister}>확인</Button>
          <CancelButton
            onClick={onClose}
            style={{
              width: "271px",
              height: "48px",
              background: "#E6E6E6",
              borderRadius: "10px",
              color: "#6E6E6E",
              font: "normal normal 400 16px/19px Pretendard",
            }}
          >
            취소
          </CancelButton>
        </Footer>
      )} */}
      {status === SUCCESS && (
        <Footer>
          <Button
            onClick={() => {
              goMyPayment(true);
            }}
          >
            확인
          </Button>
        </Footer>
      )}
    </Drawer>
  );
};
export default ProgressRegisterPayment;

const Wrapper = styled(DrawerBody)`
  padding-top: 27px;
  & > p {
    text-align: center;
    font: normal normal 600 18px/29px Pretendard;
    letter-spacing: -0.27px;
    color: #101010;
  }
`;
const Footer = styled(DrawerFooter)`
  gap: 7px;
  padding-bottom: 34px;
`;
const Loader = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 28px;
`;
const Indicator = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 118px;

  & > span {
    width: 74px;
    height: 2px;
    background: #e6e6e6;
    border-radius: 92px;

    &.active {
      background: #485af3;
    }
  }
`;
