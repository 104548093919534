import { ReactComponent as X } from "assets/svg/x.svg";

// 색상 선택 시 선택한 색상 보여주는 값
var choseColor;

const PaletteColor = ({ setLineColor, setModal }) => {
  const changeColor = (e) => {
    const color = e.target.style.backgroundColor;
    setLineColor(color);
    choseColor = color;
  };
  const changeState = () => {
    setModal(false);
  };
  return (
    <>
      <div className="palette_colorClose">
        <div className="palette_title">색상</div>
        <button className="paletteClose" onClick={changeState}>
          <X className="paletteClose" />
        </button>
      </div>
      <div style={{ textAlign: "center" }}>
        <div className="colorSection">
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#FFFFFF" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#CCCCCC" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#B8B8B8" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#A3A3A3" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#8F8F8F" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#7A7A7A" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#666666" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#525252" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#3D3D3D" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#292929" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#141414" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#000000" }}
          ></div>
        </div>
        <div className="colorSection">
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#0A2431" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#030E2A" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#0F0132" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#220233" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#280813" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#230602" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#250F02" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#281904" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#281C04" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#222105" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#21230B" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#1F2B15" }}
          ></div>
        </div>
        <div className="colorSection">
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#215168" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#091E55" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#22026D" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#4A0A6A" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#521326" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#490E06" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#4C1E07" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#53330C" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#533E0F" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#45420F" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#444816" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#36481E" }}
          ></div>
        </div>
        <div className="colorSection">
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#3B85A8" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#123285" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#3205A8" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#7213A1" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#83203D" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#72190B" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#782E11" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#805119" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#81631D" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#69651C" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#69651C" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#52722F" }}
          ></div>
        </div>
        <div className="colorSection">
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#5BC7F8" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#1F4DBD" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#3C17C6" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#841EBF" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#B52E57" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#A02716" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#A9461C" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#B67929" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#B89030" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#928C2A" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#928C2A" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#719E3F" }}
          ></div>
        </div>
        <div className="colorSection">
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#61C5F9" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#2961F6" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#734FD5" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#A44ACE" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#C24F76" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#D74023" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#EE732F" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#F0AD3C" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#F6CC46" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#BCBB3C" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#B7C743" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#89BE56" }}
          ></div>
        </div>
        <div className="colorSection">
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#74D4FA" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#427DF5" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#836CDE" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#AE65D8" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#CC6C91" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#EB5232" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#EE7E3E" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#F2B14B" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#F4CC52" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#FEF653" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#DBE757" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#9EC96F" }}
          ></div>
        </div>
        <div className="colorSection">
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#8BDAF9" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#6696F8" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#A589EF" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#C186E2" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#D98AA9" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#EC6E55" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#F09560" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#F6C068" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#F7D46E" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#FDF763" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#E7F071" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#B5D68F" }}
          ></div>
        </div>
        <div className="colorSection">
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#ABE5FB" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#8EB0F8" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#B9ADEF" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#D5A8ED" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#E4ADC2" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#EF9281" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#F4B08B" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#F7D08F" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#FAE093" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#FCF686" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#EFF496" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#CCE5AE" }}
          ></div>
        </div>
        <div className="colorSection">
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#C1EAFC" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#AEC3FA" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#CCC4F5" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#E3C3F5" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#F0C5D6" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#F1ACA5" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#F5C3AA" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#F7DBAC" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#FAE6B1" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#FFFAA6" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#F7FAB3" }}
          ></div>
          <div
            className="colorSelected"
            onClick={changeColor}
            style={{ backgroundColor: "#DDEFC5" }}
          ></div>
        </div>
      </div>
      <div>
        <div
          className="stateColor"
          style={{ backgroundColor: choseColor }}
        ></div>
      </div>
    </>
  );
};

export default PaletteColor;
