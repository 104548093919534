function detectMobileDeviceByUserAgent() {
  /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);
}

function detectMobileDeviceByViewWidth() {
  const minWidth = 500;

  return window.innerWidth <= minWidth;
}

const isMobile =
  detectMobileDeviceByUserAgent() || detectMobileDeviceByViewWidth();

export default isMobile;

export const MobileOS = {
  android: "android",
  iOS: "ios",
  other: "other",
};

export function checkDeviceOS() {
  const userAgent = navigator.userAgent.toLowerCase(); //userAgent 값 얻기

  if (userAgent.indexOf("android") > -1) {
    //안드로이드
    return MobileOS.android;
  } else if (
    userAgent.indexOf("iphone") > -1 ||
    userAgent.indexOf("ipad") > -1 ||
    userAgent.indexOf("ipod") > -1
  ) {
    //IOS
    return MobileOS.iOS;
  } else {
    //아이폰, 안드로이드 외
    return MobileOS.other;
  }
}

export function getAppInfo() {
  const userAgent = window.navigator.userAgent.toLowerCase();
  const [platform = "", appVersion = ""] =
    userAgent?.split("minddrawing/")[1]?.split("/") ?? [];

  return { isMinddrawingApp: platform && appVersion, platform, appVersion };
}

export function checkRNVideoScreenSupport() {
  const { isMinddrawingApp, platform, appVersion } = getAppInfo();

  if (!isMinddrawingApp) {
    return false;
  }

  if (platform !== MobileOS.iOS) {
    return true;
  } else {
    if (appVersion >= "1.0.2") {
      return true;
    }
  }
  return false;
}

export function checkRNPaletteAutoSaveSupport() {
  const {isMinddrawingApp, platform,appVersion} = getAppInfo();

  if(!isMinddrawingApp) {
    return false;
  }

  if(platform !== MobileOS.iOS) {
    if(appVersion >= '1.0.4') {
      return true;
    }
  } else {
    if(appVersion >= '1.0.5') {
      return true;
    }
  }
  return false;
}

export function checkRNWindowScreenSupport() {
  const { isMinddrawingApp, platform, appVersion } = getAppInfo();

  if (!isMinddrawingApp) {
    return false;
  }

  if (platform !== MobileOS.iOS) {
    if (appVersion >= "1.0.1") {
      return true;
    }
  } else {
    if (appVersion >= "1.0.3") {
      return true;
    }
  }
  return false;
}
