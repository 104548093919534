import { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";
import { useForm } from "react-hook-form";
import { useSearchParams, useParams } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";

import { ReactComponent as StarOffSVG } from "assets/svg/star-field.svg";
import { ReactComponent as StarOnSVG } from "assets/svg/star.svg";

import client from "apis/client";
import Form from "components/Form";
import useReview from "hooks/useReview";
import useNavigate from "hooks/useNavigate";

const ReviewForm = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const doctorName = searchParams.get("doctorName");
  const reservationId = searchParams.get("reservationId");
  const reviewId = searchParams.get("reviewId");
  const { goBack } = useNavigate();
  const review = useReview(reviewId);
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  console.log(errors);
  const [star, setStar] = useState(0);
  const [agree, setAgree] = useState(false);

  const onSubmit = async (data) => {
    if (star < 0) {
      setError("error", "만족도를 선택해 주세요.");
    }

    const { agreement, ...params } = data;
    const reqParams = {
      ...params,
      star,
      doctor_id: id,
      reservation_id: reservationId,
    };
    try {
      if (reviewId) {
        await client
          .patch(`/user/reviews/${reviewId}`, reqParams)
          .then((res) => {})
          .catch((e) => console.log(e));
      } else {
        await client.post("/user/reviews", reqParams);
      }

      goBack();
    } catch (e) {
      alert("에러가 발생하였습니다.");
    }
  };

  useEffect(() => {
    if (!review) return;

    setValue("checkbox", true);
    setValue("title", review.title);
    setValue("at", moment(review.at).format("YYYY-MM-DD"));
    setValue("content", review.content);

    setStar(review.star);
  }, [review, setValue]);
  useEffect(() => window.scrollTo(0, 0), []);

  const AtField = forwardRef(({ onClick }, ref) => {
    const today = moment().format("YYYY-MM-DD");
    return (
      <DateField>
        <Form.InputField
          label="상담 날짜"
          placeholder={today}
          onClick={onClick}
          ref={ref}
          {...register("at", {
            required: { value: true, message: "" },
          })}
        />
        {/* <StarOffSVG /> */}
      </DateField>
    );
  });

  return (
    <Wrapper>
      <Info>
        해당 상담사에게 미술상담을 받으신 분만 후기를 작성할 수 있습니다.
        <br />
        본인이 실제 상담받지 않은 상담사에 대한 허위 후기를 작성할 경우 업무
        방해죄 등에 해당하며, 법적 조치를 취할 수 있습니다.
      </Info>
      <Agreement>
        <input
          type="checkbox"
          checked={agree}
          onClick={() => {
            setAgree(!agree);
          }}
          {...register("agreement", {
            required: { value: !agree, message: "동의해 주세요." },
          })}
        />
        <label
          onClick={() => {
            setAgree(!agree);
          }}
        >
          예, 동의합니다
        </label>
      </Agreement>

      <DoctorName>{doctorName} 상담사</DoctorName>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.InputField
          label="제목"
          placeholder="제목을 입력해 주세요."
          {...register("title", {
            required: { value: true, message: "" },
          })}
        />

        <DatePicker
          locale={ko}
          customInput={<AtField />}
          onChange={(date) => setValue("at", moment(date).format("YYYY-MM-DD"))}
        />

        <Form.Row>
          <Form.Label>만족도</Form.Label>

          <StarSelector>
            <input
              style={{ display: "none" }}
              type="number"
              {...register("star")}
            />
            {[...Array(star).keys()].map((i) => (
              <StarOnSVG key={i} onClick={() => setStar(i + 1)} />
            ))}
            {[...Array(5 - star).keys()].map((i) => (
              <StarOffSVG key={i} onClick={() => setStar(star + i + 1)} />
            ))}
          </StarSelector>
        </Form.Row>
        <Form.Row style={{ marginBottom: "96px" }}>
          <Form.Label>상세 후기 작성</Form.Label>
          <Form.Textarea
            placeholder="최소 20자 이상 작성해 주세요."
            {...register("content", {
              required: {
                value: true,
                message: "상세 후기를 입력해주세요.",
              },
            })}
          />
        </Form.Row>

        <Form.SubmitRow>
          <BtnSection>
            {Object.keys(errors).length > 0 && (
              <Form.Error>일부 항목 입력이 유효하지 않습니다.</Form.Error>
            )}
            <Form.SubmitButton type="submit">
              {reviewId ? "수정" : "작성"} 완료
            </Form.SubmitButton>
          </BtnSection>
        </Form.SubmitRow>
      </Form>
    </Wrapper>
  );
};
export default ReviewForm;

const Wrapper = styled.section`
  padding: 0 15px;
  padding-top: 77px;
`;
const Agreement = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  & > label {
    font: normal normal 500 13px/16px Pretendard;
    letter-spacing: -0.13px;
    color: #404040;
    margin-left: 8px;
  }
`;
const Info = styled.div`
  width: 100%;
  padding: 16px;
  background: #f8f8f8;
  border-radius: 10px;
  font: normal normal 400 12px/20px Pretendard;
  letter-spacing: -0.12px;
  color: #6e6e6e;
`;
const DoctorName = styled.div`
  font: normal normal 600 18px/21px Pretendard;
  letter-spacing: -0.27px;
  color: #101010;
  margin-bottom: 27px;
  margin-top: 46px;
`;
const StarSelector = styled.div`
  display: flex;

  & > svg {
    width: 32px;
    height: 31px;
  }
  & > svg + svg {
    margin-left: 4px;
  }
`;
const DateField = styled.div`
  position: relative;
  & > svg {
    position: absolute;
    right: 0;
    bottom: 18px;
  }
`;

const BtnSection = styled.div`
  width: 100%;
  background-color: #fff;
  padding-top: 24px;
  padding-bottom: 24px;
  position: absolute;
  bottom: 0px;
  text-align: center;
  z-index: 999;
`;
