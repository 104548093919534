import { useState } from "react";
import styled from "styled-components";
import moment from "moment";

import client from "apis/client";
import { ReactComponent as FileSVG } from "assets/svg/file.svg";
import DateTimeConfirm from "components/Drawer/DateTimeConfirm";
import NoReservation from "components/Reservation/NoReservation";
import {
  Wrapper as ItemWrapper,
  Type,
  Status,
  Info,
  Actions,
  Action,
} from "components/Reservation/Components";
import useDoctorReservation from "hooks/useDoctorReservation";
import useTwilioConversation from "hooks/twilio/useTwilioConversation";
import { generateFriendlyName, generateUniqueName } from "utils/twilio";

const History = ({
  counsel_kind,
  date,
  confirm_date,
  doctor: { id: doctorId, name: doctorName },
  status,
  times,
  user: { nickname },
  onConfirm,
  onEnterRoom,
}) => {
  return (
    <ItemWrapper>
      <Type>{counsel_kind}</Type>
      <Status>상담완료</Status>
      <hr />
      <Info>
        <label>상담일</label>
        <div>
          {confirm_date ? (
            <>{moment(confirm_date).format("MM.DD(ddd) | HH:mm")}</>
          ) : date ? (
            <>
              {moment(date).format("MM.DD(ddd)")} | {times}
            </>
          ) : (
            <></>
          )}
        </div>
      </Info>
      <Info>
        <label>내담자명</label>
        <div>{nickname}</div>
      </Info>
      <Info>
        <label>상담방식</label>
        <div>{counsel_kind}</div>
      </Info>
      <Actions style={{ justifyContent: "flex-end" }}>
        <Action onClick={() => onEnterRoom(doctorId, doctorName)}>
          <FileSVG />
          상담 내용
        </Action>
      </Actions>
    </ItemWrapper>
  );
};
const Reservation = ({
  counsel_kind,
  date,
  confirm_date,
  doctor: { id: doctorId, name: doctorName },
  status,
  times,
  user: { nickname },
  onConfirm,
  onEnterRoom,
}) => {
  return (
    <ItemWrapper>
      <Type>{counsel_kind}</Type>
      <Status>{status}</Status>
      <hr />
      <Info>
        <label>예약일</label>
        <div>
          {confirm_date ? (
            <>{moment(confirm_date).format("MM.DD(ddd) | HH:mm")}</>
          ) : date ? (
            <>
              {moment(date).format("MM.DD(ddd)")} |&nbsp;
              {times.map((v, index) => (
                <div key={index} style={{ display: "inline-block" }}>
                  &nbsp;{v}&nbsp;
                  {index !== times.length - 1 ? "," : ""}
                </div>
              ))}
            </>
          ) : (
            <></>
          )}
        </div>
      </Info>
      <Info>
        <label>내담자명</label>
        <div>{nickname}</div>
      </Info>
      {status !== "예약취소" ? (
        <>
          {date && (
            <Actions>
              <Action
                onClick={() => {
                  onEnterRoom(doctorId, doctorName);
                }}
              >
                상담실 입장
              </Action>
              {status !== "상담완료" ? (
                <Action style={{ color: "#485AF3" }} onClick={onConfirm}>
                  {status == "예약대기" ? "예약확정" : "일정변경"}
                </Action>
              ) : (
                <></>
              )}
            </Actions>
          )}
        </>
      ) : (
        <></>
      )}
    </ItemWrapper>
  );
};

const DoctorReservation = () => {
  const { joinConversation } = useTwilioConversation();

  const [tab, setTab] = useState("reservation"); // 상담 내역: history / 예약 현황: reservation
  const [selected, setSelected] = useState(null);
  const [status, setStatus] = useState("");

  const { reservations } = useDoctorReservation(status);
  const enterRoom = async (doctorId, doctorName, userId, userNickname) => {
    const friendlyName = generateFriendlyName(doctorName, userNickname);
    const uniqueName = generateUniqueName(doctorId, userId);
    await joinConversation(friendlyName, uniqueName);
  };

  const confirmDateTime = async (dateTime) => {
    try {
      await client.post(`/doctor/reservations/${selected.id}/confirm`, {
        confirm_date: dateTime,
      });
      setSelected(null);
    } catch (e) {
      alert(e.response.data.message);
    }
  };
  return (
    <Wrapper>
      <Tab>
        <div
          className={tab === "reservation" ? "active" : ""}
          onClick={() => {
            setTab("reservation");
            setStatus("");
          }}
        >
          예약 현황
        </div>
        <div
          className={tab === "history" ? "active" : ""}
          onClick={() => {
            setTab("history");
            setStatus("past_reservations");
          }}
        >
          상담 내역
        </div>
      </Tab>
      <Content>
        {tab === "history" ? (
          reservations.length > 0 ? (
            reservations.map((reservation) => (
              <History
                key={reservation.id}
                {...reservation}
                onEnterRoom={() =>
                  enterRoom(
                    reservation.doctor.id,
                    reservation.doctor.name,
                    reservation.user.id,
                    reservation.user.nickname
                  )
                }
                onConfirm={() => setSelected(reservation)}
              />
            ))
          ) : (
            <NoReservation label="상담 내역" />
          )
        ) : reservations.length > 0 ? (
          reservations.map((reservation) => (
            <Reservation
              key={reservation.id}
              {...reservation}
              onEnterRoom={() =>
                enterRoom(
                  reservation.doctor.id,
                  reservation.doctor.name,
                  reservation.user.id,
                  reservation.user.nickname
                )
              }
              onConfirm={() => setSelected(reservation)}
            />
          ))
        ) : (
          <NoReservation label="예약 현황" />
        )}
      </Content>

      <DateTimeConfirm
        isShow={!!selected}
        baseDate={selected?.date}
        baseTime={selected?.times || ["09"]}
        onClose={() => setSelected(null)}
        onConfirm={(dateTime) => confirmDateTime(dateTime)}
      />
    </Wrapper>
  );
};
export default DoctorReservation;

const Wrapper = styled.section`
  width: 100%;
  height: 100%;
  padding: 12px 15px 85px 15px;
  overflow: hidden;
  overflow-y: auto;
`;
const Content = styled.section`
  // height: 100%;
  overflow-y: auto;
  margin-top: 40px;
  margin-bottom: 30px;
`;
const Tab = styled.section`
  display: flex;
  border-bottom: 2px solid #f8f8f8;
  justify-content: center;

  & > div {
    width: 120px;
    font: normal normal 400 15px/18px Pretendard;
    letter-spacing: -0.23px;
    color: #999999;
    text-align: center;
    padding-bottom: 10px;
    border-bottom: 2px solid #fff;

    &.active {
      font-weight: 500;
      color: #333333;
      border-bottom: 2px solid #333333;
    }
  }
`;
