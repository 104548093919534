import { useState } from "react";
import { useForm } from "react-hook-form";

import { requestPhoneCode } from "apis/auth";
import { signup } from "apis/doctor";
import Form from "components/Form";
import { Counsels, Section, Title, Wrapper } from "components/JoinComponents";
import useCounsels from "hooks/useCounsels";
import useNavigate from "hooks/useNavigate";
import { EMAIL_ERROR, PHONE_ERROR } from "utils/message";
import { EMAIL_PATTERN, PHONE_PATTERN } from "utils/regex";
import PolicySection from "./PolicySection";
import useAuth from "hooks/useAuth";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

const POLICIES = [
  {
    id: 1,
    title: "서비스 이용약관 동의 (필수)",
    url: "/info/terms?termType=usepolicy",
  },
  {
    id: 2,
    title: "개인 건강정보(민감정보) 수집 및 이용 동의(필수)",
    url: "/info/terms?termType=health_privacy",
  },
  {
    id: 3,
    title: "개인 정보 처리 방침 (필수)",
    url: "/info/terms?termType=privacy",
  },
];

const usePolicy = () => {
  const [policies, setPolicies] = useState(POLICIES);
  const [checkedPoliciesIdx, setCheckedPoliciesIdx] = useState([]);

  return {
    policies,
    checkedPoliciesIdx,
    checkAll: (e) => {
      if (e.target.checked) {
        setCheckedPoliciesIdx(POLICIES.map((p) => p.id));
      } else {
        setCheckedPoliciesIdx([]);
      }
    },
    checkOne: (id) =>
      !checkedPoliciesIdx.includes(id) &&
      setCheckedPoliciesIdx((prev) => [...prev, id]),
    uncheckOne: (id) =>
      checkedPoliciesIdx.includes(id) &&
      setCheckedPoliciesIdx((prev) => prev.filter((p) => p !== id)),
  };
};

const DoctorJoin = () => {
  const { goDoctorJoinRequest } = useNavigate();
  const counsels = useCounsels();
  const location = useLocation();
  const searchData = new URLSearchParams(location.search);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const { sendFcmToken } = useAuth();
  const requestCode = async () => {
    const phone = watch("phone");

    if (!phone) {
      alert("휴대전화 번호 형식을 확인해 주세요.");
      return;
    }
    await requestPhoneCode(phone);
  };

  const onSubmit = async (params) => {
    // 상답사 타입 (심리 , 일반) 추가
    if (searchData.get("kind") === "general") {
      params.doctor_type = "일반상담";
    } else {
      params.doctor_type = "미술상담";
    }
    // 서비스 이용 약관 동의
    if (checkedPoliciesIdx.length !== 3) return;

    try {
      if (params.counsel_ids.length > 3) {
        alert("대표 상담 과목 최대 3개만 선택해주세요.");
        return;
      }
      await signup(params).then((res) => {
        sendFcmToken(res.data);
      });
      goDoctorJoinRequest();
    } catch (e) {
      alert(e.response.data.message);
    }
  };

  const { policies, checkedPoliciesIdx, checkOne, uncheckOne, checkAll } =
    usePolicy();
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Wrapper>
        <Section>
          <Title>기본 정보 입력</Title>

          <Form.InputField
            label="이메일*"
            type="email"
            placeholder="example@domain.com"
            {...register("email", {
              required: { value: true, message: EMAIL_ERROR },
              pattern: {
                value: EMAIL_PATTERN,
                message: EMAIL_ERROR,
              },
            })}
            error={errors?.email?.message}
          />
          <Form.InputField
            label="이름*"
            placeholder="홍길동"
            {...register("name", {
              required: { value: true, message: "이름을 입력하세요." },
              pattern: {
                value: /^[ㄱ-ㅎ|가-힣|]+$/,
                message: "한글만 입력 가능합니다.",
              },
              maxLength: {
                value: 10,
                message: "10자 이내로 입력해주세요.",
              },
            })}
            error={errors?.name?.message}
          />

          <Form.Row>
            <Form.InputField
              label="휴대폰 번호"
              type="tel"
              placeholder="-없이 번호 입력"
              {...register("phone", {
                required: { value: true, message: PHONE_ERROR },
                pattern: {
                  value: PHONE_PATTERN,
                  message: PHONE_ERROR,
                },
                minLength: {
                  value: 11,
                  message: PHONE_ERROR,
                },
                maxLength: {
                  value: 11,
                  message: PHONE_ERROR,
                },
              })}
              error={errors?.phone?.message}
            />
            <Button
              onClick={() => {
                requestCode();
              }}
            >
              인증
            </Button>
          </Form.Row>

          <Form.Row>
            <Form.InputField
              label="인증번호 입력"
              placeholder="인증번호 입력"
              {...register("code", {
                required: true,
              })}
              error={errors?.code?.message}
            />
            <Button
              onClick={() => {
                requestCode();
              }}
            >
              재전송
            </Button>
          </Form.Row>

          {/* </Form> */}
        </Section>

        <Section>
          {/* <Form> */}
          <Form.Row>
            <Form.Label>
              대표 상담 과목* <span>(최대 3개)</span>
            </Form.Label>
            <Counsels>
              {counsels.map((counsel) => (
                <Form.ButtonCheckboxField
                  key={counsel.id}
                  label={counsel.subject}
                  value={counsel.id}
                  {...register("counsel_ids", {
                    required: {
                      value: true,
                      message: "대표 상담 과목을 하나 이상 선택하세요.",
                    },
                    maxLength: {
                      value: 3,
                      message: "대표 상담 과목은 최대 3개 선택가능합니다.",
                    },
                  })}
                />
              ))}
            </Counsels>
            {errors?.counsel_ids && (
              <Form.Error>{errors?.counsel_ids?.message}</Form.Error>
            )}
          </Form.Row>

          <Form.Row>
            <Form.Label> 한줄소개{/* * <span>(50자 이내)</span> */}</Form.Label>
            <Form.Textarea
              placeholder="당신의 오늘은 어떠셨나요? 온기가 필요할 때 찾아오세요."
              // maxLength={50}
              {...register("introduction", {
                required: { value: true, message: "한줄소개를 입력하세요." },
                // maxLength: {
                //   value: 50,
                //   message: "최대 글자수를 초과하였습니다",
                // },
              })}
            />
            {errors?.introduction && (
              <Form.Error>{errors?.introduction?.message}</Form.Error>
            )}
          </Form.Row>

          <Form.Row>
            <Form.Label>
              주요 자격 및 경력* {/* <span>(200자 이내)</span> */}
            </Form.Label>
            <Form.Textarea
              label="주요 자격 및 경력"
              placeholder="- 본인의 경력을 작성해 주세요.&#13;&#10;- 경력 및 학력을 작성해 주세요&#13;&#10;- 미술치료 자격증 명칭 및 발급 기관을 반드시 써주세요."
              // maxLength={200}
              {...register("experience", {
                required: {
                  value: true,
                  message: "주요 자격 및 경력을 입력해주세요.",
                },
                // maxLength: {
                //   value: 200,
                //   message: "최대 글자수를 초과하였습니다",
                // },
              })}
            />
            {errors?.experience && (
              <Form.Error>{errors?.experience?.message}</Form.Error>
            )}
          </Form.Row>
        </Section>

        <Section>
          <PolicySection
            policies={policies}
            checkedPoliciesIdx={checkedPoliciesIdx}
            policyValid={checkedPoliciesIdx.length === POLICIES.length}
            onCheck={checkOne}
            onUncheck={uncheckOne}
            onCheckAll={checkAll}
          />
          <Form.SubmitRow>
            <Form.SubmitButton type="submit">가입 승인 요청</Form.SubmitButton>
          </Form.SubmitRow>
        </Section>
      </Wrapper>
    </Form>
  );
};
export default DoctorJoin;

const Button = styled.div`
  position: absolute;
  right: 0;
  top: 33px;
  width: 63px;
  height: 31px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #efefef;
  border-radius: 5px;
  font: normal normal 500 14px/17px Pretendard;
  -webkit-letter-spacing: -0.21px;
  -moz-letter-spacing: -0.21px;
  -ms-letter-spacing: -0.21px;
  letter-spacing: -0.21px;
  color: #6e6e6e;
  text-align: center;
  padding-top: 7px;
  cursor: pointer;
`;
