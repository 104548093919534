import styled from "styled-components";

import Form from "components/Form";
import {
  EMAIL_ERROR,
  NICKNAME_ERROR,
  NICKNAME_REQUIRED_ERROR,
  PASSWORD_ERROR,
  PHONE_ERROR,
} from "utils/message";
import {
  EMAIL_PATTERN,
  NICKNAME_PATTERN,
  PASSWORD_PATTERN,
  PHONE_PATTERN,
} from "utils/regex";

const FormSection = ({ register, formState: { errors } }) => (
  <Wrapper>
    <Form.InputField
      label="이메일"
      type="email"
      placeholder="example@domain.com"
      {...register("email", {
        required: { value: true, message: EMAIL_ERROR },
        pattern: {
          value: EMAIL_PATTERN,
          message: EMAIL_ERROR,
        },
      })}
      error={errors?.email?.message}
    />
    <Form.InputField
      type="password"
      label="비밀번호"
      placeholder="영문, 숫자, 특수기호 조합 8자리 이상"
      {...register("password", {
        required: { value: true, message: PASSWORD_ERROR },
        pattern: {
          value: PASSWORD_PATTERN,
          message: PASSWORD_ERROR,
        },
      })}
      error={errors?.password?.message}
    />
    <Form.InputField
      label="비밀번호 재확인"
      type="password"
      placeholder="영문, 숫자, 특수기호 조합 8자리 이상"
      {...register("password_confirmation", {
        required: { value: true, message: PASSWORD_ERROR },
        pattern: {
          value: PASSWORD_PATTERN,
          message: PASSWORD_ERROR,
        },
      })}
      error={errors?.password_confirmation?.message}
    />
    <Form.InputField
      label="닉네임"
      placeholder="한글 2자 이상 작성"
      maxLength={10}
      {...register("nickname", {
        minLength: { value: 2, message: NICKNAME_REQUIRED_ERROR },
        required: { value: true, message: NICKNAME_REQUIRED_ERROR },
        pattern: {
          value: NICKNAME_PATTERN,
          message: NICKNAME_ERROR,
        },
      })}
      error={errors?.nickname?.message}
    />
    <Form.InputField
      label="휴대폰 번호"
      type="tel"
      placeholder="-없이 번호 입력"
      {...register("phone", {
        required: { value: true, message: PHONE_ERROR },
        pattern: {
          value: PHONE_PATTERN,
          message: PHONE_ERROR,
        },
        minLength: {
          value: 11,
          message: PHONE_ERROR,
        },
        maxLength: {
          value: 11,
          message: PHONE_ERROR,
        },
      })}
      error={errors?.phone?.message}
    />
  </Wrapper>
);
export default FormSection;

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 40px;
  border-bottom: 1px solid #e6e6e6;
  padding: 36px 15px 40px 15px;
`;
