import {
  CONSULTING_ROOM_PATH,
  DOCTOR_PATH,
  HOME_PATH,
  INFO_PATH,
  JOIN_PATH,
  MYINFO_PATH,
  RESET_PATH,
  CONETNTS_PATH,
} from "App";
import { useState } from "react";
import { useNavigate as _useNavigate } from "react-router-dom";

const useNavigate = () => {
  const navigate = _useNavigate();
  const [friendlyName, setFriendlyName] = useState("");
  return {
    goBack: () => navigate(-1),
    goHome: () => navigate(HOME_PATH),
    goCompanyInfo: () => navigate(INFO_PATH),
    goTerms: (type) => navigate(`${INFO_PATH}/terms?termType=${type}`),

    /* 회원가입 페이지 */
    goJoin: () => navigate(`${JOIN_PATH}`),
    goDoctorJoin: (type) =>
      navigate(`${JOIN_PATH}?userType=doctor&kind=${type}`),
    goDoctorJoinRequest: () => navigate(`${JOIN_PATH}/doctor-request`),
    goDoctorJoinInfo: () => navigate(`${JOIN_PATH}/doctor-info`),

    /* 로그인 페이지 */
    goLogin: (prevPath) =>
      navigate(`${MYINFO_PATH}/login`, {
        state: { prevPath },
      }),
    goDoctorLogin: () => navigate(`${MYINFO_PATH}/login?userType=doctor`),
    goWithdraw: () => navigate(`${MYINFO_PATH}/withdraw`),

    /* 상담치료사 및 예약 페이지 */
    goDoctor: (id) => navigate(`${DOCTOR_PATH}/${id}`),
    goReviewForm: (id, name, reservationId, reviewId) =>
      navigate(
        `${DOCTOR_PATH}/${id}/review?reservationId=${reservationId}&doctorName=${name}${
          !!reviewId ? `&reviewId=${reviewId}` : ""
        }`
      ),
    goReservation: (id) => navigate(`${DOCTOR_PATH}/${id}/reservation`),
    goReservationPayment: (doctorId) =>
      navigate(`${DOCTOR_PATH}/${doctorId}/reservation/payment`),

    /* 내 상담실 페이지 */
    goConsultingRoom: () => navigate(`${CONSULTING_ROOM_PATH}`),
    goRoom: (uniqueName) => {
      return navigate(`${CONSULTING_ROOM_PATH}/${uniqueName}`);
    },

    /*  내정보(내담자) 페이지 */
    goMyinfo: () => navigate(`${MYINFO_PATH}`),
    goMyPayment: (state) =>
      navigate(`${MYINFO_PATH}/payment-method`, { replace: state }),
    goMyPartner: () => navigate(`${MYINFO_PATH}/partner`),
    goMyPartnerSuccess: (partner, name, email) =>
      navigate(
        `${MYINFO_PATH}/partner/success?partner=${partner}&name=${name}&email=${email}`
      ),
    goRegistPayment: () => navigate(`${MYINFO_PATH}/payment-method/register`),
    goMyReservation: () => navigate(`${MYINFO_PATH}/reservation`),
    goMyReservationPaymentInfo: (id) =>
      navigate(`${MYINFO_PATH}/reservation/payment/${id}`),
    goMyReservationPaymentRefund: (id) =>
      navigate(`${MYINFO_PATH}/reservation/payment/${id}/refund`),
    goProfile: () => navigate(`${MYINFO_PATH}/profile`),
    goChangePassword: () => navigate(`${MYINFO_PATH}/profile/password`),

    /*  내정보(상담치료사) 페이지 */
    goDoctorReservation: () => navigate(`${MYINFO_PATH}/doctor/reservation`),
    goDoctorProfile: () => navigate(`${MYINFO_PATH}/doctor/profile`),

    /* (내담자) 비밀번호 리셋 */
    goPasswordReset: () => navigate(`${RESET_PATH}/request`),

    /* 콘텐츠 페이지 */
    goContents: () => navigate(`/contents`),
    goGallery: () => navigate(`${CONETNTS_PATH}/gallery`),
    goExamine: () => navigate(`${CONETNTS_PATH}/examine`),
    goExamineStart: () => navigate(`${CONETNTS_PATH}/examine/start`),
    goExamineStep: () => navigate(`${CONETNTS_PATH}/examine/step`),
    goExamineResult: () => navigate(`${CONETNTS_PATH}/examine/results`),

    /* 알림 페이지 */
    goNotificationSettings: () =>
      navigate(`${MYINFO_PATH}/notificationSettings`),
    goNotification: () => navigate(`/notification`),
  };
};
export default useNavigate;
