import { useState } from "react";

const useVideo = () => {
  const [remoteCameraOn, setRemoteCameraOn] = useState(true);
  const [remoteAudioTrack, setRemoteAudioTrack] = useState(null);
  const [remoteVideoTrack, setRemoteVideoTrack] = useState(null);

  const trackSubscribed = (track) => {
    if (track.kind === "video") {
      setRemoteVideoTrack(track);
      setRemoteCameraOn(true);
    } else if (track.kind === "audio") {
      setRemoteAudioTrack(track);
    }
  };

  const trackUnsubscribed = (track) => {
    if (track.kind === "video") {
      setRemoteVideoTrack(null);
    } else if (track.kind === "audio") {
      setRemoteAudioTrack(null);
    }
  };
  const trackDisabled = (publication) => {
    if (publication.kind === "video") {
      setRemoteCameraOn(false);
    } else if (publication.kind === "audio") {
    }
  };

  const trackEnabled = (publication) => {
    if (publication.kind === "video") {
      setRemoteCameraOn(true);
      setRemoteVideoTrack(publication.track);
    } else if (publication.kind === "audio") {
      setRemoteAudioTrack(publication.track);
    }
  };

  return {
    remoteCameraOn,
    remoteAudioTrack,
    remoteVideoTrack,
    trackSubscribed,
    trackUnsubscribed,
    trackEnabled,
    trackDisabled,
  };
};

export default useVideo;
