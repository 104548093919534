import styled from "styled-components";

const HomeSubSection = ({ title, children }) => (
  <Wrapper>
    <Title>{title}</Title>
    <Content>{children}</Content>
  </Wrapper>
);
export default HomeSubSection;

const Wrapper = styled.section``;
const Title = styled.div`
  font: normal normal bold 16px/19px Pretendard;
  letter-spacing: -0.24px;
  color: #101010;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Content = styled.div`
  margin-top: 16px;
`;
