import { useEffect, useState } from "react";
import moment from "moment";

import client from "apis/client";
import useNavigate from "hooks/useNavigate";
import { ReactComponent as FileSVG } from "assets/svg/file.svg";
import { ReactComponent as DatabaseSVG } from "assets/svg/database.svg";
import { ReactComponent as ReviewSVG } from "assets/svg/review.svg";
import { ReactComponent as ClockSVG } from "assets/svg/clock.svg";
import { ReactComponent as AlertSVG } from "assets/svg/alert-circle-outline.svg";

import ReservationDateTime from "components/Drawer/ReservationDateTime";
import { generateFriendlyName, generateUniqueName } from "utils/twilio";

import { Wrapper, Type, Status, Info, Actions, Action } from "./Components";

const MyReservation = ({
  id,
  counsel_kind,
  created_at,
  date,
  times,
  confirm_date,
  user: { id: userId, nickname },
  doctor: { id: doctorId, name: doctorName },
  status,
  review,
  onClickEnterRoom,
  onClickSetDateTime,
  payments,
}) => {
  const {
    goReviewForm,
    goMyReservationPaymentInfo,
    goMyReservationPaymentRefund,
  } = useNavigate();
  const [showDateTimeSelector, setShowDateTimeSelector] = useState(false);
  const enterRoom = async () => {
    const uniqueName = generateUniqueName(doctorId, userId);
    onClickEnterRoom(uniqueName);
  };
  return (
    <Wrapper>
      <Type>{counsel_kind}</Type>
      <Status>{status}</Status>
      <hr />
      <Info>
        <label>상담일</label>
        {status === "상담대기" || status === "상담완료" ? (
          <div>{moment(confirm_date).format("MM.DD (ddd) | HH:mm")}</div>
        ) : status === "예약대기" ? (
          <div>
            {moment(date).format("MM.DD (ddd)")} |{" "}
            <div style={{ display: "inline-block", color: "#485AF3" }}>
              예약 대기 중
            </div>
          </div>
        ) : status === "희망일정 미입력" ? (
          <div style={{ color: "#ED5D4E" }}>
            <AlertSVG style={{ verticalAlign: "top" }} /> 희망일정을 입력해
            주세요
          </div>
        ) : status === "예약취소" ? (
          ""
        ) : (
          ""
        )}
        {/* {confirm_date ? (
          <div>{moment(confirm_date).format("MM.DD(ddd) | HH:mm")}</div>
        ) : date ? (
          <div>
            {moment(date).format("MM.DD(ddd)")} |{" "}
            {status === "예약취소" ? "" : times.join(" , ")}
          </div>
        ) : (
          <div />
        )} */}
      </Info>
      <Info>
        <label>상담사</label>
        <div>{doctorName}</div>
      </Info>
      <Actions>
        {status !== "예약취소" ? (
          !date ? (
            <Action
              onClick={() => setShowDateTimeSelector(true)}
              onTouchEnd={() => setShowDateTimeSelector(true)}
            >
              <ClockSVG />
              상담 일자 접수
            </Action>
          ) : (
            <Action onClick={() => enterRoom()} onTouchEnd={() => enterRoom()}>
              <FileSVG />
              상담실 입장
            </Action>
          )
        ) : (
          <></>
        )}

        {status === "상담완료" && (
          <Action
            onClick={() => goReviewForm(doctorId, doctorName, id, review?.id)}
            onTouchEnd={() =>
              goReviewForm(doctorId, doctorName, id, review?.id)
            }
          >
            <ReviewSVG />
            후기 작성
          </Action>
        )}

        {payments !== undefined && (
          <Action
            style={{ float: "right" }}
            onClick={() => {
              if (status === "유저 접수취소") {
                goMyReservationPaymentRefund(id);
              } else {
                goMyReservationPaymentInfo(id);
              }
            }}
            onTouchEnd={() => {
              if (status === "유저 접수취소") {
                goMyReservationPaymentRefund(id);
              } else {
                goMyReservationPaymentInfo(id);
              }
            }}
          >
            <DatabaseSVG />
            결제 정보
          </Action>
        )}
      </Actions>

      <ReservationDateTime
        isShow={showDateTimeSelector}
        onClose={() => setShowDateTimeSelector(false)}
        onReservation={async (date, times) => {
          try {
            await client.patch(`/user/reservations/${id}`, {
              date,
              times,
              doctorId,
            });

            const friendlyName = generateFriendlyName(doctorName, nickname);
            const uniqueName = generateUniqueName(doctorId, userId);
            await onClickSetDateTime(
              friendlyName,
              uniqueName,
              `${counsel_kind}`,
              moment(created_at).add(4, "days").format("YYYY.MM.DD"),
              moment(date).format("ddd"),
              times
            );
          } catch (e) {
            alert(e.response.data.message);
          }
          setShowDateTimeSelector(false);
        }}
        // prepareRoom={enterRoom}
      />
    </Wrapper>
  );
};

export default MyReservation;
