import styled from "styled-components";

import { getLabelDescription } from "utils/message";

const PaymentSuccess = ({ name, counselKind, doctorName }) => {
  const { label } = getLabelDescription(counselKind);

  return (
    <Wrapper>
      &#127881;
      <div>
        <span className="name">{name}</span>님,
      </div>
      <div>결제가 완료되었습니다.</div>
      <hr />
      <div className="info">
        <label>상담방식ㅤ</label>
        <span>{label}</span>
      </div>
      <div className="info">
        <label>담당상담사</label>
        <span>{doctorName} 상담사</span>
      </div>
    </Wrapper>
  );
};
export default PaymentSuccess;

const Wrapper = styled.div`
  border: 1px solid #e6e6e6;
  border-radius: 30px;
  padding: 30pt 22pt;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    margin-bottom: 12px;
  }

  .name {
    color: #485af3;
  }

  & > div {
    font: normal normal 600 20px/30px Pretendard;
    letter-spacing: -0.2px;
    color: #101010;
  }

  & > hr {
    width: 100%;
    height: 1px;
    background-color: #e6e6e6;
    margin: 24px 0;
    border: none;
  }

  & > .info {
    width: 100%;
    padding-left: 24px;
    font: normal normal 500 16px/19px Pretendard;
    letter-spacing: -0.24px;

    & > label {
      color: #999999;
      padding-right: 16px;
      border-right: 2px solid #efefef;
    }
    & > span {
      padding-left: 16px;
      color: #101010;
    }
  }

  & > .info + .info {
    margin-top: 17px;
  }
`;
