import styled from "styled-components";

import CheckboxField from "components/CheckboxField";
import ErrorSVG from "assets/svg/field-error.svg";
import LinkSVG from "assets/svg/chevron-left-policy.svg";
import { useLocation } from "react-router-dom";

const PolicySection = ({
  policies,
  policyValid,
  checkedPoliciesIdx,
  onCheck,
  onUncheck,
  onCheckAll,
}) => {
  const location = useLocation();
  return (
    <Wrapper>
      <Title
        style={{
          margin: !location.search.includes("doctor") ? "24px 0" : "0 0 24px 0",
        }}
      >
        서비스 이용 약관
      </Title>

      <CheckboxField
        checked={policyValid}
        label="약관 전체 동의"
        onChange={onCheckAll}
      />
      <Divider />
      <PolicyList>
        {policies.map((policy) => {
          const checked = checkedPoliciesIdx.includes(policy.id);
          return (
            <CheckboxField
              key={policy.id}
              checked={checked}
              label={policy.title}
              onClick={() => (window.location.href = policy.url)}
              onChange={() =>
                checked ? onUncheck(policy.id) : onCheck(policy.id)
              }
            ></CheckboxField>
          );
        })}
      </PolicyList>

      <Warning>
        만 14세 미만은 서비스를 이용하실 수 없습니다. 청소년은 청소년 사이버
        상담센터에서 전화, 문자, 카톡 등으로 24시간 무료 심리상담이 가능합니다.
      </Warning>
      <div>
        {policyValid !== null && !policyValid ? (
          <Error>모든 약관에 동의해 주세요.</Error>
        ) : (
          <None></None>
        )}
      </div>
    </Wrapper>
  );
};
export default PolicySection;

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  /* border-top: 1px solid #e6e6e6; */
`;
const Title = styled.div`
  font: normal normal 600 18px/21px Pretendard;
  letter-spacing: -0.27px;
  color: #101010;
`;
const Divider = styled.div`
  height: 1px;
  border-bottom: 1px solid #e6e6e6;
  margin: 22px 0;
`;
const PolicyList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;

  & > section {
    position: relative;
    display: flex;
    &::after {
      content: "";
      position: absolute;
      right: 0;
      background: url(${LinkSVG});
      width: 7px;
      height: 12px;
      top: 6px;
    }
  }
`;
const Warning = styled.div`
  font: normal normal normal 13px/19px Pretendard;
  letter-spacing: -0.13px;
  color: #999999;
  margin-top: 36px;
`;
const Error = styled.div`
  position: relative;

  margin-top: 15px;
  font: normal normal 500 13px/16px Pretendard;
  letter-spacing: -0.13px;
  color: #ed5d4e;
  padding-left: 22px;

  &::before {
    position: absolute;
    left: 0;
    content: "";
    width: 16px;
    height: 16px;
    background: url(${ErrorSVG});
  }
`;

const None = styled.div`
  width: 16px;
  height: 16px;
  position: relative;
  margin-top: 15px;
  padding-left: 22px;
`;
