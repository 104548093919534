import { useEffect, useState } from "react";
import styled from "styled-components";

// import useCounsels from "hooks/useCounsels";
import Form from "components/Form";

import { Section, Message } from ".";

const DoctorInfoStep1 = ({ register, watch, formState: { errors } }) => {
  const [imgPreview, setImgPreview] = useState("");

  const { img } = watch();
  // const counsels = useCounsels();

  console.log(errors);

  useEffect(() => {
    if (img && img.length > 0) {
      if (typeof img === "string") {
        setImgPreview(img);
      } else {
        setImgPreview(URL.createObjectURL(img[0]));
      }
    }
  }, [img]);

  return (
    <>
      <Section>
        <Message>{`관리자 가입 최종 승인 후 상담사 정보는 <내 정보>에서 수정할 수 있습니다.`}</Message>

        <Form.Row>
          <Form.Label required={true}>프로필 사진*</Form.Label>
          <ProfileImage>
            <div className="preview">
              {imgPreview && <img src={imgPreview} alt="" />}
            </div>
            <input
              type="file"
              id="img"
              {...register("img", {
                // required: true,
                // validate: {
                //   isJpgOrPng: (v) => {
                //     const isValid = /jpeg|png|jpg/.test(v[0].type);
                //     if (!isValid) {
                //       alert("대표썸네일은 JPG / PNG 형식만 가능합니다.");
                //     }
                //     return isValid;
                //   },
                //   isSizeLimit: (v) => {
                //     const isValid = v[0].size / 1024 / 1024 < 2;
                //     if (!isValid) {
                //       alert("대표썸네일은 2MB 이내의 파일을 등록해주세요.");
                //     }
                //     return isValid;
                //   },
                // },
              })}
              style={{ display: "none" }}
            />
            <label className="upload-button" htmlFor="img">
              이미지 선택
            </label>
          </ProfileImage>
        </Form.Row>

        {/* <Form.Row>
          <Form.Label>
            상담 과목* <span>(최대 5개)</span>
          </Form.Label>
          <Counsels>
            {counsels.map((counsel) => (
              <Form.ButtonCheckboxField
                key={counsel.id}
                label={counsel.subject}
                value={counsel.id}
                {...register("counsel_ids", {
                  required: {
                    value: true,
                    message: "대표 상담 과목을 하나 이상 선택하세요.",
                  },
                  maxLength: {
                    value: 5,
                    message: "대표 상담 과목은 최대 5개 선택가능합니다.",
                  },
                })}
              />
            ))}
          </Counsels>
          {errors?.counsel_ids && (
            <Form.Error>{errors?.counsel_ids?.message}</Form.Error>
          )}
        </Form.Row> */}

        {/* <Form.Row>
          <Form.Label>
            상담 특징 <span>(300자 이내)</span>
          </Form.Label>
          <Form.Textarea
            placeholder="상담의 특징에 대해 작성해 주세요."
            {...register("counsel_features")}
          />
          {errors?.counsel_features && (
            <Form.Error>{errors?.counsel_features?.message}</Form.Error>
          )}
        </Form.Row> */}
      </Section>

      <Section>
        <div className="title">상담사 이야기</div>
        <Form.Row>
          <Form.Label>
            Q. 주요 상담 기법 {/* <span>(200자 이내)</span> */}
          </Form.Label>
          <Form.BorderInput
            placeholder="A. 상담사의 입력 내용"
            {...register("doctor_stories.0")}
          />
        </Form.Row>

        <Form.Row>
          <Form.Label>
            Q. 이 상담을 추천하는 사람은? {/* <span>(200자 이내)</span> */}
          </Form.Label>
          <Form.BorderInput
            placeholder="A. 상담사의 입력 내용"
            {...register("doctor_stories.1")}
          />
        </Form.Row>

        <Form.Row>
          <Form.Label>
            Q. 상담을 망설이시는 분들께 한 마디{" "}
            {/* <span>(200자 이내)</span> */}
          </Form.Label>
          <Form.BorderInput
            placeholder="A. 상담사의 입력 내용"
            {...register("doctor_stories.2")}
          />
        </Form.Row>
      </Section>
    </>
  );
};
export default DoctorInfoStep1;

const ProfileImage = styled.div`
  width: 100%;
  position: relative;

  & > .upload-button {
    position: absolute;
    top: 58px;
    left: 24px;
    width: 93px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #333333 0% 0% no-repeat padding-box;
    border-radius: 4px;
    font: normal normal 500 13px/16px Pretendard;
    letter-spacing: -0.26px;
    color: #ffffff;
  }
  & > .preview {
    width: 140px;
    height: 140px;
    border: 1px solid #efefef;
    border-radius: 10px;

    & > img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }
  & > .file-info {
    margin-top: 18px;
    font: normal normal 400 16px/19px Pretendard;
    letter-spacing: -0.16px;
    color: #777777;
  }
`;
