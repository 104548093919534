import { useForm } from "react-hook-form";
import Form from "components/Form";
import LoginFooter from "components/LoginFooter";

import { requestPhoneCode } from "apis/auth";
import useNavigate from "hooks/useNavigate";
import useAuth from "hooks/useAuth";
import { PHONE_ERROR } from "utils/message";
import { PHONE_PATTERN } from "utils/regex";

import { Wrapper, CenterSection } from "./StyledComps";
import styled from "styled-components";

const CounselorLogin = () => {
  const { loginDoctor } = useAuth();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const requestCode = async () => {
    const phone = watch("phone");

    if (!phone) {
      alert("휴대전화 번호 형식을 확인해 주세요.");
      return;
    }
    await requestPhoneCode(phone);
  };

  const onSubmit = async (params) => {
    const { code, phone } = params;
    await loginDoctor(code, phone);
  };

  return (
    <Wrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Row>
          <Form.InputField
            label="휴대폰 번호"
            type="tel"
            placeholder="-없이 번호 입력"
            {...register("phone", {
              required: { value: true, message: PHONE_ERROR },
              pattern: {
                value: PHONE_PATTERN,
                message: PHONE_ERROR,
              },
              minLength: {
                value: 11,
                message: PHONE_ERROR,
              },
              maxLength: {
                value: 11,
                message: PHONE_ERROR,
              },
            })}
            error={errors?.phone?.message}
          />
          <Button
            onClick={() => {
              requestCode();
            }}
          >
            인증
          </Button>
        </Form.Row>

        <Form.Row>
          <Form.InputField
            type="tel"
            label="인증번호 입력"
            placeholder="6자리 숫자 입력"
            {...register("code", {
              required: true,
              // valueAsNumber: true,
            })}
            error={errors?.code?.message}
          />
          <Button
            onClick={() => {
              requestCode();
            }}
          >
            재전송
          </Button>
        </Form.Row>

        <Form.SubmitRow>
          <Form.SubmitButton type="submit">로그인</Form.SubmitButton>
        </Form.SubmitRow>
      </Form>

      <CenterSection>
        <LoginFooter
          linkLabel={"상담사 회원가입"}
          message={"서비스를 처음 이용하시나요?"}
        />
      </CenterSection>
    </Wrapper>
  );
};
export default CounselorLogin;

const Button = styled.div`
  position: absolute;
  right: 0;
  top: 33px;
  width: 63px;
  height: 31px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #efefef;
  border-radius: 5px;
  font: normal normal 500 14px/17px Pretendard;
  letter-spacing: -0.21px;
  color: #6e6e6e;
  text-align: center;
  padding-top: 7px;
  cursor: pointer;
`;
