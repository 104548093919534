import React, { useEffect, useMemo } from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import moment from "moment";

import { messagesState } from "atomStates";
import useDoctor from "hooks/useDoctor";
import { ReactComponent as AlertSVG } from "assets/svg/Alert_big.svg";

import {
  CHAT_RESERVED_WORD_OVERVIEW,
  CHAT_RESERVED_WORD,
} from "utils/constant";

const ConsultingRoomOverview = ({
  doctorId,
  userId,
  roomName,
  lastMessage,
  lastMessageStr,
  lastMessageDateCreated,
  count,
  onClick,
  counselRoom,
  userType,
}) => {
  const doctor = useDoctor(doctorId);
  const messages = useRecoilValue(messagesState);
  const lastOne =
    lastMessage !== undefined ? messages[lastMessage.index] : null;
  const showNewBadge =
    lastMessageStr &&
    counselRoom.length > 0 &&
    counselRoom.filter((roomInfo) => {
      if (userType === "client") {
        return (
          roomInfo.name.includes(`doctor${doctorId}`) &&
          roomInfo.doctor_last_sent_at > roomInfo.user_last_visited_at
        );
      }

      return (
        roomInfo.name.includes(`user${userId}`) &&
        roomInfo.doctor_last_visited_at < roomInfo.user_last_sent_at
      );
    }).length > 0;

  const isToday = useMemo(() => {
    const q = new Date();
    const m = q.getMonth();
    const d = q.getDate();
    const y = q.getFullYear();
    const today = new Date(y, m, d);

    return today <= new Date(lastMessageDateCreated);
  }, [lastMessageDateCreated]);

  const isPastYear = useMemo(() => {
    const q = new Date();
    const y = q.getFullYear();
    const thisYear = new Date(y, 0, 1);

    return thisYear > new Date(lastMessageDateCreated);
  }, [lastMessageDateCreated]);

  const dateFormat = useMemo(() => {
    if (isPastYear) {
      return "YYYY. MM. DD.";
    }

    if (isToday) {
      return "A h:mm";
    }

    return "MMM Do";
  }, [isPastYear, isToday]);

  return (
    <Wrapper onClick={onClick}>
      <PhotoSection $image={doctor?.img} />
      <InfoSection>
        <div className="row">
          <div className="name">{roomName}</div>
          {lastMessageDateCreated && (
            <div className="datetime">
              {moment(lastMessageDateCreated).format(dateFormat)}
            </div>
          )}
        </div>
        <div className="row">
          <div className="message">
            {(lastMessageStr ?? "").includes(CHAT_RESERVED_WORD)
              ? CHAT_RESERVED_WORD_OVERVIEW[
                  lastMessageStr.split(CHAT_RESERVED_WORD)[1]
                ]
              : lastMessageStr === null
              ? "사진을 보냈습니다."
              : lastMessageStr}
          </div>
          {showNewBadge && (
            <div style={{ display: "inline-block", float: "right" }}>
              <AlertSVG />
            </div>
          )}
        </div>
      </InfoSection>
    </Wrapper>
  );
};

export default ConsultingRoomOverview;

const Wrapper = styled.section`
  display: flex;
  gap: 16px;
`;

const PhotoSection = styled.div`
  width: 66px;
  height: 66px;
  border-radius: 50%;
  background-color: #f8f8f8;
  background-image: url(${(p) => p.$image});
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100% 100%;
  flex-shrink: 0;
  margin: 20px 0;
`;
const InfoSection = styled.div`
  max-width: calc(100% - 82px);
  width: 100%;
  padding: 30px 0;
  border-bottom: 1px solid #e6e6e6;

  & > .row {
    display: flex;
    justify-content: space-between;

    & > .name {
      font: normal normal 600 17px/20px Pretendard;
      letter-spacing: -0.17px;
      color: #101010;
    }
    & > .datetime {
      font: normal normal 400 13px/16px Pretendard;
      letter-spacing: -0.13px;
      color: #999999;
    }
    & > .message {
      font: normal normal 400 14px/17px Pretendard;
      letter-spacing: -0.21px;
      color: #6e6e6e;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-break: break-all;

      width: calc(100% - 25px);
    }
    & > .unread-count {
      width: 24px;
      height: 24px;
      background: #485af3 0% 0% no-repeat padding-box;
      font: normal normal 500 13px/16px Pretendard;
      letter-spacing: -0.13px;
      color: #ffffff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  & > .row + .row {
    margin-top: 10px;
  }
`;
