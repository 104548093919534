import styled from "styled-components";

import useNavigate from "hooks/useNavigate";

const CompanyInfo = () => {
  const { goTerms } = useNavigate();

  return (
    <Wrapper>
      <Info>
        <label>상호명</label>
        <div>임팩트스테이션 주식회사</div>
      </Info>
      <Info>
        <label>대표이사</label>
        <div>송상훈</div>
      </Info>
      <Info>
        <label>사업자 등록번호</label>
        <div>683-86-00599</div>
      </Info>
      <Info>
        <label>통신판매업 신고번호</label>
        <div>019-서울서대문-0717</div>
      </Info>
      <Info>
        <label>주소</label>
        <div>서울특별시 중구 청계천로 40, 한국관광공사 13층05호 (다동)</div>
        {/* <div>- 02-6385-0031</div> */}
      </Info>
      <Info>
        <label>대표 이메일</label>
        <div>minddrawing@impactstation.kr</div>
      </Info>

      <Links>
        <Link onClick={() => goTerms("usepolicy")}>서비스 이용약관</Link>
        <Link onClick={() => goTerms("privacy")}>개인정보 처리방침</Link>
        <Link onClick={() => goTerms("health_privacy")}>
          개인 건강정보(민감정보) 처리방침
        </Link>
        <Link onClick={() => goTerms("refund")}>환불 규정</Link>
      </Links>
    </Wrapper>
  );
};
export default CompanyInfo;

const Wrapper = styled.section`
  padding: 100px 15px;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 120px;
`;
const Info = styled.div`
  font: normal normal 500 15px/18px Pretendard;
  letter-spacing: -0.15px;
  color: #101010;
  & > label {
    display: block;
    font: normal normal 400 15px/18px Pretendard;
    letter-spacing: -0.3px;
    color: #999999;
    margin-bottom: 12px;
  }

  & > div + div {
    margin-top: 14px;
  }

  & + & {
    margin-top: 24px;
  }
`;
const Links = styled.section`
  margin-top: 80px;
`;
const Link = styled.div`
  font: normal normal 400 14px/17px Pretendard;
  letter-spacing: 0px;
  color: #999999;

  &::after {
    content: ">";
  }

  & + & {
    margin-top: 20px;
  }
`;
