import styled from "styled-components";
import useNavigate from "hooks/useNavigate";
import { getAppInfo } from "utils/checkMobile";
import { CHAT_RESERVED_WORD } from "utils/constant";
import { ReactComponent as CheckmarkSVG } from "assets/svg/checkmark-circle.svg";
import { ReactComponent as CreateSVG } from "assets/svg/create.svg";

const ReservedChatMessage = ({ message, userType, doctorId }) => {
  const { goDoctor } = useNavigate();
  const { isMinddrawingApp } = getAppInfo();

  const [, idx] = message.split(CHAT_RESERVED_WORD);
  if (idx === "001") {
    return (
      <>
        <MessageWrapper>
          <MessageBody>
            <CheckmarkSVG width={"14px"} height={"14px"} /> 원활한 상담을 위해
            카메라, 마이크 권한을 꼭 허용해주세요.{" "}
          </MessageBody>
        </MessageWrapper>
        <MessageWrapper>
          {userType === "client" ? (
            <MessageBody>
              <CreateSVG width={"14px"} height={"14px"} /> 더 효과적인 상담
              진행을 위해 내담자님의{" "}
              <span style={{ fontWeight: "bold" }}>기본 정보</span>를 상담사님께
              남겨주세요.
              <br />
              <br />
              <PersonalData>• 연령대, 성별, 주요 고민 주제</PersonalData>
            </MessageBody>
          ) : (
            <MessageBody>
              🔒 내담자 기본 정보를 확인하시고 상담을 진행해주세요. <br />
              내담자가 기본정보를 제공하지 않았을 경우에는 채팅을 통해
              상담사님이 유도해 주셔도 좋아요 ^^
            </MessageBody>
          )}
        </MessageWrapper>
      </>
    );
  }

  if (idx === "002") {
    const buttonClickHandler = () => {
      if (userType === "client") {
        goDoctor(doctorId);
      } else {
        const doctorSurveyUrl =
          "https://docs.google.com/forms/d/1kR_yaXEFVv2QV-TckWrKB-_SzwqyshmJEENGvIMI8eQ/viewform?edit_requested=true";

        if (isMinddrawingApp) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              type: "OPEN_BROWSER",
              params: {
                url: doctorSurveyUrl, // required
              },
            })
          );
          return;
        }
        window.open(doctorSurveyUrl);
      }
    };

    if (userType === "client") {
      return (
        <ReviewMessageWrapper>
          <ReviewMessageBody>
            상담 시간이 종료되었습니다. 이번 상담은 어떠셨나요?
            <br />
            소중한 후기를 남겨주시면 감사하겠습니다 :)
          </ReviewMessageBody>
          <ReviewButton onClick={buttonClickHandler}>
            후기 작성하기 ❤️
          </ReviewButton>
        </ReviewMessageWrapper>
      );
    } else {
      return (
        <ReviewMessageWrapper>
          <ReviewMessageBody>
            상담 시간이 종료되었습니다. 이번 상담은 어떠셨나요?
            <br />
            소중한 후기를 남겨주시면 감사하겠습니다 :)
          </ReviewMessageBody>
          <ReviewButton onClick={buttonClickHandler}>
            상담 평가 설문하기 ❤️
          </ReviewButton>
        </ReviewMessageWrapper>
      );
    }
  }

  return null;
};

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #edf1ff;
  font: normal normal 500 14px/18px Pretendard;
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 12px 16px;
`;

const MessageBody = styled.div`
  font: inherit;
  padding-left: 21px;
  text-indent: -21px;
  word-break: keep-all;
`;

const ReviewButton = styled.button`
  padding: 12px 14px;
  background-color: #3b3b3b;
  color: #ffffff;
  font: inherit;
  border-radius: 10px;
`;

const PersonalData = styled.div`
  background-color: #91a7ff;
  height: 42px;
  border-radius: 7px;
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  padding-top: 12px;
`;

const ReviewMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: solid 1px #efefef;
  font: normal normal 500 14px/18px Pretendard;
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 12px 16px;
`;
const ReviewMessageBody = styled.div`
  padding: 0 0 16px 3px;
`;

export default ReservedChatMessage;
