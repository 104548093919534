import { useState } from "react";
import styled from "styled-components";

const ExamineStep4 = ({
  setResults,
  results,
  nextStep,
  openModal,
  setOpenModal,
}) => {
  const [buttonStates, setButtonStates] = useState({
    button1: false,
    button2: false,
    button3: false,
    button4: false,
    button5: false,
    button6: false,
    button7: false,
    button8: false,
    button9: false,
  });

  const handleMouseEnter = (buttonId) => {
    setButtonStates((prevState) => ({
      ...prevState,
      [buttonId]: true,
    }));
  };

  const handleMouseLeave = (buttonId) => {
    setButtonStates((prevState) => ({
      ...prevState,
      [buttonId]: false,
    }));
  };
  return (
    <>
      <Button
        value="근무하지 않음"
        hovered={buttonStates.button1}
        onMouseEnter={() => handleMouseEnter("button1")}
        onMouseLeave={() => handleMouseLeave("button1")}
        onTouchStart={() => handleMouseEnter("button1")}
        onTouchEnd={() => handleMouseLeave("button1")}
        onClick={(e) => {
          setResults({ ...results, work_type: e.target.value });
          nextStep();
        }}
      >
        근무하지 않음
      </Button>
      <Button
        value="사무직"
        hovered={buttonStates.button2}
        onMouseEnter={() => handleMouseEnter("button2")}
        onMouseLeave={() => handleMouseLeave("button2")}
        onTouchStart={() => handleMouseEnter("button2")}
        onTouchEnd={() => handleMouseLeave("button2")}
        onClick={(e) => {
          setResults({ ...results, work_type: e.target.value });
          nextStep();
        }}
      >
        사무직
      </Button>
      <Button
        value="외근직"
        hovered={buttonStates.button3}
        onMouseEnter={() => handleMouseEnter("button3")}
        onMouseLeave={() => handleMouseLeave("button3")}
        onTouchStart={() => handleMouseEnter("button3")}
        onTouchEnd={() => handleMouseLeave("button3")}
        onClick={(e) => {
          setResults({ ...results, work_type: e.target.value });
          nextStep();
        }}
      >
        외근직
      </Button>
      <Button
        value="서비스직"
        hovered={buttonStates.button4}
        onMouseEnter={() => handleMouseEnter("button4")}
        onMouseLeave={() => handleMouseLeave("button4")}
        onTouchStart={() => handleMouseEnter("button4")}
        onTouchEnd={() => handleMouseLeave("button4")}
        onClick={(e) => {
          setResults({ ...results, work_type: e.target.value });
          nextStep();
        }}
      >
        서비스직
      </Button>
      <Button
        value="현장직"
        hovered={buttonStates.button5}
        onMouseEnter={() => handleMouseEnter("button5")}
        onMouseLeave={() => handleMouseLeave("button5")}
        onTouchStart={() => handleMouseEnter("button5")}
        onTouchEnd={() => handleMouseLeave("button5")}
        onClick={(e) => {
          setResults({ ...results, work_type: e.target.value });
          nextStep();
        }}
      >
        현장직
      </Button>
      <Button
        value="연구직"
        hovered={buttonStates.button6}
        onMouseEnter={() => handleMouseEnter("button6")}
        onMouseLeave={() => handleMouseLeave("button6")}
        onTouchStart={() => handleMouseEnter("button6")}
        onTouchEnd={() => handleMouseLeave("button6")}
        onClick={(e) => {
          setResults({ ...results, work_type: e.target.value });
          nextStep();
        }}
      >
        연구직
      </Button>
      <Button
        value="가정주부"
        hovered={buttonStates.button7}
        onMouseEnter={() => handleMouseEnter("button7")}
        onMouseLeave={() => handleMouseLeave("button7")}
        onTouchStart={() => handleMouseEnter("button7")}
        onTouchEnd={() => handleMouseLeave("button7")}
        onClick={(e) => {
          setResults({ ...results, work_type: e.target.value });
          nextStep();
        }}
      >
        가정주부
      </Button>
      <Button
        value="자영업자"
        hovered={buttonStates.button8}
        onMouseEnter={() => handleMouseEnter("button8")}
        onMouseLeave={() => handleMouseLeave("button8")}
        onTouchStart={() => handleMouseEnter("button8")}
        onTouchEnd={() => handleMouseLeave("button8")}
        onClick={(e) => {
          setResults({ ...results, work_type: e.target.value });
          nextStep();
        }}
      >
        자영업자
      </Button>
      <Button
        value="기타"
        hovered={buttonStates.button9}
        onMouseEnter={() => handleMouseEnter("button9")}
        onMouseLeave={() => handleMouseLeave("button9")}
        onTouchStart={() => handleMouseEnter("button9")}
        onTouchEnd={() => handleMouseLeave("button9")}
        onClick={(e) => {
          setResults({ ...results, work_type: e.target.value });
          setOpenModal(true);
        }}
      >
        기타
      </Button>
    </>
  );
};

export default ExamineStep4;

const Button = styled.button`
  font: normal normal 500 11pt/11px Pretendard;
  border: solid 1px #e6e6e6;
  background-color: #ffffff;
  color: #101010;
  margin: 0 10pt -4pt 10pt;
  padding: 14pt 10pt;
  text-align: left;
  border-radius: 5pt;

  &:hover {
    background-color: ${(props) => (props.hovered ? "#8ba8ff" : "#ffffff")};
    color: ${(props) => (props.hovered ? "#ffffff" : "#101010")};
  }

  &:checked {
    background-color: #8ba8ff;
    color: #ffffff;
  }
`;
