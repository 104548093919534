import { useState } from "react";
import styled from "styled-components";

import Form from "components/Form";
import useAuth from "hooks/useAuth";
import useNavigate from "hooks/useNavigate";

const Withdraw = () => {
  const { goHome } = useNavigate();
  const { checkClientPassword, logout, withdrawClient } = useAuth();

  const [checked, setChecked] = useState(false);
  const [password, setPassword] = useState("");

  const requestWithdraw = async () => {
    try {
      if (!checked) {
        alert("서비스 이용 내역 삭제에 동의해주셔야 탈퇴할 수 있습니다.");
        return;
      }
      // 1. 비밀번호 확인 후,
      await checkClientPassword(password);

      try {
        // 2. 탈퇴 요청
        withdrawClient();
        logout();
        alert(
          "탈퇴 처리가 완료되었습니다. \n그동안 마음드로잉 서비스를 이용해주셔서 감사드립니다."
        );
        goHome();
      } catch (e) {
        alert(e.response.data.message);
      }
    } catch (e) {
      alert("비밀번호를 확인하세요");
    }
  };

  return (
    <Wrapper>
      <div>
        <Title>
          탈퇴시 모든 서비스
          <br /> 이용내역이 삭제됩니다.
        </Title>

        <Message>
          • 모든 미술 치료 기록이 삭제됩니다. <br />
          • 지금까지 작성한 후기 등 모든 기록이 삭제됩니다.
          <br />• 결제 내역 등의 개인정보가 모두 삭제됩니다.
        </Message>

        <Password>
          <Form.InputField
            label="비밀번호"
            placeholder="비밀번호를 입력해 주세요."
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Password>
      </div>

      <Action>
        <div>
          <input
            type="checkbox"
            checked={checked}
            onChange={() => setChecked(!checked)}
          />
          <label>
            위 모든 사항을 확인했으며, 모든 상담 정보 삭제에 동의합니다.
          </label>
        </div>
        <Button disabled={password === ""} onClick={requestWithdraw}>
          탈퇴하기
        </Button>
      </Action>
    </Wrapper>
  );
};
export default Withdraw;

const Wrapper = styled.section`
  padding: 40px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
const Title = styled.div`
  font: normal normal 600 28px/44px Pretendard;
  letter-spacing: -0.56px;
  color: #101010;
`;
const Message = styled.div`
  margin-top: 24px;
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 10px;
  padding: 20px;
  font: normal normal 400 14px/25px Pretendard;
  letter-spacing: -0.21px;
  color: #6e6e6e;
`;
const Password = styled.div`
  margin-top: 80px;
  margin-bottom: 20px;
`;
const Action = styled.div`
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  label {
    font: normal normal 500 13px/16px Pretendard;
    letter-spacing: -0.13px;
    color: #404040;
    margin-left: 8px;
  }
  & > button {
    width: 271px;
    height: 48px;
    background: #333333 0% 0% no-repeat padding-box;
    border-radius: 10px;
    font: normal normal 500 16px/19px Pretendard;
    letter-spacing: -0.16px;
    color: #ffffff;
    margin-top: 40px;
  }
`;

const Button = styled.button`
  width: 271px;
  height: 48px;
  background: #333333 0% 0% no-repeat padding-box;
  border-radius: 10px;
  font: normal normal 500 16px/19px Pretendard;
  -webkit-letter-spacing: -0.16px;
  -moz-letter-spacing: -0.16px;
  -ms-letter-spacing: -0.16px;
  letter-spacing: -0.16px;
  color: #ffffff;
  margin-top: 40px;

  &:disabled {
    color: #999 !important;
    cursor: default;
    background-color: #ddd !important;
  }
`;
