import { useState, useCallback } from "react";

const useLoading = (initialLoading = false) => {
  const [isLoading, setLoading] = useState(initialLoading);
  const turnOnLoading = useCallback(() => setLoading(true), []);
  const turnOffLoading = useCallback(() => setLoading(false), []);

  return {
    isLoading,
    turnOnLoading,
    turnOffLoading,
  };
};
export default useLoading;
