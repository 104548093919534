// import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { ReactComponent as ArrowSVG } from "assets/svg/chevron-right.svg";
import { userState } from "atomStates";
import FormSection from "./FormSection";
import MyPayment from "./MyPayment";
import { update as updateProfile } from "apis/user";
import Form from "components/Form";
import { MYINFO_PATH } from "App";
import useNavigate from "hooks/useNavigate";

const Profile = () => {
  const { goMyPartner } = useNavigate();
  const user = useRecoilValue(userState) || {};
  const { email, nickname, phone } = user;
  const form = useForm({
    defaultValues: {
      // nickname,
      phone,
    },
  });

  const onSubmit = async (params) => {
    // if (!form.formState.isDirty) return;
    try {
      await updateProfile(params);
      window.location.href = MYINFO_PATH;
    } catch (e) {
      alert("오류가 발생하였습니다.");
    }
  };

  return (
    <Wrapper>
      <Section>
        <Title>기본 정보 입력</Title>
        <form id="profile-form" onSubmit={form.handleSubmit(onSubmit)}>
          <FormSection email={email} nickname={nickname} {...form} />
        </form>
      </Section>

      <MyPayment />

      <Section>
        <Link onClick={goMyPartner}>
          제휴기관 멤버십 인증
          <ArrowSVG style={{ float: "right" }} />
        </Link>

        <Form.SubmitRow>
          <BtnSection>
            <Form.SubmitButton type="submit" form="profile-form">
              수정 완료
            </Form.SubmitButton>
          </BtnSection>
        </Form.SubmitRow>
      </Section>
    </Wrapper>
  );
};
export default Profile;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #f8f8f8;
  margin-bottom: 52px;
`;
const Section = styled.section`
  background: #ffffff;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 36px;
`;
const Title = styled.div`
  font: normal normal 600 18px/21px Pretendard;
  letter-spacing: -0.27px;
  color: #101010;
`;
const Link = styled.div`
  font: normal normal 500 16px/19px Pretendard;
  letter-spacing: -0.16px;
  color: #101010;
  margin-bottom: 80px;
`;

const BtnSection = styled.div`
  width: 100%;
  background-color: #fff;
  padding-top: 24px;
  padding-bottom: 24px;
  border-top: 1px solid #efefef;
  position: absolute;
  bottom: 0px;
  text-align: center;
  z-index: 999;
`;
