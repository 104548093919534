import styled from "styled-components";
import { useState, useRef, useEffect, useCallback, useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import moment from "moment";

import Menu from "../components/PaletteMenu";
import { ReactComponent as X } from "assets/svg/x.svg";
import PaletteColor from "components/PaletteColor";
import useLoading from "hooks/useLoading";
import LoadingBar from "components/Loading";
import { PaletteMessage } from "utils/constant";
import {
  checkRNVideoScreenSupport,
  checkRNPaletteAutoSaveSupport,
  getAppInfo,
} from "utils/checkMobile";

const Palette = ({ triggerPalette, conversations }) => {
  const canvasRef = useRef(null);
  const ctxRef = useRef(null);
  const { roomId } = useParams();
  const [conversation, setConversation] = useState(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [lineWidth, setLineWidth] = useState(1);
  const [lineColor, setLineColor] = useState("black");
  const [lineOpacity, setLineOpacity] = useState(1);
  const [modal, setModal] = useState(false);
  const { isLoading, turnOnLoading, turnOffLoading } = useLoading();
  const [previous, setPrevious] = useState("");
  const [previousWidth, setPreviousWidth] = useState(0);
  const isPaletteAutoSaveSupport = useMemo(
    () => checkRNPaletteAutoSaveSupport(),
    []
  );
  const appVideoScreenSupport = useMemo(() => checkRNVideoScreenSupport(), []);
  const { isMinddrawingApp } = getAppInfo();

  const [nowLineWidth, setNowLineWidth] = useState(1);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const loadResult = loadCanvas(ctx);

    if (!loadResult) {
      ctx.fillStyle = "#ffffff";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctxRef.current = ctx;
    }
  }, []);

  useEffect(() => {
    const conversation = conversations.find((c) => c.uniqueName === roomId);

    if (conversation) {
      setConversation(conversation);
      turnOffLoading();
    }
  }, [conversations, roomId, turnOffLoading]);

  // Tablet 크기 boolean 값
  const isTablet = useMediaQuery({
    query: "(min-width: 415px) and (max-width: 769px)",
  });

  // PC 크기 boolean 값
  const isPC = useMediaQuery({
    query: "(min-width: 1025px)",
  });

  const saveCanvas = () => {
    localStorage.setItem(
      "myCanvas",
      document.getElementById("canvas").toDataURL()
    );
  };

  const loadCanvas = (ctx) => {
    const dataURL = localStorage.getItem("myCanvas");

    if (dataURL) {
      const img = new Image();

      img.src = dataURL;
      img.onload = () => {
        ctx.drawImage(img, 0, 0);
      };
      return true;
    }

    return false;
  };

  const eraseCanvas = () => {
    localStorage.removeItem("myCanvas");
  };

  const startTouch = useCallback((e) => {
    e.preventDefault();
    ctxRef.current.beginPath();
    if (!canvasRef.current) {
      return;
    }
    const canvas = canvasRef.current;
    var touch = e.touches[0];
    var mouseEvent = new MouseEvent("mousedown", {
      clientX: touch.clientX * 0.5,
      clientY: touch.clientY * 0.5,
    });
    canvas.dispatchEvent(mouseEvent);
  }, []);

  const endTouch = useCallback((e) => {
    e.preventDefault();

    if (!canvasRef.current) {
      return;
    }
    const canvas = canvasRef.current;
    var mouseEvent = new MouseEvent("mouseup", {});
    canvas.dispatchEvent(mouseEvent);
  }, []);

  const touch = useCallback(
    (e) => {
      e.preventDefault();
      if (!canvasRef.current) {
        return;
      }
      const canvas = canvasRef.current;
      var touch = e.touches[0];
      var mouseEvent = new MouseEvent("mousemove", {
        clientX: touch.clientX,
        clientY: touch.clientY,
      });

      if (isTablet) {
        ctxRef.current.lineTo(touch.clientX - 33, touch.clientY - 127);
      } else {
        ctxRef.current.lineTo(touch.clientX - 18, touch.clientY - 113);
      }

      ctxRef.current.stroke();
      canvas.dispatchEvent(mouseEvent);
      saveCanvas();
    },
    [isTablet]
  );

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.lineCap = "round";
    ctx.lineJoin = "round";

    ctx.globalAlpha = lineOpacity;
    ctx.strokeStyle = lineColor;
    ctx.lineWidth = lineWidth;
    ctxRef.current = ctx;

    canvas.addEventListener("touchstart", startTouch);
    canvas.addEventListener("touchmove", touch);
    canvas.addEventListener("touchend", endTouch);
    return () => {
      canvas.removeEventListener("touchstart", startTouch);
      canvas.removeEventListener("touchmove", touch);
      canvas.removeEventListener("touchend", endTouch);
    };
  }, [endTouch, lineColor, lineOpacity, lineWidth, startTouch, touch]);

  const startDrawing = (e) => {
    ctxRef.current.beginPath();
    ctxRef.current.moveTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
    setIsDrawing(true);
  };

  const endDrawing = () => {
    ctxRef.current.closePath();
    setIsDrawing(false);
  };

  const draw = (e) => {
    if (!isDrawing) {
      return;
    }
    ctxRef.current.lineTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
    ctxRef.current.stroke();
    saveCanvas();
  };

  const shareCanvasToChat = async (dataUrl) => {
    turnOnLoading();

    const blob = dataURItoBlob(dataUrl);
    const sendMediaOptions = {
      contentType: blob.type,
      filename: `minddrawing_${new Date()
        .toLocaleString()
        .replace(/ |,|\//g, "_")}.png`,
      media: blob,
    };

    const response = { success: false };

    try {
      await conversation
        .sendMessage(sendMediaOptions)
        .then(() => {
          response.success = true;
        })
        .catch(() => {
          alert("이미지 전송에 실패했습니다.");
        })
        .finally(() => {
          turnOffLoading();
        });
    } catch {
      alert("이미지 전송에 실패했습니다.");
      turnOffLoading();
    }

    return response;
  };

  const closePalette = ({ isEnd }) => {
    if (isEnd) eraseCanvas();
    if (triggerPalette) {
      triggerPalette();
      return;
    } else if (isMinddrawingApp && appVideoScreenSupport) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "CLOSE_WINDOW",
        })
      );
      return;
    } else {
      window.history.back();
    }
  };

  const quitPalette = async () => {
    if (!conversation) {
      alert("채팅메세지 전송이 불가능합니다. 잠시 후 다시 시도해주세요.");
      return;
    }

    await conversation?.sendMessage(PaletteMessage.EndPalette);
    closePalette({ isEnd: true });
  };

  const saveImageToDevice = async ({ dataUrl }) => {
    const tempImgLink = document.createElement("a");
    const nowStr = moment().format("YYYY-MM-DD_HH-mm-ss");
    const fileName = `palette_${nowStr ?? new Date().getTime()}.png`;

    tempImgLink.href = dataUrl;
    tempImgLink.download = fileName;

    if (isMinddrawingApp) {
      if (isPaletteAutoSaveSupport) {
        await window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: "SAVE_IMAGE",
            params: {
              dataUrl: dataUrl,
              fileName,
            },
          })
        );
      }
      return;
    }
    tempImgLink.click();
  };

  const sendCanvasToChat = async ({ isEnd = false }) => {
    if (!conversation) {
      alert("이미지 전송이 불가능합니다. 잠시 후 다시 시도해주세요.");
      return;
    }

    const canvasSave = document.getElementById("canvas");
    const d = canvasSave.toDataURL("image/png");
    await shareCanvasToChat(d).then(async (response) => {
      if (isEnd) {
        await saveImageToDevice({ dataUrl: d });
        await conversation?.sendMessage(PaletteMessage.EndPalette);
      }

      setTimeout(() => {
        closePalette({ isEnd });
      }, 1000);
    });
  };

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const type = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const typedArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      typedArray[i] = byteString.charCodeAt(i);
    }

    return new Blob([arrayBuffer], { type: type });
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    if (
      window.confirm(
        "지금까지 그린 내용이 모두 삭제됩니다. 정말 삭제하시겠습니까?"
      )
    ) {
      var ctx = canvas.getContext("2d");
      var cnvs = document.getElementById("canvas");

      ctx.clearRect(0, 0, cnvs.width, cnvs.height);
      ctx.beginPath();
    }
  };

  const eraser = (data) => {
    if (data === "white" && lineColor === "white") {
      return;
    }
    setPrevious(lineColor);
    setNowLineWidth(lineWidth);
    setLineColor("white");
    if (lineWidth !== 10) {
      setNowLineWidth(lineWidth);
      setLineWidth(10);
    }
  };

  const pencil = () => {
    setLineWidth(nowLineWidth);
    setLineColor(previous);
  };

  useEffect(() => {
    if (lineColor === "white") return;
    setLineWidth(nowLineWidth);
  }, [lineColor]);

  return (
    <Wrapper className="paletteSec">
      <div style={{ display: "flow-root" }}>
        <button className="paletteClose" onClick={quitPalette}>
          <X className="paletteClose" />
        </button>
        <button
          className="btn_palette"
          onClick={() => sendCanvasToChat({ isEnd: true })}
        >
          전송 및 종료
        </button>
        <button className="btn_palette" onClick={sendCanvasToChat}>
          중간공유
        </button>
        {/* <button className="btn_palette" onClick={clearCanvas}>
          지우기
        </button> */}
      </div>
      <div className="drawArea">
        <Menu
          lineOpacity={lineOpacity}
          setLineColor={setLineColor}
          setLineWidth={setLineWidth}
          setLineOpacity={setLineOpacity}
          clearCanvas={clearCanvas}
          setModal={setModal}
          eraser={eraser}
          pencil={pencil}
        />
        <div className="canvasSection">
          <canvas
            id="canvas"
            onMouseDown={startDrawing}
            onMouseUp={endDrawing}
            onMouseMove={draw}
            ref={canvasRef}
            width={isPC ? "409px" : window.innerWidth * 0.9}
            height={window.innerHeight * 0.75}
          />
        </div>
      </div>
      <div className={modal ? "paletteColor" : null}>
        {modal === true ? (
          <PaletteColor
            setLineColor={setLineColor}
            setLineWidth={setLineWidth}
            setLineOpacity={setLineOpacity}
            setModal={setModal}
          />
        ) : null}
      </div>
      <LoadingBar isLoading={isLoading} />
    </Wrapper>
  );
};
export default Palette;

const Wrapper = styled.section`
  width: 100%;
  position: fixed;
  top: 0;
  margin: 0;
  z-index: 9001;
  background-color: #fff;
  bottom: 0;
  padding-top: 15px;

  & > button {
    position: absolute;
    top: 20px;
    right: 0;
    z-index: 999;
  }
`;
