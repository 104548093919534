import styled from "styled-components";
import { ReactComponent as ChatSVG } from "assets/svg/chat.svg";
import { ReactComponent as PhoneSVG } from "assets/svg/phone.svg";
import { ReactComponent as UserSVG } from "assets/svg/user.svg";
import { ReactComponent as VideoSVG } from "assets/svg/video.svg";
import { ReactComponent as StarSVG } from "assets/svg/star.svg";
import { ReactComponent as RecommentStarSVG } from "assets/svg/recommend_star.svg";

const DoctorOverview = ({
  counsel_kind,
  counsels,
  name,
  img,
  onClick,
  introduction,
  star,
  reviews_count,
  recommend,
  order,
}) => {
  const prices = Object.values(counsel_kind).filter((price) => price > 0);
  return (
    <Wrapper onClick={onClick}>
      <BodySection>
        <div style={{ display: "inline-block", width: "27.5%" }}>
          <PhotoSection $image={img} prices={prices.length} />
        </div>

        <div style={{ display: "inline-block", width: "72.5%" }}>
          <ProfileSection>
            <Name>
              <span>{name}</span>
            </Name>
            {recommend ? (
              <Recommend>
                <RecommentStarSVG style={{ marginRight: "2px" }} />
                추천
              </Recommend>
            ) : (
              <></>
            )}
            <Clinic>{introduction}</Clinic>
            <Counsels>
              {counsels.length > 0 && (
                <>• {counsels.map((c) => c.subject).join(", ")}</>
              )}
            </Counsels>
            {prices.length > 0 && (
              <Price>
                {order === "max_price"
                  ? Math.max(...prices).toLocaleString()
                  : Math.min(...prices).toLocaleString()}
                {/* {Math.min(...prices).toLocaleString()} */}
                원~
              </Price>
            )}
          </ProfileSection>
        </div>
      </BodySection>
      <FooterSection>
        <KindSection>
          {counsel_kind.chat > 0 && (
            <div>
              <ChatSVG style={{ marginTop: "2px" }} />
            </div>
          )}
          {counsel_kind.video > 0 && (
            <div>
              <VideoSVG style={{ marginTop: "1px" }} />
            </div>
          )}
          {counsel_kind.visit > 0 && (
            <div>
              <UserSVG />
            </div>
          )}
          {counsel_kind.voice > 0 && (
            <div>
              <PhoneSVG />
            </div>
          )}
        </KindSection>
        {star > 0 && (
          <StarSection>
            <StarSVG />
            <span className="star">{star.toFixed(1)}</span>
            <span className="reviews">({reviews_count})</span>
          </StarSection>
        )}
      </FooterSection>
    </Wrapper>
  );
};
export default DoctorOverview;

const Wrapper = styled.section`
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 15px 0px;
  border: 1px solid #e6e6e6;
  width: 100%;
  height: 219px;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border-radius: 15px;
  padding: 24px 16px 18px 16px;
`;
const PhotoSection = styled.div`
  width: 92px;
  height: 92px;
  border-radius: 15pt;
  background: #fff;
  background-image: url(${(p) => p.$image});
  background-size: 100% 100%;
  border: solid 1px #e6e6e6;
`;
const ProfileSection = styled.div`
  flex-grow: 1;
  margin-top: 10px;
  margin-left: 15pt;
`;
const Name = styled.div`
  font: normal normal bold 20px/24px Pretendard;
  letter-spacing: -0.3px;
  color: #101010;
  display: inline-block;

  &::after {
    content: "상담사";
    font: normal normal 400 14px/17px Pretendard;
    letter-spacing: -0.21px;
    color: #6e6e6e;
    margin-left: 6px;
  }
`;
const Clinic = styled.div`
  margin-top: 13px;
  font: normal normal 500 14px/16px Pretendard;
  letter-spacing: -0.2px;
  color: #101010;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
`;
const Counsels = styled.div`
  margin-top: 12px;
  font: normal normal 500 14px/16px Pretendard;
  letter-spacing: -0.2px;
  color: #9f9f9f;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
`;

const KindSection = styled.div`
  display: flex;

  & > div {
    /* width: 15px;
    height: 15px; */
    /* padding-right: 12px; */
    &:first-child {
      padding-right: 10px;
    }
  }

  & > div + div {
    padding-left: 10px;
    padding-right: 10px;
    border-left: solid 1px #e6e6e6;

    /* margin-left: 12px;
    padding-left: 12px; */
  }
`;

const FooterSection = styled.div`
  width: 100%;
  padding-top: 18px;
  /* padding: 19px 0; */
  display: flex;
  justify-content: space-between;
`;

const BodySection = styled.div`
  display: flex;
  padding-bottom: 18px;
  border-bottom: 1px solid #e6e6e6;
`;

const Price = styled.div`
  text-align: right;
  margin-top: 11px;
  font: normal normal 600 16px/19px Pretendard;
  letter-spacing: -0.24px;
  color: #101010;
`;

const StarSection = styled.div`
  display: flex;
  align-items: center;

  & > span.star {
    font: normal normal 600 14px/17px Pretendard;
    letter-spacing: -0.14px;
    margin-left: 4px;
  }
  & > span.reviews {
    font: normal normal 400 14px/17px Pretendard;
    letter-spacing: -0.14px;
    color: #999999;
    margin-left: 4px;
  }
`;

const Recommend = styled.div`
  font-family: Semibold;
  display: inline-block;
  margin-left: 8px;
  background-color: #edf1ff;
  padding: 3.5px 11px;
  border: solid 1px #91a7ff;
  color: #485af3;
  border-radius: 15px;
  font-size: 12px;
  font-weight: 650;
`;
