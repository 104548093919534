import styled from "styled-components";
import PropTypes from "prop-types";

import ArrowSVG from "assets/svg/chevron-left.svg";
import { ReactComponent as ArrowRightSVG } from "assets/svg/chevron-right.svg";
import useNavigate from "hooks/useNavigate";

const LoginFooter = ({
  message = "상담사 회원이신가요?",
  linkLabel = "상담사 로그인",
  onClick,
}) => {
  const { goCompanyInfo, goDoctorJoin } = useNavigate();

  return (
    <>
      <Wrapper
        style={{
          display: `${message === "상담사이신가요?" ? "flex" : ""}`,
          textAlign: `${message !== "상담사이신가요?" ? "center" : ""}`,
        }}
      >
        <Message>{message}</Message>{" "}
        {linkLabel === "상담사 로그인" ? (
          <Link onClick={onClick}>{linkLabel}</Link>
        ) : (
          <>
            <LoginButton
              onClick={() => {
                goDoctorJoin("general");
              }}
            >
              심리상담사 가입
              <ArrowRightSVG style={{ float: "right", marginTop: "1px" }} />
            </LoginButton>
            <LoginButton
              onClick={() => {
                goDoctorJoin("art");
              }}
            >
              미술심리상담사 가입
              <ArrowRightSVG style={{ float: "right", marginTop: "1px" }} />
            </LoginButton>
          </>
        )}
      </Wrapper>
      <CompanyInfo onClick={goCompanyInfo}>
        임팩트스테이션(주) 사업자 정보
      </CompanyInfo>
    </>
  );
};
export default LoginFooter;

LoginFooter.prototype = {
  message: PropTypes.string,
  linkLabel: PropTypes.string,
  linkUrl: PropTypes.string,
};

const Wrapper = styled.section`
  margin-top: 50px;
  width: 100%;
  font: normal normal normal 14px/17px Pretendard;
  letter-spacing: -0.21px;
  justify-content: center;
`;
const Message = styled.div`
  color: #999999;
`;
const Link = styled.div`
  color: #333333;
  margin-left: 4px;
  font-weight: 650;

  /* &::after {
    display: inline-block;
    content: "";
    width: 5px;
    height: 10px;
    margin-left: 8px;
    background: transparent url(${ArrowSVG}) 100% 100% no-repeat padding-box;
  } */
`;
const CompanyInfo = styled.div`
  margin-top: 30px;
  width: fit-content;
  border: 1px solid #efefef;
  border-radius: 5px;
  font: normal normal 500 14px/17px Pretendard;
  letter-spacing: -0.21px;
  color: #9d9d9d;
  padding: 7px 26px;
`;

const LoginButton = styled.div`
  display: inline-block;
  width: 176px;
  border: solid 1px #e6e6e6;
  padding: 16px;
  margin-right: 8px;
  border-radius: 5px;
  font-weight: 600;
  margin: 8px 8px 0 0;
`;
