import { useCallback, useEffect, useState, useMemo } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Client } from "@twilio/conversations";
import qs from "qs";

import { HomeLayout, MyLayout, SubPageLayout } from "containers/Layout";
import HomePage from "pages/HomePage";
import MyinfoPage from "pages/MyinfoPage";
import CompanyInfo from "containers/CompanyInfo";
import ConsultingRoom from "containers/ConsultingRoom";
import ConsultingRooms from "containers/ConsultingRooms";
import Doctor from "containers/Doctor";
import DoctorReservation from "containers/DoctorReservation";
import Doctors from "containers/Doctors";
import Join from "containers/Join";
import DoctorInfo from "containers/Join/DoctorInfo";
import DoctorJoinRequest from "containers/Join/DoctorJoinRequest";
import Login from "containers/Login";
import Payment from "containers/Payment";
import Partner from "containers/Partner";
import PartnerSuccess from "containers/PartnerSuccess";
import RegistrationPayment from "containers/Payment/RegistrationPayment";
import Profile from "containers/Profile";
import Reservation from "containers/Reservation";
import ReservationPayment from "containers/Reservation/ReservationPayment";
import DoctorProfile from "containers/Profile/DoctorProfile";
import Password from "containers/Profile/Password";
import MyReservation from "containers/MyReservation";
import MyReservationPaymentInfo from "containers/MyReservationPaymentInfo";
import MyReservationPaymentRefund from "containers/MyReservationPaymentRefund";
import ReviewForm from "containers/ReviewForm";
import Search from "containers/Search";
import Terms from "containers/Terms";
import Withdraw from "containers/Withdraw";
import PasswordReset from "containers/PasswordReset";
import PasswordResetRequest from "containers/PasswordResetRequest";
import Palette from "containers/Palette";

import useAuth from "hooks/useAuth";
import useTwilioToken from "hooks/twilio/useTwilioToken";

import { checkDeviceOS, MobileOS, getAppInfo } from "utils/checkMobile";

import "./reset.css";
import {
  friendlyName,
  twilioTokenState,
  userState,
  userTokenState,
} from "atomStates";

import TagManager from "react-gtm-module";

// 컨텐츠
import ContentsHome from "./components/Contents/ContentsHome";
import Gallery from "./components/Contents/Gallery";
import Examine from "./components/Contents/Examine";

// 알림
import Notification from "./components/Notification/Notification";
import NotificationSettings from "./components/Notification/NotificationSettings";
import Contents from "components/Contents/Contents";
import ExamineStep from "components/Contents/ExamineStart";

import { CHAT_RESERVED_WORD } from "./utils/constant";
import CallRequestModal from "components/ConsultingRoom/CallRequestModal";
import ExamineResults from "components/Contents/ExamineResults";

export const HOME_PATH = "/";
export const CONSULTING_ROOM_PATH = "/room";
export const DOCTOR_PATH = "/doctor";
export const INFO_PATH = "/info";
export const SEARCH_PATH = "/search";
export const JOIN_PATH = "/join";
export const RESET_PATH = "/reset"; // 비밀번호 초기화
export const MYINFO_PATH = "/myinfo";
export const CONETNTS_PATH = "/contents";

const CounselType = {
  video: "화상",
  text: "채팅",
  voice: "전화",
  visit: "대면",
};

function App() {
  const deviceOS = useMemo(() => checkDeviceOS(), []);
  const { settingUser, userType } = useAuth();
  const token = useRecoilValue(twilioTokenState);
  const setUserState = useSetRecoilState(userTokenState);

  const [client, setClient] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [conversation, setConversation] = useState(null);
  const [isChatScroll, setChatScroll] = useState(false);
  const [callModalInfo, setCallModalInfo] = useState({});
  const [isOpenCallModal, setOpenCallModal] = useState(false);
  const [user, setUser] = useRecoilState(userState);
  const { sendFcmToken } = useAuth();

  const { isMinddrawingApp } = getAppInfo();
  const { generateToken } = useTwilioToken();

  const sendWelcomeMessage = async ({
    conversation,
    counselType,
    untilAt,
    date,
    times,
  }) => {
    let array = [...times];
    array.sort();
    try {
      if (counselType && untilAt && date && times) {
        const message01 = `반갑습니다.\n
          신청하신 상담권은\n
          • ${CounselType[counselType] ?? ""} 상담 | 50분\n
          • 유효 기간 | ${untilAt}까지`;

        const message02 = `내담자님이 상담을 원하는 요일과 시간입니다.\n
          • 요일 | ${date}\n
          • 시간 | ${array.map((v) => {
            return `${v}`;
          })}\n
          상담사님과 상의 후 예약 시간이 확정됩니다.`;

        await conversation
          .sendMessage(`${CHAT_RESERVED_WORD}001`)
          .catch(async (e) => {
            console.log({ e });
            await conversation.sendMessage(`${CHAT_RESERVED_WORD}001`);
          });

        await conversation.sendMessage(message01).catch(async (e) => {
          console.log({ e });
          await conversation.sendMessage(message01);
        });

        await conversation.sendMessage(message02).catch(async (e) => {
          console.log({ e });
          await conversation.sendMessage(message02);
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const rollbackChatScrollState = () => {
    setChatScroll(false);
  };

  const createConversation = async (
    friendlyName,
    uniqueName,
    counsel_kind,
    until_at,
    date,
    times
  ) => {
    if (!client) return;

    try {
      const newConversation = await client.createConversation({
        friendlyName,
        uniqueName,
      });

      if (newConversation) {
        // 채팅멤버 추가
        const identities = uniqueName.split("-");
        await identities.forEach((member) => {
          newConversation.add(member);
        });

        await sendWelcomeMessage({
          conversation: newConversation,
          counselType: counsel_kind,
          untilAt: until_at,
          date,
          times,
        });
      }
    } catch (e) {
      if (e?.body?.code === 50353) {
        // Conversation with provided unique name already exists
        const newConversation = conversations.find(
          (c) => c.uniqueName === uniqueName
        );
        await sendWelcomeMessage({
          conversation: newConversation,
          counselType: counsel_kind,
          untilAt: until_at,
          date,
          times,
        });
      } else {
        console.log({ e });
      }
    }
  };

  const generateClient = useCallback(async () => {
    if (!token) return;

    const conversationClient = new Client(token);
    conversationClient.on("stateChanged", (state) => {
      console.log("connecting twilio...", state);

      if (state === "initialized") {
        setClient(conversationClient);
      }
    });
  }, [token]);

  const getConversations = useCallback(async () => {
    if (client) {
      try {
        client.getSubscribedConversations().then((conversations) => {
          if (conversations.items.length > 0) {
            const validConversations = conversations.items
              .filter(
                (conversation) =>
                  conversation.channelState.state.current === "active" &&
                  !!conversation.uniqueName &&
                  conversation.uniqueName.includes("doctor")
              )
              .sort(function (a, b) {
                const aDate = a.channelState.lastMessage?.dateCreated;
                const bDate = b.channelState.lastMessage?.dateCreated;
                if (aDate < bDate) return 1;
                if (aDate === bDate) return 0;
                return -1;
              });
            setConversations(validConversations);
          }
        });
      } catch (e) {
        console.error("Get conversations error : ", e);
        window.location.reload();
      }

      client.on("conversationAdded", (conversation) => {
        setConversations((prev) => [...prev, conversation]);
      });

      // client.on("conversationJoined", (conversation) => {
      //   console.log("conversationJoined", {conversation})

      // });

      // client.on("conversationRemoved", async (conversation) => {
      //   console.log("conversationRemoved", {conversation})

      // });
      // client.on("messageAdded", async (message) => {
      //   console.log("messageAdded", {message})

      // });
      // client.on("participantLeft", async (participant) => {
      //   console.log("participantLeft", {participant})

      // });
      // client.on("participantUpdated", async (event) => {
      //   console.log("participantUpdated", {event})

      // });
      // client.on("participantJoined", async (participant) => {
      //   console.log("participantJoined", {participant})

      // });
      // client.on("conversationUpdated", async ({ conversation }) => {
      //   console.log("conversationUpdated", {conversation})

      // });

      // client.on("messageUpdated", async ({ message }) => {
      //   console.log("messageUpdated", {message})
      // });

      // client.on("messageRemoved", async (message) => {
      //   console.log("messageRemoved", {message})

      // });

      client.on("tokenAboutToExpire", async () => {
        console.error("tokenAboutToExpire");
        window.location.reload();
      });

      client.on("tokenExpired", async () => {
        console.error("tokenExpired");
        window.location.reload();
      });

      // client.on("connectionStateChanged", (state) => {
      //   console.log("connectionStateChanged", {state})
      // });
    }
  }, [client]);

  const setConversationParticipants = useCallback(async () => {
    if (!conversation) return;

    await conversation.uniqueName
      .split("-")
      .map((member) => conversation.add(member));
  }, [conversation]);

  // twilio 토큰 준비
  useEffect(() => {
    generateToken();
  }, [generateToken]);

  useEffect(() => {
    settingUser();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (isMinddrawingApp) {
      window.history.pushState({}, "", window.location.href);
    }

    const queryObject = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    if (!isMinddrawingApp && queryObject?.open_app === "1") {
      const encodedUrl = encodeURIComponent(window.location.href);
      window.open(`minddrawing://home?url=${encodedUrl}`);
    }
  }, [isMinddrawingApp]);

  const moveVideoCall = useCallback(() => {
    window.location.href = callModalInfo.url
      ? `${callModalInfo.url}?video_on=1`
      : "/room";
    setOpenCallModal(false);
  }, [callModalInfo.url]);

  const closeCallModal = useCallback(() => {
    setOpenCallModal(false);
  }, []);

  useEffect(() => {
    const fetchTokenInfo = (e) => {
      if (typeof e.data === "string") {
        const appMessageInfo = JSON.parse(e.data);

        if (appMessageInfo.action) {
          if (appMessageInfo.action === "CHAT_SCROLL") {
            setChatScroll(true);
          }

          if (appMessageInfo.action === "TRIGGER_CALLMODAL") {
            const { title, body, profileImg, url } = appMessageInfo;
            setCallModalInfo({ title, body, profileImg, url });
            setOpenCallModal(true);
          }
        }

        if (appMessageInfo.fcmToken) {
          setUserState(appMessageInfo.fcmToken);
        }

        if (appMessageInfo.webviewUrl) {
          window.location.href = appMessageInfo.webviewUrl;
        }
      }
    };

    if (deviceOS === MobileOS.android) {
      document.addEventListener("message", fetchTokenInfo);
    } else {
      window.addEventListener("message", fetchTokenInfo);
    }

    if (isMinddrawingApp) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "FETCH_TOKEN",
        })
      );
    }

    return () => {
      if (deviceOS === MobileOS.android) {
        document.removeEventListener("message", fetchTokenInfo);
      } else {
        window.removeEventListener("message", fetchTokenInfo);
      }
    };
  }, [deviceOS, isMinddrawingApp, setUserState]);

  useEffect(() => {
    generateClient();
  }, [generateClient]);

  useEffect(() => {
    getConversations();
  }, [getConversations]);

  useEffect(() => {
    setConversationParticipants();
  }, [setConversationParticipants]);

  // GTM settings
  const tagManagerArgs = {
    gtmId: "GTM-MZHHDB4",
  };

  TagManager.initialize(tagManagerArgs);

  TagManager.dataLayer({
    dataLayer: {
      event: "middrawing UA",
    },
  });

  useEffect(() => {
    if (!user) {
      setConversations([]);
      return;
    }
    sendFcmToken(user);
  }, [sendFcmToken, user]);

  return (
    <>
      {isOpenCallModal && (
        <CallRequestModal
          profileImg={callModalInfo.profileImg}
          title={callModalInfo.title}
          apporveVideoCall={moveVideoCall}
          declineVideoCall={closeCallModal}
        />
      )}
      <BrowserRouter>
        <Routes>
          <Route path={HOME_PATH}>
            <Route path={HOME_PATH} element={<HomeLayout />}>
              <Route index element={<HomePage />} />
            </Route>

            <Route path={INFO_PATH} element={<SubPageLayout />}>
              <Route index element={<CompanyInfo />} />
              <Route path={`terms`} element={<Terms />} />
            </Route>

            <Route path={SEARCH_PATH} element={<Search />} />

            <Route path={DOCTOR_PATH} element={<SubPageLayout />}>
              <Route index element={<Doctors />} />
              <Route path={`:id`}>
                <Route index element={<Doctor />} />
                <Route path={`reservation`}>
                  <Route index element={<Reservation />} />
                  <Route
                    path={`payment`}
                    element={
                      <ReservationPayment
                        onCreateConversation={createConversation}
                      />
                    }
                  />
                </Route>
                <Route path="review" element={<ReviewForm />} />
              </Route>
            </Route>

            <Route
              path={CONSULTING_ROOM_PATH}
              element={<SubPageLayout conversation={conversation} />}
            >
              <Route
                index
                element={
                  <ConsultingRooms
                    conversations={conversations}
                    onSelectConversation={setConversation}
                    fetchConversations={getConversations}
                  />
                }
              />
              <Route path={`:roomId`}>
                <Route
                  index
                  element={
                    <ConsultingRoom
                      twilioIdentity={client?.user.identity}
                      conversations={conversations}
                      onLeaveRoom={() => setConversation(null)}
                      isChatScroll={isChatScroll}
                      rollbackChatScrollState={rollbackChatScrollState}
                    />
                  }
                />
                <Route
                  path={"palette"}
                  element={<Palette conversations={conversations} />}
                />
              </Route>
            </Route>

            <Route path={JOIN_PATH} element={<SubPageLayout />}>
              <Route index element={<Join />} />
              <Route path={"doctor-info"} element={<DoctorInfo />} />
              <Route path={"doctor-request"} element={<DoctorJoinRequest />} />
            </Route>

            <Route path={MYINFO_PATH} element={<MyLayout />}>
              <Route index element={<MyinfoPage />} />
              <Route path="login" element={<Login />} />
              <Route path="reservation">
                <Route
                  index
                  element={
                    <MyReservation
                      conversations={conversations}
                      onCreateConversation={createConversation}
                      onSelectConversation={(conversation) =>
                        setConversation(conversation)
                      }
                    />
                  }
                />
                <Route
                  path="payment/:reservationId"
                  element={<MyReservationPaymentInfo />}
                />
                <Route
                  path="payment/:reservationId/refund"
                  element={<MyReservationPaymentRefund />}
                />
              </Route>
              <Route path="payment-method">
                <Route index element={<Payment />} />
                <Route path="register" element={<RegistrationPayment />} />
              </Route>
              <Route path="partner">
                <Route index element={<Partner />} />
                <Route path="success" element={<PartnerSuccess />} />
              </Route>
              <Route path="profile">
                <Route index element={<Profile />} />
                <Route path="password" element={<Password />} />
              </Route>
              <Route
                path={"notificationSettings"}
                element={<NotificationSettings />}
              />
              <Route path="doctor">
                <Route path="reservation" element={<DoctorReservation />} />
                <Route path="profile" element={<DoctorProfile />} />
              </Route>
              {/* 탈퇴하기 */}
              <Route path="withdraw" element={<Withdraw />} />
            </Route>

            <Route path={RESET_PATH} element={<SubPageLayout />}>
              <Route path={":token/:email"} element={<PasswordReset />} />
              <Route path={"request"} element={<PasswordResetRequest />} />
            </Route>
            {/* 콘텐츠 */}
            <Route path={HOME_PATH} element={<SubPageLayout />}>
              <Route path={"/contents"} element={<ContentsHome />} />
              <Route path={"/contents/gallery"} element={<Gallery />} />
            </Route>
            {/* 알림설정 */}
            <Route path={HOME_PATH} element={<SubPageLayout />}>
              <Route
                path={"/notificationSettings"}
                element={<NotificationSettings />}
              />
              <Route path={"/notification"} element={<Notification />} />
            </Route>
          </Route>
          <Route path={CONETNTS_PATH} element={<SubPageLayout />}>
            <Route path={"examine"} element={<Contents />} />
            <Route path={"examine/start"} element={<Examine />} />
            <Route path={"examine/step"} element={<ExamineStep />} />
            <Route path={"examine/results"} element={<ExamineResults />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
