import { useEffect, useState } from "react";
import styled from "styled-components";
import ErrorSVG from "assets/svg/field-error.svg";

const ExamineStep2 = ({ setResults, results, nextStep }) => {
  const [disabled, setDisabled] = useState(true);
  const [selectValue, setSelectValue] = useState();
  const year = new Date().getFullYear();
  const years = Array.from(new Array(100), (val, index) => year - index);

  return (
    <>
      <div style={{ margin: "0 auto" }}>
        <Select
          className="ExamineStep2_Select"
          required
          onChange={(e) => {
            setSelectValue(e.target.value);
            setDisabled(false);
          }}
        >
          <option value="" disabled selected>
            연도 선택
          </option>
          {years.map((v, i) => (
            <option value={v} key={i}>
              {v}
            </option>
          ))}
        </Select>
        <Button
          disabled={disabled}
          onClick={() => {
            setResults({ ...results, birth_year: selectValue });
            nextStep();
          }}
        >
          확인
        </Button>
      </div>
      {disabled ? (
        <Error className="ExamineStep2_Error">출생연도를 선택해 주세요.</Error>
      ) : (
        <></>
      )}
    </>
  );
};

export default ExamineStep2;

const Select = styled.select`
  height: 48px;
  width: 325px;
  border: solid 1px #e6e6e6;
  margin-right: 8px;
`;

const Button = styled.button`
  width: 54px;
  height: 48px;
  border-radius: 5px;
  background-color: #333333;
  color: #ffffff;

  &:disabled {
    background-color: #bebebe;
    color: #ffffff;
  }
`;

const Error = styled.div`
  width: 387px;
  margin: 0 auto;
  display: inline-block;
  position: relative;
  margin-bottom: 12px;
  font: normal normal 500 13px/16px Pretendard;
  letter-spacing: -0.13px;
  color: #ed5d4e;
  padding-left: 22px;

  &::before {
    position: absolute;
    left: 0;
    content: "";
    width: 16px;
    height: 16px;
    background: url(${ErrorSVG});
  }
`;
