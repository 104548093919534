import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

import useDoctors from "hooks/useDoctors";

import useNavigate from "hooks/useNavigate";
import useIntersect from "hooks/useIntersect";
import { DoctorOverview } from "components/Doctor";

import SelectArrow from "assets/svg/Select_Arrow.svg";

import { useEffect, useState } from "react";

const Doctors = () => {
  const { goDoctor } = useNavigate();
  const [searchParams] = useSearchParams();
  const counsel_id = searchParams.get("counsel_id");
  const counsel = searchParams.get("counsel");
  const query = searchParams.get("query");
  const [order, setOrder] = useState("recommend");
  const [callDoctorsCnt, setCallDoctorsCnt] = useState(0);
  const [filterDoctors, setFilterDoctors] = useState([]);

  const params = new URLSearchParams(window.location.search);

  let { doctors, isFetching, hasNextPage, setNextPage } = useDoctors(
    counsel_id,
    "",
    order,
    query
  );
  const scrollRef = useIntersect(async (entry, observer) => {
    observer.unobserve(entry.target);
    if (hasNextPage && !isFetching) {
      setNextPage();
    }
  });
  const sortFilter = [];
  sortFilter[0] = { value: "recommend", key: "recommend", label: "추천 순" };
  sortFilter[1] = {
    value: "min_price",
    key: "min_price",
    label: "낮은 가격 순",
  };
  sortFilter[2] = {
    value: "max_price",
    key: "max_price",
    label: "높은 가격 순",
  };
  sortFilter[3] = { value: "star", key: "star", label: "별점 높은 순" };
  const handleSelect = (e) => {
    setOrder(e.target.value);
  };

  useEffect(() => {
    if (doctors.length <= 0) {
      setCallDoctorsCnt(callDoctorsCnt + 1);
      return;
    }
    setFilterDoctors([]);
    if (params.get("type") === null) {
      setFilterDoctors(doctors);
    } else if (params.get("type") === "general") {
      doctors.map((doctor) => {
        if (
          doctor.doctor_type === "일반상담" ||
          doctor.name === "박은미" ||
          doctor.name === "이민경" ||
          doctor.name === "김가현"
        ) {
          setFilterDoctors((prev) => [...prev, doctor]);
        }
      });
    } else if (params.get("type") === "art") {
      doctors.map((doctor) => {
        if (doctor.doctor_type === "미술상담") {
          setFilterDoctors((prev) => [...prev, doctor]);
        }
      });
    }
  }, [doctors]);

  return (
    <Wrapper>
      <SelectBox>
        <Select onChange={handleSelect} value={order}>
          {sortFilter.map((item) => (
            <option value={item.value} key={item.key}>
              {item.label}
            </option>
          ))}
        </Select>
      </SelectBox>

      {(counsel || query) && <Query>'{counsel || query}'</Query>}
      <DoctorList>
        {filterDoctors.map((doctor) => (
          <DoctorOverview
            key={doctor.id}
            {...doctor}
            order={order}
            onClick={() => goDoctor(doctor.id)}
          />
        ))}
        {callDoctorsCnt === 2 && filterDoctors.length === 0 ? (
          <div style={{ textAlign: "center", marginTop: "250px" }}>
            <p style={{ fontSize: "340%" }}>😥</p>
            <Contents>검색 결과가 없습니다.</Contents>
          </div>
        ) : (
          <></>
        )}
      </DoctorList>
      <ScrollTarget ref={scrollRef} />
    </Wrapper>
  );
};
export default Doctors;

const Wrapper = styled.section`
  // padding: 0 15px;
  // height: 100%;
  overflow-y: auto;
`;
const Query = styled.div`
  font: normal normal 600 24px/29px Pretendard;
  color: #91a7ff;
  letter-spacing: -0.48px;
  margin-top: 90px;
  padding-left: 12pt;
  padding-right: 12pt;

  &::after {
    content: "검색 결과";
    font: normal normal 500 20px/29px Pretendard;
    letter-spacing: -0.6px;
    color: #101010;
    margin-left: 4px;
  }
`;
const DoctorList = styled.div`
  height: calc(100% - 83px);
  overflow-y: auto;
  margin-bottom: 61px;
  width: 100%;

  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 12pt;
  padding: 0 12pt 8pt 12pt;
}
`;
const ScrollTarget = styled.div`
  height: 1px;
`;

const Select = styled.select`
  padding: 5pt 0 5pt 7pt;
  float: left;
  font: normal normal 650 12.5px/18px Pretendard;
  letter-spacing: -0.23px;
  color: #101010;
  -webkit-appearance: none; /* for chrome */
  -moz-appearance: none; /*for firefox*/\
  appearance: none;
  background:transparent;
  &:-ms-expand {
    display:none;
  }
`;

const SelectBox = styled.div`
  float: right;
  display: inline-block;
  border-radius: 5px;
  width: 75pt;
  border: solid 1px #efefef;
  background: url(${SelectArrow}) no-repeat 89% 50%/7px auto; //화살표 이미지지정.
  margin: 65pt 12pt 12pt 0;
`;

const Contents = styled.div`
  color: #999999;
  margin-top: 7pt;
  font: normal normal 450 11pt/18px Pretendard;
`;
