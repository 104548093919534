import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { SEARCH_PATH } from "App";
import CIPNG from "assets/images/art_therapy_CI.svg";
import { ReactComponent as SearchSVG } from "assets/svg/search.svg";
import { ReactComponent as RingSVG } from "assets/svg/Ring.svg";

import Navigate from "hooks/useNavigate";
const Header = () => {
  const navigate = useNavigate();
  const { goNotification } = Navigate();

  return (
    <Wrapper id="header" className="headerWrapper">
      <Logo src={CIPNG} alt="logo" />
      <SearchSVG
        style={{ marginLeft: "auto", marginRight: "15pt" }}
        onClick={() => navigate(`${SEARCH_PATH}`)}
      />
      <RingSVG onClick={goNotification} />
    </Wrapper>
  );
};

export default Header;

const Wrapper = styled.div`
  width: 100%;
  height: 52pt !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #efefef;
  opacity: 1;
  flex-shrink: 0;
  padding: 0px 30px 0px 15px;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 999;
`;
const Logo = styled.img`
  height: 24px;
  width: 104px;
  /* background-image: url(${(p) => p.$logo});
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100%; */
`;
