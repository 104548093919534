import client from "./client";

var value = 0;

// 컨텐츠 갤러리 API
export const fetchGalleries = async (id) => {
  if (id !== undefined && id !== null) {
    value = id;
  }
  const galleries = await client.get(`/galleries/${value ? value : "1"}`);
  return galleries;
};
