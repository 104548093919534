import React, { forwardRef } from "react";
import styled from "styled-components";
import MessageBubble from "components/ConsultingRoom/MessageBubble";
import { CHAT_RESERVED_WORD } from "utils/constant";
import ReservedChatMessage from "components/ConsultingRoom/ReservedChatMessage";

const MessageSection = (
  { identity, messages, userType, doctorId, connectVideoCall },
  ref
) => {
  return (
    <Wrapper className="messageSection" ref={ref}>
      {React.Children.toArray(
        messages.map((message) => {
          const { author, sid, body, attachedMedia, dateCreated } = message;

          if (body && body.includes(CHAT_RESERVED_WORD)) {
            return (
              <ReservedChatMessage
                userType={userType}
                doctorId={doctorId}
                message={body}
              />
            );
          }

          return (
            <MessageBubble
              key={sid}
              identity={identity}
              author={author}
              attachedMedia={attachedMedia}
              body={body}
              dateCreated={dateCreated}
              connectVideoCall={connectVideoCall}
            />
          );
        })
      )}
    </Wrapper>
  );
};

export default forwardRef(MessageSection);

const Wrapper = styled.section`
  margin-top: 40pt;
  display: flex;
  flex-direction: column;
  gap: 6px;
  height: 100%;
  overflow-y: auto;
  padding: 30px 15px;

  @media (max-width: 767px) {
    margin-bottom: 15px;
  }
`;
