import styled from "styled-components";

import CheckSVG from "assets/svg/check.svg";
import useNavigate from "hooks/useNavigate";

const DoctorJoinRequest = () => {
  const { goHome } = useNavigate();

  return (
    <Wrapper>
      <div className="Subscription">
        <Icon />
        <Title>
          <span>상담사</span>님의
          <br /> 회원가입이 신청되었습니다.
        </Title>
        <p>
          서비스 담당자의 최종 승인을 거쳐
          <br /> 계정 가입 여부가 결정됩니다.
          <br />
          <br /> 계정이 활성화된 후 서비스를 이용할 수 있습니다.
        </p>
      </div>

      <Footer>
        <Button onClick={goHome}>홈으로</Button>
      </Footer>
    </Wrapper>
  );
};
export default DoctorJoinRequest;

const Wrapper = styled.section`
  height: 100%;
  padding: 10 36px;
  padding-top: 111px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  font: normal normal 400 15px/25px Pretendard;
  letter-spacing: -0.23px;
  color: #404040;
`;

const Icon = styled.div`
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background: #485af3;
  background-image: url(${CheckSVG});
  background-repeat: no-repeat;
  background-position: center;
`;
const Title = styled.div`
  font: normal normal bold 25px/38px Pretendard;
  letter-spacing: -0.25px;
  margin-bottom: 36px;
  margin-top: 16px;

  & > span {
    color: #485af3;
  }
`;
const Footer = styled.section`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;
const Button = styled.button`
  width: 271px;
  height: 48px;
  background: #333333;
  border-radius: 10px;
  font: normal normal 500 16px/19px Pretendard;
  letter-spacing: -0.16px;
  color: #ffffff;
`;
