import { useState } from "react";
import styled from "styled-components";

const ExamineStep5 = ({ setResults, results, setStep, step }) => {
  const [buttonStates, setButtonStates] = useState({
    button1: false,
    button2: false,
    button3: false,
    button4: false,
    button5: false,
    button6: false,
    button7: false,
  });

  const handleMouseEnter = (buttonId) => {
    setButtonStates((prevState) => ({
      ...prevState,
      [buttonId]: true,
    }));
  };

  const handleMouseLeave = (buttonId) => {
    setButtonStates((prevState) => ({
      ...prevState,
      [buttonId]: false,
    }));
  };
  return (
    <>
      <Button
        value="근무 경력 없음"
        hovered={buttonStates.button1}
        onMouseEnter={() => handleMouseEnter("button1")}
        onMouseLeave={() => handleMouseLeave("button1")}
        onTouchStart={() => handleMouseEnter("button1")}
        onTouchEnd={() => handleMouseLeave("button1")}
        onClick={(e) => {
          setResults({ ...results, work_experience: e.target.value });
          setStep(step + 2);
        }}
      >
        근무 경력 없음
      </Button>
      <Button
        value="1년 미만"
        hovered={buttonStates.button2}
        onMouseEnter={() => handleMouseEnter("button2")}
        onMouseLeave={() => handleMouseLeave("button2")}
        onTouchStart={() => handleMouseEnter("button2")}
        onTouchEnd={() => handleMouseLeave("button2")}
        onClick={(e) => {
          setResults({ ...results, work_experience: e.target.value });
          setStep(step + 2);
        }}
      >
        1년 미만
      </Button>
      <Button
        value="1년 이상 ~ 3년 미만"
        hovered={buttonStates.button3}
        onMouseEnter={() => handleMouseEnter("button3")}
        onMouseLeave={() => handleMouseLeave("button3")}
        onTouchStart={() => handleMouseEnter("button3")}
        onTouchEnd={() => handleMouseLeave("button3")}
        onClick={(e) => {
          setResults({ ...results, work_experience: e.target.value });
          setStep(step + 2);
        }}
      >
        1년 이상 ~ 3년 미만
      </Button>
      <Button
        value="3년 이상 ~ 5년 미만"
        hovered={buttonStates.button4}
        onMouseEnter={() => handleMouseEnter("button4")}
        onMouseLeave={() => handleMouseLeave("button4")}
        onTouchStart={() => handleMouseEnter("button4")}
        onTouchEnd={() => handleMouseLeave("button4")}
        onClick={(e) => {
          setResults({ ...results, work_experience: e.target.value });
          setStep(step + 2);
        }}
      >
        3년 이상 ~ 5년 미만
      </Button>
      <Button
        value="5년 이상 ~ 10년 미만"
        hovered={buttonStates.button5}
        onMouseEnter={() => handleMouseEnter("button5")}
        onMouseLeave={() => handleMouseLeave("button5")}
        onTouchStart={() => handleMouseEnter("button5")}
        onTouchEnd={() => handleMouseLeave("button5")}
        onClick={(e) => {
          setResults({ ...results, work_experience: e.target.value });
          setStep(step + 2);
        }}
      >
        5년 이상 ~ 10년 미만
      </Button>
      <Button
        value="19년 이상 ~ 15년 미만"
        hovered={buttonStates.button6}
        onMouseEnter={() => handleMouseEnter("button6")}
        onMouseLeave={() => handleMouseLeave("button6")}
        onTouchStart={() => handleMouseEnter("button6")}
        onTouchEnd={() => handleMouseLeave("button6")}
        onClick={(e) => {
          setResults({ ...results, work_experience: e.target.value });
          setStep(step + 2);
        }}
      >
        10년 이상 ~ 15년 미만
      </Button>
      <Button
        value="15년 이상"
        hovered={buttonStates.button7}
        onMouseEnter={() => handleMouseEnter("button7")}
        onMouseLeave={() => handleMouseLeave("button7")}
        onTouchStart={() => handleMouseEnter("button7")}
        onTouchEnd={() => handleMouseLeave("button7")}
        onClick={(e) => {
          setResults({ ...results, work_experience: e.target.value });
          setStep(step + 2);
        }}
      >
        15년 이상
      </Button>
    </>
  );
};

export default ExamineStep5;

const Button = styled.button`
  font: normal normal 500 11pt/11px Pretendard;
  border: solid 1px #e6e6e6;
  background-color: #ffffff;
  color: #101010;
  margin: 0 10pt -4pt 10pt;
  padding: 14pt 10pt;
  text-align: left;
  border-radius: 5pt;

  &:hover {
    background-color: ${(props) => (props.hovered ? "#8ba8ff" : "#ffffff")};
    color: ${(props) => (props.hovered ? "#ffffff" : "#101010")};
  }

  &:checked {
    background-color: #8ba8ff;
    color: #ffffff;
  }
`;
