import styled from "styled-components";

import CheckSVG from "assets/svg/check.svg";
import EllipseSVG from "assets/svg/ellipse.svg";

const CheckboxField = ({
  label,
  checked,
  onChange,
  onClick,
  defaultChecked,
}) => (
  <Wrapper>
    <Input
      type="checkbox"
      checked={checked}
      onChange={onChange}
      defaultChecked={defaultChecked}
    />
    <Label onClick={onClick}>{label}</Label>
  </Wrapper>
);

export default CheckboxField;

const Wrapper = styled.section``;
const Label = styled.label`
  font: normal normal 500 15px/24px Pretendard;
  letter-spacing: -0.16px;
  color: #101010;
  margin-left: 15px;
  flex-grow: 1;
`;
const Input = styled.input`
  width: 24px;
  height: 24px;
  background-color: #f8f8f8;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #e6e6e6;
  outline: none;
  cursor: pointer;

  appearance: none;
  box-shadow: none;

  &:checked {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    box-shadow: none;
    background-color: #485af3;
    background-image: url(${CheckSVG});
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }
`;
