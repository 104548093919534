import Footer from "containers/Footer";
import useNavigate from "hooks/useNavigate";
import styled from "styled-components";
import { fetchGalleries } from "apis/content";
import { useEffect, useState } from "react";
import { ReactComponent as Left } from "assets/svg/chevron-left.svg";
import { ReactComponent as GalleryImgSVG } from "assets/svg/psychological_test-image.svg";
import psychologicalImage from "assets/images/before_gallery.png";
import { useRecoilState } from "recoil";
import { shareImage } from "atomStates";

import yourPodImage from "../../assets/images/yourpod.png";

const ContentsHome = () => {
  const { goGallery, goExamine } = useNavigate();
  const [imageUrl, setImageUrl] = useState();
  const [shareImages, setShareImages] = useRecoilState(shareImage);
  const [id, Setid] = useState(1);

  useEffect(() => {
    fetchGalleries(id).then((res) => {
      Setid(res.data.id);
      setImageUrl(res.data.img_url);
    });
  }, [, id]);

  const nextPage = (direction) => {
    if (direction === "left") {
      if (id !== 1) {
        Setid(id - 1);
      }
    } else if (direction === "right") {
      if (direction === "right") {
        Setid(id + 1);
      }
    }
  };

  return (
    <Wrapper style={{ height: "auto" }}>
      <Body className="body" style={{ overflow: "hidden" }}>
        <ContentsMenu>기분 맞춤 콘텐츠 추천</ContentsMenu>
        <div
          onClick={() => {
            window.open("https://www.yourpod.kr/choice?type=minddrawing");
          }}
          style={{
            margin: "0 15px 25px 15px",
            height: "390px",
            backgroundImage: `url(${yourPodImage})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            borderRadius: "15pt",
            cursor: "pointer",
          }}
        />
        <ContentsMenu>미술 심리 테스트</ContentsMenu>
        <div
          style={{
            margin: "0 15px 25px 15px",
            height: "390px",
          }}
        >
          <Contents className="contents">
            <Content>
              내가 생각하는 심리 상태와 <br /> 나의 실제 상태는 얼마나 다를까?
            </Content>
            <Title>지금 확인해 보세요!</Title>
            <GalleryImgSVG style={{ margin: "0 auto" }} />
            <Button
              onClick={() => {
                goExamine();
                setShareImages(psychologicalImage);
              }}
            >
              시작하기
            </Button>
          </Contents>
        </div>
        <hr
          style={{
            color: "#F8F8F8",
            height: "12px",
            backgroundColor: "#F8F8F8",
            border: "solid 1px #F8F8F8",
          }}
        />
        <ContentsMenu>힐링 갤러리</ContentsMenu>
        <div
          style={{
            margin: "0 15px 24px 15px",
            height: "390px",
            position: "relative",
          }}
        >
          <Contents
            className="contents"
            style={{
              backgroundImage: `url(${imageUrl})`,
              position: "absolute",
            }}
            onClick={() => {
              goGallery();
              setShareImages(imageUrl);
            }}
          ></Contents>
          <Arrow style={{ left: "10px" }}>
            <Left
              width={30}
              height={30}
              onClick={() => {
                nextPage("left");
              }}
            />
          </Arrow>
          <Arrow style={{ right: "15px" }}>
            <Left
              width={30}
              height={30}
              style={{ transform: "scaleX(-1)" }}
              onClick={() => {
                nextPage("right");
              }}
            />
          </Arrow>
        </div>
      </Body>
    </Wrapper>
  );
};

export default ContentsHome;

const Wrapper = styled.div`
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Body = styled.div`
  height: 100%;
  overflow-y: auto;
  flex-grow: 1;
  padding-top: 70px;
`;

const ContentsMenu = styled.div`
  margin: 25px 10px 10px 14px;
  font: normal normal 600 17.5pt/21pt Pretendard;
  display: inline-block;
`;

const Contents = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  border-radius: 15pt;
  text-align: center;
  background-color: #19191b;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 100% 100%;
  justify-content: space-between;
`;

const Arrow = styled.div`
  filter: invert(100%) sepia(0%) saturate(7492%) hue-rotate(251deg)
    brightness(118%) contrast(100%);
  width: 21pt;
  height: 21pt;
  position: absolute;
  margin-top: 190px;
`;

const Content = styled.div`
  color: #fdf6e7;
  font-size: 14px;
  padding: 36px 0 16px 0;
  line-height: 22px;
`;
const Title = styled.div`
  color: #fdf6e7;
  font-size: 16pt;
  font-weight: 600;
  padding-bottom: 20px;
`;

const Button = styled.button`
  background-color: #333333;
  color: #ffffff;
  margin: 0 auto;
  width: 271px;
  height: 48px;
  border-radius: 10pt;
  margin: 20px 0 24px 0;
`;
