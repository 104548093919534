import { useState } from "react";
import styled from "styled-components";
import moment from "moment";

import { ReactComponent as StarSVG } from "assets/svg/star.svg";
import { ReactComponent as StarEmptySVG } from "assets/svg/star-empty.svg";
import { ReactComponent as ArrowSVG } from "assets/svg/chevron-left.svg";

const Review = ({ title, content, user, at, my, star, onClickEdit }) => {
  const [showContent, setShowContent] = useState(false);

  return (
    <Wrapper>
      <TitleRow>
        <Title>{title}</Title>
        {my && <EditButton onClick={onClickEdit}>수정하기</EditButton>}
      </TitleRow>
      <Row>
        <Stars>
          {[...Array(star).keys()].map((i) => (
            <StarSVG key={i} />
          ))}
          {[...Array(5 - star).keys()].map((i) => (
            <StarEmptySVG key={i} />
          ))}
        </Stars>
        <Name>{user.nickname}</Name>
        <At>{moment(at).format("YY.MM.DD")}</At>
        <ArrowSVG
          style={{
            transform: !showContent ? "rotate(90deg)" : "rotate(270deg)",
          }}
          onClick={() => setShowContent(!showContent)}
        />
      </Row>
      {!showContent && (
        <Content>
          {content.split("\n").map((row, i) => (
            <span key={i}>
              {row}
              <br />
            </span>
          ))}
        </Content>
      )}
    </Wrapper>
  );
};
export default Review;

const Wrapper = styled.section`
  width: 100%;
  padding: 20px 0;

  & + & {
    border-top: 1px solid #efefef;
  }
`;
const Row = styled.div`
  display: flex;
  position: relative;

  & > svg {
    width: 7px;
    transform: matrix(0, 1, -1, 0, 0, 0);
    position: absolute;
    right: 0;
  }
`;
const TitleRow = styled(Row)`
  justify-content: space-between;
  margin-bottom: 3px;
`;
const Title = styled.div`
  font: normal normal 600 17px/20px Pretendard;
  letter-spacing: -0.26px;
  color: #333333;
  margin-bottom: 14px;
`;
const Content = styled.div`
  font: normal normal 400 15px/25px Pretendard;
  letter-spacing: -0.15px;
  color: #101010;
  margin-top: 17px;
`;
const EditButton = styled.button`
  background-color: unset;
  /* width: 44px; */
  height: 16px;
  text-decoration: underline;
  font: normal normal 400 13px/16px Pretendard;
  letter-spacing: -0.2px;
  color: #999999;
`;
const Stars = styled.div`
  padding-right: 12px;
`;
const Name = styled.div`
  font: normal normal 500 14px/17px Pretendard;
  letter-spacing: -0.14px;
  color: #6e6e6e;
  border-left: 1px solid #e6e6e6;
  padding: 0 12px;
  margin: 2px 0;
`;
const At = styled.div`
  font: normal normal 400 14px/17px Pretendard;
  letter-spacing: -0.14px;
  color: #6e6e6e;
  border-left: 1px solid #e6e6e6;
  padding: 0 12px;
  margin: 2px 0;
`;
