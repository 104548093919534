import { Outlet } from "react-router-dom";
import styled from "styled-components";
import Header from "../Header";
import Footer from "../Footer";

const Layout = () => (
  <Wrapper className="mainWrapper">
    <Header />
    <Body className="body">
      <Outlet />
    </Body>
    <Footer />
  </Wrapper>
);

export default Layout;

const Wrapper = styled.div`
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // padding-bottom: 61px;
`;
const Body = styled.div`
  height: 100%;
  overflow-y: auto;
  flex-grow: 1;
  padding-top: 70px;
  position: relative;
`;
