const KEY_USER = "_user";

const _get = (key) => JSON.parse(window.localStorage.getItem(key) || '""');
const _set = (key, value) =>
  window.localStorage.setItem(key, JSON.stringify(value));

export const getUser = () => _get(KEY_USER);
/**
 * 사용자 정보 저장
 * @param {*} user : { email, nickname, active }
 * @returns
 */
export const setUser = (user) => _set(KEY_USER, user);
