import styled from "styled-components";

import { ReactComponent as NewLoaderSVG } from "assets/svg/new-loader.svg";

const LoadingBar = ({ isLoading }) => {
  if (!isLoading) {
    return null;
  }

  return (
    <Loader>
      <NewLoaderSVG width="70" />
    </Loader>
  );
};

const Loader = styled.div`
  position: absolute;
  top: calc(50% - 70px);
  left: calc(50% - 35px);

  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default LoadingBar;
