import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import client from "apis/client";
import useNavigate from "hooks/useNavigate";
import { PASSWORD_DISMATCH_ERROR, PASSWORD_ERROR } from "utils/message";
import { PASSWORD_PATTERN } from "utils/regex";

import InputField from "components/Form/InputField";

const PasswordReset = () => {
  const { token, email } = useParams();
  const { goLogin } = useNavigate();
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");

  const [btnState, setBtnState] = useState(true);

  const handleRequest = async () => {
    if (password !== rePassword) {
      alert(PASSWORD_DISMATCH_ERROR);
      return;
    }
    if (!PASSWORD_PATTERN.test(password)) {
      alert(PASSWORD_ERROR);
      return;
    }

    try {
      await client.post("/user/password/confirm", {
        email,
        token,
        new_password: password,
      });
      alert("비밀번호가 변경되었습니다.");
      goLogin();
    } catch (e) {
      alert(e.response.data.message);
    }
  };

  useEffect(() => {
    if (password !== "" && password !== "") {
      if (
        PASSWORD_PATTERN.test(password) === PASSWORD_PATTERN.test(rePassword)
      ) {
        setBtnState(false);
      }
    } else {
      setBtnState(true);
    }
  }, [password, rePassword, btnState]);

  return (
    <Wrapper>
      <Title>
        새로운 비밀번호를
        <br /> 설정해 주세요.
      </Title>

      <Info>계정의 비밀번호를 변경합니다.</Info>

      <Fields>
        <InputField
          label="비밀번호"
          type="password"
          placeholder="영문, 숫자, 특수기호 조합 8자리 이상"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />

        <InputField
          label="비밀번호 재확인"
          type="password"
          placeholder="영문, 숫자, 특수기호 조합 8자리 이상"
          value={rePassword}
          onChange={(e) => setRePassword(e.target.value)}
        />
      </Fields>

      <Footer className="resetButton">
        <button disabled={btnState} onClick={handleRequest}>
          비밀번호 변경하기
        </button>
      </Footer>
    </Wrapper>
  );
};
export default PasswordReset;

const Wrapper = styled.section`
  padding: 0 15px;
  padding-top: 36px;

  font: normal normal 400 15px/25px Pretendard;
  letter-spacing: -0.23px;
  color: #404040;
`;
const Title = styled.div`
  font: normal normal 700 20.5pt/44pt Pretendard;
  letter-spacing: -0.2pt;
  color: #101010;
  margin-bottom: 15px;
  margin-top: 55px;
  line-height: 44px;
`;
const Info = styled.div`
  font: normal normal 400 15px/25pt Pretendard;
  letter-spacing: -0.23pt;
  line-height: 24px;
  color: #404040;
  margin-bottom: 32pt;
`;
const Fields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;
const Footer = styled.div`
  width: calc(100% - 30px);
  padding-bottom: 44px;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  // margin-bottom: 29px;

  & > button {
    width: 271px;
    height: 48px;
    background: #333333 0% 0% no-repeat padding-box;
    border-radius: 10px;
    font: normal normal 500 16px/19px Pretendard;
    letter-spacing: -0.16px;
    color: #ffffff;
  }
  & > button:disabled {
    background: rgb(203, 203, 203);
    color: rgb(255, 255, 255);
    font: 500 16px / 19px Pretendard !important;
    border-radius: 10px !important;
  }
`;
