import { examineResult } from "atomStates";
import { useRecoilState } from "recoil";
import styled from "styled-components";

import { ReactComponent as ResultsImage1SVG } from "assets/svg/results_image_1.svg";
import { ReactComponent as ResultsImage2SVG } from "assets/svg/results_image_2.svg";
import { ReactComponent as ResultsImage3SVG } from "assets/svg/results_image_3.svg";
import { ReactComponent as ResultsImage4SVG } from "assets/svg/results_image_4.svg";

import _useNavigate from "hooks/useNavigate";
import { useNavigate } from "react-router-dom";

const ExamineResults = () => {
  const { goGallery } = _useNavigate();
  const navigate = useNavigate();
  const [examineResults, setExamineResults] = useRecoilState(examineResult);
  const ramdomNumber = Math.floor(Math.random() * 4 + 1);
  return (
    <Wapper>
      <Title>지금 내 마음은..</Title>
      <Background length={examineResults.results.length}>
        {examineResults.results !== undefined
          ? examineResults.results.map((value, i) => (
              <Text key={i}>• {value}</Text>
            ))
          : ""}
        {ramdomNumber === 1 ? (
          <ResultsImage1SVG
            width={168}
            height={162}
            style={{ position: "absolute", right: "0", bottom: "0" }}
          />
        ) : ramdomNumber === 2 ? (
          <ResultsImage2SVG
            width={168}
            height={162}
            style={{ position: "absolute", right: "0", bottom: "0" }}
          />
        ) : ramdomNumber === 3 ? (
          <ResultsImage3SVG
            width={168}
            height={162}
            style={{ position: "absolute", right: "0", bottom: "0" }}
          />
        ) : (
          <ResultsImage4SVG
            width={168}
            height={162}
            style={{ position: "absolute", right: "0", bottom: "0" }}
          />
        )}
      </Background>
      <Button
        onClick={() => {
          navigate("/doctor");
        }}
      >
        내게 맞는 상담사 추천
      </Button>
      <Button
        style={{
          color: "#333333",
          backgroundColor: "#ffffff",
          border: "solid 1px #E6E6E6",
        }}
        onClick={() => {
          goGallery();
        }}
      >
        내게 맞는 콘텐츠 찾기
      </Button>
    </Wapper>
  );
};

export default ExamineResults;

const Wapper = styled.div`
  margin-top: 130px;
  text-align: center;
  margin-bottom: 85px;
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: 700;
`;

const Background = styled.div`
  position: relative;
  background-color: #18181b;
  margin: 20px 25px 47px 25px;
  border-radius: 15px;
  height: ${(props) => props.length * 64 + 220}px;
  text-align: left;
`;

const Text = styled.div`
  color: #cbc5ba;
  padding: 30px 15px 0px 15px;
  font-size: 12.5px;
  line-height: 17px;
`;

const Button = styled.button`
  text-align: center;
  margin-bottom: 5px;
  width: 271px;
  height: 48px;
  cursor: pointer;
  background-color: #333333;
  color: #ffffff;
  border-radius: 10px;
  font: 500 16px / 19px Pretendard;
`;
