import styled from "styled-components";

import SadFacePNG from "assets/images/sad-but-relieved-face.png";

const NoReservation = ({ label = "상담 내역" }) => (
  <Wrapper>
    <img src={SadFacePNG} alt="sad-face" />
    <p>{label}이 없습니다.</p>
  </Wrapper>
);

export default NoReservation;

const Wrapper = styled.section`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > p {
    font: normal normal 500 15px/18px Pretendard;
    letter-spacing: -0.23px;
    color: #999999;
    margin-top: 18px;
  }
`;
