import { useEffect, useRef } from "react";
import styled from "styled-components";
import { ReactComponent as X } from "assets/svg/x.svg";

const ImageModal = ({ changeState, mediaURL }) => {
  const ImageClosed = () => {
    changeState(false);
  };
  const imgRef = useRef();
  const closeRef = useRef();

  useEffect(() => {
    if (
      window.innerHeight < imgRef.current.clientHeight ||
      window.innerHeight < imgRef.current.clientHeight
    ) {
      // imageRef style
      imgRef.current.style.width = "100%";
      // imgRef.current.style.height = "100%";
      // closeRef style
      closeRef.current.style.background = "#101010";
      closeRef.current.style.borderRadius = "50%";
      closeRef.current.style.padding = "9px";
      closeRef.current.style.width = "34px";
      closeRef.current.style.height = "34px";
    }
  }, [imgRef, closeRef]);

  return (
    <Modal>
      <img src={mediaURL} ref={imgRef} />
      <Button onClick={ImageClosed}>
        <X ref={closeRef} className="imageModalX" />
      </Button>
    </Modal>
  );
};

const Modal = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 60px);
  z-index: 1000;
  background: #000000;
  height: 100%;
`;

const Button = styled.button`
  right: 0;
  position: fixed;
  width: 60px;
  top: 10px;
  height: 40px;
  padding: 2px 5px;
  font-weight: 300;
  text-shadow: 0 1px 0 #fff;
  font-size: 1.3rem;
  color: #ffffff;
  z-index: 9999;
`;

export default ImageModal;
