import { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { signEdit } from "apis/doctor";
import useNavigate from "hooks/useNavigate";
import useUser from "hooks/useUser";
import Form from "components/Form";

import DoctorInfoStep1 from "./DoctorInfoStep1";
import DoctorInfoStep2 from "./DoctorInfoStep2";

const DoctorInfo = () => {
  const [step, setStep] = useState(1); // 1단계, 2단계에 나누어 정보 입력
  const { goDoctorLogin } = useNavigate();
  const { userParamForUpdate: defaultParams } = useUser();

  const form = useForm();
  const { handleSubmit } = form;
  const onSubmit = async (params) => {
    try {
      if (step === 1) {
        setStep(2);
        window.scrollTo(0, 0);
      } else {
        await signEdit({
          ...defaultParams,
          ...params,
        });
        goDoctorLogin();
      }
    } catch (e) {
      setStep(2);
      // alert(e.response.data.message);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FieldSection>
        {step === 1 ? (
          <DoctorInfoStep1 {...form} />
        ) : (
          <DoctorInfoStep2 {...form} />
        )}
      </FieldSection>

      <Form.SubmitRow style={{ background: "#fff", marginBottom: "24px" }}>
        <Form.SubmitButton type="submit">
          {step === 1 ? "다음으로" : "작성 완료"}
        </Form.SubmitButton>
        {form.errors && (
          <Form.Error>일부 항목 입력이 유효하지 않습니다.</Form.Error>
        )}
      </Form.SubmitRow>
    </Form>
  );
};
export default DoctorInfo;

const FieldSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #f8f8f8 0% 0% no-repeat padding-box;
`;
export const Section = styled.section`
  background: #ffffff;
  padding: 36px 15px 40px 15px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  border-bottom: 1px solid #e6e6e6;

  & + & {
    border-top: 1px solid #e6e6e6;
  }

  & > .title {
    font: normal normal 600 18px/21px Pretendard;
    letter-spacing: -0.27px;
    color: #101010;
  }

  &:last-child {
    border-bottom: none;
  }
`;
export const Message = styled.div`
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border-radius: 10px;
  font: normal normal 500 11px/15px Pretendard;
  letter-spacing: -0.12px;
  color: #6e6e6e;
  padding: 15px 10px;
`;
