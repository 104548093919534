import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import styled from "styled-components";

import Footer from "../Footer";
import Header from "components/Header";
import useAuth from "hooks/useAuth";
import useNavigate from "hooks/useNavigate";

const MyLayout = () => {
  const { userType, settingUser } = useAuth();

  const { goBack, goLogin, goProfile, goMyinfo } = useNavigate();
  const { pathname } = useLocation();

  const isLogin = /login/.test(pathname);
  const isProfile = /profile/.test(pathname);
  const isDoctorProfile = /profile\/doctor/.test(pathname);
  const isPassword = /password/.test(pathname);
  const isPaymentMethod = /payment-method/.test(pathname);
  const isReservation = /reservation/.test(pathname);
  const isPaymentHistory = /history/.test(pathname);
  const isRefund = /refund/.test(pathname);
  const isPartner = /partner/.test(pathname);
  const isWithdraw = /withdraw/.test(pathname);
  const isNotificationSettings = /notificationSettings/.test(pathname);
  const availbleGoBack =
    /withdraw|profile|payment|reservation|history|partner|notificationSettings|(login\?userType=counselor)/.test(
      pathname
    );

  const showFooter =
    !isProfile && !isPaymentMethod && !isPartner && !isRefund && !isWithdraw;

  useEffect(() => {
    if (isLogin) return;
    if (!userType) {
      goLogin();
    } else {
      settingUser();
    }
  }, []); // eslint-disable-line

  return (
    <Wrapper className="mainWrapper">
      <Header
        className="headerWrapper"
        style={{
          borderBottom:
            isReservation && userType === "doctor"
              ? "none"
              : "1px solid #efefef",
        }}
      >
        {availbleGoBack && <Header.GoBackButton onClick={goBack} />}
        <div style={{ paddingTop: "26px" }}>
          {isReservation
            ? "내 상담 내역"
            : isPaymentHistory
            ? "내 결재 내역"
            : isRefund
            ? "환불 정보 확인"
            : isPassword
            ? "비밀번호 변경"
            : isPartner
            ? "제휴기관 멤버십 인증"
            : isProfile
            ? isDoctorProfile
              ? "상담사 회원 수정"
              : "내 정보 수정"
            : isPaymentMethod
            ? /register/.test(pathname)
              ? "카드 등록"
              : "결제 수단 관리"
            : isWithdraw
            ? "탈퇴하기"
            : isNotificationSettings
            ? "알림설정"
            : "내 정보"}
        </div>
      </Header>

      {showFooter ? (
        <>
          <Body>
            <Outlet />
          </Body>
          <Footer />
        </>
      ) : (
        <BodyWithoutFooter>
          <Outlet />
        </BodyWithoutFooter>
      )}
    </Wrapper>
  );
};
export default MyLayout;

const Wrapper = styled.div`
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Body = styled.div`
  height: 100%;
  overflow-y: auto;
  flex-grow: 1;
  padding-top: 69px;
  /* padding-bottom: 120px; */
`;
const BodyWithoutFooter = styled(Body)`
  padding-bottom: 0;
`;
