import styled from "styled-components";

export const Button = styled.button`
  width: 271px;
  height: 48px;
  background: #333333;
  border-radius: 10px;
  font: normal normal 500 16px/19px Pretendard;
  letter-spacing: -0.16px;
  color: #ffffff;
  /* position: absolute;
  bottom: 50px; */
`;
export const CancelButton = styled(Button)`
  background: unset;
  color: #333333;
`;
export const DrawerBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 76px;
`;
export const DrawerFooter = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
