import styled from "styled-components";

import { ReactComponent as CheckSVG } from "assets/svg/check.svg";

const StatusIndicator = ({ currentIdx, statusList }) => (
  <Wrapper>
    <div className="wrapper">
      {statusList.map((status, i) => (
        <div key={i} className={i <= currentIdx ? "active" : ""}>
          <div className="status-line" />
          <div className="status-icon">{i <= currentIdx && <CheckSVG />}</div>
          <label>{status}</label>
        </div>
      ))}
    </div>
  </Wrapper>
);

export default StatusIndicator;

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  & > .wrapper {
    display: flex;
    & > div {
      width: 78px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      & > label {
        font: normal normal 500 14px/17px Pretendard;
        letter-spacing: -0.21px;
        color: #bebebe;
        margin-top: 8px;
      }

      & > .status-icon {
        width: 37px;
        height: 37px;
        background: #ffffff;
        border-radius: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #e6e6e6;
      }

      & > .status-line::before {
        content: "";
        width: 80px;
        height: 1px;
        display: block;
        background-color: #e6e6e6;
        position: absolute;
        right: 20px;
        top: 18px;
        z-index: -999;
      }

      &.active > label {
        color: #333333;
      }
      &.active > .status-icon {
        background: #485af3;
        border: none;
      }

      &.active > .status-line::before {
        background-color: #485af3;
      }

      &:first-child > .status-line::before {
        height: 0;
      }
    }
  }
`;
