import { useState, useRef } from "react";
import { useForm } from "react-hook-form";

import styled from "styled-components";

import { signup } from "apis/user";

import FormSection from "./FormSection";
import PolicySection from "./PolicySection";
import useAuth from "hooks/useAuth";
import useNavigate from "hooks/useNavigate";
import useTwilioToken from "hooks/twilio/useTwilioToken";
import { PASSWORD_DISMATCH_ERROR } from "utils/message";
import { PASSWORD_ERROR } from "utils/message";
import InfoPartnerCheck from "components/Popups/InfoPartnerCheck";

import {
  getUser as getUserFromStorage,
  setUser as setUserToStorage,
} from "utils/storage";
import { MYINFO_PATH } from "App";
const POLICIES = [
  {
    id: 1,
    title: "서비스 이용약관 동의 (필수)",
    url: "/info/terms?termType=usepolicy",
  },
  {
    id: 2,
    title: "개인 건강정보(민감정보) 수집 및 이용 동의(필수)",
    url: "/info/terms?termType=health_privacy",
  },
  {
    id: 3,
    title: "개인 정보 처리 방침 (필수)",
    url: "/info/terms?termType=privacy",
  },
];

const useJoinField = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [nickname, setNickname] = useState("");
  const [phone, setPhone] = useState("");

  return {
    email,
    password,
    passwordConfirm,
    nickname,
    phone,
    changeEmail: (e) => setEmail(e.target.value),
    changePassword: (e) => setPassword(e.target.value),
    changePasswordConfirm: (e) => setPasswordConfirm(e.target.value),
    changeNickname: (e) => setNickname(e.target.value),
    changePhone: (e) => setPhone(e.target.value),
  };
};

const usePolicy = () => {
  const [policies, setPolicies] = useState(POLICIES);
  const [checkedPoliciesIdx, setCheckedPoliciesIdx] = useState([]);

  return {
    policies,
    checkedPoliciesIdx,
    checkAll: (e) => {
      if (e.target.checked) {
        setCheckedPoliciesIdx(POLICIES.map((p) => p.id));
      } else {
        setCheckedPoliciesIdx([]);
      }
    },
    checkOne: (id) =>
      !checkedPoliciesIdx.includes(id) &&
      setCheckedPoliciesIdx((prev) => [...prev, id]),
    uncheckOne: (id) =>
      checkedPoliciesIdx.includes(id) &&
      setCheckedPoliciesIdx((prev) => prev.filter((p) => p !== id)),
  };
};

const ClientJoin = () => {
  const formRef = useRef(null);
  const { goMyinfo } = useNavigate();
  const joinField = useJoinField();
  const { policies, checkedPoliciesIdx, checkOne, uncheckOne, checkAll } =
    usePolicy();

  const {
    changeEmail,
    changePassword,
    changePasswordConfirm,
    changeNickname,
    changePhone,
    ...fields
  } = joinField;
  const [joinAfterConfirm, setJoinAfterConfirm] = useState(false);
  const [showInfoPartnerCheck, setShowInfoPartnerCheck] = useState(false);
  const form = useForm();
  const { generateToken } = useTwilioToken();
  const { loginClient, sendFcmToken } = useAuth();
  const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
  const onSubmit = async (data) => {
    if (!passwordRegex.test(data.password)) {
      form.setError(
        "password_confirmation",
        { message: PASSWORD_ERROR },
        { shouldFocus: true }
      );
      return;
    }
    if (checkedPoliciesIdx.length !== POLICIES.length) {
      return;
    }
    if (data.password !== data.password_confirmation) {
      form.setError(
        "password_confirmation",
        { message: PASSWORD_DISMATCH_ERROR },
        { shouldFocus: true }
      );
    } else {
      try {
        setUserToStorage({
          // ...resp.data,
          userType: "client",
        });

        await signup(data).then((res) => {
          sendFcmToken(res.data);
        });

        if (joinAfterConfirm) {
          await loginClient(data.email, data.password);
          await generateToken();
          setShowInfoPartnerCheck(true);
        } else {
          goMyinfo();
        }
      } catch (e) {
        alert(e.response.data.message);
      }
    }
  };

  return (
    <Wrapper>
      <Section>
        <form
          ref={formRef}
          id="join-form"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <FormSection {...form} />
        </form>
      </Section>

      <Section style={{ padding: "0 15px" }}>
        <PolicySection
          policies={policies}
          checkedPoliciesIdx={checkedPoliciesIdx}
          policyValid={checkedPoliciesIdx.length === POLICIES.length}
          onCheck={checkOne}
          onUncheck={uncheckOne}
          onCheckAll={checkAll}
        />
        <ActionSection>
          <JoinAndCheckAllianceButton
            onClick={() => setJoinAfterConfirm(true)}
            type="submit"
            form="join-form"
          >
            가입하고 제휴기관 멤버십 인증하기
          </JoinAndCheckAllianceButton>
          <JoinButton
            onClick={() => setJoinAfterConfirm(false)}
            type="submit"
            form="join-form"
          >
            가입 완료
          </JoinButton>
        </ActionSection>
      </Section>

      {showInfoPartnerCheck && (
        <InfoPartnerCheck onClose={() => setShowInfoPartnerCheck(false)} />
      )}
    </Wrapper>
  );
};

export default ClientJoin;

const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #f8f8f8;
  overflow-y: auto;
`;

const Section = styled.div`
  padding-top: 69px;
  background: #fff;
`;
const ActionSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-top: 46px;
  margin-bottom: 24px;
`;
const JoinButton = styled.button`
  width: 271px;
  height: 48px;
  background: #333333;
  border-radius: 10px;
  text-align: center;
  font: normal normal 500 16px/19px Pretendard;
  letter-spacing: -0.16px;
  color: #ffffff;
`;
const JoinAndCheckAllianceButton = styled.button`
  width: 271px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  text-align: center;
  font: normal normal 500 16px/19px Pretendard;
  letter-spacing: -0.16px;
  color: #333333;
`;
