import styled from "styled-components";

import { ReactComponent as ChatSVG } from "assets/svg/chat.svg";
import { ReactComponent as PhoneSVG } from "assets/svg/phone.svg";
import { ReactComponent as UserSVG } from "assets/svg/user.svg";
import { ReactComponent as VideoSVG } from "assets/svg/video.svg";
import { ReactComponent as StarSVG } from "assets/svg/star.svg";
import { ReactComponent as RecommentStarSVG } from "assets/svg/recommend_star.svg";

const CounselorOverview = ({
  name,
  introduction,
  counsels,
  counsel_kind,
  img,
  star,
  reviews_count,
  recommend,
  onClick,
}) => {
  const prices = Object.values(counsel_kind).filter((price) => price > 0);
  return (
    <Wrapper onClick={onClick}>
      <BodySection>
        <div style={{ display: "inline-block", width: "27.5%" }}>
          <IDPhoto $image={img} prices={prices.length} />
        </div>
        <div style={{ display: "inline-block", width: "72.5%" }}>
          <ProfileSection>
            <Name>
              <span>{name}</span> 상담사
            </Name>
            {recommend ? (
              <Recommend>
                <RecommentStarSVG style={{ marginRight: "2px" }} />
                추천
              </Recommend>
            ) : (
              <></>
            )}
            <Message>{introduction}</Message>
            <Subject>
              {counsels.length > 0 && (
                <>• {counsels.map((c) => c.subject).join(", ")}</>
              )}
            </Subject>
            {prices.length > 0 && (
              <Price>
                {Math.min(...prices).toLocaleString()}
                원~
              </Price>
            )}
          </ProfileSection>
        </div>
      </BodySection>
      <FooterSection>
        <KindSection>
          {counsel_kind.chat > 0 && (
            <div>
              <ChatSVG />
            </div>
          )}
          {counsel_kind.video > 0 && (
            <div>
              <VideoSVG />
            </div>
          )}
          {counsel_kind.visit > 0 && (
            <div>
              <UserSVG />
            </div>
          )}
          {counsel_kind.voice > 0 && (
            <div>
              <PhoneSVG />
            </div>
          )}
        </KindSection>

        {star > 0 && (
          <StarSection>
            <StarSVG />
            <span className="star">{star.toFixed(1)}</span>
            <span className="reviews">({reviews_count})</span>
          </StarSection>
        )}
      </FooterSection>
    </Wrapper>
  );
};
export default CounselorOverview;

const Wrapper = styled.section`
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 15px 0px;
  border: 1px solid #e6e6e6;
  width: 100%;
  height: 219px;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border-radius: 15px;
  padding: 24px 16px 18px 16px;
`;
const BodySection = styled.div`
  padding-bottom: 18px;
  border-bottom: 1px solid #e6e6e6;
`;
const IDPhoto = styled.div`
  width: 92px;
  height: 92px;
  border-radius: 15pt;
  background: #fff;
  background-image: url(${(p) => p.$image});
  background-size: 100% 100%;
  border: solid 1px #e6e6e6;
  margin-bottom: ${(props) => (props.prices > 0 ? "14px" : "0px")};
`;
const ProfileSection = styled.div`
  margin-top: 10px;
  margin-left: 15pt;
`;
const Name = styled.div`
  font: normal normal 400 12px/21px Pretendard;
  letter-spacing: -0.18px;
  color: #6e6e6e;
  height: 21px;
  display: inline-block;

  & > span {
    margin-right: 4px;
    font: normal normal bold 18px/21px Pretendard;
    letter-spacing: -0.27px;
    color: #101010;
    display: inline-block;
  }
`;
const Message = styled.div`
  margin-top: 13px;
  font: normal normal 500 14px/16px Pretendard;
  letter-spacing: -0.2px;
  color: #101010;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Subject = styled.div`
  margin-top: 12px;
  font: normal normal 500 14px/16px Pretendard;
  letter-spacing: -0.2px;
  color: #9f9f9f;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Price = styled.div`
  text-align: right;
  margin-top: 11px;
  font: normal normal 600 16px/19px Pretendard;
  letter-spacing: -0.24px;
  color: #101010;
`;
const FooterSection = styled.div`
  width: 100%;
  padding-top: 18px;
  /* padding: 19px 0; */
  display: flex;
  justify-content: space-between;
`;
const KindSection = styled.div`
  display: flex;
  /* gap: 12px; */

  & > div {
    padding: 0 12px;
    /* width: 15px;
    height: 15px; */
    /* padding-right: 12px; */
    &:first-child {
      padding-left: 0px;
    }
  }

  & > div + div {
    border-left: 1px solid #e6e6e6;

    /* margin-left: 12px;
    padding-left: 12px; */
  }
`;

const StarSection = styled.div`
  display: flex;
  align-items: center;

  & > span.star {
    font: normal normal 600 14px/17px Pretendard;
    letter-spacing: -0.14px;
    margin-left: 4px;
  }
  & > span.reviews {
    font: normal normal 400 14px/17px Pretendard;
    letter-spacing: -0.14px;
    color: #999999;
    margin-left: 4px;
  }
`;

const Recommend = styled.div`
  font-family: Semibold;
  display: inline-block;
  margin-left: 8px;
  background-color: #edf1ff;
  padding: 3.5px 11px;
  border: solid 1px #91a7ff;
  color: #485af3;
  border-radius: 15px;
  font-size: 12px;
  font-weight: 650;
`;
