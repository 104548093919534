import { useEffect, useCallback } from "react";
import { useRecoilState } from "recoil";

import { userState, userTokenState } from "atomStates";
import client from "apis/client";
import {
  getUser as getUserFromStorage,
  setUser as setUserToStorage,
} from "utils/storage";

import { MYINFO_PATH, HOME_PATH } from "App";
import { getAppInfo, checkDeviceOS } from "utils/checkMobile";

const useAuth = () => {
  const { userType } = getUserFromStorage() || { userType: "" };
  const [user, setUser] = useRecoilState(userState);
  const [fcmToken] = useRecoilState(userTokenState);
  const { isMinddrawingApp, platform } = getAppInfo();

  let devices = window.navigator.userAgent;
  if (devices.includes("iPhone") || devices.includes("iPad")) {
    devices = "ios";
  } else if (devices.includes("Android")) {
    devices = "android";
  }
  const sendFcmToken = useCallback(
    (data) => {
      if (data) {
        setUser(data);
      }
      if (user !== "" && fcmToken !== "") {
        if (userType === "client") {
          client
            .post("/user/devices", {
              token: fcmToken,
              platform: platform,
              app_version: isMinddrawingApp,
              os_version: checkDeviceOS(),
              device_name: window.navigator.userAgent,
            })
            .then((res) => {})
            .catch((e) => console.log(e));
        } else if (userType === "doctor") {
          client
            .post("/doctor/devices", {
              token: fcmToken,
              platform: platform,
              app_version: isMinddrawingApp,
              os_version: checkDeviceOS(),
              device_name: window.navigator.userAgent,
            })
            .then((res) => {});
        }
      }
    },
    [devices, fcmToken, setUser, user, userType]
  );

  useEffect(() => {
    if (user && fcmToken) {
      sendFcmToken();
    }
  }, [fcmToken, sendFcmToken, user]);

  // 내담자 로그인
  const loginClient = async (email, password, callback) => {
    if (!email || !password) return;
    await client.post("/user/login", { email, password });
    setUserToStorage({
      // ...resp.data,
      userType: "client",
    });

    await settingUser();

    if (callback !== undefined) {
      callback();
    }
  };

  //내담자 비밀번호 확인
  const checkClientPassword = async (password) => {
    if (!user) return;
    await client.post("/user/login", { email: user.email, password });
  };

  //내담자 탈퇴요청
  const withdrawClient = async () => {
    if (!user) return;
    await client.post("/user/withdraw", {
      email: user.email,
    });
  };

  // 상담사 로그인
  const loginDoctor = async (code, phone) => {
    if (!phone || !code) return;

    try {
      await client.post("/doctor/login", {
        code,
        phone,
      });
      setUserToStorage({
        userType: "doctor",
      });
      window.location.replace(MYINFO_PATH);
    } catch (e) {
      alert(e.response.data.message);
    }
  };

  // 로그아웃
  const logout = async () => {
    if (window.confirm("로그아웃 하시겠습니까 ?")) {
      try {
        userType === "doctor"
          ? await client.delete("/doctor/logout")
          : await client.delete("/user/logout");
        setUserToStorage(null);
        setUser(null);
      } catch (e) {
        console.log(e);
      } finally {
        window.location.replace(`${HOME_PATH}`);
      }
    } else {
      return;
    }
  };

  // 사용자(내담자 or 상담사) 정보 가져오기
  const settingUser = async () => {
    try {
      const { data } =
        userType === "doctor"
          ? await client.get("/doctor/me")
          : await client.get("/user/me");

      setUser({
        ...data,
        name: data.nickname || data.name,
        isDoctor: userType === "doctor",
      });
    } catch (e) {
      console.log("setting user failed: " + e.message);
      setUserToStorage(null);
      // goLogin();
    }
  };

  return {
    user,
    userType,
    loginClient,
    checkClientPassword,
    withdrawClient,
    loginDoctor,
    logout,
    settingUser,
    sendFcmToken,
  };
};

export default useAuth;
