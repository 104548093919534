import styled from "styled-components";

import { ReactComponent as CheckSVG } from "assets/svg/check-blue.svg";

const CreditCard = ({
  id,
  isActive,
  card_name,
  card_number,
  onClick,
  onClickDelete,
}) => (
  <Wrapper $active={isActive} onClick={onClick}>
    <CardInfo $active={isActive}>
      <div>
        <label className="name">{card_name}</label>
        <div>{card_number}</div>
      </div>

      <input type="checkbox" defaultChecked={isActive} id={`card-${id}`} />
      <label htmlFor={`card-${id}`}>{isActive && <CheckSVG />}</label>
    </CardInfo>

    <Action $active={isActive}>
      <button onClick={onClickDelete}>삭제</button>
    </Action>
  </Wrapper>
);
export default CreditCard;

const Wrapper = styled.div`
  width: 100%;
  height: 93px;
  background: ${(p) => (p.$active ? "#485AF3" : "#f8f8f8")} 0% 0% no-repeat
    padding-box;
  border-radius: 15px;
  display: flex;
  padding: 24px;

  & + & {
    margin-top: 12px;
  }
`;
const CardInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font: normal normal 400 14px/17px Pretendard;
  letter-spacing: -0.21px;
  color: ${(p) => (p.$active ? "#FFFFFF" : "#6e6e6e")};
  padding-right: 24px;

  label.name {
    display: inline-block;
    font: normal normal 500 17px/20px Pretendard;
    letter-spacing: -0.17px;
    color: ${(p) => (p.$active ? "#FFFFFF" : "#101010")};
    margin-bottom: 8px;
  }

  input[type="checkbox"] {
    display: none;
  }
  input[type="checkbox"] + label {
    display: inline-block;
    width: 24px;
    height: 24px;
    background: #efefef;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  input[type="checkbox"]:checked + label {
    position: relative;
    background: #f8f8f8;
  }
`;
const Action = styled.div`
  flex-shrink: 0;

  button {
    height: 45px;
    background-color: unset;
    font: normal normal 500 14px/17px Pretendard;
    letter-spacing: -0.14px;
    color: ${(p) => (p.$active ? "#FFFFFF" : "#999999")};
    border-left: 1px solid #e6e6e6;
    padding-left: 24px;
    box-shadow: none;
    background-image: none;
  }
`;
