import styled from "styled-components";

import DefaultImage from "assets/images/default.png";

const CallRequestModal = ({
  profileImg,
  title,
  declineVideoCall,
  apporveVideoCall,
}) => {
  return (
    <CallWrapper>
      <Profile src={profileImg ?? DefaultImage} />
      <CallContent>
        <CallTitle>{title ?? '마음드로잉'}</CallTitle>
        <CallDesc>통화 연결을 요청합니다</CallDesc>
        <CallButtonWrapper>
          <DeclineButton onClick={declineVideoCall}>거절</DeclineButton>
          <ApproveButton onClick={apporveVideoCall}>수락</ApproveButton>
        </CallButtonWrapper>
      </CallContent>
    </CallWrapper>
  )
}

const CallWrapper = styled.div`
  z-index: 99999;
  position: absolute;
  display: flex;
  top: 15vh;
  left: calc(50% - 192px);

  max-width: 384px;
  width: calc(100% - 30px);
  border-radius: 20px;
  border: 1px solid #EFEFEF;
  box-shadow: 0px 0px 10px #0000000D;
  background-color: #fff;
  padding: 12px;

  @media (max-width: 1024px) {
    left: 15px;
    max-width: 100%;
  }
`;

const Profile = styled.img`
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: 15px;
  background-color: #F8F8F8;
  margin-right: 12px;
`;

const CallContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const CallTitle = styled.div`
  font: normal normal 600 15px/20px Pretendard;
  margin-bottom: 4px;
`;
const CallDesc = styled.div`
  font: normal normal 400 14px/18px Pretendard;
  margin-bottom: 12px;
`;
const CallButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

const CommonButton = styled.button`
  border-radius: 5px;
  text-align: center;
  font: normal normal 400 14px Pretendard;
  color: #fff;
  max-width: 128px;
  width: calc((100vw - 120px) / 2);
  height: 29px;
`;

const DeclineButton = styled(CommonButton)`
  background-color: #ED5D4E;
`;

const ApproveButton = styled(CommonButton)`
  background-color: #62C53B;
`;

export default CallRequestModal;