import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";

import useUser from "hooks/useUser";
import Form from "components/Form";
import CounselKind from "components/Reservation/CounselKind";
import useCreditCards from "hooks/useCreditCards";
import useDoctor from "hooks/useDoctor";
import useNavigate from "hooks/useNavigate";
import useReservation from "hooks/useReservation";
import { useRecoilState } from "recoil";
import { reservationState } from "atomStates";

const SelectionCounselKind = () => {
  const { user } = useUser();
  const [credit, setCredit] = useState(0); // credit이 있으면 제휴회원
  const [reservation, setReservation] = useRecoilState(reservationState);
  // const [partnerName, setPartnerName] = useState(""); // 제휴기관 명
  const {
    reservation: { counsel_kind: selectedCounselKind },
    createPurchase,
    prepareReservation,
  } = useReservation();
  const { id } = useParams();
  const { availblePayment } = useCreditCards();
  const doctor = useDoctor(id);
  const [selectedKind, setSelectedKind] = useState("");

  const { goMyPayment, goReservationPayment } = useNavigate();

  const { chat, video, visit, voice } = doctor?.counsel_kind || {
    chat: "",
    video: "",
    visit: "",
    voice: "",
  }; // 상담권 종류 별 가격

  useEffect(() => {
    setReservation({
      user_nickname: "", // 구매자(내담자)) 닉네임
      doctor_id: 0, // 상담사 id
      doctor_name: "", // 상담사 이름
      price: "", // 금액
      counsel_kind: "", // 상담권 종류
      credit: 0, // 제휴 회원일 경우, 사용가능한 크레딧
      partner_name: "", // 제휴기관명
      purchase_id: 0, // 구매 id
      customer_uid: "", // 결재 수단 id
      merchant_uid: "", // 결재 완료 id
      status: "",
      // 상담일자/시간 (step3)
      date: "",
      times: [],
    });
  }, []);

  const purchase = async () => {
    if (availblePayment || !!credit) {
      // 2. 결제 수단이 있거나 제휴회원(크레딧 존재) 이면,
      if (doctor.id && selectedKind) {
        // 2-1. 같은 상담사 + 상담권으로 구매 중일 경우, 다음 스텝으로 이어 진행 처리.
        // await createPurchase(doctor.id, selectedKind, credit, partnerName);
        prepareReservation(
          doctor.id,
          selectedKind,
          credit,
          user?.partner_organization_name,
          user?.nickname
        );
      }
    } else {
      // 1. 결재 수단이 없으면, 등록 페이지로 이동
      goMyPayment(false);
    }
  };

  useEffect(() => {
    if (user) {
      setCredit(user.remaining_counsel_count || 0);
      // setPartnerName(user.partner_organization_name || "");
    }
  }, [user]);

  useEffect(() => {
    if (!selectedCounselKind) return;
    (async () => {
      if (!!credit) {
        // 크레딧 보유 회원이면, 바로 예약 생성 요청.
        await createPurchase();
      }
      goReservationPayment(doctor.id);
    })();
  }, [credit, selectedCounselKind]);

  return (
    <Wrapper>
      <ScrollSection>
        {!!chat && (
          <CounselKind
            selected={selectedKind === "chat"}
            kind={"chat"}
            price={chat}
            onClick={() => setSelectedKind("chat")}
          />
        )}

        {!!video && (
          <CounselKind
            selected={selectedKind === "video"}
            kind={"video"}
            price={video}
            onClick={() => setSelectedKind("video")}
          />
        )}

        {!!visit && (
          <CounselKind
            selected={selectedKind === "visit"}
            kind={"visit"}
            price={visit}
            onClick={() => setSelectedKind("visit")}
          />
        )}

        {!!voice && (
          <CounselKind
            selected={selectedKind === "voice"}
            kind={"voice"}
            price={voice}
            onClick={() => setSelectedKind("voice")}
          />
        )}
      </ScrollSection>

      <SubmitRow>
        <Form.SubmitButton
          disabled={!selectedKind}
          onClick={purchase}
          $light={!selectedKind}
        >
          상담권 구매하기
        </Form.SubmitButton>
      </SubmitRow>
    </Wrapper>
  );
};
export default SelectionCounselKind;

const Wrapper = styled.section`
  height: 100%;
  padding-bottom: 50px;
`;
const ScrollSection = styled.section`
  padding: 89px 15px 97px 15px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  overflow-y: auto;
`;
const SubmitRow = styled(Form.SubmitRow)`
  width: 100%;
  background-color: #fff;
  padding-top: 24px;
  padding-bottom: 24px;
  border-top: 1px solid #efefef;
  position: absolute;
  bottom: 0px;
`;
