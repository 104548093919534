import { useCallback, useEffect, useState } from "react";
// import { useRecoilValue } from "recoil";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { last } from "lodash";
import moment from "moment";

import { refundState } from "atomStates";
import client from "apis/client";
import useRefund from "hooks/useRefund";
import useNavigate from "hooks/useNavigate";

const MyReservationPaymentRefund = () => {
  // const { reservation } = useRecoilValue(refundState);
  const { goMyReservation, goBack, goHome } = useNavigate();
  const { reservationId } = useParams();
  const [counselKind, setCounselKind] = useState("");
  const [doctorId, setDoctorId] = useState("");
  const [doctorName, setDoctorName] = useState("");
  const [payment, setPayment] = useState(null);
  const [refundSuccess, setRefundSuccess] = useState(false);

  const { setReservation } = useRefund();

  const refund = async () => {
    try {
      await client.post(`/user/reservations/${reservationId}/cancel`, {
        doctor_id: doctorId,
        counsel_kind: counselKind,
      });
      setRefundSuccess(true);
    } catch (e) {
      setRefundSuccess(false);
    }
  };
  const fetch = useCallback(async () => {
    if (!reservationId) return;

    const { data } = await client.get(`/user/reservations/${reservationId}`);
    const {
      counsel_kind,
      doctor: { name, id },
      payments,
      status,
    } = data;
    setReservation(data);
    setDoctorId(id);
    setCounselKind(counsel_kind);
    setDoctorName(name);
    if (status.includes("취소")) {
      setRefundSuccess(true);
    }
    setPayment(payments);
    // if (payments.length > 0) {
    //   setPayment(last(payments));
    // }
  }, [reservationId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <Wrapper>
      {refundSuccess ? (
        <>
          <SuccessMessage>
            환불 신청이
            <br /> 완료되었습니다.
          </SuccessMessage>
          <Counsel>
            <div className="title">{counselKind} 상담</div>
            <div>{doctorName} 상담사 (50분 x 1회)</div>
          </Counsel>

          <InfoRow>
            <label>환불 예정일</label>
            <div>평일 3-7일 이내</div>
          </InfoRow>
          <InfoRow>
            <label>환불 수단</label>
            <div>{payment?.pay_method}</div>
          </InfoRow>

          <ActionRow>
            <Button $light={true} onClick={goMyReservation}>
              결제내역 보기
            </Button>
            <Button onClick={goHome}>홈으로 이동</Button>
          </ActionRow>
        </>
      ) : (
        <>
          <Counsel>
            <div className="title">{counselKind} 상담</div>
            <div>{doctorName} 상담사 (50분 x 1회)</div>
          </Counsel>

          <PaymentStatus>
            <div className="title">
              <span>환불 정보를 확인하세요.</span>
            </div>
          </PaymentStatus>

          <InfoRow>
            <label>결제 일시</label>
            <div>
              {payment
                ? moment(payment.created_at).format("YY.MM.DD hh:mm")
                : ""}
            </div>
          </InfoRow>
          <InfoRow>
            <label>결제 수단</label>
            <div>{payment?.pay_method}</div>
          </InfoRow>
          <InfoRow>
            <label>상품 금액</label>
            <div>{payment?.amount.toLocaleString()}원</div>
          </InfoRow>

          <Divider />

          <PaymentTotal>
            <label>환불 예상 금액</label>
            <div>{payment?.amount.toLocaleString()}원</div>
          </PaymentTotal>

          <ActionRow>
            <Button $light={true} onClick={goBack}>
              이전
            </Button>
            <Button onClick={refund}>환불 신청하기</Button>
          </ActionRow>
        </>
      )}
    </Wrapper>
  );
};
export default MyReservationPaymentRefund;

const Wrapper = styled.section`
  width: 100%;
  height: 100%;
  padding: 20px 15px 0 15px;
  /* overflow: hidden; */
  overflow-y: auto;
  position: relative;
`;
const Counsel = styled.div`
  padding: 24px;
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 15px;
  font: normal normal 400 14px/17px Pretendard;
  letter-spacing: -0.14px;
  color: #6e6e6e;
  margin-bottom: 36px;

  .title {
    font: normal normal 600 20px/24px Pretendard;
    letter-spacing: -0.2px;
    color: #101010;
    margin-bottom: 11px;
  }
`;
const PaymentStatus = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 44px;

  .title {
    font: normal normal 600 18px/21px Pretendard;
    letter-spacing: -0.36px;
    color: #101010;
  }

  .status {
    width: 76px;
    background: #f0f2ff 0% 0% no-repeat padding-box;
    border-radius: 5px;
    font: normal normal 500 14px/17px Pretendard;
    letter-spacing: 0px;
    color: #485af3;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 0;
  }
`;
const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  font: normal normal 500 15px/18px Pretendard;
  letter-spacing: -0.15px;
  color: #101010;

  label {
    font: normal normal 400 15px/18px Pretendard;
    letter-spacing: -0.3px;
    color: #999999;
  }

  & + & {
    margin-top: 16px;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #e6e6e6;
  margin: 28px 0;
`;

const PaymentTotal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background: #f8f8f8;
  border-radius: 10px;
  width: 100%;
  font: normal normal bold 20px/24px Pretendard;
  letter-spacing: -0.2px;
  color: #ed5d4e;
  padding: 28px 16px;

  label {
    font: normal normal 500 16px/19px Pretendard;
    letter-spacing: -0.16px;
    color: #101010;
    margin-bottom: 10px;
  }
`;

const ActionRow = styled.div`
  width: 100%;
  margin-top: 19px;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  /* position: absolute;
  bottom: 50px; */
`;

const Button = styled.button`
  width: 100%;
  height: 48px;
  background: ${(p) => (p.$light ? "#fff" : "#333333")};
  border-radius: 5px;
  font: normal normal 500 16px/19px Pretendard;
  letter-spacing: -0.16px;
  color: ${(p) => (p.$light ? "#333333" : "#ffffff")};
  border: ${(p) => (p.$light ? "1px solid #E6E6E6" : "none")};
`;

const SuccessMessage = styled.div`
  font: normal normal 600 24px/38px Pretendard;
  letter-spacing: -0.24px;
  color: #101010;
  margin-top: 22px;
  margin-bottom: 44px;
`;
