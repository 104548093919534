import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import WarningPage from "./WarningPage";

import { ReactComponent as ThumbsUpSVG } from "assets/svg/thumbsUp.svg";
import useNavigate from "hooks/useNavigate";

import ExamineStep1 from "./ExamineStep/ExamineStep1";
import ExamineStep2 from "./ExamineStep/ExamineStep2";
import ExamineStep3 from "./ExamineStep/ExamineStep3";
import ExamineStep4 from "./ExamineStep/ExamineStep4";
import ExamineStep5 from "./ExamineStep/ExamineStep5";
import ExamineStep7 from "./ExamineStep/ExamineStep7";
import ExamineStep8 from "./ExamineStep/ExamineStep8";
import ExamineStep9 from "./ExamineStep/ExamineStep9";
import ExamineStep11 from "./ExamineStep/ExamineStep11";
import client from "apis/client";
import ExamineModal from "./ExamineModal";
import { useRecoilState } from "recoil";
import { examineResult, examineStep } from "atomStates";
import ExamineStep12 from "./ExamineStep/ExamineStep12";
import ExamineStep13 from "./ExamineStep/ExamineStep13";
import ExamineStep14 from "./ExamineStep/ExamineStep14";
import ExamineStep15 from "./ExamineStep/ExamineStep15";
import ExamineStep16 from "./ExamineStep/ExamineStep16";
import ExamineStep17 from "./ExamineStep/ExamineStep17";
import ExamineStep18 from "./ExamineStep/ExamineStep18";
import ExamineStep19 from "./ExamineStep/ExamineStep19";
import ExamineStep20 from "./ExamineStep/ExamineStep20";
import ExamineStep21 from "./ExamineStep/ExamineStep21";
import ExamineStep22 from "./ExamineStep/ExamineStep22";
import ExamineStep23 from "./ExamineStep/ExamineStep23";
import ExamineStep24 from "./ExamineStep/ExamineStep24";
import ExamineStep25 from "./ExamineStep/ExamineStep25";
import ExamineStep26 from "./ExamineStep/ExamineStep26";
import ExamineStep27 from "./ExamineStep/ExamineStep27";
import ExamineStep28 from "./ExamineStep/ExamineStep28";
import ExamineStep29 from "./ExamineStep/ExamineStep29";
import ExamineStep30 from "./ExamineStep/ExamineStep30";

const ExamineStart = () => {
  const [step, setStep] = useRecoilState(examineStep);
  const { goExamineResult } = useNavigate();
  const [headerText, setHeaderText] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [examineResults, setExamineResults] = useRecoilState(examineResult);
  const textRef = useRef(null);
  // 2024. 04. 27 : 추가적인 컬럼이 더이상 생기지 않는다는 답변 확인 이후 + 백앤드 없이 하드코딩으로 해결하기로 함
  const [results, setResults] = useState({
    gender: "", // 남자, 여자
    birth_year: "", // "2000"
    education: "", // "고등졸업 , 학사재학 , 학사졸업 , 석사재학 , 석사졸업"
    work_type: "", // "사무직 , 외근직 , 현장직 , 기타"
    work_experience: "", // "1년 미만 , 1년 이상 ~ 3년 미만 , 3년 이상 ~ 5년 미만 , 5년 이상 ~ 10년 미만 , 10년 이상 ~ 15년 미만 , 15년 이상"
    weather: "", // "날씨가 좋아요 , 날씨가 좋지도 않고 , 나쁘지도 않다 , 날씨가 나빠요"
    background: "", // "거친 파도 , 해안가의 잔잔한 파도 , 화산"
    stars: [],
    cats: [],
    parachutes: [],
    trees: [],
    rats: [],
    chicks: [],
    additional_questions: {},
  });

  useEffect(() => {
    if (step >= 1 && step < 6) {
      setHeaderText("테스트에 필요한 인적 사항을 입력합니다.");
    }
    if (step > 6 && step < 10) {
      setHeaderText("Step I. 그림 완성하기");
    }
    if (step === 10) {
      setHeaderText("");
    }
    if (step > 10) {
      setHeaderText("Step II. 최근 2달간의 경험을 바탕으로 답해 주세요.");
    }
    if (step === 31) result();
  }, [step]);

  const nextStep = () => {
    setStep(step + 1);
  };

  const result = () => {
    client
      .post("/psychological_tests", {
        gender: results.gender,
        birth_year: results.birth_year,
        education: results.education,
        work_type: results.work_type,
        work_experience: results.work_experience,
        weather: results.weather,
        background: results.background,
        stars: results.stars,
        cats: results.cats,
        parachutes: results.parachutes,
        trees: results.trees,
        rats: results.rats,
        chicks: results.chicks,
        additional_questions: {},
      })
      .then((res) => {
        setExamineResults(res.data);
        goExamineResult();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    setStep(1);
  }, []);

  return (
    <>
      <Wrapper>
        <Progress>
          <Dealt dealt={step} />
        </Progress>
        {headerText !== "" ? <HeaderText>{headerText}</HeaderText> : <></>}
        <Title ref={textRef}>
          {step === 1
            ? "나의 성별은"
            : step === 2
            ? "나의 출생연도는"
            : step === 3
            ? "나의 학력은"
            : step === 4
            ? "나의 근무 형태는"
            : step === 5
            ? "나의 현재 근무 경력은"
            : step === 7
            ? "본인이 생각하는 날씨를 선택하세요."
            : step === 8
            ? "본인이 원하는 기본 배경을 선택하세요."
            : step === 9
            ? "스티커를 선택해 보세요."
            : 11 < step < 31
            ? ExperienceQuestionList[step - 11]
            : ""}
        </Title>
        {step === 1 ? (
          <ExamineStep1
            setResults={setResults}
            results={results}
            setStep={setStep}
          />
        ) : step === 2 ? (
          <ExamineStep2
            setResults={setResults}
            results={results}
            nextStep={nextStep}
          />
        ) : step === 3 ? (
          <ExamineStep3
            setResults={setResults}
            results={results}
            nextStep={nextStep}
          />
        ) : step === 4 ? (
          <ExamineStep4
            setResults={setResults}
            results={results}
            nextStep={nextStep}
            openModal={openModal}
            setOpenModal={setOpenModal}
          />
        ) : step === 5 ? (
          <ExamineStep5
            setResults={setResults}
            results={results}
            setStep={setStep}
            step={step}
          />
        ) : step === 7 ? (
          <ExamineStep7
            setResults={setResults}
            results={results}
            nextStep={nextStep}
          />
        ) : step === 8 ? (
          <ExamineStep8
            setResults={setResults}
            results={results}
            nextStep={nextStep}
          />
        ) : step === 9 ? (
          <ExamineStep9
            setResults={setResults}
            results={results}
            nextStep={nextStep}
          />
        ) : step === 10 ? (
          <div
            style={{
              textAlign: "center",
              marginTop: "80pt",
              height: "100%",
            }}
          >
            <ThumbsUpSVG style={{ marginBottom: "20pt" }} />
            <Title style={{ marginBottom: "40pt !important" }}>
              그림을 완성하셨군요!
            </Title>
            <Comtents>
              <div style={{ marginBottom: "12px" }}>
                테스트에 참여해 주셔서 감사합니다.
              </div>
              몇가지 질문에 답변하면 <br />
              더욱 정확한 결과를 얻을 수 있어요.
            </Comtents>
            <ButtonDiv>
              <NextStepButton
                className="examineStep10_Button"
                onClick={nextStep}
                style={{
                  backgroundColor: "#FFFFFF",
                  color: "#333333",
                  border: "solid 1px #BEBEBE",
                }}
              >
                더 정확한 결과 보기
              </NextStepButton>
              <NextStepButton
                className="examineStep10_Button"
                style={{ marginRight: "0px" }}
                onClick={() => {
                  setStep(31);
                }}
              >
                빠른 결과 보기
              </NextStepButton>
            </ButtonDiv>
          </div>
        ) : step === 11 ? (
          <ExamineStep11
            setResults={setResults}
            results={results}
            nextStep={nextStep}
          />
        ) : step === 12 ? (
          <ExamineStep12
            setResults={setResults}
            results={results}
            nextStep={nextStep}
          />
        ) : step === 13 ? (
          <ExamineStep13
            setResults={setResults}
            results={results}
            nextStep={nextStep}
          />
        ) : step === 14 ? (
          <ExamineStep14
            setResults={setResults}
            results={results}
            nextStep={nextStep}
          />
        ) : step === 15 ? (
          <ExamineStep15
            setResults={setResults}
            results={results}
            nextStep={nextStep}
          />
        ) : step === 16 ? (
          <ExamineStep16
            setResults={setResults}
            results={results}
            nextStep={nextStep}
          />
        ) : step === 17 ? (
          <ExamineStep17
            setResults={setResults}
            results={results}
            nextStep={nextStep}
          />
        ) : step === 18 ? (
          <ExamineStep18
            setResults={setResults}
            results={results}
            nextStep={nextStep}
          />
        ) : step === 19 ? (
          <ExamineStep19
            setResults={setResults}
            results={results}
            nextStep={nextStep}
          />
        ) : step === 20 ? (
          <ExamineStep20
            setResults={setResults}
            results={results}
            nextStep={nextStep}
          />
        ) : step === 21 ? (
          <ExamineStep21
            setResults={setResults}
            results={results}
            nextStep={nextStep}
          />
        ) : step === 22 ? (
          <ExamineStep22
            setResults={setResults}
            results={results}
            nextStep={nextStep}
          />
        ) : step === 23 ? (
          <ExamineStep23
            setResults={setResults}
            results={results}
            nextStep={nextStep}
          />
        ) : step === 24 ? (
          <ExamineStep24
            setResults={setResults}
            results={results}
            nextStep={nextStep}
          />
        ) : step === 25 ? (
          <ExamineStep25
            setResults={setResults}
            results={results}
            nextStep={nextStep}
          />
        ) : step === 26 ? (
          <ExamineStep26
            setResults={setResults}
            results={results}
            nextStep={nextStep}
          />
        ) : step === 27 ? (
          <ExamineStep27
            setResults={setResults}
            results={results}
            nextStep={nextStep}
          />
        ) : step === 28 ? (
          <ExamineStep28
            setResults={setResults}
            results={results}
            nextStep={nextStep}
          />
        ) : step === 29 ? (
          <ExamineStep29
            setResults={setResults}
            results={results}
            nextStep={nextStep}
          />
        ) : step === 30 ? (
          <ExamineStep30
            setResults={setResults}
            results={results}
            nextStep={nextStep}
          />
        ) : step === 0 ? (
          <WarningPage setStep={setStep}></WarningPage>
        ) : (
          <></>
        )}
      </Wrapper>
      {openModal ? (
        <ExamineModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          results={results}
          setResults={setResults}
          step={step}
          nextStep={nextStep}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default ExamineStart;

const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #ffffff;
  margin-bottom: 85px;
`;

const Progress = styled.div`
  margin-top: 69px;
  width: 100%;
  height: 4px;
  background-color: #efefef;
`;
const Dealt = styled.div`
  background-color: #101010;
  width: ${(props) =>
    props.dealt < 11 ? props.dealt * 10 + "%" : (props.dealt - 10) * 5 + "%"};
  height: 100%;
`;

const HeaderText = styled.div`
  margin: 0 10pt 0 10pt;
  padding: 11pt 0 10pt 11pt;
  background-color: #f8f8f8;
  border: solid 1px #e6e6e6;
  border-radius: 5pt;
  font-size: 11pt;
  color: #8e8e8e;
`;

const Title = styled.div`
  margin: 0pt 10pt 0pt 10pt;
  font: normal normal 700 28px/11px Pretendard;
  line-height: 25pt;
  white-space: pre-wrap;
`;

const NextStepButton = styled.button`
  width: 170px;
  height: 48px;
  margin-right: 14px;
  background: #333333 0% 0% no-repeat padding-box;
  border-radius: 10px;
  font: normal normal 500 16px/19px Pretendard;
  letter-spacing: -0.16px;
  color: #ffffff;
  margin-left: auto;
`;

const Comtents = styled.div`
  font: normal normal 300 16px/19px Pretendard;
  color: #101010;
  margin-left: 20pt;
  margin-right: 20pt;
  margin-bottom: 130pt;
  margin: 23pt 20pt 200px 20pt;
`;

const ExperienceQuestionList = [
  "평소에는 아무렇지도 않던 일들이 귀찮게 느껴졌다.",
  "먹고 싶지 않았다. / 입맛이 없었다.",
  "가족이나 친구가 도와주더라도 울적한 기분을 떨쳐버릴 수 없었다.",
  "다른 사람들만큼 능력이 있다고 느꼈다.",
  "무슨 일을 하던 정신을 집중하기가 힘들었다.",
  "우울했다.",
  "하는 일마다 힘들게 느껴졌다.",
  "미래에 대하여 희망적으로 느꼈다.",
  "내 인생은 실패작이라는 생각이 들었다.",
  "두려움을 느꼈다.",
  "잠을 설쳤다. / 잠을 잘 이루지 못했다.",
  "행복했다.",
  "평소보다 말을 적게 했다. / 말수가 줄었다.",
  "세상에 홀로 있는 듯한 외로움을 느꼈다.",
  "사람들이 나에게 차갑게 대하는 것 같았다.",
  "생활이 즐거웠다.",
  "갑자기 울음이 나왔다.",
  "슬픔을 느꼈다.",
  "사람들이 나를 싫어하는 것 같았다.",
  "도무지 무엇을 시작할 기운이 나지 않았다.",
];

const ButtonDiv = styled.div`
  position: fixed;
  bottom: 85px;
  width: 100%;
  left: 0;
  right: 0;
`;
