import { useEffect, useState } from "react";
import styled from "styled-components";
import { maxBy } from "lodash";

import CreditPayment from "components/Reservation/CreditPayment";
import PaymentSelector from "components/Reservation/PaymentSelector";
import PaymentSuccess from "components/Reservation/PaymentSuccess";
import ReservationConfirm from "components/Reservation/ReservationConfirm";
import StatusIndicator from "components/Reservation/StatusIndicator";
import useCreditCards from "hooks/useCreditCards";
import useReservation from "hooks/useReservation";
import ReservationDateTime from "components/Drawer/ReservationDateTime";
import { useRecoilState } from "recoil";
import { paymentState } from "atomStates";

const PAYMENT = "payment";
const CONFIRM = "confirm";
const SUCCESS = "success";

const ReservationPayment = ({ onCreateConversation }) => {
  const {
    reservation: {
      user_nickname,
      counsel_kind,
      customer_uid,
      doctor_name,
      merchant_uid,
      purchase_id,
      status: paymentStatus,
      credit,
      partner_name,
      price,
    },
    createPurchase,
    setCustomerUid,
    payCreditPurchase,
    payPurchase,
  } = useReservation();
  const [showDateTimeSelector, setShowDateTimeSelector] = useState(false);
  const [paymentStates, setPaymentStates] = useRecoilState(paymentState);
  const [status, setStatus] = useState(PAYMENT);
  const { creditCards } = useCreditCards();
  const moveNext = async () => {
    if (!!credit && status !== SUCCESS) {
      await payCreditPurchase();
      setStatus(SUCCESS);
    } else {
      if (status === PAYMENT) {
        if (customer_uid) {
          await createPurchase();
          setStatus(CONFIRM);
        }
      }
      if (status === CONFIRM) {
        if (purchase_id) {
          await payPurchase();
          setStatus(SUCCESS);
        }
      }

      if (status === SUCCESS) {
        setShowDateTimeSelector(true);
      }
    }
    setPaymentStates(status);
  };

  useEffect(() => {
    setPaymentStates(status);
  }, [status]);

  useEffect(() => {
    if (creditCards.length === 0) return;

    setCustomerUid(maxBy(creditCards, "id").customer_uid);
  }, [creditCards]);

  useEffect(() => {
    if (merchant_uid) {
      setStatus(SUCCESS);
    }
  }, [merchant_uid]);

  useEffect(() => {
    if (!!credit && paymentStatus === "결제완료") {
      setStatus(SUCCESS);
    }
  }, [paymentStatus, credit]);
  return (
    <Wrapper>
      <ScrollSection
        style={{
          padding: status !== "confirm" ? "60px 15px 0 15px" : "60px 0px",
        }}
      >
        {!!credit ? (
          status === SUCCESS ? (
            <PaymentSuccess
              name={user_nickname}
              counselKind={counsel_kind}
              doctorName={doctor_name}
            />
          ) : (
            <div>
              <CreditPayment
                partner={partner_name}
                creditCount={credit}
                counselKind={counsel_kind}
                price={price}
              />
            </div>
          )
        ) : (
          <div>
            <StatusIndicator
              statusList={["카드확인", "내역확인", "예약완료"]}
              currentIdx={status === PAYMENT ? 0 : status === CONFIRM ? 1 : 2}
            />

            <Body style={{ marginTop: "20px" }}>
              {status === PAYMENT ? (
                <PaymentSelector
                  creditCards={creditCards}
                  activeUid={customer_uid}
                  onClick={(uid) => setCustomerUid(uid)}
                />
              ) : status === CONFIRM ? (
                <ReservationConfirm counselKind={counsel_kind} price={price} />
              ) : (
                <PaymentSuccess
                  name={user_nickname}
                  counselKind={counsel_kind}
                  doctorName={doctor_name}
                />
              )}
            </Body>
          </div>
        )}
      </ScrollSection>
      <ActionRow>
        <button onClick={moveNext}>
          {!!credit
            ? "접수하기"
            : status === SUCCESS
            ? "상담 일자 접수"
            : "다음으로"}
        </button>
      </ActionRow>

      <ReservationDateTime
        isShow={showDateTimeSelector}
        prepareRoom={onCreateConversation}
        onClose={() => setShowDateTimeSelector(false)}
      />
    </Wrapper>
  );
};
export default ReservationPayment;

const Wrapper = styled.section`
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  height: 100%;
  overflow-y: auto;
`;
const ScrollSection = styled.section`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  overflow-y: auto;
`;
const Body = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ActionRow = styled.div`
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid #e6e6e6;
  padding: 24px 0;
  position: absolute;
  bottom: 0;

  & > button {
    width: 271px;
    height: 48px;
    background: #333333 0% 0% no-repeat padding-box;
    border-radius: 10px;
    font: normal normal 500 16px/19px Pretendard;
    letter-spacing: -0.16px;
    color: #ffffff;
  }
`;
