import { useEffect, useRef } from "react";
import styled, {css} from "styled-components";

const MyTracks = ({ audioTrack, videoTrack, previewOn}) => {
  const videoRef = useRef();
  const audioRef = useRef();

  useEffect(() => {
    videoTrack.attach(videoRef.current);
  }, [videoTrack]);

  useEffect(() => {
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
    }
  }, [audioTrack]);

  return (
    <Wrapper previewOn={previewOn}>
      <video
        ref={videoRef}
        autoPlay={true}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          display: "block",
          objectPosition: "center",
        }}
      />
      <audio ref={audioRef} autoPlay={true} muted={false} />
    </Wrapper>
  );
};
export default MyTracks;

const Wrapper = styled.section`
  width: 96px;
  height: 187px;
  position: absolute;
  bottom: 200px;
  right: 15px;
  z-index: 9010;
  background-color: #999999;
  border-radius: 15px;

  & > video {
    border-radius: 15px;
  }

  ${props => props.previewOn && css`
    width: 50%;
    height: 40%;
    bottom: 0;
    right: 0;
    border-radius: 0;
    background-color: #fff;

    & > video {
      border-radius: 0;
    }
  `}
`;
