export const trackpubsToTracks = (trackMap) => {
  const track = Array.from(trackMap.values())
    .map((publication) => publication.track)
    .filter((track) => track !== null);

  return track.length > 0 ? track[0] : null;
};

export const mapToArray = (map) => Array.from(map.values());

export const generateFriendlyName = (doctorName, clientNickname) =>
  `${doctorName} 상담사-${clientNickname}`;
export const generateUniqueName = (doctorId, userId) =>
  `doctor${doctorId}-user${userId}`;
export const getIdsFromUniqueName = (uniqueName) => uniqueName.match(/(\d+)/g) ?? [null];
