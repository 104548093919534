import { ReactComponent as RGBColorLogo } from "assets/svg/palette_color.svg";
import { ReactComponent as Erase } from "assets/svg/Data-Erase-256.svg";
import { ReactComponent as Pen } from "assets/svg/Pen-Line-WF-256.svg";
import { ReactComponent as Delete } from "assets/svg/Delete-01-WF-256.svg";

const PaletteMenu = ({
  lineOpacity,
  setLineWidth,
  setLineOpacity,
  setModal,
  clearCanvas,
  eraser,
  pencil,
}) => {
  const erase = () => {
    eraser("white");
  };
  return (
    <div className="topMenuDiv">
      <div className="topMenu">
        <div style={{ display: "inline-block", height: "65px" }}>
          <div className="iconCSS">
            <RGBColorLogo
              onClick={() => {
                setModal(true);
              }}
              style={{ marginTop: "10px", height: "44px" }}
            />
          </div>
          <div className="iconCSS">
            <Delete
              onClick={clearCanvas}
              style={{ marginTop: "10px", height: "44px" }}
            />
          </div>
          <div className="iconCSS">
            {" "}
            <Erase
              onClick={erase}
              style={{ marginTop: "10px", height: "44px" }}
            />
          </div>

          <div className="iconCSS">
            <Pen
              onClick={pencil}
              style={{ marginTop: "10px", height: "44px" }}
            />
          </div>
          <div className="topMenu_width">
            <label className="labelName">굵기</label>
            <input
              className="inputRange"
              type="range"
              min="0.1"
              max="10"
              step={0.2}
              defaultValue={1}
              onChange={(e) => {
                setLineWidth(e.target.value);
              }}
            />
          </div>
          <div className="topMenu_opacity">
            <label className="labelName">펜압</label>
            <input
              className="inputRange"
              type="range"
              min="1"
              max="100"
              defaultValue={lineOpacity * 100}
              onChange={(e) => {
                setLineOpacity(e.target.value / 100);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaletteMenu;
