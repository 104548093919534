import styled from "styled-components";

export const Wrapper = styled.section`
  width: 100%;
  padding: 24px;
  border: 1px solid #efefef;
  background: #f8f8f8;
  border-radius: 15px;

  & > hr {
    width: 100%;
    height: 1px;
    background-color: #e6e6e6;
    margin: 16px 0;
    border: none;
  }

  & + & {
    margin-top: 12pt;
  }
`;
export const Status = styled.div`
  font: normal normal bold 24px/29px Pretendard;
  letter-spacing: -0.12px;
  color: #101010;
`;
export const Type = styled.div`
  width: fit-content;
  padding: 3px 20px;
  background: #efefef;
  border-radius: 5px;
  font: normal normal 500 14px/17px Pretendard;
  letter-spacing: 0px;
  color: #6e6e6e;
  margin-bottom: 16px;
`;
export const Info = styled.div`
  display: flex;

  font: normal normal 500 15px/18px Pretendard;
  letter-spacing: -0.23px;
  color: #101010;

  & > label {
    width: 70px;
    color: #999999;
  }

  & + & {
    margin-top: 13px;
  }
`;
export const Actions = styled.section`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;
export const Action = styled.div`
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 5px;
  font: normal normal 500 14px/17px Pretendard;
  letter-spacing: -0.21px;
  color: #404040;
  padding: 7px 12px;
  cursor: pointer;

  & > svg {
    margin-right: 4px;
  }
`;
