import { createContext } from "react";
import { useSearchParams } from "react-router-dom";

import ClientJoin from "./ClientJoin";
import DoctorJoin from "./DoctorJoin";

// export const FormContext = createContext();
// export const PolicyContext = createContext();

const Join = () => {
  const [params] = useSearchParams();
  const userType = params.get("userType") || "client";

  if (userType !== "client") {
    return <DoctorJoin />;
  }
  return <ClientJoin />;
};

export default Join;
