import { forwardRef } from "react";
import styled from "styled-components";

const ButtonCheckboxField = forwardRef(
  ({ preferredType, label, ...rest }, ref) => (
    // <Wrapper>
    //   <Input ref={ref} type="checkbox" {...rest} />
    //   <Label>{label}</Label>
    // </Wrapper>
    <Wrapper preferredType={preferredType}>
      <label>
        <input ref={ref} type="checkbox" {...rest} />
        <span>{label}</span>
      </label>
    </Wrapper>
  )
);

export default ButtonCheckboxField;

const Wrapper = styled.div`
  ${(props) =>
    props.preferredType === "day" || props.preferredType === "times"
      ? `    display: inline-block;
  border: solid 1px #e6e6e6;
  border-radius: 5px;
  margin-right: 4px;`
      : ""};
  & > label {
    cursor: pointer;
    & > input {
      left: -99999px;
      position: absolute;
      &:checked ~ span {
        background: #f7f8ff;
        border: 1px solid #485af3;
        border-radius: 5px;
        color: #485af3;
        font: normal normal 500 14px/17px Pretendard;
      }
    }
    & > span {
      align-items: center;
      display: inline-flex;
      justify-content: center;
      width: ${(props) =>
        props.preferredType === "day"
          ? "45px"
          : props.preferredType === "times"
          ? "116px"
          : "88px"};
      height: ${(props) =>
        props.preferredType === "day" || props.preferredType === "times"
          ? "50px"
          : "40px"};
      background: ${(props) =>
        props.preferredType === "day" || props.preferredType === "times"
          ? "#FFFFF 0% 0% no-repeat padding-box"
          : "#f8f8f8 0% 0% no-repeat padding-box"};
      border-radius: 5px;
      font: normal normal 400 14px/17px Pretendard;
      letter-spacing: -0.21px;
      color: #101010;
      font-weight: 500;
    }
  }
`;
