import styled from "styled-components";

import HomeSubSection from "components/HomeSubSection";
import { DOCTOR_PATH } from "App";

import { ReactComponent as ArtPsychologicalSVG } from "assets/svg/art-psychological.svg";
import { ReactComponent as PsychologicalSVG } from "assets/svg/psychological.svg";
import { useNavigate } from "react-router-dom";

const MatchingConsultation = () => {
  const navigate = useNavigate();
  const moveDoctorList = (type) => navigate(`${DOCTOR_PATH}?type=${type}`);
  return (
    <RecommendList>
      <HomeSubSection title={<Title>나에게 맞는 상담은?</Title>}>
        <div style={{ textAlign: "center" }}>
          <PsychologicalSVG
            style={{
              cursor: "pointer",
              width: "48%",
              marginRight: "8px",
              height: "100%",
            }}
            onClick={() => {
              moveDoctorList("general");
            }}
          />
          <ArtPsychologicalSVG
            style={{ cursor: "pointer", width: "48%", height: "100%" }}
            onClick={() => {
              moveDoctorList("art");
            }}
          />
        </div>
      </HomeSubSection>
    </RecommendList>
  );
};
export default MatchingConsultation;

const Title = styled.div`
  display: flex;
  align-items: center;

  & > img {
    margin-left: 4px;
  }
`;

const RecommendList = styled.div`
  padding-right: 12pt;
  padding-left: 12pt;
`;
