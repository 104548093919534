import styled from "styled-components";

import useNavigate from "hooks/useNavigate";

const PaymentSelector = ({ creditCards, activeUid, onClick }) => {
  const { goRegistPayment } = useNavigate();

  return (
    <>
      <Title className="hanacardTitle">
        등록하신 아래 카드로 결제가 진행됩니다.
        <br />
        <span className="hanacard">
          *하나카드 결제 진행은 어려우니 양해 부탁드립니다.
        </span>
      </Title>

      {creditCards.map((card) => (
        <Card
          key={card.id}
          $active={activeUid === card.customer_uid}
          onClick={() => onClick(card.customer_uid)}
        >
          <label>{card.card_name}</label>
          <span>{card.card_number}</span>
        </Card>
      ))}

      <AddCard>
        <span onClick={goRegistPayment}>다른 카드 등록</span>
      </AddCard>
    </>
  );
};
export default PaymentSelector;

const Title = styled.div`
  font: normal normal 500 15px/18px Pretendard;
  letter-spacing: -0.15px;
  color: #101010;
  padding: 21px 24px;
  width: 100%;
  border: 1px solid #efefef;
  border-radius: 15px;
  margin-bottom: 12px;

  & > span {
    display: inline-block;
    text-align: right;
    font: normal normal 400 12px/17px Pretendard;
    letter-spacing: -0.14px;
    color: #999999;
    margin-top: 10px;
  }
`;
const Card = styled.div`
  width: 100%;
  height: 93px;
  background: ${(p) => (p.$active ? "#485AF3" : "#f8f8f8")};
  border-radius: 15px;
  padding: 24px;
  display: flex;
  flex-direction: column;

  font: normal normal 400 14px/17px Pretendard;
  letter-spacing: -0.21px;
  color: ${(p) => (p.$active ? "#fff" : "#6e6e6e")};

  & > label {
    font: normal normal 600 17px/20px Pretendard;
    letter-spacing: -0.17px;
    color: ${(p) => (p.$active ? "#fff" : "#101010")};
    margin-bottom: 8px;
  }

  & + & {
    margin-top: 10px;
  }
`;
const AddCard = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  span {
    display: inline-block;
    text-align: right;
    text-decoration: underline;
    font: normal normal 400 14px/17px Pretendard;
    letter-spacing: -0.14px;
    color: #999999;
  }
`;
