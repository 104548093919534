import styled from "styled-components";

const Popup = ({ children }) => (
  <Wrapper>
    <div className="drawer-mask" />
    <div className="drawer-content-wrapper">
      <Content>{children}</Content>
    </div>
  </Wrapper>
);
export default Popup;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: block;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;

  & > .drawer-mask {
    position: absolute;
    inset: 0;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.45);
    pointer-events: auto;
  }

  & > .drawer-content-wrapper {
    z-index: 1000;
    position: relative;
    width: auto;
    margin: 0 15px;
    pointer-events: none;

    display: flex;
    align-items: center;
    min-height: 100%;
  }
`;
const Content = styled.div`
  position: relative;
  pointer-events: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #9999991a;
  background-clip: padding-box;
  border: 1px solid #efefef;
  border-radius: 10px;
  outline: 0;
  width: 100%;
  min-height: 297px;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
`;

export const ActionButton = styled.button`
  width: 271px;
  height: 48px;
  background: #333333 0% 0% no-repeat padding-box;
  border-radius: 10px;
  font: normal normal 500 16px/19px Pretendard;
  letter-spacing: -0.16px;
  color: #ffffff;
`;
