import { useEffect, useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";

import client from "apis/client";
import useNavigate from "hooks/useNavigate";
import useUser from "hooks/useUser";
import Form from "components/Form";

import DoctorProfileStep1 from "./DoctorProfileStep1";
import DoctorProfileStep2 from "./DoctorProfileStep2";

const DoctorProfile = () => {
  const [step, setStep] = useState(1); // 1단계, 2단계에 나누어 정보 입력
  const { goMyinfo } = useNavigate();
  const { userParamForUpdate: defaultParams } = useUser();
  const [checkedItems, setCheckedItems] = useState([]);
  const form = useForm();
  const {
    handleSubmit,
    setValue,
    formState: { dirtyFields },
  } = form;
  const getItems = (data) => {
    setCheckedItems(data);
  };

  const onSubmit = async (params) => {
    var check = true;
    var num = 0;
    Object.entries(params.counsel_kind).filter((entries) => {
      if (entries[1] !== 0 && entries[1] !== "0" && entries[1] !== "") {
        num++;
      }
    });
    if (num !== checkedItems.length) {
      check = false;
    }
    Object.entries(params.counsel_kind).map((entries) => {
      if (entries[1] === 0 || entries[1] === "0" || entries[1] === "") {
        if (checkedItems.includes(entries[0])) {
          check = false;
        }
      }
      if (entries[1] !== 0 && entries[1] !== "0" && entries[1] !== "") {
        if (!checkedItems.includes(entries[0])) {
          check = false;
        }
      }
    });
    try {
      if (step === 1) {
        if (
          params.img === null ||
          params.img === undefined ||
          params.img === ""
        ) {
          alert("이미지를 등록해주세요.");
          return;
        }
        if (checkedItems.length === 0) {
          return false;
        }
        if (params.counsel_ids.length > 3) {
          alert("대표 상담 과목 최대 3개만 선택해주세요.");
          return false;
        }
        // Object.entries(params.counsel_kind).map((entries, idx) => {
        //   checkedItems.filter((el) => {
        //     if (Object.values(entries)[0] === el) {
        //       if (!Object.values(entries)[1]) {
        //         return (check = false);
        //       }
        //     }
        //   });
        // });
        if (!check) {
          alert("상담 방식과 상담권 가격을 선택하세요.");
          return false;
        }

        setStep(2);
        window.scrollTo(0, 0);
      } else {
        const editedKeys = Object.keys(dirtyFields);
        const editedParams = Object.fromEntries(
          editedKeys.map((key) => [key, params[key]])
        );

        if (editedKeys.includes("img") || editedKeys.includes("attachment")) {
          await client.put(
            "/doctor",
            {
              img: editedParams.hasOwnProperty("img")
                ? editedParams.img[0]
                : undefined,
              attachment: editedParams.hasOwnProperty("attachment")
                ? editedParams.attachment[0]
                : undefined,
            },
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
        }
        await client.put("/doctor", editedParams);
        goMyinfo();
      }
    } catch (e) {
      alert("에러가 발생하였습니다.");
      // setStep(2);
    }
  };

  useEffect(() => {
    if (!defaultParams) return;
    if (Object.keys(defaultParams).length === 0) return;

    setValue("email", defaultParams.email);
    setValue("name", defaultParams.name);
    setValue("phone", defaultParams.phone);
    setValue(
      "img",
      defaultParams.img.includes("missing") ? null : defaultParams.img
    );
    setValue("attachment", defaultParams.attachment);

    setValue(
      "counsel_ids",
      defaultParams.counsels?.map((c) => String(c.id))
    );
    setValue("introduction", defaultParams.introduction);
    setValue("experience", defaultParams.experience);
    setValue("counsel_kind", defaultParams.counsel_kind);
    setValue("counsel_features", defaultParams.counsel_features);
    setValue("doctor_stories", defaultParams.doctor_stories);
    setValue("account_info", defaultParams.account_info);
    setValue("organization_info", defaultParams.organization_info);
  }, [defaultParams, setValue]);
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FieldSection>
        {step === 1 ? (
          <DoctorProfileStep1
            getItems={getItems}
            {...form}
            defaultParams={defaultParams}
          />
        ) : (
          <DoctorProfileStep2 {...form} />
        )}
      </FieldSection>
      {form.errors && (
        <Form.Error>일부 항목 입력이 유효하지 않습니다.</Form.Error>
      )}
      <Form.SubmitRow style={{ background: "#fff", marginBottom: "24px" }}>
        <Form.SubmitButton type="submit">
          {step === 1 ? "다음으로" : "수정 완료"}
        </Form.SubmitButton>
      </Form.SubmitRow>
    </Form>
  );
};
export default DoctorProfile;

const FieldSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #f8f8f8 0% 0% no-repeat padding-box;
`;
export const Section = styled.section`
  background: #ffffff;
  padding: 90px 15px 40px 15px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  border-bottom: 1px solid #e6e6e6;

  & + & {
    border-top: 1px solid #e6e6e6;
  }

  & > .title {
    font: normal normal 600 18px/21px Pretendard;
    letter-spacing: -0.27px;
    color: #101010;
  }

  &:last-child {
    border-bottom: none;
  }
`;
export const Message = styled.div`
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border-radius: 10px;
  font: normal normal 500 11px/15px Pretendard;
  letter-spacing: -0.12px;
  color: #6e6e6e;
  padding: 15px 10px;
`;
