import { useEffect, useState } from "react";

import client from "apis/client";

// TODO: 페이지네이션 구현 방법 확인 할 것.
const PER_PAGE = 100;
const useReviews = (doctorId) => {
  // const [page, setPage] = useState(1);
  const page = 1;
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    (async () => {
      const {
        data: {
          results,
          // page: { current, pages, total },
        },
      } = await client.get(
        `/user/reviews?page=${page}&per=${PER_PAGE}${
          doctorId !== undefined ? `&doctor_id=${doctorId}` : ""
        }`
      );
      setReviews(results);
    })();
  }, [doctorId, page]);

  return reviews;
};
export default useReviews;
