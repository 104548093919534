import { useCallback, useEffect, useState, useMemo, useRef } from "react";
import styled from "styled-components";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";

import "swiper/css";
import "swiper/css/pagination";

import client from "apis/client";
import { checkRNWindowScreenSupport } from "utils/checkMobile";

const Banner = () => {
  const [banners, setBanners] = useState([]);
  const isAppWindowScreenSupport = useMemo(
    () => checkRNWindowScreenSupport(),
    []
  );
  const swiperRef = useRef();
  const fetch = useCallback(async () => {
    const {
      data: { banners },
    } = await client.get(`/banners`);
    setBanners(banners);
  }, []);

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    if (banners.length > 1) {
      swiperRef.current.swiper.autoplay.start();
    } else {
      swiperRef.current.swiper.autoplay.stop();
    }
  }, [banners]);

  const openBanner = (bannerUrl) => {
    const isMinddrawingUrl =
      bannerUrl.includes("minddrawing.kr") ||
      bannerUrl.includes("minddrawing.fadoc.kr");
    if (isAppWindowScreenSupport) {
      if (isMinddrawingUrl) {
        window.location.href = bannerUrl;
      } else {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: "OPEN_SCREEN",
            params: {
              url: bannerUrl,
            },
          })
        );
      }
      return;
    }

    window.open(
      bannerUrl.startsWith("http") ? bannerUrl : "https://" + bannerUrl,
      "_blank"
    );
  };

  return (
    <Wrapper className="Banner">
      <Swiper
        ref={swiperRef}
        spaceBetween={16}
        slidesPerView={1}
        pagination={true}
        modules={[Autoplay, Pagination]}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
      >
        {banners.map((banner) => (
          <SwiperSlide key={banner.id}>
            <ImageBanner
              $img={banner.img}
              onClick={() => openBanner(banner.url)}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Wrapper>
  );
};
export default Banner;

const Wrapper = styled.section`
  height: 188px;
  border-radius: 15px;
`;
const ImageBanner = styled.div`
  height: 188px;
  background-image: url(${(p) => p.$img});
  background-size: 100% 100%;
  border-radius: 15px;
`;
