import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";

import client from "apis/client";
import { requestPhoneCode } from "apis/auth";
import Form from "components/Form";
import useUser from "hooks/useUser";
import FailPartnerCheck from "components/Popups/FailPartnerCheck";
import useNavigate from "hooks/useNavigate";

const Partner = () => {
  const { goMyPartnerSuccess } = useNavigate();
  const [showFail, setShowFail] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    setError,
    setValue,
    formState: { dirtyFields, errors },
  } = useForm();
  const { userParamForUpdate: defaultParams } = useUser();
  const { phone, code } = watch();

  const requestCode = async () => {
    if (!phone) return;
    await requestPhoneCode(phone);
  };

  const onSubmit = async (params) => {
    try {
      const {
        data: { name, nickname },
      } = await client.post("/user/validate_partner_organization", params);
      goMyPartnerSuccess(name, nickname);
    } catch (e) {
      setShowFail(true);
    }
  };

  useEffect(() => {
    if (!defaultParams) return;

    setValue("email", defaultParams.email);
    setValue("phone", defaultParams.phone);
  }, [defaultParams, setValue]);

  return (
    <Wrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {/* <Form.InputField
          label="이메일"
          type="email"
          placeholder="example@domain.com"
          disabled={true}
          {...register("email", {
            //   required: { value: true, message: EMAIL_ERROR },
            //   pattern: {
            //     value: EMAIL_PATTERN,
            //     message: EMAIL_ERROR,
            //   },
          })}
          // error={errors?.email?.message}
        /> */}

        <Form.Row>
          <Form.InputField
            label="휴대폰 번호"
            type="tel"
            placeholder="-없이 번호 입력"
            disabled={true}
            {...register("phone", {
              //   required: { value: true, message: PHONE_ERROR },
              //   pattern: {
              //     value: PHONE_PATTERN,
              //     message: PHONE_ERROR,
              //   },
              //   minLength: {
              //     value: 11,
              //     message: PHONE_ERROR,
              //   },
              //   maxLength: {
              //     value: 11,
              //     message: PHONE_ERROR,
              //   },
            })}
            // error={errors?.phone?.message}
          />
          <Form.Button onClick={requestCode}>인증</Form.Button>
        </Form.Row>
        <Form.Row>
          <Form.InputField
            label="인증번호 입력"
            placeholder="인증번호 입력"
            {...register("code", {
              required: true,
            })}
            error={errors?.code?.message}
          />
          <Form.Button>확인</Form.Button>
        </Form.Row>

        <Form.SubmitRow>
          <Form.SubmitButton disabled={!code} type="submit">
            인증완료
          </Form.SubmitButton>
        </Form.SubmitRow>
      </Form>

      {showFail && <FailPartnerCheck onClose={() => setShowFail(false)} />}
    </Wrapper>
  );
};
export default Partner;

const Wrapper = styled.section`
  padding: 0 15px;
  padding-top: 35px;

  ${Form.SubmitRow} {
    width: calc(100% - 30px);
    position: absolute;
    bottom: 52px;
  }
`;
