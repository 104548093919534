import { useCallback, useEffect, useState } from "react";

import { fetchDoctors } from "apis/doctor";

const useDoctors = (counselId, counselKind, order, query) => {
  const [isFetching, setIsFetching] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [doctors, setDoctors] = useState([]);
  const [page, setPage] = useState({ page: 1 });
  const [pageCount, setPageCount] = useState(1);
  const [itemCount] = useState(0);
  const setNextPage = () => setPage({ page: page.page + 1 });
  useEffect(() => {
    doctors.length = 0;
    setPage({ page: 1 });
    setPageCount(page.page);
  }, [order, counselKind, counselId, query]);

  const fetchByPage = useCallback(async () => {
    setIsFetching(true);
    try {
      const {
        data: {
          doctors,
          page: { pages, total },
        },
      } = await fetchDoctors(page.page, counselId, counselKind, order, query);
      setDoctors((prev) => [...prev, ...doctors]);
      setPageCount(pages);
    } finally {
      setIsFetching(false);
    }
  }, [page]);

  useEffect(() => {
    setHasNextPage(pageCount > page.page);
  }, [pageCount, page]);

  useEffect(() => {
    (async () => {
      if (isFetching) {
        return;
      }
      await fetchByPage();
    })();
  }, [fetchByPage]);

  return {
    isFetching,
    doctors,
    pageCount,
    itemCount,
    hasNextPage,
    setNextPage,
  };
};
export default useDoctors;
