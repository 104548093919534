import { useEffect } from "react";
import { useSearchParams, useNavigate as _useNavigate } from "react-router-dom";
import useAuth from "hooks/useAuth";

import ClientLogin from "./ClientLogin";
import DoctorLogin from "./DoctorLogin";

const Login = () => {
  const { userType } = useAuth();
  const navigate = _useNavigate();
  const [params] = useSearchParams();
  const signinType = params.get("userType") || 'client';

  useEffect(() => {
    if (userType) {
      navigate(-2);
    }
  }, [])

  if (signinType !== "client") {
    return <DoctorLogin />;
  }
  return <ClientLogin />;
};

export default Login;
