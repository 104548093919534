import styled from "styled-components";

import useCreditCards from "hooks/useCreditCards";
import useNavigate from "hooks/useNavigate";
import { ReactComponent as ArrowSVG } from "assets/svg/chevron-right.svg";
import { ReactComponent as PlusSVG } from "assets/svg/plus.svg";

const MyPayment = () => {
  const { creditCards } = useCreditCards();
  const { goMyPayment, goRegistPayment } = useNavigate();
  return (
    <Wrapper>
      <h2>결제 수단</h2>
      {creditCards.length !== 0 ? (
        creditCards.map((card) => (
          <div
            style={{ marginBottom: "12pt" }}
            onClick={creditCards.length === 0 ? goRegistPayment : goMyPayment}
          >
            <Card key={card.id}>
              <label>{card.card_name}</label>
              <span>{card.card_number}</span>
            </Card>
            <ArrowSVG style={{ float: "right" }} />
          </div>
        ))
      ) : (
        <div
          style={{
            border: "solid 1px #E6E6E6",
            backgroundColor: "#FFFFF",
            padding: "13.5pt",
            borderRadius: "5pt",
          }}
          onClick={creditCards.length === 0 ? goRegistPayment : goMyPayment}
        >
          <PlusSVG style={{ verticalAlign: "middle" }} />
          <CardPlus>카드 추가하기</CardPlus>
        </div>
      )}
    </Wrapper>
  );
};
export default MyPayment;

const Wrapper = styled.section`
  background: #ffffff;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 36px;
  padding-bottom: 24px;

  & > h2 {
    font: normal normal 600 18px/21px Pretendard;
    letter-spacing: -0.27px;
    color: #101010;
    margin-bottom: 24px;
  }
`;
const Card = styled.div`
  display: inline-block;
  font: normal normal 500 16px/19px Pretendard;
  letter-spacing: -0.16px;
  & > label {
    color: #101010;
    padding-right: 20px;
  }
  & > span {
    color: #999999;
  }

  & + & {
    margin-top: 16px;
  }
`;

const CardPlus = styled.div`
  color: #101010;
  display: inline-block;
  margin-left: 8px;
  font: normal normal 500 15px/19px Pretendard;
`;
