import styled from "styled-components";

import { getLabelDescription } from "utils/message";
import RefundInfo from "./RefundInfo";

const CreditPayment = ({ partner, counselKind, creditCount, price }) => {
  const { label } = getLabelDescription(counselKind);

  return (
    <Wrapper>
      <Section>
        <CreditSection>
          <div className="row">
            <label>제휴 기관</label>
            <span>{partner}</span>
          </div>
          <div className="row">
            <label>잔여 상담 횟수</label>
            <span>{creditCount}회</span>
          </div>
        </CreditSection>

        <hr />
        <CreditTitle>상담권 상세 정보</CreditTitle>
        <Payment>
          <div>
            <span className="type">{label}</span>
            <span>• 50분 x 1회</span>
          </div>
          <p>
            {label === "텍스트 상담"
              ? "채팅을 통한 미술 심리 상담을 진행합니다."
              : label === "화상 상담"
              ? "화상으로 얼굴을 마주보며 그림을 통한 미술 심리 상담이 진행됩니다."
              : label === "대면 상담"
              ? "별도의 공간에 직접 방문하여 미술 심리 상담을 진행합니다."
              : "전화로 상호간 목소리를 들으며 그림을 통한 미술 심리 상담이 진행됩니다."}
          </p>

          <hr />
          <div className="price-row">
            <label>총 결제 금액</label>
            <span>{price.toLocaleString()} 원</span>
          </div>
        </Payment>
      </Section>

      <RefundInfo />
    </Wrapper>
  );
};
export default CreditPayment;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;
const Section = styled.div`
  padding: 0 15px 20px 15px;

  hr {
    border: 0;
    border-top: 1px solid #e6e6e6;
    margin: 30px 0;
  }
`;
const CreditSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 19px;
  .row {
    display: flex;
    justify-content: space-between;
  }
  label {
    font: normal normal 500 15px/18px Pretendard;
    letter-spacing: -0.3px;
    color: #999999;
  }
`;
const CreditTitle = styled.div`
  width: 100%;
  font: normal normal 600 17px/20px Pretendard;
  letter-spacing: -0.26px;
  color: #6e6e6e;
  margin-bottom: 16px;
`;
const Payment = styled.section`
  width: 100%;
  padding: 24px;
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 15px;
  font: normal normal 400 14px/17px Pretendard;
  letter-spacing: -0.28px;
  color: #6e6e6e;

  .type {
    font: normal normal 600 20px/24px Pretendard;
    letter-spacing: -0.2px;
    color: #101010;
    margin-right: 12px;
  }
  & > p {
    margin-top: 13px;
    color: #6e6e6e;
  }
  & > hr {
    border: 0;
    border-top: 1px dashed #e6e6e6;
    margin: 24px 0 26px 0;
  }
  & > .price-row {
    display: flex;
    justify-content: space-between;
    font: normal normal bold 20px/24px Pretendard;
    letter-spacing: -0.2px;
    color: #1a1a1a;

    & > label {
      font: normal normal 500 16px/24px Pretendard;
      letter-spacing: -0.16px;
      color: #101010;
    }
  }
`;
