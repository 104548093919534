import styled from "styled-components";

import Popup, { ActionButton } from ".";

const DeletePaymentMethod = ({ onConfirm, onClose }) => {
  return (
    <Popup>
      <Wrapper>
        <label>결제 수단 삭제</label>
        <div>
          확인을 클릭하면
          <br /> 등록하신 카드 정보가 삭제됩니다.
        </div>
        <ActionButton onClick={onConfirm}>확인</ActionButton>
        <button
          className="cancel"
          style={{
            width: "271px",
            height: "48px",
            background: "#E6E6E6",
            borderRadius: "10px",
            color: "#6E6E6E",
            font: "normal normal 400 16px/19px Pretendard",
          }}
          onClick={onClose}
        >
          취소
        </button>
      </Wrapper>
    </Popup>
  );
};
export default DeletePaymentMethod;

const Wrapper = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 24px;

  & > label {
    font: normal normal bold 25px/30px Pretendard;
    letter-spacing: -0.25px;
    color: #101010;
  }

  & > div {
    text-align: center;
    font: normal normal 400 15px/25px Pretendard;
    letter-spacing: -0.23px;
    color: #404040;
    margin-top: 24px;
    margin-bottom: 48px;
  }

  & > button.cancel {
    background-color: unset;
    font: normal normal 500 16px/19px Pretendard;
    letter-spacing: -0.16px;
    color: #333333;
    margin-top: 10px;
  }
`;
